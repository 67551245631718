import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    container: 'onBoarding'
}


const sidebarTabSlice = createSlice({
    name: 'tabs',
    initialState: initialState,
    reducers: {
        changeTab(state, action) {
            state.container = action.payload;
        }
    }
});

export const {changeTab} = sidebarTabSlice.actions;

// console.log(changeTab('onBoarding'));

export default sidebarTabSlice.reducer;