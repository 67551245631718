import React, { useState } from 'react'
import VisibilityTargets from './VisibilityTargets';
import VisibilityCampaigns from './VisibilityCampaigns';

const VisibilityMain = () => {
    const [tabs, setTabs] = useState('visibilityTargets');
    const handleTabs = (tab) => {
        setTabs(tab);
    }
    return (
        <div className=''>
            <div className='tabs_box'>
                <button className={tabs === 'visibilityTargets' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}
                    onClick={() => handleTabs('visibilityTargets')}>Visibility Targets</button>
                <button className={tabs === 'visibilityCampaigns' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}
                    onClick={() => handleTabs('visibilityCampaigns')}>Visibility Campaigns</button>
            </div>
            <div>
                {tabs === 'visibilityTargets' && <VisibilityTargets />}
                {tabs === 'visibilityCampaigns' && <VisibilityCampaigns />}
            </div>
        </div>
    )
}

export default VisibilityMain