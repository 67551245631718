import React from 'react';
import './BidGraph.css';
import axios from "axios";
import { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import { BASE_URL } from '../../../../../appConstants';
import { getAppStandardDate } from '../../../../../utils/commonFunction';
import Loader from '../../../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../../../errorComponent/ReportsErrorPage';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarGraph = (props) => {
    const { graphDataType, dateFilter, monthYearArray } = props;
    const token = localStorage.getItem("token");
    const appParams = useSelector(state => state.appParams);
    const { current_brand, current_agency } = appParams;
    const [state, setState] = useState({
        labels: [],
        keywords_paused: [],
        products_paused: [],
        keywords_added: [],
        product_targets_added: [],
        negative_keywords_added: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // const { start_date, end_date } = dateFilter;
        // //console.log("dateFilter", dateFilter)
        const start_date = getAppStandardDate(dateFilter.startDate);
        const end_date = getAppStandardDate(dateFilter.endDate);

        let body = {
            graph_data_type: graphDataType,
        }
        if (graphDataType === "daily") {
            body["start_date"] = start_date
            body["end_date"] = end_date
        } else {
            let m_y_array = []
            for (let el of monthYearArray) {
                if (el.selected) {
                    m_y_array.push(el.month_year);
                }
            }
            body["month_year_array"] = m_y_array
            if (!m_y_array.length) {
                return;
            }
        }


        setLoading(true);
        axios.post(`${BASE_URL}bidChanges/getBidChangesGraph?brandId=${current_brand._id}`, body, {
            headers: {
                token
            }
        }).then(function (response) {
            const { graph_data_array, graph_data_type } = response.data.data;
            if (response.status === 200 && graph_data_array.length === 0) {
                throw Error(`no data available for graph in this brand`);
            }
            console.log(graph_data_array);
            setError(false);
            let labelsArray = [];
            let keywordsPausedArray = [];
            let productsPausedArray = [];
            let keywordsAddedArray = [];
            let productTargetsAddedArray = [];
            let negativeKeywordsAddedArray = []
            if (graph_data_array.length > 0) {
                graph_data_array.forEach(e => {
                    // console.log(e);
                    const { time_stamp,
                        keywords_paused,
                        products_paused,
                        keywords_added,
                        product_targets_added,
                        negative_keywords_added
                    } = e;
                    if (graph_data_type === "daily") {
                        labelsArray.push((time_stamp.split("T")[0]));
                    } else {
                        labelsArray.push(time_stamp)
                    }
                    keywordsPausedArray.push(keywords_paused);
                    productsPausedArray.push(products_paused);
                    keywordsAddedArray.push(keywords_added);
                    productTargetsAddedArray.push(product_targets_added);
                    negativeKeywordsAddedArray.push(negative_keywords_added);
                })
            }
            setState(prevState => ({
                ...prevState,
                labels: labelsArray,
                keywords_paused: keywordsPausedArray,
                products_paused: productsPausedArray,
                keywords_added: keywordsAddedArray,
                product_targets_added: productTargetsAddedArray,
                negative_keywords_added: negativeKeywordsAddedArray
            }))
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
            setError(error.message);
        });
    }, [graphDataType, dateFilter, current_brand._id, monthYearArray])

    console.log(state);

    const data = {
        labels: state.labels,
        datasets: [
            {
                label: 'Keywords Paused',
                data: state.keywords_paused,
                backgroundColor: 'rgba(230, 57, 71,0.7)',
            },
            {
                label: 'Products Paused',
                data: state.products_paused,
                backgroundColor: 'rgba(237, 174, 73,0.7)',
            },
            {
                label: 'Keywords Added',
                data: state.keywords_added,
                backgroundColor: 'rgba(51, 118, 189,0.7)',
            },
            {
                label: 'Product Targets Added',
                data: state.product_targets_added,
                backgroundColor: 'rgba(0, 121, 140,0.7)',
            },
            {
                label: 'Negative Keywords Added',
                data: state.negative_keywords_added,
                backgroundColor: 'rgba(159, 179, 29,0.7)',
            },
        ],
    };
    return <div style={{ position: "relative" }}>
        {
            error ? <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                <Chart
                    type='bar'
                    data={data}
                    height={300}
                    options={{
                        plugins: {
                            // title: {
                            //     display: true,
                            //     text: 'Bid',
                            // },
                            legend: {
                                position: 'bottom',
                                display: true,
                            },
                            tooltip: {
                                backgroundColor: 'rgba(255, 255, 255,1)',
                                bodyColor: 'rgba(0, 0, 0 , 0.8)',
                                titleColor: 'rgba(0, 0, 0 , 0.6)',
                                titleFont: {
                                    size: 14,
                                    weight: 'bold'
                                },
                                titleMarginBottom: 6,
                                padding: 10,
                                bodySpacing: 8,
                                bodyFont: {
                                    weight: "bold",
                                    size: 14
                                },
                                borderColor: "rgba(189, 189, 189, 1)",
                                borderWidth: 1,
                                // boxWidth: 40,
                                boxPadding: 10,
                                bodyAlign: 'right',
                            }
                        },
                        // responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                            mode: 'index',
                            intersect: false,
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                }
                            },
                            y: {
                                grid: {
                                    display: true,

                                },
                                ticks: {
                                    maxTicksLimit: 5,
                                }
                            }
                        }
                    }}
                />
        }
        {
            loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(40%)" }} > < Loader /></div>
        }
    </div>
}

export default BarGraph;