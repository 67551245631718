import React, {
    forwardRef,
    memo,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const KEY_ARROW_LEFT = 'ArrowLeft';
const KEY_ARROW_RIGHT = 'ArrowRight';

export default memo(
    forwardRef((props, ref) => {
        const createInitialState = () => {
            let startValue;
            let highlightAllOnFocus = true;
            const eventKey = props.eventKey;

            if (eventKey === KEY_BACKSPACE) {
                // if backspace or delete pressed, we clear the cell
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                // if a letter was pressed, we start with the letter
                startValue = eventKey;
                highlightAllOnFocus = false;
            } else {
                // otherwise we start with the current value
                startValue = props.value;
                if (eventKey === KEY_F2) {
                    highlightAllOnFocus = false;
                }
            }

            return {
                value: startValue,
                highlightAllOnFocus,
            };
        };

        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
            initialState.highlightAllOnFocus
        );
        const refInput = useRef(null);

        useEffect(() => {
            const eInput = refInput.current;
            eInput.focus();
            if (highlightAllOnFocus) {
                eInput.select();

                setHighlightAllOnFocus(false);
            } else {
                const length = eInput.value ? eInput.value.length : 0;
                if (length > 0) {
                    eInput.setSelectionRange(length, length);
                }
            }
        }, []);

        const isCharacter = props.eventKey && props.eventKey.length === 1;
        const cancelBeforeStart =
            isCharacter && '1234567890'.indexOf(props.eventKey) < 0;

        const isLeftOrRight = (event) => {
            return [KEY_ARROW_LEFT, KEY_ARROW_RIGHT].indexOf(event.key) > -1;
        };

        const isCharNumeric = (charStr) => {
            return !!/\d/.test(charStr);
        };

        function isCharDecimal(charStr) {
            return '.'.indexOf(charStr) === 0;
        }

        const isNumericKey = (event) => {
            const charStr = event.key;
            // console.log(charStr);
            return isCharNumeric(charStr) || isCharDecimal(charStr);
        };

        const isBackspace = (event) => {
            return event.key === KEY_BACKSPACE;
        };

        const finishedEditingPressed = (event) => {
            const key = event.key;
            return key === KEY_ENTER || key === KEY_TAB;
        };

        const onKeyDown = (event) => {
            if (isLeftOrRight(event) || isBackspace(event)) {
                event.stopPropagation();
                return;
            }

            if (!finishedEditingPressed(event) && !isNumericKey(event)) {
                if (event.preventDefault) event.preventDefault();
            }

            if (finishedEditingPressed(event)) {
                props.stopEditing();
            }
        };

        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing 
                getValue() {
                    return value === '' || value == null ? null : value;
                },
                isCancelBeforeStart() {
                    return cancelBeforeStart;
                },
                isCancelAfterEnd() {
                    const finalValue = this.getValue();
                    // console.log(finalValue);
                    return finalValue != null && finalValue > 100000000000
                },
            };
        });

        return (
            <input style={{ border: 'none', outline: 'none' }}
                ref={refInput}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                onKeyDown={(event) => onKeyDown(event)}
                className="numeric-input"
            />
        );
    })
);