import { useNavigate, Outlet } from "react-router-dom";
import "./Home.css";
import Body from "../headerContentFooterComponent/Body";
import Header from "../headerContentFooterComponent/Header";
import Content from "../headerContentFooterComponent/Content";
import RightHeaderElement from "../headerContentFooterComponent/RightHeaderElement";
import LeftHeaderElement from "../headerContentFooterComponent/LeftHeaderElement";
import enlyticalLogo2 from '../../enlyticalLogo2.png';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAppParamsData } from "../../redux/appParams/appParamsActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { HEADER } from "../../appUiConstants";
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import axios from "axios";
import { BASE_URL } from "../../appConstants";


const Home = () => {
  const popupRef = useRef(null);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const appParams = useSelector((state) => state.appParams);
  const { current_brand, brand_array, agency_array, current_agency } = appParams;
  const tabs = useSelector(state => state.tabs);
  const { container } = tabs;
  const user = useSelector((state) => state.user);
  const { user_domain } = user;

  const [toggle, setToggle] = useState(false);
  const [toggleAgency, setToggleAgency] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryAgency, setSearchQueryAgency] = useState('');
  const [brands, setBrands] = useState([]);
  const [anchorEl, setAnchorEl] = useState("");
  const open = Boolean(anchorEl);
  const [agency, setAgency] = useState([]);
  const [currentAgency, setCurrentAgency] = useState('');
  const [currentBrand, setCurrentBrand] = useState("");

  const token = localStorage.getItem('token');

  // console.log(agency_array);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl("");
  };

  useEffect(() => {
    setCurrentAgency(current_agency._id);
    setAgency(agency_array);
    setBrands(brand_array);
    setCurrentBrand(current_brand);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgency]);

  const handleToggle = () => {
    setToggle(prevState => !prevState);
    setSearchQuery("");
  }

  const handleToggleAgency = () => {
    setToggleAgency(prevState => !prevState);
    setSearchQueryAgency("");
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setToggle(false);
        setToggleAgency(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  //To get the height for grid
  const [containerHeight, setContainerHeight] = useState();
  useEffect(() => {
    const height = window.innerHeight;
    const netHeight = height - HEADER.height;
    setContainerHeight(netHeight + 10);
  }, []);
  window.addEventListener("resize", () => {
    const height = window.innerHeight;
    const netHeight = height - HEADER.height;
    setContainerHeight(netHeight + 10);
  });
  //

  const agencyChangeHandler = (agency) => {
    // console.log(agency._id);
    dispatch(saveAppParamsData({ current_agency: agency }));
    setCurrentAgency(agency._id);
    setToggleAgency(false);

    axios.get(`${BASE_URL}brand?agencyId=${current_agency._id}`, {
      headers: {
        token
      }
    }).then(res => {
      const { brand_array } = res.data.data;
      // console.log(brand_array);
      setBrands(brand_array);
      setCurrentBrand(brand_array[0]);
      dispatch(saveAppParamsData({ current_brand: brand_array[0] }))
    }).catch(err => {
      console.log(err);
    })
  }

  const handleBrandChange = (brand) => {
    localStorage.setItem("currentBrand", brand._id);
    setCurrentBrand(brand);
    dispatch(saveAppParamsData({ current_brand: brand }));
    setToggle(false);
  }

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleInputChangeAgency = (e) => {
    setSearchQueryAgency(e.target.value);
  }
  const searchResults = brands.filter((el) => el.client_name.toLowerCase().includes(searchQuery.toLowerCase()));
  const searchResultsAgency = agency_array.filter((el) => el.name.toLowerCase().includes(searchQueryAgency.toLowerCase()));

  // const handleAgencyChange = (e) => {
  //   const current_agency = agency.find(agency => agency._id === e.target.value);
  //   dispatch(saveAppParamsData({ current_agency: current_agency }));
  //   setCurrentAgency(current_agency._id);
  //   axios.get(`${BASE_URL}brand?agencyId=${current_agency._id}`, {
  //     headers: {
  //       token
  //     }
  //   }).then(res => {
  //     const { brand_array } = res.data.data;
  //     // console.log(brand_array);
  //     setBrands(brand_array);
  //     setCurrentBrand(brand_array[0]);
  //     dispatch(saveAppParamsData({ current_brand: brand_array[0] }))
  //   }).catch(err => {
  //     console.log(err);
  //   })
  // }

  return (
    <Body className="noAuthBody">
      <Header className="authHeader">

        <LeftHeaderElement className="leftHeaderElement">
          <div style={{ marginLeft: "10px" }}>
            {/* <img height={"40px"} src={EnlyticalLogo} /> */}
            <img height={"40px"} src={enlyticalLogo2} />
          </div>
        </LeftHeaderElement>

        <RightHeaderElement className="authHeaderRight">
          <div>
            {
              !(container === 'settings') &&
              <div className="selectAgencyandBrand" ref={popupRef}>
                <div>
                  {
                    user_domain === 'enlytical_admin' &&
                    <div className="agency_select_container">
                      <div className="currentBrandName" onClick={handleToggleAgency}>{current_agency.name} <span><i className={toggleAgency ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i></span></div>
                      <div className="agencyOption" style={{ display: toggleAgency ? 'block' : 'none' }}>
                        <div className="searchInputContainer">
                          <div className="brandSearchInputContainer">
                            <i className="bi bi-search px-1"></i>
                            <input className="brandSearchInput" type="text" value={searchQueryAgency} onChange={handleInputChangeAgency} placeholder="search" />
                          </div>
                        </div>
                        <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                        <div className="brandListContainer">
                          {agency_array.length > 0 &&
                            searchResultsAgency.map((el, i) => {
                              return (
                                <div
                                  className={`brandList-${el._id === currentAgency ? 'active' : ""}`}
                                  key={i}
                                  onClick={() => agencyChangeHandler(el)}
                                >{el.name}
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div>
                  <div className="currentBrandName" onClick={handleToggle}>{currentBrand?.client_name?.charAt(0).toUpperCase() + currentBrand?.client_name?.slice(1)} <span><i className={toggle ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i></span></div>

                  <div className="brandOption" style={{ display: toggle ? 'block' : 'none' }}>
                    <div className="selectBrandDetails1">
                      <h5>{currentBrand?.client_name?.charAt(0).toUpperCase() + currentBrand?.client_name?.slice(1)}</h5>
                      <div style={{ color: '#757575', fontSize: '14px' }}>ID: <span style={{ color: 'black' }}>{currentBrand?.entity_id}</span></div>
                      <div style={{ color: '#757575', fontSize: '14px' }}>Marketplace: <span style={{ color: 'black' }}>{currentBrand?.country}</span></div>
                    </div>
                    {
                      brands.length !== 1 &&
                      <div className="searchInputContainer">
                        <div className="brandSearchInputContainer">
                          <i className="bi bi-search px-1"></i>
                          <input className="brandSearchInput" type="text" value={searchQuery} onChange={handleInputChange} placeholder="search" />
                        </div>
                      </div>
                    }
                    <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                    <div className="brandListContainer">
                      {brands.length > 0 &&
                        searchResults.map((brand, i) => {
                          return (
                            <div
                              className={`brandList-${brand === currentBrand ? 'active' : ""}`}
                              key={i}
                              onClick={() => handleBrandChange(brand)}
                            >
                              {brand.client_name.charAt(0).toUpperCase() + brand.client_name.slice(1)}
                              <div style={{ fontSize: '11px' }}>{brand.country}</div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="userIcon"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              // display: "flex",
              cursor: "pointer",
            }}
          >
            <div
              onClick={handleClick}
            >
              <Avatar sx={{ height: 35, width: 35, color: "#6c757d" }} />
              {/* {user.first_name.split(" ")[0][0].toUpperCase()}</Avatar>// */}

            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <div className="menu-username">
                <div style={{ backgroundColor: '#d9dee4', padding: '5px', borderRadius: '5px', fontSize: '17px' }}>
                  Hi, {user ? (user.first_name?.charAt(0)?.toUpperCase() + user.first_name?.slice(1)) : 'Undefined'}
                  <div style={{ fontSize: '14px', color: '#007bff' }}>{user.email}</div>
                </div>
              </div>
              <hr style={{ margin: '5px 0' }} />
              {/* <MenuItem onClick={() => {
                navigate("/home/app");
                setAnchorEl("");
              }}><span><PermIdentityIcon /></span>
                <span>Your Clients</span></MenuItem>
              <hr style={{ margin: '5px 0' }} /> */}
              <MenuItem onClick={() => {
                localStorage.removeItem("token");
                navigate("/user/login");
              }}>
                <span><LogoutIcon /></span>
                <span>
                  Logout
                </span>
              </MenuItem>
            </Menu>
          </div>
        </RightHeaderElement>
      </Header>
      <Content style={{ height: containerHeight }} className="authContent">
        <Outlet />
      </Content>
    </Body >
  );
};

export default Home;
