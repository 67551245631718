import React, { useMemo, useState, useCallback, useRef } from 'react';
import { AgGridReact } from "ag-grid-react";
import { useSelector } from 'react-redux';
import { debounce } from '../../../../commonFunction/commomFunction';

import BudgetUpload from './subComponents/BudgetUpload';
import NumericEditor from '../../../agGridCommonComponent/NumericEditor';
import { NotificationManager } from 'react-notifications';


function MonthlyBudgetProjection({ onMonthChange, setUpload, handleCancel, handleSubmit, range, rowData, setRowData, types, setType, selectedDate }) {
    const gridRef = useRef();
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { user_type } } = appParams;
    const defaultColDef = useMemo(() => ({ sortable: true, flex: 1, resizable: true, filter: true }), []);

    const onCellValueChanged = (event) => {
        const { colDef, newValue, data } = event;
        // console.log(colDef.field, newValue, data);
        // console.log(parseFloat(newValue));
        let val;
        if (colDef.field === 'spend' || colDef.field === 'acos') {
            val = parseFloat(newValue);
        } else {
            val = newValue
        }

        const updatedData = { ...data, [colDef.field]: val };
        const spend = parseFloat(updatedData['spend']) || 0;
        const acos = parseFloat(updatedData['acos']) || 0;
        const sales = Number(acos) && Number(spend) ? Number(((spend / acos) * 100).toFixed(0)) : 0;
        updatedData['sales'] = sales;

        const updatedRowData = rowData.map(row =>
            row.category === updatedData.category ? updatedData : row
        );
        setRowData(updatedRowData);
    }

    const editFn = () => {
        if (user_type && user_type === 'brand_viewer') {
            return false;
        }
        if (selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            return false
        }
        return true;
    }

    const HEADER_DISPLAY = {
        AD_Type: "AD Type",
        Keyword_Type: 'Keyword Type',
        Target_Spend: "Target Spend",
        Target_ACOS: 'Target ACOS',
        Target_Sales: 'Target Sales',
    }

    const columnDefs = useMemo(() => {
        if (types === "CATEGORY") {
            return ([
                { headerName: 'Category', field: 'category', width: 320, },
                {
                    headerName: HEADER_DISPLAY['Target_Spend'], field: 'spend', width: 280,
                    editable: editFn,
                    cellEditor: NumericEditor,
                },
                {
                    headerName: HEADER_DISPLAY['Target_ACOS'], field: 'acos', width: 280,
                    editable: editFn,
                    cellEditor: NumericEditor,
                },
                { headerName: HEADER_DISPLAY['Target_Sales'], field: 'sales', width: 280, },
                {
                    headerName: 'Objective', field: 'objective', width: 280,
                    editable: editFn,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['ROI', 'Visibility', 'Growth'],
                    },
                },
                // { headerName: 'region', field: 'region', width: 280, },
                {
                    headerName: 'Exclude', field: 'exclude', width: 280,
                    editable: editFn,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['No', 'Yes'],
                    },
                },
            ])
        } else if (types === "ADTYPE") {
            return ([
                { headerName: 'Category', field: 'category', width: 320, },
                { headerName: HEADER_DISPLAY['AD_Type'], field: 'ad_type', width: 320, },
                {
                    headerName: HEADER_DISPLAY['Target_Spend'], field: 'spend', width: 280,
                    editable: editFn,
                    cellEditor: NumericEditor,
                },
                {
                    headerName: HEADER_DISPLAY['Target_ACOS'], field: 'acos', width: 280,
                    editable: editFn,
                    cellEditor: NumericEditor,
                },
                { headerName: HEADER_DISPLAY['Target_Sales'], field: 'sales', width: 280, },
                {
                    headerName: 'Objective', field: 'objective', width: 280,
                    editable: editFn,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['ROI', 'Visibility', 'Growth'],
                    },
                },
                // { headerName: 'region', field: 'region', width: 280, },
                {
                    headerName: 'Exclude', field: 'exclude', width: 280,
                    editable: editFn,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['No', 'Yes'],
                    },
                },
            ])
        } else {
            return ([
                { headerName: 'Category', field: 'category', width: 320, },
                { headerName: HEADER_DISPLAY['AD_Type'], field: 'ad_type', width: 320, },
                { headerName: HEADER_DISPLAY['Keyword_Type'], field: 'keyword_type', width: 320, },
                {
                    headerName: HEADER_DISPLAY['Target_Spend'], field: 'spend', width: 280,
                    editable: editFn,
                    cellEditor: NumericEditor,
                },
                {
                    headerName: HEADER_DISPLAY['Target_ACOS'], field: 'acos', width: 280,
                    editable: editFn,
                    cellEditor: NumericEditor,
                },
                { headerName: HEADER_DISPLAY['Target_Sales'], field: 'sales', width: 280, },
                {
                    headerName: 'Objective', field: 'objective', width: 280,
                    editable: editFn,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['ROI', 'Visibility', 'Growth'],
                    },
                },
                // { headerName: 'region', field: 'region', width: 280, },
                {
                    headerName: 'Exclude', field: 'exclude', width: 280,
                    editable: editFn,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['No', 'Yes'],
                    },
                },
            ])
        }
    }, [types]);


    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({
            fileName: `MonthlyBudget-${types}-${appParams?.current_brand?.client_name}.csv`,
            processHeaderCallback: (params) => {
                // Skip exporting header for 'col3'
                if (params.column.getColId() === 'sales') {
                    return null;
                }
                return params.column.getColDef().headerName;
            },
            processCellCallback: (params) => {
                // Skip exporting data for 'col3'
                if (params.column.getColId() === 'sales') {
                    return null;
                }
                return params.value;
            },
        });
    }, [types])


    return (
        <div className='product_master_container'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <span style={{ color: '#b8b6b6', fontSize: "15px", paddingLeft: '18px' }}>Instructions:</span>
                    <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
                        <li>Please choose the .xlsx, .xls and .csv file. Please upload excel with exact headers and their position.</li>
                        <li>Please double click on any cell to edit in-line.</li>
                        <li>Please confirm with all the details, then submit the excel file.</li>
                        <li>Please do not put any symbol(ex. ₹, %) in excel file.</li>
                        <li>If your ACOS is 42%, please write 42 only don't write 0.42 .</li>
                    </ul>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <select className="form-select form-select-sm" value={types} onChange={(e) => setType(e.target.value)}  >
                            <option value={"CATEGORY"} >Category</option>
                            <option value={"ADTYPE"} > Ad Type</option>
                            <option value={"KEYWORD"} >Keyword Type</option>
                        </select>
                    </div>
                    <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
                        <BudgetUpload
                            rowData={rowData}
                            setRowData={setRowData}
                            // setUpload={setUpload}
                            types={types}
                            range={range}
                            selectedDate={selectedDate} />
                        <button style={{ maxHeight: "31px" }} type='button' className='submit_btn' onClick={onBtnExport}><i className="bi bi-download"></i> Download</button>
                    </div>
                </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: 500, margin: "10px 0 10px 0" }}>
                <AgGridReact
                    ref={gridRef}
                    // onRowClicked={onRowClicked}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowHeight={40}
                    headerHeight={50}
                    // rowSelection={'multiple'}
                    // rowMultiSelectWithClick={true}
                    suppressLoadingOverlay={true}
                    suppressNoRowsOverlay={true}
                    suppressMovableColumns={true}
                    suppressDragLeaveHidesColumns={true}
                    onCellValueChanged={onCellValueChanged}
                    suppressMenuHide={true}
                />
            </div>
            <div className='product_master_footer'>
                <button type='button' className={`${user_type && user_type === 'brand_viewer' ? 'cancel_btn disabled-button' : 'cancel_btn'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={debounce(handleCancel, 200)}>Cancel</button>
                <button type='button' className={`${user_type && user_type === 'brand_viewer' ? 'submit_btn disabled-button' : 'submit_btn'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={debounce(handleSubmit, 200)}>Submit</button>
            </div>
        </div>
    )
}

export default MonthlyBudgetProjection;