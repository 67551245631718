
import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import "./Dnd.css";
import { CATEGORY_TYPE_TO_DISPLY } from "../../appConstants";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};


function Dnd(props) {
  const { currentCategory } = props;
  const { category } = currentCategory;
  const [columns, setColumns] = useState();
  // //console.log(columns);
  const [inputField, setInputField] = useState("");
  const [inputVal, setInputVal] = useState("");


  useEffect(() => {
    const brand = currentCategory.brand.map((el) => {
      return {
        id: uuid(),
        content: el,
      };
    });
    const core = currentCategory.core.map((el) => {
      return {
        id: uuid(),
        content: el,
      };
    });

    const generic = currentCategory.generic.map((el) => {
      return {
        id: uuid(),
        content: el,
      };
    });

    const competitor = currentCategory.competitor.map((el) => {
      return {
        id: uuid(),
        content: el,
      };
    });

    const columnsFromBackend = {
      ["brand"]: {
        name: "Brand",
        items: brand,
      },
      ["core"]: {
        name: "Core",
        items: core,
      },
      ["generic"]: {
        name: "Generic",
        items: generic,
      },
      ["competitor"]: {
        name: "Competitor",
        items: competitor,
      },
      ["delete"]: {
        name: "Delete",
        items: [],
      },
    };
    setColumns(columnsFromBackend);
  }, [currentCategory]);

  useEffect(() => {
    if (columns && category) {
      props.onDrag({
        ...columns,
        category,
      });
    }
  }, [columns]);

  const addInputField = (e) => {
    setInputVal("");
    setInputField(e);
  }

  const removeInputField = (e) => {
    setInputField(e);
    setInputField(false);
    addInputField();
  }

  const inputChange = (e) => {
    setInputVal(e.target.value);
  }


  const addKeyword = (e) => {
    if (inputVal !== "") {
      props.addKeyWord(e, category, inputVal);
    }

    // Object.entries(columns).forEach(([el, i]) => {
    //   if (el === e && inputVal !== "") {
    //     i.items.unshift({
    //       id: uuid(),
    //       content: { name: inputVal, rank: "User Added" }
    //     })
    //   }
    //   if (el === e) {
    //     setInputToggle(true);
    //   }
    // })
    setInputField(false);
    setInputVal("");
  }


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {columns && (
          <div style={{ display: "flex" }}>
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      // alignItems: "center",
                    }}
                  >
                    <div key={columnId}>
                      {(column.name === "Delete") ? <p className="delete-column-text">Drag here to Delete your Keywords <i className="bi bi-trash3"></i></p> :
                        <>
                          <h3
                            style={{
                              margin: "0px 10px 0px 10px",
                              padding: "10px 10px 15px 10px",
                              fontSize: "16px",
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#003769",
                              backgroundColor: "lightgrey"
                            }}
                          >
                            {column.name.toUpperCase()}
                            {
                              inputField !== columnId ? <i onClick={() => addInputField(columnId)} style={{ cursor: "pointer" }} className="bi bi-plus-square"></i> : <i onClick={removeInputField} style={{ cursor: "pointer" }} className="bi bi-x-square"></i>
                            }

                          </h3>
                        </>
                      }
                      <div style={{ margin: "0px 10px 12px 10px" }}>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={column.name === "Delete" ? "droppable-area-delete" : "droppable-area"}
                                style={{
                                  background: (column.name === "Delete") ? "#f047476f" : "lightgrey",
                                  padding: 8,
                                  overflowY: column.name === "Delete" ? "none" : "scroll"
                                }}
                              >
                                {
                                  inputField === columnId && (<div>
                                    <input value={inputVal} onChange={inputChange} style={{ width: "163px", marginBottom: "10px" }} type="text" placeholder="Enter your Keywords" />

                                    <ArrowRightAltIcon
                                      onClick={() => addKeyword(columnId)}
                                      style={{ postion: "relative", background: "#fff", float: "right", marginTop: 0, height: "29px", border: "1px solid black" }}
                                    />
                                  </div>)
                                }
                                {column.items.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              textAlign: "left",
                                              padding: 5,
                                              margin: "0 0 8px 0",
                                              backgroundColor:
                                                snapshot.isDragging
                                                  ? "#e7e5e5"
                                                  : "#fff",
                                              color: "#003769",
                                              ...provided.draggableProps.style,

                                            }}
                                            className="dragBox"
                                          >
                                            {item.content.name}
                                            <span className="rankBox" style={{ marginLeft: "8px", fontSize: "11px" }}>Rank:{item.content.rank}</span>

                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  </div>
                );
              })}
            </DragDropContext>
          </div>
        )}
      </div>
    </>
  );
}

export default Dnd;
