import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';

export default forwardRef((props, ref) => {
    const [keywordType, setKeywordType] = useState('All');
    // console.log(props);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return keywordType !== "All";
            },

            doesFilterPass(params) {
                // console.log(params);
                return params.data.keyword_type === keywordType;
            },

            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() { },

            setModel() { },
        };
    });

    const onYearChange = (event) => {
        setKeywordType(event.target.value);
    };

    useEffect(() => {
        props.filterChangedCallback();
    }, [keywordType]);

    return (
        <div
            style={{ display: 'flex', flexDirection: 'column' }}
            onChange={onYearChange}
        >
            <div
                style={{
                    padding: '10px',
                    backgroundColor: '#d3d3d3',
                    textAlign: 'center',
                }}
            >
                Select Your Keyword Type
            </div>
            <label style={{ margin: '5px' }}>
                <input type="radio" name="year" value="Brand" /> Brand
            </label>
            <label style={{ margin: '5px' }}>
                <input type="radio" name="year" value="Generic" /> Generic
            </label>
            <label style={{ margin: '5px' }}>
                <input type="radio" name="year" value="Competition" /> Competition
            </label>
            <label style={{ margin: '5px' }}>
                <input type="radio" name="year" value="Others" /> Others
            </label>
            <label style={{ margin: '5px', }} >
                <input type="radio" name="year" value="All" checked={keywordType === 'All'} />
                Remove filter
            </label>
        </div>
    );
});