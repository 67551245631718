exports.getMontheInText = (monthInNumber) => {
    const monthObj = {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
    }
    const month = `${monthInNumber}`;
    return monthObj[month];
}

exports.getMonthAndYearArray = (past, future, inputDate) => {
    const input = new Date(inputDate);
    const pastDate = new Date(input);
    const futureDate = new Date(input);
    pastDate.setMonth(pastDate.getMonth() - past);
    futureDate.setMonth(futureDate.getMonth() + future);
    const dateArray = [];
    for (let i = 0; i <= 6; i++) {
        const date = new Date(pastDate);
        date.setMonth(date.getMonth() + i);
        const monthInText = this.getMontheInText(date.getMonth() + 1);
        const year = date.getFullYear();
        dateArray.push(`${monthInText}-${year}`);
    }
    return dateArray
}

exports.getFirstDayOfMonthAndYearArray = (past, future, inputDate) => {
    const input = new Date(inputDate);
    const pastDate = new Date(input);
    const futureDate = new Date(input);
    pastDate.setMonth(pastDate.getMonth() - past);
    futureDate.setMonth(futureDate.getMonth() + future);
    const dateArray = [];
    for (let i = 0; i <= 6; i++) {
        const date = new Date(pastDate);
        date.setMonth(date.getMonth() + i);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        let finalMonth;
        if (`${month}`.length < 2) {
            finalMonth = `0${month}`
        } else {
            finalMonth = month;
        }
        dateArray.push(`${year}-${finalMonth}-01T00:00:00.000+00:00`);
    }
    return dateArray
}

exports.getTodaysDate = () => {
    let today = new Date();
    return `${(today.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
exports.getYesterdaysDate = () => {
    let today = new Date();
    today.setDate(today.getDate() - 1);
    return `${(today.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
exports.getDayBeforeYesterdaysDate = () => {
    let today = new Date();
    today.setDate(today.getDate() - 2);
    return `${(today.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
exports.getTminus3date = () => {
    let today = new Date();
    today.setDate(today.getDate() - 3);
    return `${(today.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}

exports.getLast30Days = () => {
    const last30Days = new Date();
    last30Days.setDate(last30Days.getDate() - 30)
    return `${(last30Days.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
exports.getLast31Days = () => {
    const last31Days = new Date();
    last31Days.setDate(last31Days.getDate() - 31)
    return `${(last31Days.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}
exports.getLast7Days = () => {
    const last7Days = new Date();
    last7Days.setDate(last7Days.getDate() - 7)
    return `${(last7Days.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}

exports.getDateOfNintyDaysBack = () => {
    let today = new Date();
    today.setDate(-90);
    return `${(today.toISOString()).split("T")[0]}T00:00:00.000+00:00`
}

const formatDate = (date) => {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}


exports.getAppStandardDate = (d) => {
    let date = formatDate(d);
    return `${date}T00:00:00.000+00:00`
}

// exports.getDateMonthYearDisplay = (id) => {
//     let d = new Date(id);
//     d.setDate(d.getDate() + 1);
//     let date = (new Date(d).toISOString()).split("T")[0];
//     let dateArray = date.split("-");
//     return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
// }


exports.getDateMonthYearDisplay = (d) => {
    const date = new Date(d).toLocaleDateString();
    const dateSplit = date.split("/");
    return `${dateSplit[1]}-${dateSplit[0]}-${dateSplit[2]}`
}

