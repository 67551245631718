import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import './TimeBucketTable.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AG_AR } from '../../../../../appConstants';
import { BASE_URL } from '../../../../../appConstants';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getAppStandardDate } from '../../../../../utils/commonFunction';
import { formatSingleDateFn } from '../../../../../commonFunction/commomFunction';
import Loader from '../../../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../../../errorComponent/ReportsErrorPage';
import { DateComponent } from '../../ARComponents/cellRenderer';

const TimeBucketTable = (props) => {
    const gridRef = useRef();
    const { dateRange, } = props;
    // console.log(dateRange);
    const token = localStorage.getItem('token');
    const appParams = useSelector(state => state.appParams);
    const { current_brand,current_agency } = appParams;
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1, minWidth: 100, }), [])

    const start_date = getAppStandardDate(dateRange.startDate);
    const end_date = getAppStandardDate(dateRange.endDate);

    useEffect(() => {
        setLoading(true);
        setRowData([]);
        axios.post(`${BASE_URL}bidChanges/getTimeWiseBidChanges?brandId=${current_brand._id}`, {
            start_date,
            end_date,
            "time_range_array": [[0, 6], [6, 9], [9, 12], [12, 15], [15, 18], [18, 24]]
        }, {
            headers: {
                token
            }
        }).then((response) => {
            // console.log(response.data.data);
            const { bid_changes } = response.data.data;
            if (response.status === 200 && bid_changes.length === 0) {
                throw Error(`no data available for time bucket in this brand`);
            }
            setError(false);
            setRowData(bid_changes);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setError(error.message);
        })
    }, [start_date, end_date, current_brand._id]);

   

    const columnDefs = useMemo(() => [
        { headerName: 'Dates', field: 'time_stamp', width: 150, pinned: true, cellRenderer: DateComponent, cellClass: 'color-column' },
        { headerName: '00 AM-06 AM', field: 'time_0_to_6', width: 100 },
        { headerName: '06 AM-09 AM', field: 'time_6_to_9', width: 100 },
        { headerName: '09 AM-12 PM', field: 'time_9_to_12', width: 100 },
        { headerName: '12 PM-03 PM ', field: 'time_12_to_15', width: 100 },
        { headerName: '03 PM-06 PM', field: 'time_15_to_18', width: 100 },
        { headerName: '06 PM-12 PM', field: 'time_18_to_24', width: 100 },
        { headerName: 'Total', field: 'total', width: 100 },
    ], [])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    return (
        <div style={{ position: "relative" }}>
            {
                error ? <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <>
                        <div className='grid-download-container'>
                            <span className='gridDownloadBtn' onClick={onBtnExport}><i style={{ WebkitTextStroke: "0.4px" }} className="bi bi-download"></i></span>
                        </div>
                        <div className="ag-theme-alpine" style={{ height: 200, width: '100%', marginTop: '4px' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                rowHeight={AG_AR.rH}
                                headerHeight={AG_AR.hH}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                suppressNoRowsOverlay={true}
                                suppressDragLeaveHidesColumns={true}
                                suppressMenuHide={true}
                            ></AgGridReact>
                        </div>
                    </>
            }
            {
                loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} > < Loader /></div>
            }
        </div>
    )
}

export default TimeBucketTable;