import React, { useState, useEffect } from 'react';
import './BidSummary.css';
import axios from 'axios';
import { BASE_URL } from '../../../../appConstants';
import { useSelector } from 'react-redux';
import { getAppStandardDate } from '../../../../utils/commonFunction';
import Loader from '../../../commonComponent/Loader/Loader';
import { HEADER } from '../../../../appUiConstants';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import * as React from 'react';
// import AccordionActions from '@mui/material/AccordionActions';
// import Button from '@mui/material/Button';

// export default function AccordionUsage() {
//   return (
//     <div>
//       <Accordion>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel1-content"
//           id="panel1-header"
//         >
//           Accordion 1
//         </AccordionSummary>
//         <AccordionDetails>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//           malesuada lacus ex, sit amet blandit leo lobortis eget.
//         </AccordionDetails>
//       </Accordion>
//       <Accordion>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel2-content"
//           id="panel2-header"
//         >
//           Accordion 2
//         </AccordionSummary>
//         <AccordionDetails>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//           malesuada lacus ex, sit amet blandit leo lobortis eget.
//         </AccordionDetails>
//       </Accordion>
//       <Accordion defaultExpanded>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel3-content"
//           id="panel3-header"
//         >
//           Accordion Actions
//         </AccordionSummary>
//         <AccordionDetails>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//           malesuada lacus ex, sit amet blandit leo lobortis eget.
//         </AccordionDetails>
//         <AccordionActions>
//           <Button>Cancel</Button>
//           <Button>Agree</Button>
//         </AccordionActions>
//       </Accordion>
//     </div>
//   );
// }


const BidSummary = (props) => {
  const { dateRange } = props;
  // console.log(dateRange);
  const appParams = useSelector(state => state.appParams);
  const { current_brand, brands, current_agency } = appParams;
  // console.log(current_brand)
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const start_date = getAppStandardDate(dateRange.startDate);
  const end_date = getAppStandardDate(dateRange.endDate);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setLoading(true);
    axios.post(`${BASE_URL}bidChanges/getSummary?brandId=${current_brand._id}`, {
      start_date,
      end_date,
    }, {
      headers: {
        token
      }
    }).then((response) => {
      // console.log(response.data.data);
      const { summary } = response.data.data;
      // console.log(summary);
      setData(summary);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }, [start_date, end_date, current_brand._id]);

  //To get the height for grid
  const [containerHeight, setContainerHeight] = useState();
  useEffect(() => {
    const height = window.innerHeight;
    const netHeight = height - HEADER.height - 75;
    setContainerHeight(netHeight);
  }, []);
  window.addEventListener("resize", () => {
    const height = window.innerHeight;
    const netHeight = height - HEADER.height - 75;
    setContainerHeight(netHeight);
  });
  //


  return (
    <div style={{ height: containerHeight }} className="bidSummaryContainer">
      {loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} > < Loader /></div>}
      {
        !data.length &&
        <div className='summary_substitute_container'>
          <div className='summary_box'>
            <h3>Amazon API Error! No Data Available!</h3>
            <h5>Please Select Other Dates To See Bid Summary.</h5>
          </div>
        </div>
      }
      <div>
        {
          data.length > 0 &&
          data?.map((el) => {
            const { bids_decreased, bids_increased, advertised_products_added, advertised_products_paused, broad_keywords_added,
              broad_keywords_paused, exact_keywords_added, exact_keywords_paused, phrase_keywords_added, phrase_keywords_paused, product_targets_added,
              product_targets_paused, negative_keywords_added, negative_keywords_paused, negative_products_added, bids_without_adjustment,
              bids_with_adjustment, campaigns_created, campaigns_paused, campaigns_paused_per_day } = el;
            return (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ background: '#bdbdbd' }}
                  >
                    Bids
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="bidSummaryRow">
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{bids_increased}</div>
                          <div>Bids Increased</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-graph-up-arrow"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{bids_decreased}</div>
                          <div>Bids Decreased</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_2' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-graph-down-arrow"></i></button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ background: '#bdbdbd' }}
                  >
                    Targets
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="bidSummaryRow">
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{exact_keywords_added + phrase_keywords_added + broad_keywords_added}</div>
                          <div>Positive Keyword Added</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_3' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{exact_keywords_paused + phrase_keywords_paused + broad_keywords_paused}</div>
                          <div>Positive Keywords Paused</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                    </div>

                    <div className="bidSummaryRow">
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{negative_keywords_added}</div>
                          <div>Negative Keyword Added</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_3' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{negative_keywords_paused}</div>
                          <div>Negative Keywords Paused</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                    </div>

                    <div className="bidSummaryRow">
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{product_targets_added}</div>
                          <div>Product Targets Added</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_3' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{product_targets_paused}</div>
                          <div>Product Targets Paused</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ background: '#bdbdbd' }}
                  >
                    Campaigns
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="bidSummaryRow">
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{campaigns_created}</div>
                          <div>Campaigns Added</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_3' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{campaigns_paused}</div>
                          <div>Campaigns Paused</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{campaigns_paused_per_day}</div>
                          <div>Campaigns Paused Per Day</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ background: '#bdbdbd' }}
                  >
                    Adjustments
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="bidSummaryRow">
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{bids_with_adjustment}</div>
                          <div>Bids With Adjustment</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_3' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                      <div className='rowSubContainer'>
                        <div className='rowSub'>
                          <div className='row_sub_value'>{bids_without_adjustment}</div>
                          <div>Bids Without Adjustment</div>
                        </div>
                        <div className='rowSub'>
                          <button className='row_sub_btn_1' type='button'><i style={{ WebkitTextStroke: '0.4px' }} className="bi bi-dash-circle"></i></button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            )
          })
        }
      </div>


    </div >
  )
}

export default BidSummary;