import React, { useState, useRef, } from 'react';
import '../ProductMaster.css';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { NotificationManager } from 'react-notifications';

function BudgetUpload(props) {
    const fileInputRef = useRef(null);
    const { rowData, setRowData, setUpload, types, range, selectedDate } = props;
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { _id, client_profile_id, client_name } } = appParams;
    const [file, setFile] = useState(null);

    const resetUpload = (str) => {
        NotificationManager.error(str, 'Error', 5000);
        setTimeout(() => {
            fileInputRef.current.form.reset();
        }, 1000)
    }

    const successUpload = (headers, dataArr) => {
        const arrOfObjdata = dataArr.map((row) => {
            const dataObj = {};
            headers.forEach((header, index) => {
                dataObj[header] = row[index];
            });
            dataObj.Target_Sales = Number(((dataObj.Target_Spend / dataObj.Target_ACOS) * 100).toFixed(0))
            return dataObj;
        });
        // console.log(arrOfObjdata);
        setFile(arrOfObjdata);
    }

    const handleFileChange = (e) => {
        let extractedFile = e.target.files[0];
        if (extractedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                // console.log(workbook);
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const dataArr = jsonData.slice(1);
                let headers = jsonData[0];

                const arrOfObjdata = dataArr.map((row) => {
                    const dataObj = {};
                    headers.forEach((header, index) => {
                        dataObj[header] = row[index];
                    });
                    return dataObj;
                });

                const productMasterCat = rowData.map((el) => el.category);

                if (jsonData.length > 0) {
                    let headers = jsonData[0];
                    headers = headers.map(el => {
                        let newEl;
                        if (el.includes(' ')) {
                            newEl = el.replace(/ /g, '_');
                        } else {
                            newEl = el;
                        }
                        return newEl
                    })
                    // console.log(headers);
                    if (types === 'CATEGORY') {
                        const checkHeaders = ['Category', 'Target_Spend', 'Target_ACOS', 'Objective', 'Exclude'];
                        const checkAllElExist = checkHeaders.every(el => headers.includes(el));
                        if (checkHeaders.length === 5 && checkAllElExist) {
                            successUpload(headers, dataArr);
                        } else {
                            resetUpload("File doesn't contain the required headers and data")
                        }
                    } else if (types === 'ADTYPE') {
                        let checkHeaders = ['Category', 'AD_Type', 'Target_Spend', 'Target_ACOS', 'Objective', 'Exclude']
                        const checkAllElExist = checkHeaders.every(el => headers.includes(el));
                        if (checkHeaders.length === 6 && checkAllElExist) {
                            successUpload(headers, dataArr);
                        } else {
                            resetUpload("File doesn't contain the required headers and data")
                        }
                    } else if (types === 'KEYWORD') {
                        let checkHeaders = ['Category', 'AD_Type', 'Keyword_Type', 'Target_Spend', 'Target_ACOS', 'Objective', 'Exclude']
                        const checkAllElExist = checkHeaders.every(el => headers.includes(el));
                        if (checkHeaders.length === 7 && checkAllElExist) {
                            successUpload(headers, dataArr);
                        } else {
                            resetUpload("File doesn't contain the required headers and data")
                        }
                    }
                    for (let el of arrOfObjdata) {
                        if (!productMasterCat.includes(el.Category)) {
                            resetUpload("you can't add or delete any category");
                        }
                    }

                    for (let el of dataArr) {
                        // console.log(el, el.length);
                        if (types === 'CATEGORY') {
                            // if (isNaN(el[1]) || isNaN(el[2])) {
                            //     return resetUpload('Spend, ACOS should be a number')
                            // }
                            // if (el[1] < 0 || el[2] < 0) {
                            //     return resetUpload('Spend, ACOS should be postive number.')
                            // }
                            if (el[el.length - 1] !== 'No' && el[el.length - 1] !== 'Yes') {
                                return resetUpload("Exclude is case sensitive. It should 'Yes' or 'No'.")
                            }
                            if (el[el.length - 2] !== 'ROI' && el[el.length - 2] !== 'Visibility' && el[el.length - 2] !== 'Growth') {
                                return resetUpload("Objective is case sensitive. It should 'ROI'or 'Visibility' or 'Growth'.")
                            }
                        } else if (types === 'ADTYPE') {
                            // if (isNaN(el[2]) || isNaN(el[3])) {
                            //     return resetUpload('Spend, ACOS should be a number')
                            // }
                            // if (el[2] < 0 || el[3] < 0) {
                            //     return resetUpload('Spend, ACOS should be postive number.')
                            // }
                            if (el[el.length - 1] !== 'No' && el[el.length - 1] !== 'Yes') {
                                return resetUpload("Exclude is case sensitive. It should 'Yes' or 'No'.")
                            }
                            if (el[el.length - 2] !== 'ROI' && el[el.length - 2] !== 'Visibility' && el[el.length - 2] !== 'Growth') {
                                return resetUpload("Objective is case sensitive. It should 'ROI'or 'Visibility' or 'Growth'.")
                            }
                        } else if (types === 'KEYWORD') {
                            // if (isNaN(el[3]) || isNaN(el[4])) {
                            //     return resetUpload('Spend, ACOS should be a number')
                            // }
                            // if (el[3] < 0 || el[4] < 0) {
                            //     return resetUpload('Spend, ACOS should be postive number.')
                            // }
                            if (el[el.length - 1] !== 'No' && el[el.length - 1] !== 'Yes') {
                                return resetUpload("Exclude is case sensitive. It should 'Yes' or 'No'.")
                            }
                            if (el[el.length - 2] !== 'ROI' && el[el.length - 2] !== 'Visibility' && el[el.length - 2] !== 'Growth') {
                                return resetUpload("Objective is case sensitive. It should 'ROI'or 'Visibility' or 'Growth'.")
                            }
                        }
                    }

                } else {
                    setFile([]);
                }
            };
            reader.readAsBinaryString(extractedFile);
        }
    }

    const updateUpload = () => {
        if (file) {
            let data;
            if (types === 'CATEGORY') {
                data = rowData.map((row) => {
                    const fileData = file.find((fileData) => fileData.Category === row.category);
                    return {
                        category: row.category,
                        spend: fileData?.Target_Spend || 0,
                        sales: fileData?.Target_Sales || 0,
                        acos: fileData?.Target_ACOS || 0,
                        objective: fileData?.Objective || "ROI",
                        exclude: fileData?.Exclude || "No"
                    }

                })
            } else if (types === 'ADTYPE') {
                data = rowData.map((row) => {
                    const fileData = file.find((fileData) => fileData.Category === row.category && fileData.AD_Type === row.ad_type);
                    return {
                        category: row.category,
                        ad_type: row.ad_type,
                        spend: fileData?.Target_Spend || 0,
                        sales: fileData?.Target_Sales || 0,
                        acos: fileData?.Target_ACOS || 0,
                        objective: fileData?.Objective || "ROI",
                        exclude: fileData?.Exclude || "No"
                    }

                })
            } else if (types === 'KEYWORD') {
                data = rowData.map((row) => {
                    const fileData = file.find((fileData) => fileData.Category === row.category && fileData.AD_Type === row.ad_type && fileData.Keyword_Type === row.keyword_type);
                    return {
                        category: row.category,
                        ad_type: row.ad_type,
                        keyword_type: row.keyword_type,
                        spend: fileData?.Target_Spend || 0,
                        sales: fileData?.Target_Sales || 0,
                        acos: fileData?.Target_ACOS || 0,
                        objective: fileData?.Objective || "ROI",
                        exclude: fileData?.Exclude || "No"
                    }
                })
            }
            // console.log(data);
            setRowData(data)
            // if (types === 'CATEGORY') {
            //     let data = {
            //         clientBudgets: file.map((el) => {
            //             const { Category, Target_Spend, Target_Sales, Target_ACOS, Objective, Exclude } = el;
            //             return {
            //                 category: Category, spend: Target_Spend || 0, sales: Target_Sales || 0, acos: Target_ACOS || 0, objective: Objective, exclude: Exclude
            //             }
            //         })
            //     }
            //     setRowData(data.clientBudgets);

            // } else if (types === 'ADTYPE') {
            //     let data = {
            //         clientBudgets: file.map((el) => {
            //             const { Category, AD_Type, Target_Spend, Target_Sales, Target_ACOS, Objective, Exclude } = el;
            //             return {
            //                 category: Category, ad_type: AD_Type, spend: Target_Spend || 0, sales: Target_Sales || 0, acos: Target_ACOS || 0, objective: Objective, exclude: Exclude
            //             }
            //         })
            //     }
            //     setRowData(data.clientBudgets);

            // } else if (types === 'KEYWORD') {
            //     let data = {
            //         clientBudgets: file.map((el) => {
            //             const { Category, AD_Type, Keyword_Type, Target_Spend, Target_Sales, Target_ACOS, Objective, Exclude } = el;
            //             return {
            //                 category: Category, ad_type: AD_Type, keyword_type: Keyword_Type, spend: Target_Spend || 0, sales: Target_Sales || 0, acos: Target_ACOS || 0, objective: Objective, exclude: Exclude
            //             }
            //         })
            //     }
            //     setRowData(data.clientBudgets);

            // }
        }
        else {
            resetUpload("Something went wrong!! Unable to upload");
        }
        // setUpload(true);
        fileInputRef.current.form.reset();
    }

    const handleUpload = () => {
        if (selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            resetUpload('You can not upload previous month budget')
            return;
        }
        // else if (selectedDate.toLocaleDateString() === new Date(range[2]).toLocaleDateString()) {
        // if (selectedDate.getDate() >= 25) {
        //     updateUpload();
        // } else {
        //     resetUpload('You can upload the projection for next month after 25th.')
        //     return;
        // }
        // updateUpload();
        // } 
        updateUpload();

    }
    return (
        <div className='upload_container'>
            <div className='selected_upload'>
                <form>
                    <input
                        ref={fileInputRef}
                        className='form-control form-control-sm'
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChange}
                    />
                </form>
            </div>
            <button type='button' className='cancel_btn' onClick={handleUpload}><i className="bi bi-upload"></i> Upload</button>
        </div>
    )
}

export default BudgetUpload;