import { useEffect, useState } from "react";
import { getMontheInText } from "../../../../commonFunction/commomFunction";
import "./MonthlyBudgetDistribution.scss";
import { ViewEnum } from "./MonthlyBudgets";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";



function MonthlyBudgetDistributionWeight({ totalSpend, handleViewChange, view, monthIndex = 3, year = 2023, projections, handleSubmitProjections }) {
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { user_type } } = appParams;
    const daysInMonth = (monthIndex, year) => {
        return new Date(year, monthIndex, 0).getDate();
    }

    const totalSVDWeekendPercentage = 100 * .40 * .35;
    const totalSVDWeekPercentage = 100 * .40 * .65;
    const totalBAUWeekendPercentage = 100 * .60 * .35;
    const totalBAUWeekPercentage = 100 * .60 * .65

    const svdWeekendUnit = Math.round((100 * 8.2) / 2.64);
    const svdWeekUnit = Math.round((100 * 5.2) / 2.64);
    const bauWeekendUnit = Math.round((100 * 2.84) / 2.64);
    const bauWeekUnit = Math.round(100);


    const isPastDate = (date) => {
        const currentDate = new Date(year, monthIndex - 1, date)
        const todayDate = new Date()
        if (currentDate.getTime() < todayDate.getTime()) return true
        else return false
    }

    const getDaysDisplacement = () => {
        const dayIndex = new Date(year, monthIndex - 1, 1).getDay()
        if (dayIndex === 0) return 7
        return dayIndex;
    }

    const initialState = {
        SVD_WEEKEND: {
            ratio: 0,
            spend: 0
        },
        SVD_WEEK: {
            ratio: 0,
            spend: 0
        },
        BAU_WEEKEND: {
            ratio: 0,
            spend: 0
        },
        BAU_WEEK: {
            ratio: 0,
            spend: 0
        },
        TOTAL: {
            ratio: 100,
            spend: totalSpend
        },
    }

    const [groupInput, setGroupInput] = useState(initialState)
    const [input, setInput] = useState({})


    const calculateBasedOnWeight = (reset = true) => {
        const dates = daysInMonth(monthIndex, year);
        let total = 0;
        let totalSVDWeekend = 0;
        let totalSVDWeek = 0;
        let totalBAUWeekend = 0;
        let totalBAUWeek = 0;

        if (reset && projections && projections.length > 0) {
            const minProjection = Math.min(
                ...projections.map(({ projection }) => projection)
                    .filter((projection) => projection !== 0)
            )
            const minUnit = 100;
            projections.forEach(({ date, projection, unit }) => {
                if (unit !== undefined) input[date] = { value: Number(unit) };
                else if (projection !== undefined) {
                    const currUnit = Math.round((minUnit * projection) / minProjection)
                    input[date] = { value: currUnit };
                }
                input[date].projection = projection
            })
        }

        for (let date = 1; date <= dates; date++) {
            const currDate = new Date(year, monthIndex - 1, date);
            if (!input[currDate.getDate()]) input[currDate.getDate()] = {};
            const currInput = input[currDate.getDate()];
            if (currDate.getDate() < 8) {
                // SVD
                if (currDate.getDay() === 6 || currDate.getDay() === 0) {
                    // WEEKEND
                    currInput.group = "SVD_WEEKEND"

                    currInput.value = (currInput.value === undefined) ? svdWeekendUnit : currInput.value
                    total += Number(currInput.value)
                    totalSVDWeekend += Number(currInput.value)
                } else {
                    currInput.group = "SVD_WEEK"
                    currInput.value = (currInput.value === undefined) ? svdWeekUnit : currInput.value
                    total += Number(currInput.value)
                    totalSVDWeek += Number(currInput.value)
                }
            } else {
                // BAU
                if (currDate.getDay() === 6 || currDate.getDay() === 0) {
                    // WEEKEND
                    currInput.group = "BAU_WEEKEND"
                    currInput.value = (currInput.value === undefined) ? bauWeekendUnit : currInput.value
                    total += Number(currInput.value)
                    totalBAUWeekend += Number(currInput.value)
                } else {
                    currInput.group = "BAU_WEEK"
                    currInput.value = (currInput.value === undefined) ? bauWeekUnit : currInput.value
                    total += Number(currInput.value)
                    totalBAUWeek += Number(currInput.value)
                }
            }
        }


        for (let date = 1; date <= dates; date++) {
            const currDate = new Date(year, monthIndex - 1, date);
            const currInput = input[currDate.getDate()];
            input[date].projection = input[date].projection ? input[date].projection : (currInput.value / total) * 100
        }

        let totalPercentage = 100;
        let svdWeekendPercentage = 0;
        let svdWeekPercentage = 0;
        let bauWeekendPercentage = 0;
        let bauWeekPercentage = 0;

        let date = 1
        let usedProjections = 0
        while (isPastDate(date)) {
            const currDate = new Date(year, monthIndex - 1, date);
            const currInput = input[currDate.getDate()];
            usedProjections += currInput.totalRatio = Number(currInput.projection.toFixed(2));
            date++;
        }
        totalPercentage -= usedProjections;

        for (let date = 1; date <= dates; date++) {
            const currDate = new Date(year, monthIndex - 1, date);
            const currInput = input[currDate.getDate()];
            if (!isPastDate(date)) {
                currInput.totalRatio = Number(((currInput.value / total) * totalPercentage).toFixed(2))
            }
            if (currInput.totalRatio === 0) {
                currInput.groupRatio = "0.0";
                continue;
            }
            if (currDate.getDate() < 8) {
                // SVD
                if (currDate.getDay() === 6 || currDate.getDay() === 0) {
                    // WEEKEND
                    currInput.groupRatio = Number(((currInput.value / (totalSVDWeekend)) * 100).toFixed(1))
                    svdWeekendPercentage += currInput.totalRatio;
                } else {
                    currInput.groupRatio = Number(((currInput.value / (totalSVDWeek)) * 100).toFixed(1))
                    svdWeekPercentage += currInput.totalRatio
                }
            } else {
                // BAU
                if (currDate.getDay() === 6 || currDate.getDay() === 0) {
                    // WEEKEND 
                    currInput.groupRatio = Number(((currInput.value / (totalBAUWeekend)) * 100).toFixed(1))
                    bauWeekendPercentage += currInput.totalRatio
                } else {
                    currInput.groupRatio = Number(((currInput.value / (totalBAUWeek)) * 100).toFixed(1))
                    bauWeekPercentage += currInput.totalRatio
                }
            }
        }



        groupInput.SVD_WEEKEND.ratio = Number((svdWeekendPercentage).toFixed(1))
        groupInput.SVD_WEEK.ratio = Number((svdWeekPercentage).toFixed(1))
        groupInput.BAU_WEEKEND.ratio = Number((bauWeekendPercentage).toFixed(1))
        groupInput.BAU_WEEK.ratio = Number((bauWeekPercentage).toFixed(1))

        groupInput.SVD_WEEKEND.spend = Number(((svdWeekendPercentage * totalSpend) / 100).toFixed(0))
        groupInput.SVD_WEEK.spend = Number(((svdWeekPercentage * totalSpend) / 100).toFixed(0))
        groupInput.BAU_WEEKEND.spend = Number(((bauWeekendPercentage * totalSpend) / 100).toFixed(0))
        groupInput.BAU_WEEK.spend = Number(((bauWeekPercentage * totalSpend) / 100).toFixed(0))
        setInput((prev) => { return { ...prev } })
        return true;
    }



    useEffect(() => {
        calculateBasedOnWeight();
    }, [])

    const handleChange = ({ target: { value, name } }) => {
        if (isNaN(Number(value))) return;
        if (Number(value) < 0) return;
        const prevValues = {
            value: input[name].value,
            edit: input[name].edit
        }
        input[name].value = value;
        input[name].edit = true;
        if (!calculateBasedOnWeight(false)) {
            input[name].value = prevValues.value;
            input[name].edit = prevValues.edit;
        }

    }

    const getGroupColoring = ({ group, edit }, disabled) => {
        // console.log(disabled);
        if (edit) {
            if (group === "SVD_WEEKEND") return "svdWeekendEdit"
            if (group === "SVD_WEEK") return "svdWeekEdit"
            if (group === "BAU_WEEKEND") return "bauWeekendEdit"
            if (group === "BAU_WEEK") return "bauWeekEdit"
        } else {
            if (disabled) {
                if (group === "SVD_WEEKEND") return "svdWeekendDisabled"
                if (group === "SVD_WEEK") return "svdWeekDisabled"
                if (group === "BAU_WEEKEND") return "bauWeekendDisabled"
                if (group === "BAU_WEEK") return "bauWeekDisabled"
            } else {
                if (group === "SVD_WEEKEND") return "svdWeekend"
                if (group === "SVD_WEEK") return "svdWeek"
                if (group === "BAU_WEEKEND") return "bauWeekend"
                if (group === "BAU_WEEK") return "bauWeek"
            }

        }
    }


    const handleSubmit = () => {
        const updateProjection = Object.keys(input).map((date) => {
            return {
                date: date, unit: input[date].value, projection: Number(input[date].totalRatio.toFixed(2))
            }
        })
        handleSubmitProjections(updateProjection)
    }


    return (
        <div class="calendar">

            <div class="month">
                <div>{getMontheInText(monthIndex)} <span class="year">{year}</span></div>
            </div>
            <div style={{ width: '100%', display: 'grid', justifyContent: 'space-evenly', gap: '30px' }}>
                <div className="monthBudget-header">

                    <div className="monthBudget-groupContainer">
                        <table className="monthBudget-table">
                            <tr>
                                <th>Group</th>
                                <th>Budget</th>
                                <th>Percentage</th>
                            </tr>
                            {
                                Object.keys(groupInput).map((group, index) => (
                                    <tr>
                                        <td>
                                            <label className="monthBudget-groupContainer-group-label" htmlFor={group}>{group}</label>
                                        </td>
                                        <td>
                                            <div className={`monthBudget-groupContainer-group-input ${getGroupColoring({ group, edit: groupInput[group].edit })}`}>
                                                <span className="monthBudget-groupContainer-group-input__rupeeSign">₹</span>
                                                <input className="monthBudget-groupContainer-group-input__rupeeInput" disabled type="text" id={group} name={group} value={groupInput[group].spend}></input>
                                                {groupInput[group].edit && <span className="monthBudget-groupContainer-group-input__save">📁</span>}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`monthBudget-groupContainer-group-input ${getGroupColoring({ group, edit: groupInput[group].edit })}`}>
                                                <input className="monthBudget-groupContainer-group-input__rupeeInput" disabled type="text" id={group} name={group} value={groupInput[group].ratio}></input>
                                                <span className="monthBudget-groupContainer-group-input__rupeeSign">%</span>
                                                {groupInput[group].edit && <span className="monthBudget-groupContainer-group-input__save">📁</span>}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </table>
                    </div>

                    <div className='monthBudget-date'>
                        {/* <select name="view" id="type" onChange={handleViewChange} value={view}>
                        {Object.keys(ViewEnum).map((view) =>
                            <option value={ViewEnum[view]}>{ViewEnum[view]}</option>
                        )}
                    </select> */}
                    </div>
                </div>
                {/* <br /><br /> */}
                <div>
                    <div class="days">
                        <span>Mon</span>
                        <span>Tue</span>
                        <span>Wed</span>
                        <span>Thu</span>
                        <span>Fri</span>
                        <span>Sat</span>
                        <span>Sun</span>
                    </div>
                    <div class="dates">
                        {
                            Object.keys(input).map((date, index) => (
                                <div key={index} className={`dates-date ${getGroupColoring(input[date], isPastDate(date))}`} style={(date === "1") ? { gridColumn: getDaysDisplacement() } : {}}>
                                    <label className="dates-date-label" htmlFor={date}>
                                        <div className="dates-date-label-headers">
                                            <p className="dates-date-label-headers__percentage">{input[date].totalRatio + "/" + input[date].groupRatio}</p>
                                            <p className="dates-date-label-headers__name">{date}{input[date].edit && <span>📁</span>}</p>
                                        </div>
                                        <input className="dates-date-label__input" disabled={isPastDate(date) ? "disabled" : ""} type="text" id={date} name={date} value={input[date].value} onChange={handleChange} ></input>
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', position: 'relative' }}>
                <button className={`${user_type && user_type === 'brand_viewer' ? 'submit_distribution disabled-button' : 'submit_distribution'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}

export default MonthlyBudgetDistributionWeight;