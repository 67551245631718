import { useState, useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import { BASE_URL } from "../../appConstants";
import axios from "axios";
import "./Otp.css";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Loader from "../commonComponent/Loader/Loader";

const Otp = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        otp: "",
        password: "",
        email: ""
    });

    const [error, setError] = useState({
        otp: false,
        password: false,
        email: false
    });
    const [logic, setLogic] = useState({
        reSendOtp: true,
        seconds: ""
    });

    useState(() => {
        const loginEmail = localStorage.getItem("email");
        setState(prevState => ({
            ...prevState,
            email: loginEmail
        }));
    }, []);

    const counter = () => {
        setLogic(prevState => ({ ...prevState, reSendOtp: false }));
        let s = 30;
        const tick = () => {
            if (s > 0) {
                setTimeout(tick, 1000);
                s = s - 1;
                setLogic(prevState => ({ ...prevState, seconds: s }));
            } else {
                setLogic(prevState => ({ ...prevState, reSendOtp: true }));
            }
        }
        tick()
    }

    useEffect(() => {
        counter();
    }, []);

    const onChange = (e) => {
        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
        setError(prevState => ({
            ...prevState,
            [e.target.name]: false
        }));


    }

    const onClick = (e) => {
        e.preventDefault();
        const { otp, password, email } = state;
        if (otp === "") {
            setError(prevState => ({ ...prevState, otp: true }));
            return;
        } else if (email === "") {
            setError(prevState => ({ ...prevState, inValidEmail: true }));
            return;
        } else if (password === "") {
            setError(prevState => ({ ...prevState, password: true }));
            return;
        }
        setLoader(true);
        axios.post(`${BASE_URL}auth/setpassword`, {
            email,
            otp,
            password
        }).then((res) => {
            // console.log(res);
            const { data } = res;
            setLoader(false);
            // console.log("=====SUCCESS=====>", res);
            navigate("/user/login");
            NotificationManager.success(data.data.message, "Success", 3000);

        }).catch((error) => {
            console.log(error);
            NotificationManager.error(error.response.data.data.message, "Error", 3000);
            setLoader(false);

        })
    }

    const reSendOtp = async () => {
        try {
            const res = await axios.post(`${BASE_URL}auth/generateOtp`, {
                email: state.email
            });
            // console.log(res);
            const { status, data } = res;
            // console.log(status, data);
            if (status === "error") {
                // console.log("=====ERROR=====>", res);
                NotificationManager.error(data.message, "Error", 4000);
            } else {
                // console.log("=====SUCCESS=====>", res);
                NotificationManager.success(data.message, "Success", 3000);
                counter();
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="otpMain" >
            <div className="otpContainer">
                {
                    !loader && <>
                        <form className="form">
                            <div className="" >
                                OTP has been sent to your registered email address.  {logic.reSendOtp ? <span onClick={reSendOtp} className="resendOtpBtn" >Resend OTP</span> : <span className="resendOtpBtn" >Fetching OTP:{logic.seconds} s</span>}
                            </div>
                            <hr />
                            <div className="mb-1"  >
                                <input
                                    placeholder="Email"
                                    className="form-control inputStyle"
                                    type="email"
                                    onChange={onChange}
                                    name={"email"}
                                    value={state.email}
                                    disabled={true}
                                />
                                {/* <label onClick={() => navigate("/user/forgetPassword")} className="" >Edit Email 📧 </label> */}
                                <label></label>
                            </div>
                            <div className="mb-1" >
                                <input
                                    placeholder="Enter OTP"
                                    className="form-control inputStyle"
                                    type="number"
                                    onChange={onChange}
                                    name={"otp"}
                                    value={state.otp}
                                />
                                <label className="" >{error.otp ? "🔐 Please enter otp." : ""}</label>
                            </div>
                            <div className=""  >
                                <input
                                    placeholder="Set Password"
                                    className="form-control inputStyle"
                                    type="password"
                                    onChange={onChange}
                                    name={"password"}
                                    value={state.password}
                                />
                                <label className="" >{error.password ? "🗝️ Please create new password." : ""}</label>
                            </div>

                            <button
                                style={{ backgroundColor: "var(--active-color)", color: "#fff", fontSize: '17px' }}
                                className="btn btn-m mt-3 mb-3 col-lg-12"
                                onClick={onClick}>
                                Confirm
                            </button>
                        </form>
                    </>
                }

                {
                    loader && <div className="otpVerifyLoader" >
                        <Loader />
                    </div>
                }
                <NotificationContainer />
            </div>
        </div>
    )
}

export default Otp;