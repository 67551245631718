import React, { useEffect, useState, useRef } from 'react';
import './BenchmarkContainer.css';
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS, registerables,
    Tooltip,
} from 'chart.js';
import axios from 'axios';
import { CategoryScale } from 'chart.js';
import Loader from '../../commonComponent/Loader/Loader';
import { BASE_URL } from '../../../appConstants';



const BenchmarkContainer = (props) => {

    ChartJS.register(Tooltip,
        ...registerables,
        {
            id: 'line',
            afterDraw: function (chart) {
                if (chart.config.type === 'line') {
                    if (chart.tooltip?._active && chart.tooltip?._active.length) {
                        const activePoint = chart.tooltip?._active[0];
                        const { ctx } = chart;
                        const x = activePoint.element.x;
                        const topY = chart.scales.y.top;
                        const bottomY = chart.scales.y.bottom;
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = 'lightgrey';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        });

    const popupRef = useRef();
    const [active, setActive] = useState('SP');
    const [state, setState] = useState({
        labels: [],
        acos: [],
        cpc: [],
        ctr: [],
        cvr: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const adTypeArr = [
        { id: 'sp', type: 'SP' },
        { id: 'sb', type: 'SB' },
        { id: 'sbv', type: 'SBV' },
        { id: 'sd', type: 'SD' },
    ]
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState("")

    const [adType, setAdType] = useState(adTypeArr[0]?.id);

    function getLastThreeYear() {
        const currentYear = new Date().getFullYear();
        const prevYear = new Date().getFullYear() - 1;
        const prevYear2 = new Date().getFullYear() - 2;
        const prevYear3 = new Date().getFullYear() - 3;
        return [prevYear3, prevYear2, prevYear, currentYear]
    }
    const [yearArr, setYearArr] = useState(getLastThreeYear())
    const [year, setYear] = useState(yearArr[yearArr.length - 1]);
    const metricArr = [{ value: 'acos', name: 'ACOS' }, { value: 'cpc', name: 'CPC' }, { value: 'ctr', name: 'CTR' }, { value: 'cvr', name: 'CVR' }]
    const [metric, setMetric] = useState([metricArr[0]?.value, metricArr[1]?.value]);
    // console.log(metric);

    const [searchQuery, setSearchQuery] = useState(null);

    const [toggle, setToggle] = useState(false);
    const handleToggle = () => {
        setToggle(!toggle);
    }

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    }

    const token = localStorage.getItem('token');


    function debounce(func, delay) {
        let timeout;
        const debouncedFunction = (...args) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                func(...args);
            }, delay);
        };
        debouncedFunction.cancel = () => {
            clearTimeout(timeout);
        };
        return debouncedFunction;
    }

    const fetchCategories = () => {
        if (searchQuery?.length > 1) {
            axios.get(`${BASE_URL}benchmark/categories?last_node_phrase=${searchQuery}`, {
                headers: {
                    token
                }
            }).then((response) => {
                const data = response.data.data.categories;
                // console.log(data);
                setCategories(data);
            }).catch((err) => console.log(err))
        }
    }

    useEffect(() => {
        const debounceFn = debounce(() => {
            fetchCategories()
        }, 300)
        debounceFn();
        return (() => {
            debounceFn.cancel()
        })
    }, [searchQuery])

    useEffect(() => {
        if (category) {
            setIsLoading(true)
            const encodedLastNode = encodeURIComponent(category)
            axios.get(`${BASE_URL}benchmark/app?year=${year}&last_node=${encodedLastNode}&metric=acos&metric=cvr&metric=cpc&metric=ctr&ad_type=${adType}`, {
                headers: {
                    token
                }
            })
                .then((res) => {
                    const data = res.data.data.benchmark
                    // console.log(data);
                    let labelArr = [];
                    let acosArr = [];
                    let cpcArr = [];
                    let ctrArr = [];
                    let cvrArr = [];
                    // let dataArr = []
                    data?.forEach((el) => {
                        const month = new Date(el.time_stamp);
                        const label = month.toLocaleString('default', { month: 'short' });
                        labelArr.push(label);
                        acosArr.push(el.acos);
                        cpcArr.push(el.cpc)
                        ctrArr.push(el.ctr)
                        cvrArr.push(el.cvr)
                    })
                    setState((prevState) => ({
                        ...prevState,
                        labels: labelArr,
                        acos: acosArr,
                        cpc: cpcArr,
                        ctr: ctrArr,
                        cvr: cvrArr,
                        // data: dataArr
                    }))
                }).catch(err => {
                    console.log(err);
                    // if (err.response.status === 406) {
                    //     setOpen(true);
                    // }
                    // const errMessage = err?.response?.data?.error || "Please Try Again"
                    // if (token && errMessage.includes("You can not change last node from")) {
                    //     localStorage.removeItem('token');
                    //     toast("Token Expired.")
                    //     setToken(undefined)
                    // }
                    // if (err?.response?.data?.last_node) setCategory(err.response.data.last_node)
                    // toast(errMessage)
                }).finally(() => {
                    setIsLoading(false);
                })
        }
    }, [adType, year, category, metric])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function colorSet(val) {
        if (val === 'acos') return 'rgba(230, 57, 71,0.7)'
        if (val === 'cpc') return 'rgba(237, 174, 73,0.7)'
        if (val === 'ctr') return 'rgba(51, 118, 189,0.7)'
        if (val === 'cvr') return 'rgba(0, 121, 140,0.7)'
    }

    // rgba(22, 124, 159, 0.70) #3B7C95 rgba(15, 78, 109, 0.90) #084666

    function colorSet2(val) {
        if (val === 'SP') return 'rgba(22, 124, 159, 0.70)'
        if (val === 'SB') return '#3B7C95'
        if (val === 'SBV') return 'rgba(15, 78, 109, 0.90)'
        if (val === 'SD') return '#084666'
    }

    const newDataset = (arr) => {
        let dataArr = [];
        let el = {};
        for (let e of arr) {
            el = {
                label: e?.toUpperCase(),
                data: state[e],
                borderColor: colorSet(e),
            }
            dataArr.push(el)
        }
        return dataArr;
    }

    const data = {
        labels: state.labels,
        datasets: newDataset(metric),
    };

    const handleAdType = (el) => {
        // console.log(el);
        setAdType(el.id)
        setActive(el.type)
    }

    const handleYear = (el) => {
        if (year === el) {
            setYear(null);
        } else {
            setYear(el)
        }
    }

    const handleMetric = (el) => {
        if (metric.includes(el)) {
            setMetric(metric.filter((i) => i !== el));
        } else if (metric.length < 2) {
            setMetric([...metric, el]);
        }
    }

    const handleCategories = (el) => {
        setCategory(el);
        setToggle(false);
    }

    const tooltipCallback = (tooltipItem, data) => {
        const label = data.datasets[tooltipItem.datasetIndex].label || '';
        const dataValue = (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex] || 0);

        if (label === 'ACOS') {
            return `${label}:  ${Math.ceil(dataValue)}%`;
        }
        if (label === 'CPC') {
            return `${label}:  ₹${Math.ceil(dataValue.toLocaleString("en-IN"))}`;
        }
        if (label === 'CTR') {
            return `${label}:  ${Math.ceil(dataValue)}%`;
        }
        if (label === 'CVR') {
            return `${label}:  ${Math.ceil(dataValue)}%`;
        }

    }


    return (
        <>
            <div className='benchmark_container'>
                <div className='benchmark_header'>
                    <div className='benchmark_category'>
                        <span>Select a Category to View Trends</span>
                        <div className="" ref={popupRef}>
                            <div className="catName" onClick={handleToggle}>
                                <div>{category ? category : 'Search your category'}</div>
                                <div><i className="bi bi-chevron-expand"></i></div>
                            </div>
                            <div className="searchResultBox" style={{ display: toggle ? 'block' : 'none' }}>
                                <div className="searchInputContainer">
                                    <div className="catSearchInputContainer">
                                        <i className="bi bi-search"></i>
                                        <input className="searchInput" type="text" value={searchQuery} onChange={handleInputChange} placeholder="search" />
                                    </div>
                                </div>
                                <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                                <div className="catListContainer">
                                    {categories?.length > 0 &&
                                        categories?.map((el, i) => {
                                            return (
                                                <div
                                                    // className={`brandList-${el._id === currentAgency ? 'active' : ""}`}
                                                    key={i}
                                                    onClick={() => handleCategories(el)}
                                                >{el}
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="benchmark_content">
                    <div className='benchmark_ad_year'>
                        <div className='benchmark_adtype'>
                            {adTypeArr.map((el) => {
                                return (
                                    <button style={{ backgroundColor: `${colorSet2(el.type)}` }} className={active === el.type ? "benchmark_button_active" : "benchmark_button"} key={el.id} value={el.id} onClick={() => handleAdType(el)}>{el.type}</button>
                                )
                            })}
                        </div>
                        <div className='benchmark_years'>
                            <div className='benchmark_year'>
                                {yearArr.map((el) => {
                                    return (
                                        <div className='benchmark_year_el' key={el}><input type="checkbox" checked={year === el} onChange={() => handleYear(el)} /> {el}</div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='benchmark_graph'>
                        <div style={{ width: '88%', padding: '20px' }}>
                            {!category && <div style={{
                                zIndex: 2,
                                position: "absolute",
                                left: "40%", top: "50%",
                                background: '#fff',
                                border: '1px solid #bdbdbd', padding: '4px 8px', borderRadius: '5px'
                            }}>Please select any category</div>}
                            {state.labels.length === 0 && category && <div style={{
                                zIndex: 1,
                                position: "absolute",
                                left: "40%", top: "50%",
                                background: '#fff',
                                border: '1px solid #bdbdbd', padding: '4px 8px', borderRadius: '5px'
                            }}>No data available for this category</div>}
                            <Chart
                                type='line'
                                data={data}
                                height={100}
                                options={{
                                    elements: {
                                        point: {
                                            radius: 0,
                                        }
                                    },
                                    tension: 0.2,
                                    borderWidth: 2,
                                    // maintainAspectRatio: false,
                                    interaction: {
                                        mode: "index",
                                        intersect: false
                                    },
                                    plugins: {
                                        legend: {
                                            position: 'right',
                                            display: false,
                                            // labels: {
                                            //   textAlign: 'center',
                                            //   boxWidth: 90,
                                            //   boxHeight: 40,
                                            //   font: {
                                            //     size: 24
                                            //   }
                                            // }
                                        },
                                        tooltip: {
                                            usePointStyle: true,
                                            backgroundColor: 'rgba(255, 255, 255,1)',
                                            bodyColor: 'rgba(0, 0, 0 , 0.8)',
                                            titleColor: 'rgba(0, 0, 0 , 0.6)',
                                            titleFont: {
                                                size: 14,
                                                weight: 'bold'
                                            },
                                            titleMarginBottom: 6,
                                            padding: 10,
                                            bodySpacing: 8,
                                            bodyFont: {
                                                weight: "bold",
                                                size: 14
                                            },
                                            borderColor: "rgba(189, 189, 189, 1)",
                                            borderWidth: 1,
                                            boxWidth: 40,
                                            boxPadding: 10,
                                            bodyAlign: 'right',
                                            // intersect: false
                                            // mode: "x"
                                            callbacks: {
                                                label: (tooltipItem) => tooltipCallback(tooltipItem, data)
                                            }
                                        },
                                    }
                                }}
                            />

                        </div>
                        <div className='benchmark_kpi'>
                            {metricArr.map((el) => {
                                return (
                                    <div
                                        style={{ border: `2px solid ${colorSet(el.value)}`, borderRadius: '5px', padding: '4px 8px' }}
                                        key={el.value}
                                    ><input type="checkbox" checked={metric.includes(el.value)}
                                        onChange={() => handleMetric(el.value)} /> {el.name}</div>
                                )
                            })}
                        </div>
                    </div>
                    {isLoading && <div style={{ zIndex: 999, position: "absolute", left: "50%", top: "50%" }}><Loader /></div>}
                </div>
            </div>
            {/* <ModalBox
                user={user}
                setUser={setUser}
                open={open}
                setOpenLogin={setOpenLogin}
                setOpen={setOpen}
                setToken={setToken}
            /> */}
        </>
    )
}

export default BenchmarkContainer;