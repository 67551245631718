import React, { useEffect } from 'react'
import { useState } from 'react';
import './Pagination.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

function Pagination({ docCount, rowPerPage, setRowPerPage, currentPage, setCurrentPage }) {
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;

    const [page, setPage] = useState("");
    const rowPerPageArr = [50, 100, 200];
    const totalPage = Math.ceil(docCount / rowPerPage);

    useEffect(() => {
        setCurrentPage(1);
    }, [current_brand._id])

    const onChangeHandler = (e) => {
        setPage(e.target.value);
    }
    const pageHandler = () => {
        if (!page) return;
        if (page > totalPage) {
            NotificationManager.error("Invalid Page No.", 'Error', 3000);
            setPage("");
        } else {
            setCurrentPage(Number(page));
            setPage("");
        }
    }

    let timer;
    const pageChangeHandler = (button) => {
        // console.log(button);
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (button === 'prev' && currentPage === 1) {
                return setCurrentPage(1);
            }
            if (button === 'prev') {
                setCurrentPage(currentPage - 1);
            }
            if (button === 'next') {
                setCurrentPage(currentPage + 1);
            }
            if (button === 'next' && currentPage === totalPage) {
                return setCurrentPage(currentPage);
            }

        }, 500)
        setPage('');
    }

    const rowPerPageHandler = (e) => {
        setPage("");
        setCurrentPage(1);
        setRowPerPage(e.target.value);
    }

    return (
        <div className='pagination_container'>
            <div>
                <span>No. of rows per page: </span>
                <select name="" id="" value={rowPerPage} onChange={rowPerPageHandler}>
                    {rowPerPageArr.length > 0 &&
                        rowPerPageArr.map((el) => {
                            return (
                                <option key={el} value={el}>{el}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className='back_next_container'>
                <span onClick={() => pageChangeHandler('prev')}>◀️</span>
                <span style={{ fontSize: '16px' }}>Page {currentPage} of {totalPage}</span>
                <span onClick={() => pageChangeHandler('next')}>▶️</span>
            </div>
            <div>
                <input className='page_input' type="number" placeholder='Enter page no.' value={page} onChange={(e) => onChangeHandler(e)} />
                <button className='go-btn' onClick={pageHandler}>Go</button>
            </div>
            <NotificationContainer />
        </div>
    )
}

export default Pagination;