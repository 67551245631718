import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Popover from '@mui/material/Popover';


const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    display: "flex",
    // justifyContent: "space-between",
    padding: "10px",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "5px",

    background: isDragging ? "#fff" : "white",
    marginBottom: "5px",
    boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#ececec" : "#ececec",
    padding: 4,
    // margin: "10px 0"
});

export default function ServiceCommandUnit(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Droppable droppableId={props.type} type={`child`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {props.parent_asin_array.map((item, index) => (
                        <Draggable key={item._id} draggableId={item._id} index={index}>
                            {(provided, snapshot) => (
                                <div>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                        {...provided.dragHandleProps}
                                    >   <span style={{ display: "flex" }} onClick={handleClick} >
                                            <div style={{ display: "flex" }}>
                                                <img src={item.image_url} style={{ height: "32px", width: "32px", borderRadius: "50%", cursor: "pointer" }} />
                                                <span style={{ cursor: "pointer" }} className="dropdown-toggle mx-1"></span>
                                            </div>


                                        </span>
                                        <a style={{ textDecoration: "none" }} href={`https://www.amazon.in/dp/${item.platform_code}`} target="_blank">
                                            <abbr style={{ textDecoration: "none" }} title={item.name}>{item.name.split("|")[0]}</abbr>
                                        </a>
                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            sx={{ color: "#003769" }}
                                        >
                                            <div key={index + 1} style={{ color: "#003769" }} className="px-3 p-3">
                                                Ratings: {item.rating}
                                            </div>
                                            <div key={index} style={{ color: "#003769" }} className="px-3 pb-3">
                                                Reviews: {item.no_of_reviews}
                                            </div>
                                        </Popover>

                                        {/* <span
                                            {...provided.dragHandleProps}
                                            style={{ float: "right" }}
                                        >
                                            <i class="bi bi-arrows-move"></i>
                                        </span> */}
                                    </div>

                                    {provided.placeholder}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );

}