import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import './SellersList.css';
import { AgGridReact } from "ag-grid-react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../../appConstants';
// import SellerListUpload from './subComponents/SellerListUpload';
import { NotificationManager } from 'react-notifications';
import Loader from '../../../commonComponent/Loader/Loader';
import { debounce } from '../../../../commonFunction/commomFunction';


function SellersList() {
  const gridRef = useRef();
  const appParams = useSelector(state => state.appParams);
  const { current_brand: { _id, user_type } } = appParams;
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const token = localStorage.getItem('token');

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL}clientSellerDetail/master?brandId=${_id}`, {
      headers: {
        token
      }
    }).then((res) => {
      const data = res.data?.data;
      // console.log(data);
      setRowData(data);
      setIsLoading(false);
    }).catch(err => {
      console.log(err);
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id])

  const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1, minWidth: 150 }), []);


  const SellerTypeComponent = ({ seller_type, data }) => {
    let checked = (data.seller_type.toLowerCase() === seller_type.toLowerCase()) ? "checked" : undefined;
    // console.log(data.seller_type);
    return (
      <div className='seller_type'>
        <input type='radio' checked={checked} onChange={(e) => {
          if (seller_type !== data.seller_type) {
            data.seller_type = seller_type;
            setRowData((prev) => {
              return { ...prev }
            })
          }
        }} />
      </div>
    )
  }

  const columnDefs = useMemo(() => [
    { headerName: 'Seller Name', field: 'seller_name', width: 150, },
    {
      headerName: 'Authorised', field: 'authorised', width: 150,
      // editable: true,
      cellRenderer: ({ data }) => SellerTypeComponent({ seller_type: "authorised", data })
    },
    {
      headerName: 'Unauthorised', field: 'unauthorised', width: 150,
      // editable: true,
      cellRenderer: ({ data }) => SellerTypeComponent({ seller_type: "unauthorised", data })
    },
    { headerName: 'Portfolio', field: 'seller_portfolio', width: 300 },
    { headerName: 'Ratings', field: 'seller_rating', width: 100 },
  ], [rowData])

  const handleCancel = useCallback(() => {
    setIsLoading(true);
    axios.get(`${BASE_URL}clientSellerDetail/master?brandId=${_id}`, {
      headers: {
        token
      }
    }).then((res) => {
      const data = res.data?.data;
      // console.log("👋👋", data);
      setRowData(data);
      setIsLoading(false);
    }).catch(err => {
      console.log(err);
      setIsLoading(false)
      const errMsg = err?.response?.data?.data?.message || 'Somthing went wrong.'
      NotificationManager.error(errMsg, 'error', 3000)
    })
    // NotificationManager.success('Sellers already updated', 'success', 3000)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData])

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    axios.put(`${BASE_URL}clientSellerDetail/master/${rowData?.seller_master?._id}?brandId=${_id}`,
      {
        seller_array: rowData?.seller_master?.seller_array
      },
      {
        headers: {
          token
        }
      }).then((res) => {
        console.log(res.data.data.seller_master);
        const { seller_array } = res.data.data.seller_master;
        // seller_array.forEach((el)=>{
        //   el.seller_type = 
        // })
        NotificationManager.success('Sellers submited successfully', 'success', 3000);
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
        setIsLoading(false)
        const errMsg = err?.response?.data?.data?.message || 'You have viewer access. You can not submit'
        NotificationManager.error(errMsg, 'error', 3000)
      })
    // NotificationManager.success('Sellers already updated', 'success', 3000);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData])

  const onBtnExport = useCallback(() => {
    // console.log(gridRef.current.api);
    gridRef.current.api.exportDataAsCsv();
  }, [])

  return (
    <div className='product_master_container'>
      {isLoading && <div style={{ zIndex: 1, position: "absolute", left: "50%", top: "50%" }}><Loader /></div>}
      <div style={{ display: 'flex' }}>
        <div className='instruction_header'>
          <span style={{ color: '#b8b6b6', fontSize: "15px", paddingLeft: '18px' }}>Instructions:</span>
          <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
            <li>Please double click on "Seller Type" fields to choose Authorised Seller or Unauthorised Seller.</li>
            <li>Please confirm with all the details and submit.</li>
          </ul>
        </div>
        <div className='seller_master_header'>
          {/* <SellerListUpload rowData={rowData} setRowData={setRowData} setUpload={setUpload} /> */}
          <button style={{ minHeight: "31px" }} type='button' className='submit_btn' onClick={onBtnExport}><i className="bi bi-download"></i> Download</button>
        </div>
      </div>

      <div className="ag-theme-alpine" style={{ height: 500, margin: "10px 0 10px 0" }}>
        <AgGridReact
          ref={gridRef}
          // onRowClicked={onRowClicked}
          rowData={rowData?.seller_master?.seller_array}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowHeight={40}
          headerHeight={50}
          // rowSelection={'multiple'}
          // rowMultiSelectWithClick={true}
          suppressLoadingOverlay={true}
          suppressNoRowsOverlay={true}
          suppressMovableColumns={true}
          suppressDragLeaveHidesColumns={true}
          suppressMenuHide={true}
        />
      </div>

      <div className='product_master_footer'>
        <button type='button' className={`${user_type && user_type === 'brand_viewer' ? 'cancel_btn disabled-button' : 'cancel_btn'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={debounce(handleCancel, 200)}>Cancel</button>
        <button type='button' className={`${user_type && user_type === 'brand_viewer' ? 'submit_btn disabled-button' : 'submit_btn'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={debounce(handleSubmit, 200)}>Submit</button>
      </div>
    </div>
  )
}

export default SellersList;