import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { NotificationManager } from 'react-notifications';

function VisibilityUpload(props) {
    const fileInputRef = useRef();
    const { rowData, setRowData, columnDefs, file, setFile } = props;
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { _id, client_profile_id, client_name } } = appParams;

    const handleFileChange = (e) => {
        const extractedFile = e.target.files[0];
        if (extractedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const dataArr = jsonData.slice(1);
                // console.log(dataArr);
                // setFile(jsonData);
                if (jsonData.length > 0) {
                    const headers = jsonData[0];
                    // console.log(headers);
                    const checkHeaders = ['target', 'asin'];
                    // console.log(checkHeaders);
                    const checkAllElExist = checkHeaders.every(el => headers.includes(el));
                    if (headers.length === 2 && checkAllElExist) {
                        const arrOfObjdata = dataArr.map((row) => {
                            const dataObj = {};
                            headers.forEach((header, index) => {
                                dataObj[header] = row[index];
                            });
                            return dataObj;
                        });
                        setFile(arrOfObjdata);
                    } else {
                        NotificationManager.error("File doesn't contain the required headers and data", 'Error', 3000);
                        setTimeout(() => {
                            fileInputRef.current.form.reset();
                        }, 1000)
                    }
                } else {
                    setFile([]);
                }
            };
            reader.readAsBinaryString(extractedFile);
        }
    }

    const handleUpload = () => {
        if (file) {
            // console.log(el);
            let visibility = file.map((el) => {
                // console.log(el);
                const { target, asin } = el;
                return {
                    target,
                    asin,
                    status: "draft"
                }
            })
            // console.log(visibility);
            setRowData((prevState) => {
                const newState = [...visibility, ...prevState]
                return newState;
            });
        }
        else {
            console.log('error');
        }
        fileInputRef.current.form.reset();
    }
    return (
        <div className='upload_container'>
            <div className='selected_upload'>
                <form>
                    <input
                        ref={fileInputRef}
                        className='form-control form-control-sm'
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChange}
                    />
                </form>
            </div>
            <button type='button' className='cancel_btn' onClick={handleUpload}><i className="bi bi-upload"></i> Upload</button>
        </div>
    )
}

export default VisibilityUpload;