import React, { useState, useMemo, useEffect } from 'react';
import { AgGridReact } from "ag-grid-react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../../../appConstants';
import '../Keywords.css';
import ModalComponent from '../../../../commonComponent/Modal/ModalComponent';
import { NotificationManager } from 'react-notifications';
import Loader from '../../../../commonComponent/Loader/Loader';

function SalesKeywords() {
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;
    const [rowData, setRowData] = useState([]);
    const [objId, setObjId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [typeObj, setTypeObj] = useState({})
    const [val, setVal] = useState();
    const [newKeywordObj, setNewKeywordObj] = useState({
        targeting: "",
        type: ""
    });
    const token = localStorage.getItem('token');

    const fetchTopSalesKeyword = () => {
        setIsLoading(true);
        axios.get(`${BASE_URL}keywords/topSalesKeyword?brandId=${current_brand._id}`,
            // {
            //     time_stamp: getAppStandardDate(selectedDate),
            // },
            {
                headers: {
                    token
                }
            }).then((res) => {

                const data = res.data.data.topSalesKeywords.keyword_array
                setObjId(res.data.data.topSalesKeywords._id);
                let typeObj = {

                };
                for (let i = 0; i < data.length; i++) {
                    let type = data[i].type;
                    if (typeObj[type]) {
                        typeObj[type] += 1;
                    } else {
                        typeObj[type] = 1;
                    }

                }
                setTypeObj(typeObj)
                setRowData(data);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        fetchTopSalesKeyword()
    }, [current_brand._id]);

    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1, minWidth: 150 }), []);

    const TargetingComponent = (props) => {
        const { targeting } = props.data;
        const handleChange = (e) => {
            targeting = e.target.value;
        }
        return (
            <div onChange={handleChange}>{targeting}</div>
        )
    }
    const handleCellValueChanged = (params) => {
        const { newValue, oldValue } = params;

        setRowData(prevState => {
            let prdf = structuredClone(prevState);
            function changeRank(targeting, newRank) {
                const currentRank = parseInt(oldValue);
                if (currentRank > newRank) {
                    prdf
                        .filter(item => item.priority_rank >= newRank && item.priority_rank < currentRank)
                        .forEach(item => item.priority_rank += 1);
                } else if (currentRank < newRank) {
                    prdf
                        .filter(item => item.priority_rank <= newRank && item.priority_rank > currentRank)
                        .forEach(item => item.priority_rank -= 1);
                }

                let res = prdf.find(item => item.targeting === targeting).priority_rank = newRank;
                return prdf.sort((a, b) => a.priority_rank - b.priority_rank);
            }
            changeRank(params.data.targeting, parseInt(newValue));
            return prdf
        })
        params.api.refreshCells({ rowNodes: [params.node], force: true });
    };
    const columnDefs = useMemo(() => [
        { headerName: 'Targeting', field: 'targeting', width: 150, editable: true, cellRenderer: TargetingComponent },
        {
            headerName: 'Type', field: 'type', width: 150,
            // editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: {
            //     values: ["brand", "generic", "competition"],
            // }
        },
        { headerName: 'L1M Sales Rank', field: 'priority_rank', width: 150, editable: true, onCellValueChanged: handleCellValueChanged, },
        { headerName: 'L1M Sales Volume', field: 'sales_volume', width: 200 },
        { headerName: 'L2M Sales Rank', field: 'l2m_priority_rank', width: 150, },
        { headerName: 'L2M Sales Volume', field: 'l2m_sales_volume', width: 200 },
        { headerName: 'L3M Sales Rank', field: 'l2m_priority_rank', width: 150, },
        { headerName: 'L3M Sales Volume', field: 'l3m_sales_volume', width: 200 },
    ], [])


    const onCellEditingStopped = (event) => {
        // const { data: { _id }, column: { colId } } = event;
        const { newValue, data: { targeting }, oldValue } = event;
        console.log(rowData)
        // setRowData(prdf => {
        //     function changeRank(targeting, newRank) {
        //         const currentRank = prdf.find(item => item.targeting === targeting).priority_rank;

        //         if (currentRank > newRank) {
        //             prdf
        //                 .filter(item => item.priority_rank >= newRank && item.priority_rank < currentRank)
        //                 .forEach(item => item.priority_rank += 1);
        //         } else if (currentRank < newRank) {
        //             prdf
        //                 .filter(item => item.priority_rank <= newRank && item.priority_rank > currentRank)
        //                 .forEach(item => item.priority_rank -= 1);
        //         }

        //         prdf.find(item => item.targeting === targeting).priority_rank = newRank;

        //         return prdf.sort((a, b) => a.priority_rank - b.priority_rank);
        //     }


        //     changeRank(targeting, parseInt(newValue));
        //     console.log(prdf);
        //     return prdf;
        // })
        // setLoading(true);
        // axios.put(`${BASE_URL}keywords/topSalesKeyword/${_id}?brandId=${current_brand._id}`,
        //     {
        //         [colId]: event.data[colId]
        //     },
        //     {
        //         headers: {
        //             token
        //         }
        //     }).then((res) => {
        //         console.log(res.data.data);
        //     }).catch((err) => {
        //         // console.log(err.response.data.data.message);
        //         // setLoading(false);
        //         // fetchData();
        //         // NotificationManager.error(err.response.data.data.message, 'error', 3000);
        //     })
    }

    const [open, setOpen] = useState(false);
    const type = ['brand', "generic", "cmpetition"];

    const addKeyword = () => {
        setOpen(true);
    }
    const [keyword, setKeywords] = useState()

    const handleTypeChange = () => {

    }

    const handleSumbit = () => {
        setIsLoading(true);
        axios.put(`${BASE_URL}keywords/topSalesKeyword/${objId}?brandId=${current_brand._id}`,
            {
                keyword_array: rowData
            },
            {
                headers: {
                    token
                }
            }).then((res) => {
                const { _id, keyword_array } = res.data.data.updateTopSalesKeyword;
                let typeObj = {
                    brand: 0,
                    generic: 0,
                    competition: 0
                };
                for (let i = 0; i < keyword_array.length; i++) {
                    let type = keyword_array[i].type;
                    typeObj[type] += 1;
                }
                setTypeObj(typeObj)
                setRowData(keyword_array);
                setIsLoading(false);
                NotificationManager.success('Top Sales Keyword submited successfully', 'success', 3000)
            }).catch((err) => {
                // console.log(err.response.data.data.message);
                // setLoading(false);
                // fetchData();
                NotificationManager.error(err.response.data.data.message, 'error', 3000);
            }).finally(() => {
                setIsLoading(false);
            })
    }
    const onInputChange = (e) => {

        setNewKeywordObj(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
    const addNewKeyword = () => {
        setOpen(false);
        setRowData(prevState => ([...prevState, { ...newKeywordObj, sales_volume: "", priority_rank: "" }]));
    }
    let typeArray = [];
    for (let el in typeObj) {
        typeArray.push([el, typeObj[el]]);
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='type_count_container'>

                    {
                        typeArray.map((el, i) => {
                            return <span key={i}> <strong>{`${el[0]}:${el[1]}`.toUpperCase()} |</strong> </span>
                        })
                    }
                </div>
                {/* <div>
                    <button className='add_keywords' onClick={addKeyword}>+Add Keyword</button>
                </div> */}
            </div>
            <div className="ag-theme-alpine" style={{ height: 450, margin: "10px 0 10px 0" }}>
                <AgGridReact
                    // ref={gridRef}
                    // onRowClicked={onRowClicked}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowHeight={40}
                    headerHeight={50}
                    // rowSelection={'multiple'}
                    // rowMultiSelectWithClick={true}
                    //  onCellEditingStopped={onCellEditingStopped}
                    suppressLoadingOverlay={true}
                    suppressNoRowsOverlay={true}
                    suppressMovableColumns={true}
                    suppressDragLeaveHidesColumns={true}
                />
            </div>
            <ModalComponent
                open={open}
                modalClose={() => setOpen(false)}
            >
                <form className='modal_keyword_form' action="">

                    <div className='modal_keyword_input_form'>
                        <span><strong>Targeting: </strong></span>
                        <input onChange={onInputChange} value={newKeywordObj.targeting} type="text" name="targeting" className="form-control" />
                    </div>
                    <div className='modal_keyword_input_form'>
                        <span><strong>Type: </strong></span>
                        <select onChange={onInputChange} value={newKeywordObj.type} name="type" id="" className="form-select">
                            {type.map((el, i) => {
                                return (
                                    <option key={i} value={el} >{el}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "10px" }}>
                        <button className='modal_button_cancel' onClick={() => setOpen(false)}>Cancel</button>
                        <button type='button' className='modal_button_ok' onClick={addNewKeyword} >Add</button>
                    </div>
                </form>
            </ModalComponent>
            {/* <div className='product_master_footer'>
                <button type='button' className='cancel_btn' >Cancel</button>
                <button type='button' className='submit_btn' onClick={handleSumbit} >Submit</button>
            </div> */}
            {
                isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
            }
        </div>

    )
}

export default SalesKeywords