import React, { useState, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../appConstants';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import Loader from '../../commonComponent/Loader/Loader';
import './MainContainer.scss';
import { useSelector } from 'react-redux';
import ModalComponent from '../../commonComponent/Modal/ModalComponent';
import VisibilityUpload from './visibilityComponents/VisibilityUpload';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const Visibility = () => {
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { client_profile_id } } = appParams;
    const gridRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [isSubmited, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    

    const [file, setFile] = useState(null);
    const [mobileNo, setMobileNo] = useState('');
    const [visibilityRecords, setVisibilityRecords] = useState([]);

    const [check, setCheck] = useState(false);

    const fetchData = () => {
        setIsLoading(true)
        axios.get(`${BASE_URL}visibility-check/${client_profile_id}`, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data.data.visibilityCheckRecords;
            // console.log(data);
            setRowData(data);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [client_profile_id])

    const editFn = (params) => {
        if (params.node.data.status === "draft") {
            return true;
        }
        return false;
    }
    const disabledCellRenderer = ({ value, disabled }) => {
        return (
            <div style={{ opacity: disabled ? 0.7 : 1, }}>
                {value}
            </div>
        );
    };


    const StatusComp = (props) => {
        const { data: { _id, status }, onChange } = props;

        const handleChange = (e) => {
            onChange(e.target.checked, _id);
        };
        return (
            <div>
                <input type="checkbox" value={check} onChange={handleChange} disabled={status !== 'done'} />
            </div>
        )
    }

    const ImageURLComponent = (props) => {
        const { file_url } = props.data;
        const link = encodeURIComponent(file_url);
        return (
            <div>
                <a href={file_url} style={{ color: "var(--active-color)", cursor: "pointer", fontWeight: "600" }} target="_blank">{file_url}</a>
            </div>
        )
    }

    const VisibilityComp = (props) => {
        const { visibility_status } = props.data;
        const showVisibility = (visibility) => {
            if (visibility === 0) {
                return 'Yes'
            } else if (visibility === 1) {
                return "No"
            } else {
                return null
            }
        }
        return (
            <div>
                {showVisibility(visibility_status)}
            </div>
        )
    }


    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, flex: 1 }), []);
    const columnDefs = useMemo(() => ([
        { headerName: 'Target', field: 'target', editable: editFn, cellRendererParams: { disabled: '' }, cellRenderer: disabledCellRenderer },
        { headerName: 'Asin', field: 'asin', editable: editFn, cellRendererParams: { disabled: '' }, cellRenderer: disabledCellRenderer },
        { headerName: 'Status', field: 'status', editable: false, cellRendererParams: { disabled: true }, cellRenderer: disabledCellRenderer },
        { headerName: 'Visibility', field: 'visibility_status', cellRenderer: VisibilityComp },
        { headerName: 'SFR', field: 'sfr' },
        { headerName: 'Search Volume', field: 'search_volume' },
        { headerName: 'Created At', field: 'created_at' },
        { headerName: 'Checked At', field: 'checked_at' },
        { headerName: 'Image URL', field: 'file_url', cellRenderer: ImageURLComponent },
        {
            headerName: 'Mark to Send',
            field: '',
            cellRendererFramework: StatusComp,
            cellRendererParams: {
                onChange: (newValue, rowId) => {
                    if (newValue && !visibilityRecords.includes(rowId)) {
                        visibilityRecords.push(rowId)
                    } else {
                        visibilityRecords.pop();
                    }
                }
            },
        },
    ]), [visibilityRecords]);


    const addRow = () => {
        const newRow = {
            target: '',
            asin: '',
            status: 'draft',
            // img_url: '',
            // marked: false
        };
        setFile([newRow]);
        setRowData([newRow, ...rowData,]);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const res = await axios.post(`${BASE_URL}visibility-check/${client_profile_id}`, {
                data: file
            }, {
                headers: {
                    token
                }
            })
            // setIsLoading(false);
            // console.log(file, res.data.data.createdVisibilityCheckRecords);
            // const data = res.data.data.createdVisibilityCheckRecords;
            // setRowData([...data, ...rowData]);
            fetchData();
            NotificationManager.success('Data Successfully Submited', 'Success', 3000)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
            NotificationManager.error('Unable To Submit Data', 'Error', 3000)
        }
    }

    const saveSelectedRows = () => {
        setOpen(true)
    };

    const handleSend = async () => {
        // console.log(visibilityRecords, mobileNo);
        setIsLoading(true);
        if (visibilityRecords.length === 0) {
            setOpen(false);
            setMobileNo("");
            return NotificationManager.error('Select any row to send', 'Error', 3000)
        }
        if (!mobileNo) {
            return NotificationManager.error('Please provide a Mobile No.', 'Error', 3000)
        }
        try {
            await axios.post(`${BASE_URL}visibility-check/send/${client_profile_id}`, {
                phoneNo: mobileNo,
                visibilityRecords: visibilityRecords
            })
            setIsLoading(false);
            NotificationManager.success('Successfully sent', 'Success', 3000);
            setMobileNo("");
            setCheck(false);
            setVisibilityRecords([]);
        } catch (error) {
            console.log(error);
            setIsLoading(false)
            NotificationManager.error('Unable to send', 'Error', 3000)
        }
        setOpen(false);
    }

    return (
        <div>
            <div className="ag-theme-alpine" style={{ height: 525, width: '100%' }}>
                {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        rowHeight={AG_AR.rH}
                        headerHeight={AG_AR.hH}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressNoRowsOverlay={true}
                        suppressDragLeaveHidesColumns={true}
                        suppressMenuHide={true}
                        editType={"fullRow"}
                        rowSelection='multiple'
                    >
                    </AgGridReact>
                }
                {
                    isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                }
            </div>
            <div className='button_check'>
                <div style={{ marginTop: '10px' }}>
                    <VisibilityUpload
                        rowData={rowData}
                        setRowData={setRowData}
                        columnDefs={columnDefs}
                        file={file}
                        setFile={setFile}
                    />
                </div>
                <button className='keyword-button' onClick={addRow}>Add-Row</button>
                <button className='keyword-button' onClick={handleSubmit}>Submit</button>
                <button className='keyword-button' onClick={saveSelectedRows}>Send On Whatsapp</button>
            </div>
            <ModalComponent
                open={open}
                modalClose={() => setOpen(false)}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        {/* <div>Initialize Visibility Check Reports On Whatsapp :</div> */}
                        <a className='buttonLink' href='https://wa.me/+918587000241?text=Initialize Visibility Check Reports' target='_blank' rel='noreferrer'>Initialize Visibility Check Reports On Whatsapp</a>
                    </div>
                    <hr></hr>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <label htmlFor="">Enter Your WhatsApp <i className="bi bi-whatsapp"></i> Number :</label>
                        <input className='form-control form-control-sm' type="number" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} />
                        <button className='buttonLink' onClick={handleSend}>Send Marked Targets</button>
                    </div>
                </div>
            </ModalComponent>
            
        </div>
    )
}

export default Visibility