import { useEffect } from "react";
import axios from "axios";
import LineGraph from "../../../graphs/LineGraph";
import { useState } from "react";
import { BASE_URL } from "../../../../appConstants";
import { useSelector } from "react-redux";
import { getAppStandardDate } from "../../../../utils/commonFunction";
import Loader from "../../../commonComponent/Loader/Loader";
import ReportsErrorPage from "../../../errorComponent/ReportsErrorPage";

const LineGraphComponent = (props) => {
    const token = localStorage.getItem("token");
    const appParams = useSelector(state => state.appParams);
    const { current_brand, current_agency } = appParams;
    const { adType, graphDataType, tgic, clickedTile, dateFilter, group_by, loader, setLoader, monthYearArray, monthYearArrayWeek } = props;
    const [error, setError] = useState(null);
    const [state, setState] = useState({
        labels: [],
        impressions: [],
        clicks: [],
        ctr: [],
        cpc: [],
        orders: [],
        conversions: [],
        acos: [],
        cost: [],
        sales: [],
        roas: [],
        tos_is: []
    })

    useEffect(() => {

        const start_date = getAppStandardDate(dateFilter.startDate);
        const end_date = getAppStandardDate(dateFilter.endDate);
        // console.log(start_date, end_date);
        let body = {
            graph_data_type: graphDataType,
            group_by,

        }
        if (graphDataType === "daily") {
            body["start_date"] = start_date
            body["end_date"] = end_date
        } else {
            let m_y_array = []
            for (let el of monthYearArray) {
                if (el.selected) {
                    m_y_array.push(el.month_year);
                }
            }
            body["month_year_array"] = m_y_array
            if (!m_y_array.length) {
                return;
            }
        }
        setLoader(true);
        axios.post(`${BASE_URL}dashboard/advertisingReport/getGraphData?brandId=${current_brand._id}`,
            body
            , {
                headers: {
                    token
                }
            }).then(function (response) {
                const { graph_data_array, graph_data_type } = response.data.data;
                // console.log(graph_data_type);
                if (response.status === 200 && graph_data_array.length === 0) {
                    throw Error(`No data available for graph in this brand`);
                }
                setError(false);
                const labelsArray = [];
                const salesArray = [];
                const costArray = [];
                const acosArray = [];
                const ordersArray = [];
                const clicksArray = [];
                const cpcArray = [];
                const impressionsArray = [];
                const ctrArray = [];
                const conversionsArray = [];
                const roasArray = [];
                const tos_isArray = [];
                if (graph_data_array.length > 0) {
                    graph_data_array.forEach(e => {
                        const { time_stamp,
                            impressions,
                            clicks,
                            ctr,
                            cpc,
                            orders,
                            conversions,
                            acos,
                            cost,
                            sales,
                            roas,
                            tos_is
                        } = e;
                        if (graph_data_type === "daily") {
                            labelsArray.push((time_stamp.split("T")[0]));
                        } else {
                            labelsArray.push(time_stamp)
                        }
                        impressionsArray.push(impressions);
                        clicksArray.push(clicks);
                        ctrArray.push(ctr);
                        cpcArray.push(cpc);
                        ordersArray.push(orders);
                        conversionsArray.push(conversions);
                        acosArray.push(acos);
                        costArray.push(cost);
                        salesArray.push(sales);
                        roasArray.push(roas);
                        tos_isArray.push(tos_is);
                    })
                }
                setState(prevState => ({
                    ...prevState,
                    graph_data_type,
                    labels: labelsArray,
                    impressions: impressionsArray,
                    clicks: clicksArray,
                    ctr: ctrArray,
                    cpc: cpcArray,
                    orders: ordersArray,
                    conversions: conversionsArray,
                    acos: acosArray,
                    cost: costArray,
                    sales: salesArray,
                    roas: roasArray,
                    tos_is: tos_isArray
                }))
                setLoader(false);
            }).catch(function (error) {
                console.log(error);
                setLoader(false);
                setError(error.message);
            });
    }, [adType, graphDataType, dateFilter, current_brand._id, monthYearArray, monthYearArrayWeek])

    const getSunSatColorBackground = (index, defaultColor) => {
        const time_stamp = state.labels[index];
        // console.log(time_stamp);
        if (state.graph_data_type === "daily") {
            const day = new Date(`${time_stamp}T00:00:00.000+00:00`).getDay();
            // console.log(day);
            if (day === 0) return "rgba(31, 92, 120, 0.7)";//orange sunday
            if (day === 6) return "rgba(108, 117, 125, 0.7)";//yellow saturday
            return defaultColor;
        } else {
            return defaultColor;
        }
    }

    const getSunSatColorBorder = (index, defaultColor) => {
        const time_stamp = state.labels[index];
        if (state.graph_data_type === "daily") {
            const day = new Date(`${time_stamp}T00:00:00.000+00:00`).getDay();
            if (day === 0) return "rgba(31, 92, 120, 1)";//orange sunday
            if (day === 6) return "rgba(108, 117, 125, 1)";//yellow saturday
            return defaultColor;
        } else {
            return defaultColor;
        }
    }
    const getBorderColorForMetric = (metric) => {
        const [metricObj] = clickedTile.filter(obj => obj.id === metric);
        if (metricObj) {
            return metricObj.borderColor
        } else {
            return ""
        }
    }
    const getBackgroundColorForMetric = (metric) => {
        const [metricObj] = clickedTile.filter(obj => obj.id === metric);
        if (metricObj) {
            return metricObj.backgroundColor
        } else {
            return ""
        }
    }
    const graphDatasets = [
        {
            label: 'Impressions',
            // fill: true,
            data: state.impressions,
            // borderColor: 'rgba(50,205,50, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "impressions" ? getSunSatColorBorder(index, getBorderColorForMetric("impressions")) : getBorderColorForMetric("impressions");
            },
            // backgroundColor: 'rgba(50,205,50, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "impressions" ? getSunSatColorBackground(index, getBackgroundColorForMetric("impressions")) : getBackgroundColorForMetric("impressions");
            },
            axesColor: getBorderColorForMetric("impressions"),
            // pointStyle: 'cross',
            borderWidth: 2
        },
        {
            label: 'Sales',
            // fill: true,
            data: state.sales,
            // borderColor: 'rgba(255, 99, 132, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "sales" ? getSunSatColorBorder(index, getBorderColorForMetric("sales")) : getBorderColorForMetric("sales");
            },
            //  backgroundColor: 'rgba(255, 99, 132, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "sales" ? getSunSatColorBackground(index, getBackgroundColorForMetric("sales")) : getBackgroundColorForMetric("sales");
            },
            axesColor: getBorderColorForMetric("sales"),
            // pointStyle: 'rectRot',
            borderWidth: 2


        },
        {
            label: 'cost',
            // fill: true,
            data: state.cost,
            // borderColor: 'rgba(54, 162, 235, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "cost" ? getSunSatColorBorder(index, getBorderColorForMetric("cost")) : getBorderColorForMetric("cost");
            },
            // backgroundColor: 'rgba(54, 162, 235, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "cost" ? getSunSatColorBackground(index, getBackgroundColorForMetric("cost")) : getBackgroundColorForMetric("cost");
            },
            axesColor: getBorderColorForMetric("cost"),
            // pointStyle: 'triangle',
            borderWidth: 2

        },
        {
            label: 'ACOS',
            // fill: true,
            data: state.acos,
            // borderColor: 'rgba(255, 206, 86, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "acos" ? getSunSatColorBorder(index, getBorderColorForMetric("acos")) : getBorderColorForMetric("acos");
            },
            // backgroundColor: 'rgba(255, 206, 86, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "acos" ? getSunSatColorBackground(index, getBackgroundColorForMetric("acos")) : getBackgroundColorForMetric("acos");
            },
            axesColor: getBorderColorForMetric("acos"),
            // pointStyle: 'circle',
            borderWidth: 2

        },
        {
            label: 'Orders',
            // fill: true,
            data: state.orders,
            // borderColor: 'rgba(75, 192, 192, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "orders" ? getSunSatColorBorder(index, getBorderColorForMetric("orders")) : getBorderColorForMetric("orders");
            },
            // backgroundColor: 'rgba(75, 192, 192, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "orders" ? getSunSatColorBackground(index, getBackgroundColorForMetric("orders")) : getBackgroundColorForMetric("orders");
            },
            axesColor: getBorderColorForMetric("orders"),
            // pointStyle: 'rect',
            borderWidth: 2
        },
        {
            label: 'Clicks',
            // fill: true,
            data: state.clicks,
            // borderColor: 'rgba(153, 102, 255, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "clicks" ? getSunSatColorBorder(index, getBorderColorForMetric("clicks")) : getBorderColorForMetric("clicks");
            },
            // backgroundColor: 'rgba(153, 102, 255, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "clicks" ? getSunSatColorBackground(index, getBackgroundColorForMetric("clicks")) : getBackgroundColorForMetric("clicks");
            },
            axesColor: getBorderColorForMetric("clicks"),
            // pointStyle: 'rectRounded',
            borderWidth: 2

        },
        {
            label: 'CPC',
            // fill: true,
            data: state.cpc,
            // borderColor: 'rgba(255,20,147, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "cpc" ? getSunSatColorBorder(index, getBorderColorForMetric("cpc")) : getBorderColorForMetric("cpc");
            },
            // backgroundColor: 'rgba(255,20,147, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "cpc" ? getSunSatColorBackground(index, getBackgroundColorForMetric("cpc")) : getBackgroundColorForMetric("cpc");
            },
            axesColor: getBorderColorForMetric("cpc"),
            // pointStyle: 'star',
            borderWidth: 2
        },
        {
            label: 'CTR',
            // fill: true,
            data: state.ctr,
            //  borderColor: 'rgba(205,92,92, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "ctr" ? getSunSatColorBorder(index, getBorderColorForMetric("ctr")) : getBorderColorForMetric("ctr");
            },
            //  backgroundColor: 'rgba(205,92,92, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "ctr" ? getSunSatColorBackground(index, getBackgroundColorForMetric("ctr")) : getBackgroundColorForMetric("ctr");
            },
            axesColor: getBorderColorForMetric("ctr"),
            // pointStyle: 'crossRot',
            borderWidth: 2
        },
        {
            label: 'Conversion',
            // fill: true,
            data: state.conversions,
            //  borderColor: 'rgba(205,92,92, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "conversion" ? getSunSatColorBorder(index, getBorderColorForMetric("conversion")) : getBorderColorForMetric("conversion");
            },
            //  backgroundColor: 'rgba(205,92,92, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "conversion" ? getSunSatColorBackground(index, getBackgroundColorForMetric("conversion")) : getBackgroundColorForMetric("conversion");
            },
            axesColor: getBorderColorForMetric("conversion"),
            // pointStyle: 'circle',
            borderWidth: 2
        },
        {
            label: 'ROAS',
            // fill: true,
            data: state.roas,
            //  borderColor: 'rgba(205,92,92, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "roas" ? getSunSatColorBorder(index, getBorderColorForMetric("roas")) : getBorderColorForMetric("roas");
            },
            //  backgroundColor: 'rgba(205,92,92, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "roas" ? getSunSatColorBackground(index, getBackgroundColorForMetric("roas")) : getBackgroundColorForMetric("roas");
            },
            axesColor: getBorderColorForMetric("roas"),
            // pointStyle: 'triangle',
            borderWidth: 2
        },
        {
            label: 'TOS_IS',
            // fill: true,
            data: state.tos_is,
            //  borderColor: 'rgba(205,92,92, 1)',
            borderColor: function (e) {
                const { index } = e;
                return tgic === "tos_is" ? getSunSatColorBorder(index, getBorderColorForMetric("tos_is")) : getBorderColorForMetric("tos_is");
            },
            //  backgroundColor: 'rgba(205,92,92, 0.2)',
            backgroundColor: function (e) {
                const { index } = e;
                return tgic === "tos_is" ? getSunSatColorBackground(index, getBackgroundColorForMetric("tos_is")) : getBackgroundColorForMetric("tos_is");
            },
            axesColor: getBorderColorForMetric("tos_is"),
            // pointStyle: 'triangle',
            borderWidth: 2
        },
    ]

    const data_sets = [];
    const yAxesArray = [{ axes: "y", color: null }, { axes: "y1", color: null }, { axes: "y2", color: null }, { axes: "y3", color: null },];
    let yAxesArrayIndex = 0;
    graphDatasets.forEach(e => {
        // console.log(e)
        const [status] = clickedTile.filter(obj => obj.id === e.label.toLowerCase());
        if (e.label.toLowerCase() === tgic) {
            e.type = "bar"
        } else {
            e.type = "line"
        }
        if (status) {
            e.yAxisID = yAxesArray[yAxesArrayIndex].axes;
            // //console.log(e.yAxisID);
            yAxesArray[yAxesArrayIndex].color = e.axesColor;
            delete e.axesColor;
            if (e.label.toLowerCase() === "cost") {
                e.label = "Spend"
            }
            data_sets.push(e)
            yAxesArrayIndex += 1;
        }
    })


    const graphAreaSelectFn = (e) => {
        props.graphAreaSelectFn(e);
    }
    return (
        <>
            {
                loader && (
                    <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >
                        <Loader />
                    </div>
                )
            }
            {
                error ? <div style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <>
                        {
                            clickedTile.length ? (
                                < LineGraph
                                    data={{
                                        labels: state.labels,
                                        datasets: data_sets,
                                    }}
                                    yAxesColor={yAxesArray[0].color}
                                    y1AxesColor={yAxesArray[1].color}
                                    y2AxesColor={yAxesArray[2].color}
                                    y3AxesColor={yAxesArray[3].color}
                                    graphDataType={graphDataType}
                                    graphAreaSelectFn={graphAreaSelectFn}

                                />
                            ) : (
                                <div style={{ height: 260, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <h5>No Data</h5>
                                    <h6>Please select the metrics to see the graph</h6>
                                </div>
                            )
                        }
                    </>
            }

        </>

    )
}
export default LineGraphComponent;