import React, { useContext, useState } from 'react';
import './BidDetails.css';
import LineGraphComponent from '../ARComponents/LineGraphComponent';
import TimeBucketTable from './BidChangesSubComponents/TimeBucketTable';
import DateWiseBidTable from './BidChangesSubComponents/DateWiseBidTable';
import DetailedBidTable from './BidChangesSubComponents/DetailedBidTable';
import BarGraph from './BidChangesSubComponents/BidGraph';
// import { BidDetailsContext } from '../BidChangesContainer';

const BidDetails = (props) => {

    // const { fs, setFs, dateRange, rowData } = useContext(BidDetailsContext);
    // console.log(fs, setFs, dateRange, rowData);
    const {
        dateRange,
        fs,
        setFs,
        filter1,
        loader,
        setLoader,
        lineGraphErrorToggle,
        clickedTile,
        tgic,
        activeBtn,
        setActiveBtn,
        filterObj,
        setFilterObj,
        rowData,
        loading,
        graphDataType,
        resetColumn,
        popup,
        setPopup,
        error,
        monthYearArray,
        monthYearArrayWeek,
        currentPage,
        setCurrentPage,
        rowPerPage,
        setRowPerPage, docCount
    } = props;
    const [adType, setAdType] = useState(["SB", "SBVC", "SD", "SP"]);

    return (
        <div className='bidDetailsContainer'>
            <div className='bidGraphContainer'>
                {
                    lineGraphErrorToggle && <div className="lineGraphComponentError"> <p>You cannot select more than four KPIs.</p></div>
                }
                {
                    ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) && <LineGraphComponent
                        clickedTile={clickedTile}
                        adType={adType}
                        graphDataType={graphDataType}
                        tgic={tgic}
                        dateFilter={dateRange[0]}
                        loader={loader}
                        setLoader={setLoader}
                        group_by={"category"}
                        monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                    />
                }
            </div>
            <div className='bidBarGraphContainer' >
                {((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                    <BarGraph
                        dateFilter={dateRange[0]}
                        graphDataType={graphDataType}
                        filter1={filter1}
                        monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                    />
                }
            </div>
            <div className='bidDetailsTableContainer'>
                <span className='bidTableHeading'>Bid Adjustment in Time Bucket</span>
                <TimeBucketTable
                    dateRange={dateRange[0]}
                />
            </div>
            <div className='bidDetailsTableContainer'>
                <span className='bidTableHeading'>Bid Adjustment by Category-wise</span>
                <DateWiseBidTable
                    group_by={["category"]}
                    dateRange={dateRange[0]}
                    startingColumn={[{ headerName: 'Category', field: 'category', width: 150, pinned: true, cellClass: 'color-column' }]}
                    dependancyArr={[]}
                    selectRow={fs.fs_category}
                    selectFsName={"fs_category"}
                    setFs={setFs}
                    fs={fs}
                />
            </div>
            <div className='bidDetailsTableContainer'>
                <span className='bidTableHeading'>Bid Adjustment by AdType-wise</span>
                <DateWiseBidTable
                    group_by={["campaign_type"]}
                    dateRange={dateRange[0]}
                    startingColumn={[{ headerName: 'Ad-Type', field: 'campaign_type', width: 150, pinned: true, cellClass: 'color-column' },]}
                    dependancyArr={[fs.fs_category]}
                    selectRow={fs.fs_ad_type}
                    selectFsName={"fs_ad_type"}
                    setFs={setFs}
                    fs={fs}
                    filterObj={{
                        category_array: fs.fs_category
                    }}
                />
            </div>
            <div className='bidDetailsTableContainer'>
                <span className='bidTableHeading'>Bid Adjustment by Keyword Type-wise</span>
                <DateWiseBidTable
                    group_by={["keyword_type"]}
                    dateRange={dateRange[0]}
                    startingColumn={[{ headerName: 'Keyword Type', field: 'keyword_type', width: 150, pinned: true, cellClass: 'color-column' },]}
                    dependancyArr={[fs.fs_category, fs.fs_ad_type]}
                    selectRow={fs.fs_keyword_type}
                    selectFsName={"fs_keyword_type"}
                    setFs={setFs}
                    fs={fs}
                    filterObj={{
                        category_array: fs.fs_category,
                        campaign_type_array: fs.fs_ad_type
                    }}
                />
            </div>
            <div className='bidDetailsTableContainer'>
                <span className='bidTableHeading'>Bid Adjustment by Targeting Type-wise</span>
                <DateWiseBidTable
                    group_by={["targeting_type"]}
                    dateRange={dateRange[0]}
                    startingColumn={[{ headerName: 'Targeting Type', field: 'targeting_type', width: 150, pinned: true, cellClass: 'color-column' },]}
                    dependancyArr={[fs.fs_category, fs.fs_ad_type, fs.fs_keyword_type]}
                    selectRow={fs.fs_targeting_type}
                    selectFsName={"fs_targeting_type"}
                    setFs={setFs}
                    fs={fs}
                    filterObj={{
                        category_array: fs.fs_category,
                        campaign_type_array: fs.fs_ad_type,
                        keyword_type_array: fs.fs_keyword_type
                    }}
                />
            </div>
            <div className='bidDetailsTableContainer'>
                <span className='bidTableHeading'> Detailed View of Bid Adjustment</span>
                {
                    dateRange &&
                    <DetailedBidTable
                        resetColumn={resetColumn}
                        setClickedColumn={props.setClickedColumn}
                        dateRange={dateRange[0]}
                        activeBtn={activeBtn}
                        setActiveBtn={setActiveBtn}
                        filterObj={filterObj}
                        setFilterObj={setFilterObj}
                        rowData={rowData}
                        loading={loading}
                        popup={popup}
                        setPopup={setPopup}
                        error={error}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        rowPerPage={rowPerPage}
                        setRowPerPage={setRowPerPage}
                        docCount={docCount}
                    />
                }
            </div>
        </div>
    )
}

export default BidDetails;