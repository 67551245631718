import React, { useState, useEffect, useRef, createContext } from 'react';
import './BidChangesContainer.css';
import BidDetails from './BidChangesComponents/BidDetails';
import BidSummary from './BidChangesComponents/BidSummary';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangePickerComponent from '../../commonComponent/DateRangePicker/DateRangePicker';
import { formatDateFn, getMonthInTextArray, getMonthYearArray } from '../../../commonFunction/commomFunction';
import { addDays } from 'date-fns';
import ModalComponent from '../../commonComponent/Modal/ModalComponent';
import Tile from './ARComponents/Tile';
import axios from 'axios';
import { BASE_URL } from '../../../appConstants';
import { useSelector } from 'react-redux';
import { getYesterdaysDate, getAppStandardDate } from '../../../utils/commonFunction';
import MonthPicker from '../../commonComponent/DateRangePicker/MonthPicker';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import HourlyBids from './BidChangesComponents/HourlyBids';
import PausedBid from './BidChangesComponents/PausedBid';

export const BidDetailsContext = createContext();

const BidChangesContainer = () => {
    const popupRef = useRef();
    const appParams = useSelector(state => state.appParams);
    const { current_brand, current_agency } = appParams;
    const token = localStorage.getItem('token');
    const [toggle, setToggle] = useState('bid_summary');
    const [toggleDateRange, setToggleDateRange] = useState(false);
    const [loader, setLoader] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [arScroll, setArScroll] = useState(false);
    const [fs, setFs] = useState({
        fs_category: [],
        fs_ad_type: [],
        fs_keyword_type: [],
        fs_targeting_type: []
    });
    const { fs_category, fs_ad_type, fs_keyword_type, fs_targeting_type } = fs;
    const [graphDataType, setGraphDataType] = useState("daily");//graphDataType,setGraphDataType
    const [filter1, setFilter1] = useState([
        // { name: "Daily", status: true },
        { name: "Weekly", status: false },
        { name: "Monthly", status: false },
    ]);
    const [state, setState] = useState({
        tile_array: [],
    });
    const [clickedTile, setClickedTile] = useState([
        { id: "sales", borderColor: "rgba(221, 119, 3, 1)", backgroundColor: "rgba(221, 119, 3, 0.6)" },
        { id: 'cost', borderColor: "rgba(163, 49, 123,1)", backgroundColor: "rgba(163, 49, 123,0.6)" },
        { id: 'acos', borderColor: "rgba(46, 140, 184, 1)", backgroundColor: "rgba(46, 140, 184, 0.6)" },
    ]);
    const [tgic, setTileGraphIconClicked] = useState("sales");//tileGraphIconClicked,setTileGraphIconClicked
    const [lineGraphErrorToggle, setLineGraphErrorToggle] = useState(false);

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const [columnDefs, setColumnDefs] = useState([]);
    const [activeBtn, setActiveBtn] = useState("BID_AMOUNT");
    const [filterObj, setFilterObj] = useState({
        change_type: "BID_AMOUNT",
        entity_type: ""
    });
    const [clickedColumn, setClickedColumn] = useState([]);
    const [monthYearArray, setMonthYearArray] = useState(null);
    const [monthYearArrayWeek, setMonthYearArrayWeek] = useState(null);
    function checkArrayElement(inputArray, checkArray) {
        let count = 0;
        let result = false;
        if (inputArray.length === checkArray.length) {
            for (let el of checkArray) {
                if (inputArray.includes(el)) {
                    count++
                }
            }
            if (checkArray.length === count) {
                result = true;
            }
        }
        return result;
    }
    // modal state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalOpen = () => {
        if (
            toggleDateRange ||
            tgic !== 'sales' ||
            graphDataType !== 'daily' ||
            fs_category.length > 0 ||
            fs_ad_type.length > 0 ||
            fs_keyword_type.length > 0 ||
            fs_targeting_type.length > 0 ||
            !(clickedTile.filter(el => ["sales", "cost", "acos"].includes(el.id)).length === 3 && clickedTile.length === 3) ||
            ((dateRange[0]?.startDate)?.getDate() !== (addDays(new Date(), -8)).getDate() || (dateRange[0]?.endDate)?.getDate() !== (addDays(new Date(), -1)).getDate()) ||
            activeBtn !== 'BID_AMOUNT' ||
            !checkArrayElement(clickedColumn, ["Date", "Time", "Targeting", "Match Type", "Previous Value", "New Value", "Bid Difference"]) ||
            (popup === true)
        ) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    };
    // 
    const modalClose = () => setIsModalOpen(false);

    useEffect(() => {
        setDateRange([
            {
                startDate: addDays(new Date(), -8),
                endDate: addDays(new Date(), - 1),
                key: 'selection',
                name: "Daily"
            },
        ]);

        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setToggleDateRange(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (toggle !== 'bid_details') return;
        axios.post(`${BASE_URL}dashboard/advertisingReport/getTileData?brandId=${current_brand?._id}`, {
            time_stamp: getYesterdaysDate()
        }, {
            headers: {
                token
            }
        }).then(function (response) {
            // console.log(response.data.data);
            const { tile_array } = response.data.data;
            setState(prevState => ({ ...prevState, tile_array }))
        }).catch(function (error) {
            console.log(error);
        });
        setState({ tile_array: [] });
    }, [current_brand?._id, toggle]);

    const [resetColumn, setResetColumn] = useState(false);
    const [popup, setPopup] = useState(false);

    const removeAllFilter = (props) => {
        setResetColumn(prevState => (!prevState));
        setToggleDateRange(false);
        setClickedTile([
            { id: "sales", borderColor: "rgba(221, 119, 3, 1)", backgroundColor: "rgba(221, 119, 3, 0.6)" },
            { id: 'cost', borderColor: "rgba(163, 49, 123,1)", backgroundColor: "rgba(163, 49, 123,0.6)" },
            { id: 'acos', borderColor: "rgba(46, 140, 184, 1)", backgroundColor: "rgba(46, 140, 184, 0.6)" },
        ]);
        setTileGraphIconClicked('sales');
        setGraphDataType('daily');
        setFs({
            fs_category: [],
            fs_ad_type: [],
            fs_keyword_type: [],
            fs_targeting_type: []
        });
        setFilter1([
            // { name: "Daily", status: true },
            { name: "Weekly", status: false },
            { name: "Monthly", status: false },
        ]);
        setDateRange([
            {
                startDate: addDays(new Date(), -8),
                endDate: addDays(new Date(), - 1),
                key: 'selection'
            },
        ]);
        setActiveBtn('BID_AMOUNT');
        setFilterObj({
            change_type: "BID_AMOUNT",
            entity_type: ""
        })
        setPopup(false);

        setIsModalOpen(false);
        setDWMtoggle('')
    }

    const handleClick = (e) => {
        // console.log(e);
        setToggle(e);
        setToggleDateRange(false);
    }

    const onDateRangeApply = (item) => {
        // console.log(item)
        setDateRange(item);
        setToggleDateRange(false)
    }
    const dateRangePickerOpen = () => {
        setToggleDateRange(true)
    }
    const dateRangePickerClose = () => {
        setToggleDateRange(false)
    }

    let scrollRowMasterTimer;
    const onArScroll = (e) => {
        const top = e.target.scrollTop;
        clearTimeout(scrollRowMasterTimer);
        scrollRowMasterTimer = setTimeout(() => {
            if (top > 0) {
                setArScroll(true);
            } else {
                setArScroll(false);
            }

        }, 10);
    }

    const filterClicked1 = (e) => {
        const id = e.target.id;
        let selected;
        const newFilter = filter1.map(el => {
            if (id === el.name) {
                selected = !el.status;
                return {
                    name: el.name,
                    status: !el.status
                }
            } else {
                return {
                    name: el.name,
                    status: false
                }
            }
        })

        setFilter1(newFilter);
        // console.log(selected)
        if (selected === true) {
            setGraphDataType(`${id}`.toLowerCase())
            // console.log("if")
        } else {
            setGraphDataType('daily');
            // console.log("else")
        }
        if (e.target.id === "Daily" && selected) {
            setDateRange([
                {
                    startDate: addDays(new Date(), -8),
                    endDate: addDays(new Date(), - 1),
                    key: 'selection',
                    name: "Daily"
                },
            ]);
        } else if (e.target.id === "Weekly" && selected) {
            // setDateRange([
            //     {
            //         startDate: getPrevMonthFirstDate(addDays(new Date(), -1)),
            //         endDate: addDays(new Date(), - 1),
            //         key: 'selection',
            //         name: "Weekly"
            //     },
            // ]);
            let monthYearArray = getMonthYearArray(4, "weekly");
            setMonthYearArrayWeek(monthYearArray);
        } else if (e.target.id === "Monthly" && selected) {
            // setDateRange([
            //     {
            //         startDate: getForthMonthFirstDateFromThisMonth(addDays(new Date(), -1)),
            //         endDate: addDays(new Date(), - 1),
            //         key: 'selection',
            //         name: "Monthly"
            //     },
            // ]);
            let monthYearArray = getMonthYearArray(4, "monthly");
            setMonthYearArray(monthYearArray);
        } else {
            setDateRange([
                {
                    startDate: addDays(new Date(), -8),
                    endDate: addDays(new Date(), - 1),
                    key: 'selection',
                    name: "Daily"
                },
            ]);

        }
    }

    const tileClickedFn = (id) => {
        const colorArray = [
            { borderColor: "rgba(221, 119, 3, 1)", backgroundColor: "rgba(221, 119, 3, 0.5)" },
            { borderColor: "rgba(163, 49, 123,1)", backgroundColor: "rgba(163, 49, 123,0.5)" },
            { borderColor: "rgba(46, 140, 184, 1)", backgroundColor: "rgba(46, 140, 184, 0.5)" },
            { borderColor: "rgba(88, 112, 33, 1)", backgroundColor: "rgba(88, 112, 33, 0.5)" }
        ]//RGB code for color
        const arr = [...clickedTile];
        const index = arr.findIndex(obj => obj.id === id) //indexOf(id);
        if (index === -1) {
            if (arr.length < 4) {
                arr.push({ id });
                arr.forEach((el, i) => {
                    let { borderColor, backgroundColor } = colorArray[i]
                    el.borderColor = borderColor;
                    el.backgroundColor = backgroundColor;
                })
            } else {
                setLineGraphErrorToggle(true);
                setTimeout(() => {
                    setLineGraphErrorToggle(false);
                }, 2000);
            }
        } else {
            arr.splice(index, 1);
        }
        setClickedTile(arr);
    }

    const tileGraphIconClickedFn = (id) => {
        setTileGraphIconClicked(id);
    }

    const start_date = getAppStandardDate(dateRange[0]?.startDate);
    const end_date = getAppStandardDate(dateRange[0]?.endDate);

    // pagination logic
    const [currentPage, setCurrentPage] = useState(1);
    const [docCount, setDocCount] = useState();
    const [rowPerPage, setRowPerPage] = useState(50);
    //

    useEffect(() => {
        if (toggle !== 'bid_details') return;

        setLoading(true);
        const { change_type, entity_type } = filterObj;
        const bodyObj = {
            start_date,
            end_date
        }
        if (change_type === "BID_AMOUNT") {
            bodyObj["change_type"] = "BID_AMOUNT"
        } else if (change_type === "STATUS") {
            bodyObj["change_type"] = "STATUS"
            bodyObj["new_value"] = "PAUSED"
        } else if (change_type === "CREATED") {
            bodyObj["change_type"] = "CREATED"
        } else if (entity_type === "NEGATIVE_KEYWORD") {
            bodyObj["entity_type"] = "NEGATIVE_KEYWORD"
        }
        // console.log(bodyObj);
        axios.post(`${BASE_URL}bidChanges?brandId=${current_brand._id}&page=${currentPage}&limit=${rowPerPage}`, {
            ...bodyObj,
            category_array: fs_category,
            campaign_type_array: fs_ad_type,
            keyword_type_array: fs_keyword_type,
            targeting_type_array: fs_targeting_type,
        }, {
            headers: {
                token
            }
        }).then((response) => {
            const { bid_changes, documents } = response.data.data;
            setDocCount(documents);
            if (response.status === 200 && bid_changes.length === 0) {
                throw Error(`no data available for detailed bid adjustment in this brand`);
            }
            setError(false);
            setRowData(bid_changes);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setError(error.message);
        })
    }, [current_brand?._id, start_date, end_date, filterObj, fs_category, fs_ad_type, fs_keyword_type, fs_targeting_type, toggle, currentPage, rowPerPage]);

    const onMonthSelect = (month_year) => {
        let mya = structuredClone(monthYearArray);
        mya.forEach(el => {
            if (el.month_year === month_year) {
                el.selected = !el.selected
            }
        })
        setMonthYearArray(mya);
    }
    const onWeekSelect = (month_year) => {
        let myaw = structuredClone(monthYearArrayWeek);
        // console.log(month_year)
        myaw.forEach(el => {
            if (el.month_year === month_year) {
                el.selected = !el.selected
            }
        })
        setMonthYearArrayWeek(myaw);
    }

    const [DWMtoggle, setDWMtoggle] = useState('');
    function handleDWMChange(e, value) {
        setDWMtoggle(value);
    }


    return (
        <div className='bidChangesContainer' onScroll={onArScroll}>
            <div className='bidChangesStickyContainer' style={{ backgroundColor: arScroll ? "#f2f2f2" : "", boxShadow: arScroll ? "rgba(0, 0, 0, 0.1) 0px 1.95px 2px" : "", }}>
                <div className='bidChangesContainerHeader'>
                    <div className='bidChangesHeadingContainer'>
                        <h5 className='bidChangesHeading'>Bid Changes</h5>
                        {/* <Switch size="small" sx={{ marginRight: '6px', color: '#03A0E7', borderLeft: '1px solid #bdbdbd' }} onChange={handleToggle} /> */}
                        <button className={`bidChangesToggleBtn ${toggle === 'bid_summary' ? 'active' : ""}`} onClick={() => handleClick('bid_summary')}>Bid Summary</button>
                        {/* <button className={`bidChangesToggleBtn ${toggle === 'hourly_bids' ? 'active' : ""}`} onClick={() => handleClick('hourly_bids')}>Hourly Bids</button>
                        <button className={`bidChangesToggleBtn ${toggle === 'paused_bids' ? 'active' : ""}`} onClick={() => handleClick('paused_bids')}>Paused Targets</button> */}
                        <button className={`bidChangesToggleBtn ${toggle === 'bid_details' ? 'active' : ""}`} onClick={() => handleClick('bid_details')}>Bid Details</button>
                    </div>

                    <div className='row_Filters'>
                        {
                            toggle === 'bid_details' &&
                            <>
                                <div className="row_1Filter" >
                                    <div
                                        onClick={modalOpen}
                                        className={(
                                            toggleDateRange ||
                                            tgic !== 'sales' ||
                                            graphDataType !== 'daily' ||
                                            fs_category.length > 0 ||
                                            fs_ad_type.length > 0 ||
                                            fs_keyword_type.length > 0 ||
                                            fs_targeting_type.length > 0 ||
                                            !(clickedTile.filter(el => ["sales", "cost", "acos"].includes(el.id)).length === 3 && clickedTile.length === 3) ||
                                            ((dateRange[0]?.startDate)?.getDate() !== (addDays(new Date(), -8)).getDate() || (dateRange[0]?.endDate)?.getDate() !== (addDays(new Date(), -1)).getDate()) ||
                                            activeBtn !== 'BID_AMOUNT' ||
                                            !checkArrayElement(clickedColumn, ["Date", "Time", "Targeting", "Match Type", "Previous Value", "New Value", "Bid Difference"]) ||
                                            (popup === true)
                                        ) ? "removeAllFilterClicked removeAllFilter" : "removeAllFilter"}
                                    >
                                        <i style={{ marginRight: '5px' }}>
                                            <i className="bi bi-arrow-counterclockwise" ></i>
                                        </i>
                                        Reset
                                    </div>
                                    {
                                        isModalOpen &&
                                        <ModalComponent open={isModalOpen} modalClose={modalClose}>
                                            <div className="modal_text">Do you really want to reset ?</div>
                                            <div className="modal_button_container">
                                                <button className="modal_button_cancel" onClick={modalClose}>Cancel</button>
                                                <button className="modal_button_ok" onClick={removeAllFilter}>Reset</button>
                                            </div>
                                        </ModalComponent>
                                    }
                                </div>
                                <div className='row_4Filter'>
                                    {/* {
                                        filter1.map((el) => {
                                            return (
                                                <button
                                                    disabled={loader ? true : false}
                                                    key={el.name}
                                                    id={el.name}
                                                    className={el.status ? "filterClicked filter" : "filter"}
                                                    onClick={filterClicked1}>{el.name[0]}
                                                </button>
                                            )
                                        })
                                    } */}
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={DWMtoggle}
                                        exclusive
                                        onChange={handleDWMChange}
                                        aria-label="Platform"
                                        disabled={loader ? true : false}
                                        sx={{ maxHeight: 30 }}
                                    >
                                        {filter1.map((el) => {
                                            return (
                                                <ToggleButton sx={{ color: '#525252' }} key={el.name} id={el.name} value={el.name} onClick={filterClicked1}>{el.name[0]}</ToggleButton>
                                            )
                                        })}
                                    </ToggleButtonGroup>
                                </div>
                            </>
                        }
                        <div ref={popupRef} className="bidDateRangeContainer">
                            {(toggle !== 'hourly_bids' && toggle !== 'paused_bids') &&
                                <>
                                    {
                                        dateRange.length > 0 && <div onClick={() => setToggleDateRange(prevState => !prevState)} className={`bidDetails_date_display ${toggleDateRange ? "bid_date_display_active" : ""}`} >
                                            <CalendarMonthIcon sx={{ marginRight: '10px' }} />
                                            {
                                                dateRange.length && graphDataType === "daily" && <div style={{ minWidth: 180 }} >{formatDateFn(dateRange[0].startDate, dateRange[0].endDate)}</div>
                                            }
                                            {
                                                monthYearArrayWeek && graphDataType === "weekly" && <div style={{ minWidth: 180 }} >{getMonthInTextArray(monthYearArrayWeek)}</div>
                                            }
                                            {
                                                monthYearArray && graphDataType === "monthly" && <div style={{ minWidth: 180 }} >{getMonthInTextArray(monthYearArray)}</div>
                                            }
                                        </div>
                                    }
                                </>
                            }
                            <div style={{ display: toggleDateRange ? "block" : "none" }} className="bidDetails_date_range" >
                                {
                                    dateRange.length && graphDataType === "daily" && <DateRangePickerComponent
                                        dateRange={dateRange}
                                        onDateRangeApply={onDateRangeApply}
                                        dateRangePickerOpen={dateRangePickerOpen}
                                        dateRangePickerClose={dateRangePickerClose}
                                    />
                                }
                                {
                                    monthYearArrayWeek && graphDataType === "weekly" && <MonthPicker
                                        onWeekSelect={onWeekSelect}
                                        monthYearArrayWeek={monthYearArrayWeek}
                                        graphDataType={graphDataType}
                                    />
                                }
                                {
                                    monthYearArray && graphDataType === "monthly" && <MonthPicker
                                        onMonthSelect={onMonthSelect}
                                        monthYearArray={monthYearArray}
                                        graphDataType={graphDataType}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        toggle === 'bid_details' &&
                        <div className='bidTileContainer'>
                            {
                                state.tile_array.length ? state.tile_array.map(tile => {
                                    const [status] = clickedTile.filter(obj => obj.id === tile.name);
                                    return <Tile
                                        clicked={status}
                                        // clickedClass={status ? `clickedTile-${tile.name}` : ""}
                                        topBorderColor={status ? status.borderColor : ""}
                                        tileClickedFn={tileClickedFn}
                                        tileGraphIconClickedFn={tileGraphIconClickedFn}
                                        tgic={tgic}
                                        key={tile.name}
                                        tile={tile}
                                        showDetail={!arScroll}
                                        clickedTile={clickedTile}
                                    />
                                }) : <p>Loading...</p>
                            }
                        </div>
                    }
                </div>
            </div>

            <div className='bidChangesContainerContent'>
                {/* <BidDetailsContext.Provider value={{
                    monthYearArray: graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray, resetColumn, dateRange, arScroll, fs, setFs, graphDataType, filter1, loader, setLoader,
                    lineGraphErrorToggle, clickedTile, tgic, activeBtn, setActiveBtn, filterObj, setFilterObj, rowData, loading,
                    setClickedColumn, popup, setPopup, error, currentPage, numberOfPages, setCurrentPage, rowPerPage, setRowPerPage
                }}> */}
                <div>
                    {
                        toggle === 'bid_details' && dateRange.length > 0 && <BidDetails
                            monthYearArray={monthYearArray}
                            monthYearArrayWeek={monthYearArrayWeek}
                            resetColumn={resetColumn}
                            dateRange={dateRange}
                            arScroll={arScroll}
                            fs={fs}
                            setFs={setFs}
                            graphDataType={graphDataType}
                            filter1={filter1}
                            loader={loader}
                            setLoader={setLoader}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            clickedTile={clickedTile}
                            tgic={tgic}
                            activeBtn={activeBtn}
                            setActiveBtn={setActiveBtn}
                            filterObj={filterObj}
                            setFilterObj={setFilterObj}
                            rowData={rowData}
                            loading={loading}
                            setClickedColumn={setClickedColumn}
                            popup={popup}
                            setPopup={setPopup}
                            error={error}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            rowPerPage={rowPerPage}
                            setRowPerPage={setRowPerPage}
                            docCount={docCount}
                        />
                    }
                </div>
                {/* </BidDetailsContext.Provider> */}
                <div>
                    {
                        toggle === 'bid_summary' && dateRange.length > 0 && <BidSummary
                            dateRange={dateRange[0]}

                        />
                    }
                </div>
                {/* <div>
                    {
                        toggle === 'hourly_bids' && <HourlyBids />
                    }
                </div>
                <div>
                    {
                        toggle === 'paused_bids' && <PausedBid />
                    }
                </div> */}

            </div>
        </div>
    )
}

export default BidChangesContainer;