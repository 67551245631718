import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, isSameDay } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import './MultiDateRange.css';
import { getDynamicDateRange } from '../../../commonFunction/commomFunction';
const MultiDateRange = (props) => {
  const stylesMulti = {
    dateRangeWrapper: {
      width: 250,
      height: 280,
      display: 'inline-block',
    },
    dateRangeInput: {
      height: 20,
      fontSize: 14,
    },
  };

  const { closeDateRange } = props;

  const [dateRange, setDateRange] = useState(null);
  const [yesterday, setYesterday] = useState(null);
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1)
    setYesterday(yesterday)
    setDateRange(props.dateRange)
  }, [props.dateRange])

  const apply = () => {
    props.onDateRangeApply(dateRange);
  }
  const onChange = (item) => {
    // console.log(item);
    if ((item.selection1?.name || item.selection2?.name) === "userdefined") {
      delete item.selection1?.name;
      delete item.selection2?.name;
      if (toggle) {
        setDateRange(prevState => ({ ...prevState, selection1: { ...item.selection1, key: "selection1" } }));
        setToggle(prevState => !prevState);
      } else {
        setDateRange(prevState => ({ ...prevState, selection2: { ...item.selection1, key: "selection2" } }));
        setToggle(prevState => !prevState);
      }
    } else {
      setDateRange(prevState => ({ ...prevState, ...item }))
    }
  }
  return (
    <div className='MultiDateRangeContainer'>
      {
        dateRange && yesterday && <DateRangePicker
          maxDate={yesterday}
          ranges={[dateRange.selection1, dateRange.selection2]}
          onChange={onChange}
          style={stylesMulti.dateRangeWrapper}
          months={2}
          direction="horizontal" rangeColors={['rgba(3, 159, 231, 0.8)', 'rgb(221, 119, 3)']}
          showDateDisplay={false}
          staticRanges={[
            {
              label: "T-1",
              range: () => ({
                startDate: addDays(new Date(), - 1),
                endDate: addDays(new Date(), - 1),
                name: "userdefined"
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
              }
            },
            {
              label: "T-2",
              range: () => ({
                startDate: addDays(new Date(), - 2),
                endDate: addDays(new Date(), - 2),
                name: "userdefined"
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
              }
            }, {
              label: "This Week",
              range: () => ({ ...getDynamicDateRange(addDays(new Date(), - 1), "thisweek"), name: "userdefined" }),
              isSelected(range) {
                const definedRange = this.range();
                return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
              }
            },
            {
              label: "Last Week",
              range: () => ({ ...getDynamicDateRange(addDays(new Date(), - 1), "lastweek"), name: "userdefined" }),
              isSelected(range) {
                const definedRange = this.range();
                return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
              }
            },
            {
              label: "This Month",
              range: () => ({ ...getDynamicDateRange(addDays(new Date(), - 1), "thismonth"), name: "userdefined" }),
              isSelected(range) {
                const definedRange = this.range();
                return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
              }
            },
            {
              label: "Last Month",
              range: () => ({ ...getDynamicDateRange(addDays(new Date(), - 1), "lastmonth"), name: "userdefined" }),
              isSelected(range) {
                const definedRange = this.range();
                return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
              }
            }
          ]}
        />
      }

      <div className='dateRangePickerButtonContainer' >
        <div onClick={closeDateRange} className='dateRangePickerCancelButton'  >
          Cancel
        </div>
        <div onClick={apply} className='dateRangePickerApplyButton' >
          Apply
        </div>
      </div>
    </div>
  )
}

export default MultiDateRange;