import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { AgGridReact } from "ag-grid-react";
import './Competition.css';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../../appConstants';
import axios from 'axios';
import ModalComponent from '../../../commonComponent/Modal/ModalComponent';
import Loader from '../../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../../errorComponent/ReportsErrorPage';
import { NotificationManager } from 'react-notifications';

function Competition() {
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;
    const gridRef = useRef();
    const popupRef1 = useRef();
    const popupRef2 = useRef();
    const global_id = useRef();
    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [asins, setAsins] = useState([]);

    const [searchQuery1, setSearchQuery1] = useState('');
    const [searchQuery2, setSearchQuery2] = useState('');
    const [toggle1, setToggle1] = useState(false);
    const [toggle2, setToggle2] = useState(false);

    const [asinValue, setAsinValue] = useState();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);

    const [competitionAsin, setCompetitionAsin] = useState([]);
    const [compAsinValue, setCompAsinValue] = useState()



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef1.current && !popupRef1.current.contains(event.target)) {
                setToggle1(false);
            }
            if (popupRef2.current && !popupRef2.current.contains(event.target)) {
                setToggle2(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getCompetitionTableData = () => {
        setIsLoading(true);
        axios.get(`${BASE_URL}competitionTarget?brandId=${current_brand._id}`, {
            headers: {
                token
            }
        }).then((res) => {
            // console.log(res.data);
            const { _id } = res.data.clientCompetitionTarget;
            global_id.current = _id;
            const data = res.data.clientCompetitionTarget?.targets;
            setRowData(data);
            setError(false);
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setError('No competition targets exist, Please create your first target');
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        axios.get(`${BASE_URL}competitionTarget/asins?brandId=${current_brand._id}`, {
            headers: {
                token
            }
        }).then((res) => {
            // console.log(res.data.asins);
            const data = res.data.asins;
            setAsins(data);
        }).catch((err) => {
            console.log(err);
        })
        axios.get(`${BASE_URL}competitionTarget/competitions?brandId=${current_brand._id}`, {
            headers: {
                token
            }
        }).then((res) => {
            // console.log(res.data.asins);
            const data = res.data.asins;
            setCompetitionAsin(data);
        }).catch((err) => {
            console.log(err);
        })
        getCompetitionTableData();
    }, [current_brand._id])

    useEffect(() => {
        setAsinValue(asins[0]);
        setCompAsinValue(competitionAsin[0])
    }, [])

    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1, minWidth: 150 }), []);

    const onRowValueChanged = useCallback((event) => {
        const data = event.data;
        console.log(data);
        if (data.competition_asin.length !== 10 || !(data.competition_asin.startsWith('B0'))) {
            return NotificationManager.error('Invalid Asin', 'error', 3000);
        }
        setIsLoading(true);
        axios.patch(`${BASE_URL}competitionTarget/${global_id.current}/${data._id}`, {
            brand_asin: data.brand_asin,
            competition_asin: data.competition_asin,
            competition_brand: data.competition_brand
        }, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data.updatedCompetitionTarget.targets;
            // console.log(data);
            // setRowData(data);
            setIsLoading(false);
            getCompetitionTableData();
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const [edit, setEdit] = useState(false);

    const RemoveComponent = (props) => {
        const { rowIndex } = props;
        const { _id } = props.data;
        // console.log(_id, global_id);

        const onBtStartEditing = useCallback(() => {
            gridRef.current.api.setFocusedCell(rowIndex, "competition_asin");
            gridRef.current.api.startEditingCell({
                rowIndex: rowIndex,
                colKey: "competition_asin",
            });
            setEdit(true);
        }, [rowIndex, edit]);

        const onBtStopEditing = useCallback(() => {
            gridRef.current.api.stopEditing();
            setEdit(false);
        }, [edit]);

        const deleteTarget = () => {
            // console.log(_id, global_id);
            // alert(global_id)
            setIsLoading(true);
            axios.delete(`${BASE_URL}competitionTarget/${global_id.current}/${_id}`)
                .then((res) => {
                    setRowData(prevState => prevState.filter((el) => el._id !== _id))
                    setIsLoading(false);
                })
                .catch(err => console.log(err)).finally(() => setIsLoading(false))
        }

        return (
            <>{edit ? <button className='btn btn-sm admin-edit-btn' onClick={onBtStopEditing}>Save Row</button> : <button className='btn btn-sm admin-edit-btn' onClick={onBtStartEditing}>Edit Row</button>}
                <button className='btn btn-sm admin-delete-btn' onClick={deleteTarget}>Delete</button>
            </>
        )
    }

    const BrandAsinComp = (props) => {
        return <a href={`https://www.amazon.in/dp/${props.value}`} style={{ color: 'rgb(0, 115, 199)' }} target="_blank">{props.value}</a>
    }

    const columnDefs = useMemo(() => [
        { headerName: 'Product Name', field: 'name', width: 150, },
        { headerName: 'Brand ASIN', field: 'brand_asin', width: 150, cellRenderer: BrandAsinComp },
        { headerName: 'Competition ASIN', field: 'competition_asin', width: 150, editable: true, cellRenderer: BrandAsinComp },
        { headerName: 'Competition Brand', field: 'competition_brand', width: 150, editable: true },
        // { headerName: 'Competition Product Name', field: 'competition_pname', width: 150, },
        { headerName: 'Action', field: '', width: 150, cellRenderer: RemoveComponent },
    ], [])


    const addCompetitionTarget = () => {
        setOpen(true);
    }

    const handleCancel = () => {
        setOpen(false);
        setAsinValue('');
        setCompAsinValue("");
    }

    const handleUpdateCompetiton = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put(`${BASE_URL}competitionTarget?brandId=${current_brand._id}`, {
            brand_asin: asinValue.asin,
            competition_asin: compAsinValue?.asin,
            competition_brand: compAsinValue?.competition_brand_name
        }, {
            headers: {
                token
            }
        }).then((res) => {
            // console.log(res);
            getCompetitionTableData();
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        })
        setOpen(false);
        setAsinValue('');
        setCompAsinValue("");
    }


    const searchAsin = asins.filter((el) => el?.asin?.toLowerCase().includes(searchQuery1.toLowerCase()) || el?.name?.toLowerCase().includes(searchQuery1.toLowerCase()));
    const searchCompetitionAsin = competitionAsin.filter((el) => el?.asin?.toLowerCase().includes(searchQuery2.toLowerCase()) || el?.name?.toLowerCase().includes(searchQuery2.toLowerCase()));

    const handleInputChange1 = (e) => {
        console.log(e.target.value);
        setSearchQuery1(e.target.value);
        // setAsinValue(e.target.value)
    };
    const handleInputChange2 = (e) => {
        console.log(e.target.value);
        setSearchQuery2(e.target.value);
        // setCompAsinValue(e.target.value)
    }

    const handleToggle1 = () => {
        setToggle1(prevState => !prevState);
        setSearchQuery1("");
    }
    const handleToggle2 = () => {
        setToggle2(prevState => !prevState);
        setSearchQuery2("");
    }

    const handleAsinValue = (el) => {
        setAsinValue(el);
        setToggle1(false);
    }

    const handleCompetitionValue = (el) => {
        setCompAsinValue(el);
        setToggle2(false);
    }


    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className='btn btn-sm admin-edit-btn' onClick={addCompetitionTarget} ><span>&#43;</span> Competition Target</button>
            </div>
            {
                error ? <div style={{ height: 550, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <div className="ag-theme-alpine" style={{ height: 550, margin: "10px 0 10px 0" }}>
                        <AgGridReact
                            ref={gridRef}
                            // onRowClicked={onRowClicked}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            rowHeight={40}
                            headerHeight={50}
                            // rowSelection={'multiple'}
                            // rowMultiSelectWithClick={true}
                            suppressLoadingOverlay={true}
                            suppressNoRowsOverlay={true}
                            suppressMovableColumns={true}
                            suppressDragLeaveHidesColumns={true}
                            editType={"fullRow"}
                            // onCellValueChanged={onCellValueChanged}
                            onRowValueChanged={onRowValueChanged}
                        />
                    </div>
            }
            <ModalComponent
                open={open}
                modalClose={() => setOpen(false)}
            >
                <form className='modal_keyword_form'>
                    <div ref={popupRef1}>
                        <label htmlFor="">Brand ASIN</label>
                        <div className="form form-control asin" onClick={handleToggle1}>{asinValue ? `${asinValue?.asin}-${asinValue?.name}` : 'Please Select ASIN'} <span><i className={toggle1 ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i></span></div>
                        <div className="asin_select_container_1" style={{ display: toggle1 ? 'block' : 'none' }}>
                            <div className="searchInputContainer">
                                <div className="brandSearchInputContainer">
                                    <i className="bi bi-search px-1"></i>
                                    <input className="brandSearchInput" type="text" value={searchQuery1} onChange={handleInputChange1} placeholder="search" />
                                </div>
                            </div>
                            <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                            <div className="brandListContainer">
                                {asins.length > 0 &&
                                    searchAsin.map((el, i) => {
                                        return (
                                            <div
                                                className={`brandList-${el === asinValue ? 'active' : ""}`}
                                                key={i}
                                                onClick={() => handleAsinValue(el)}
                                            >
                                                <strong>{el?.asin}</strong>-{el?.name}
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div ref={popupRef2}>
                        <label htmlFor="">Competition ASIN</label>
                        <div className="form form-control asin" onClick={handleToggle2}>{compAsinValue ? `${compAsinValue?.asin}-${compAsinValue?.name}` : 'Please Select Competition ASIN'} <span><i className={toggle2 ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i></span></div>
                        <div className="asin_select_container_2" style={{ display: toggle2 ? 'block' : 'none' }}>
                            <div className="searchInputContainer">
                                <div className="brandSearchInputContainer">
                                    <i className="bi bi-search px-1"></i>
                                    <input className="brandSearchInput" type="text" value={searchQuery2} onChange={handleInputChange2} placeholder="search" />
                                </div>
                            </div>
                            <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                            <div className="brandListContainer">
                                {competitionAsin.length > 0 &&
                                    searchCompetitionAsin.map((el, i) => {
                                        return (
                                            <div
                                                className={`brandList-${el === compAsinValue ? 'active' : ""}`}
                                                key={i}
                                                onClick={() => handleCompetitionValue(el)}
                                            >
                                                <strong>{el?.asin}</strong>-{el?.name}
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>

                    {/* <div className='asin_input'>
                        <label htmlFor="">Competition ASIN</label>
                        <input className='form form-control' type="text" name='competition_asin'
                            value={targets.competition_asin}
                            onChange={handleNewTargets}
                            placeholder='Competition ASIN' />
                    </div> */}
                    <div className='asin_input'>
                        <label htmlFor="">Competition Brand</label>
                        <input className='form form-control' type="text" name='competition_brand'
                            value={compAsinValue?.competition_brand_name}
                            readOnly={true}
                            // onChange={handleNewTargets}
                            placeholder='Competition Brand' />
                    </div>
                    <div className='asin_button_container'>
                        <button className='cancel_btn' onClick={handleCancel}>Cancel</button>
                        <button className='submit_btn' onClick={handleUpdateCompetiton}>OK</button>
                    </div>
                </form>
            </ModalComponent>
            {
                isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
            }
        </div>
    )
}

export default Competition;