import React, { useState, useEffect } from 'react';
import './ForgetPassword.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../commonComponent/Loader/Loader';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BASE_URL } from '../../appConstants';
import axios from 'axios';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        email: "",
    });

    const [error, setError] = useState({
        email: false,
        inValidEmail: false
    });


    // useEffect(() => {
    //     const loginEmail = localStorage.getItem("loginEmail");
    //     if (loginEmail) {
    //         setState({
    //             email: loginEmail
    //         })
    //     }
    // }, []);

    const onChange = (e) => {
        // console.log(e.target.value);
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevState => ({
            ...prevState,
            email: false,
            inValidEmail: false
        }));

    }

    const onClick = async (e) => {
        e.preventDefault();
        const { email } = state;
        if (email === "") {
            setError(prevState => ({ ...prevState, email: true }));
            return;
        } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            setError(prevState => ({ ...prevState, inValidEmail: true }));
            return;
        }
        setLoader(true);
        try {
            const res = await axios.post(`${BASE_URL}auth/generateOtp`, {
                email,
            });
            // console.log(res);
            const { status, data } = res.data;
            setLoader(false);
            if (status === "error") {
                NotificationManager.error(data.message, "Error", 4000);
            } else {
                localStorage.setItem('email', state.email);
                navigate(`/user/verifyOtp`);
            }
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    }

    const goBackClick = (() => {
        navigate('/user/login');
    })
    return (
        <div className='mainForgetPassword'>

            <div className="ForgetPasswordContainer">
                {
                    !loader && <>
                        <div className="" >
                            Please enter your email which you have used during Login.
                        </div>
                        <hr />
                        <form className='form'>
                            <div className="mb-4 emailInput"  >
                                <label className="mb-1">Email:</label>
                                <input
                                    placeholder="name@example.com"
                                    className='form-control inputStyle'
                                    type="email"
                                    onChange={onChange}
                                    name={"email"}
                                    value={state.email}
                                />
                                <label className="" >{error.email ? "📧 Please provide your email." : error.inValidEmail ? "📧 Please enter a valid email." : ""}</label>
                            </div>
                            <div className='forgotPassBtnContainer'>
                                <button
                                    style={{ backgroundColor: "var(--secondary-color)", color: "#fff", fontSize: '17px' }}
                                    className="btn btn-m col-lg-5 " onClick={goBackClick}>&larr; Go Back
                                </button>
                                <button
                                    style={{ backgroundColor: "var(--active-color)", color: "#fff", fontSize: '17px', }}
                                    className="btn btn-m col-lg-5" onClick={onClick}>Confirm Email
                                </button>
                            </div>
                        </form>
                    </>
                }


                {
                    loader && <div className="forgotPasswordLoader" >
                        <Loader />
                    </div>
                }
                <NotificationContainer />
            </div>
        </div>
    )
}

export default ForgetPassword