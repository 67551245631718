import { useState, useEffect, useMemo } from "react";
import './Edit.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { BASE_URL } from "../../../../../appConstants";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import Loader from '../../../../commonComponent/Loader/Loader';
import { useSelector } from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
};

const Edit = (props) => {
    const { open, handleClose, modalData, setRefreshBrandUser } = props;
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;
    const user = useSelector(state => state.user);
    const { user_domain } = user;
    // console.log(user);

    const [state, setState] = useState({
        user_type: "",
        status: "",
        brand_id: "",
        agency_id: "",
        b_id: ""
    })
    const [loading, setLoading] = useState(false);
    const adminAccess = useMemo(() => [
        { id: 0, name: 'Agency Admin', value: "agency_admin" },
        { id: 1, name: 'Brand Admin', value: "brand_admin" },
        { id: 2, name: 'Brand Viewer', value: "brand_viewer" },
    ], []);
    const clientAccess = useMemo(() => [
        { id: 0, name: 'Brand Admin', value: "brand_admin" },
        { id: 1, name: 'Brand Viewer', value: "brand_viewer" },
    ], []);
    const brandAccess = useMemo(() => [
        { id: 0, name: 'Brand Viewer', value: "brand_viewer" },
    ], []);


    const { brand_array, agency_array, _id } = modalData;

    useEffect(() => {
        setState({
            user_type: brand_array?.length ? brand_array[0]?.user_type : agency_array[0]?.user_type,
            status: brand_array?.length ? brand_array[0]?.status : agency_array[0]?.status,
            b_id: brand_array && brand_array[0]?._id,
            brand_id: brand_array && brand_array[0]?.brand_id,
            agency_id: agency_array && agency_array[0]?._id
        });
    }, []);
    const handleChange = (e) => {
        if (e.target.name === "user_type") {
            setState((prevState) => ({
                ...prevState,
                user_type: e.target.value,
            }))
        } else if (e.target.name === "status") {
            setState((prevState) => ({
                ...prevState,
                status: e.target.checked,
            }))
        }
        // if(e.target.value)
        // console.log(e.target.value);

    }
    // //console.log(state);

    const handleUpdateUser = () => {
        // //console.log(state);
        setLoading(true);
        const { user_type, status, brand_id, agency_id, b_id } = state;
        const token = localStorage.getItem('token');
        if (brand_id) {
            axios.put(`${BASE_URL}brand/updateUserBrand/${brand_id}/${b_id}`, {
                status: status,
                user_type: user_type,
            }, {
                headers: {
                    token
                }
            }).then((response) => {
                // console.log(response.data.data);
                setLoading(false);
                NotificationManager.success(response.data.data.message, "success", 3000);
                setRefreshBrandUser(prevState => !prevState);
            }).catch(error => {
                setLoading(false);
                //console.log(error);
                NotificationManager.error(error.data.data.message, "error", 3000);

            })
        } else if (agency_id) {
            axios.put(`${BASE_URL}agency/updateUserAgency/${agency_id}`, {
                status: status,
            }, {
                headers: {
                    token
                }
            }).then((response) => {
                console.log(response.data.data);
                setLoading(false);
                NotificationManager.success(response.data.data.message, "success", 3000);
                setRefreshBrandUser(prevState => !prevState);
            }).catch(error => {
                setLoading(false);
                //console.log(error);
                NotificationManager.error(error.data.data.message, "error", 3000);

            })
        }
        handleClose();
    }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form>
                        <div className="edit-access mt-2">
                            {
                                (agency_array?.length > 0) ? "" :
                                    <>
                                        <label id="access" className="edit-label">Access: </label>
                                        <select className="edit-access-select" name="user_type"
                                            value={state.user_type}
                                            onChange={handleChange}
                                        >
                                            {
                                                user_domain === 'enlytical_admin' ?
                                                    clientAccess.map((el, i) => {
                                                        return (
                                                            <option key={el.id} value={el.value}>{el.name}</option>
                                                        )
                                                    }) : (current_brand.user_type && current_brand.user_type === 'brand_admin' ?
                                                        brandAccess.map((el) => {
                                                            return (
                                                                <option key={el.id} value={el.value}>{el.name}</option>
                                                            )
                                                        }) :
                                                        clientAccess.map((el) => {
                                                            return (
                                                                <option key={el.id} value={el.value}>{el.name}</option>
                                                            )
                                                        })
                                                    )
                                            }
                                        </select>
                                    </>
                            }
                        </div>

                        <div className='edit-status mt-2'>
                            <div className="edit-label">Status: </div>
                            <div className="form-check form-switch edit-status-input">
                                <input className="form-check-input" type="checkbox" role="switch" name='status' checked={state.status} onChange={handleChange} />
                                <label className="edit-label">{state.status === true ? "Active" : "Inactive"}</label>
                            </div>
                        </div>

                        <div className='edit-btn-container mt-2'>
                            <button className='btn btn-sm new-user-btn-cancel' type="button" onClick={handleClose}>Cancel</button>
                            <button className='btn btn-sm new-user-btn-invite' type="button" onClick={handleUpdateUser}>Save</button>
                        </div>
                    </form>
                </Box>
            </Modal>
            {
                loading && (
                    <div style={{ position: "absolute", top: "50%", left: "50%", height: 0, zIndex: 999 }} >
                        <Loader />
                    </div>
                )
            }
            <NotificationContainer />
        </div>
    );
}

export default Edit;