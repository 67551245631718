import React, { useEffect, useMemo, useState } from 'react'
import './KeywordCatContainer.scss';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../appConstants';
import axios from 'axios';
import { getLast7Days, getLast30Days, getTminus3date } from '../../../utils/commonFunction';
import Loader from '../../commonComponent/Loader/Loader';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { DateComponent, DateComponentWithYear } from '../advertisingReport/ARComponents/cellRenderer';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import KeywordIndexing from './KeywordIndexing';

function KeywordCatContainer() {
  const appParams = useSelector(state => state.appParams);
  const { current_brand: { client_profile_id, main_category } } = appParams;
  const cat = main_category?.split('|');
  const [rowData, setRowData] = useState([]);
  const [dateType, setDateType] = useState('Daily');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dateTypeArr, setDateTypeArr] = useState([
    { name: 'Daily', status: true },
    { name: 'Weekly', status: false },
    { name: 'Monthly', status: false },
  ])

  const [keywordCategories, setKeywordCategories] = useState([])
  const [category, setCategory] = useState()

  const dateFn = (dateType) => {
    if (dateType === 'Daily') {
      return getTminus3date()
    } else if (dateType === 'Weekly') {
      return getLast7Days()
    } else if (dateType === 'Monthly') {
      return getLast30Days()
    }
  }

  const token = localStorage.getItem('token');

  useEffect(() => {
    // const fetchCategories = async () => {
    //   setIsLoading(true)
    //   const { data: { data: { categories } } } = await axios.get(`${BASE_URL}keywordCategorization/categories?client_profile_id=${client_profile_id}`)
    //   setKeywordCategories(categories);
    //   setCategory(categories[0])
    //   setIsLoading(false)
    // }
    // fetchCategories();
    setKeywordCategories(cat);
    setCategory(cat[0])
  }, [client_profile_id])

  useEffect(() => {
    setIsLoading(true);
    if (!category) {
      setRowData([]);
      setIsLoading(false);
      // throw Error('No category mapped with this brand.')
      return;
    }
    axios.post(`${BASE_URL}keywordCategorization`,
      {
        main_category: category,
        time_stamp: dateFn(dateType),
        // time_stamp: '2024-02-09T00:00:00.000+00:00',
        check_column: 'added_search_terms'
      },
      {
        headers: {
          token
        }
      }).then((res) => {
        const data = res.data.data.keywordCatData;
        // console.log(data);
        if (res.status === 200 && data.length === 0) {
          setRowData(data);
          throw Error('No categorization for this category for today. Please see weekly data.')
        }
        setError(false);
        setRowData(data);
      }).catch((err) => {
        console.log(err);
        setError(err.message)
      }).finally(() => {
        setIsLoading(false);
      })
  }, [category, dateType])


  const handleDWMChange = (e) => {
    const { name, value } = e.target;
    // console.log(name,value);
    setDateType(value);
    const newArr = dateTypeArr?.map((el) => {
      if (name === el.name) {
        return {
          name: el.name,
          status: !el.status
        }
      } else {
        return {
          name: el.name,
          status: false
        }
      }
    })
    setDateTypeArr(newArr);
  }

  const handleCatChange = (e) => {
    // console.log(e.target.value);
    setCategory(e.target.value);
  }

  const rowClassRules = {
    'rag-red-outer': (params) => params.data.approval === 'Yes'
  }

  const editFn = () => {
    if (dateType === 'Daily') {
      return true;
    }
    return false;
  }

  const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, }), []);
  const columnDefs = useMemo(() => ([
    { headerName: 'Date', field: 'time_stamp', cellRenderer: DateComponentWithYear },
    { headerName: 'Search Term', field: 'search_term' },
    { headerName: 'Brand', field: 'brand', editable: editFn },
    { headerName: 'Approval', field: 'approval' },
    { headerName: 'Main Category', field: 'main_category' },
    { headerName: 'Last Node', field: 'last_node' },
    { headerName: 'Keyword Rank', field: 'keyword_rank' },
    { headerName: 'Search Volume', field: 'search_volume' },
  ]), [])

  const onCellValueChanged = (event) => {
    // console.log(event);
    const { data, colDef, newValue } = event;
    const updatedData = { ...data, [colDef.field]: newValue };

    const approval = 'No';
    updatedData['approval'] = approval;

    // console.log(updatedData);
    setIsLoading(true);
    axios.patch(`${BASE_URL}keywordCategorization/${data._id}`, {
      brand: updatedData.brand
    }, {
      headers: {
        token
      }
    }).then((res) => {
      //console.log(res.data);
      const updatedAllData = rowData.map((el) => el._id === updatedData._id ? updatedData : el)
      setRowData(updatedAllData);
    }).catch((err) => {
      console.log(err);
      NotificationManager.error('Unable to update data', 'Error', 3000)
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}keywordCategorization/submit`, {
        // client_profile_id: client_profile_id
        client_profile_id: '4422554122982285'
      }, {
        headers: {
          token
        }
      })
      setIsLoading(false);
      if (res.status === 200) {
        NotificationManager.success('Data Successfully Submited', 'Success', 3000)
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      NotificationManager.error('Unable to Submit Data', 'Error', 3000)
    }
  }


  return (
    <>
      <div>
        <div>
          <span style={{ color: '#b8b6b6', fontSize: "15px" }}>Instructions:</span>
          <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
            <li>You can edit the <strong>Brand column</strong> value by double click to active cell and after putting the value press enter to save it.</li>
            <li>Please Submit your saved value by clicking the <strong>Submit</strong> button down below.</li>
            <li>Mention the <strong>Brand Name</strong> according to the actual name, be specific for the brand's name.</li>
            <li>If the search term is Generic, do not use <strong>"generic", "genric"</strong> etc.</li>
          </ul>
        </div>
        <div className='keyword_cat_container-content' >
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '14px', marginRight: '10px', paddingTop: '3px' }}>Category: </div>
            <select value={category} onChange={handleCatChange} name="" id="" className='form-select form-select-sm keyword_cat_container-select'>
              {keywordCategories?.map((category, index) => {
                return (
                  <option key={index} value={category}>{category}</option>
                )
              })}
            </select>
          </div>
          <div>
            <ToggleButtonGroup
              color="primary"
              value={dateType}
              onChange={handleDWMChange}
              aria-label="Platform"
              // disabled={loader ? true : false}
              sx={{ maxHeight: 28, boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px" }}
            >
              {
                dateTypeArr?.map((el) => {
                  return (
                    <ToggleButton key={el.name} name={el.name} value={el.name}>{el.name[0]}</ToggleButton>
                  )
                })
              }
            </ToggleButtonGroup>
          </div>
        </div>

        <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
          {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
            <AgGridReact
              // ref={gridRef}
              rowData={rowData}
              rowHeight={AG_AR.rH}
              headerHeight={AG_AR.hH}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressNoRowsOverlay={true}
              suppressDragLeaveHidesColumns={true}
              rowClassRules={rowClassRules}
              onCellValueChanged={onCellValueChanged}
              suppressMenuHide={true}
            >
            </AgGridReact>
          }
          {
            isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
          }
        </div>
        <div className='button-contain'>
          {/* <button className='keyword-button' onClick={handleSubmit}>Submit</button> */}
        </div>

      </div>
    </>
  )
}

export default KeywordCatContainer;