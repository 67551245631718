import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { saveUserData } from "../../redux/user/userActions";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./Login.css";
import { saveAppParamsData } from "../../redux/appParams/appParamsActions";
import { BASE_URL } from "../../appConstants";
import EnlyticalLogo from "../../EnlyticalLogo.png";
import Loader from "../commonComponent/Loader/Loader";
import { useDispatch } from "react-redux";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  //console.log(user);
  const [state, setState] = useState({
    password: "",
    email: "",
  });
  const [formErrors, setFromErrors] = useState({
    email: false,
    password: false,
    invalidEmail: false,
  });
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
    if (name === "email") {
      setFromErrors(prevState => ({
        ...prevState,
        email: false,
        invalidEmail: false
      }));
    } else {
      setFromErrors(prevState => ({
        ...prevState,
        [name]: false,
      }));
    }

  };

  const handleSubmit = async (e) => {
    const { email, password } = state;
    if (email === "") {
      setFromErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
      return;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setFromErrors((prevState) => ({
        ...prevState,
        invalidEmail: true,
      }));
      return;
    } else if (password === "") {
      setFromErrors((prevState) => ({
        ...prevState,
        password: true,
      }));
      return;
    }

    setLoading(true);
    try {
      const auth = await axios.post(`${BASE_URL}auth/login`, state);
      const { token, user } = auth.data.data;
      dispatch(saveUserData(user));
      // console.log(user);
      localStorage.setItem("token", token);
      if (user.user_domain === 'enlytical_admin') {
        const getAgency = await axios.get(`${BASE_URL}agency`, {
          headers: {
            token
          }
        })
        const { agency_array } = getAgency.data.data;
        const { _id, } = agency_array[0];
        // console.log(agency_array);
        const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
          headers: {
            token
          }
        })
        const { brand_array } = getBrand.data.data;
        // console.log(brand_array);
        let current_brand = brand_array[0];
          if (brand_array?.length) {
            let currentBrandInLocal = localStorage.getItem("currentBrand");
            if (currentBrandInLocal) {
              for (let b of brand_array) {
                if (b._id === currentBrandInLocal) {
                  current_brand = b;
                  break;
                }
              }
            }
          }
        dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
        if (brand_array.length === 0) {
          navigate("/inactiveUser");
        } else {
          navigate("/home/app");
        }
      } else if (user.user_domain === 'user') {
        const getAgency = await axios.get(`${BASE_URL}agency`, {
          headers: {
            token
          }
        })
        const { agency_array } = getAgency.data.data;
        // console.log(agency_array);
        if (agency_array.length === 0) {
          const getBrand = await axios.get(`${BASE_URL}brand`, {
            headers: {
              token
            }
          })
          const { brand_array } = getBrand.data.data;
          // console.log(brand_array);
          let current_brand = brand_array[0];
          if (brand_array?.length) {
            let currentBrandInLocal = localStorage.getItem("currentBrand");
            if (currentBrandInLocal) {
              for (let b of brand_array) {
                if (b._id === currentBrandInLocal) {
                  current_brand = b;
                  break;
                }
              }
            }
          }
          dispatch(saveAppParamsData({ agency_array, current_agency: [], brand_array, current_brand: current_brand }));

          if (brand_array.length === 0) {
            navigate("/inactiveUser");
          } else {
            navigate("/home/app");
          }
        } else if (agency_array.length > 0) {
          const { _id, name } = agency_array[0];
          const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
            headers: {
              token
            }
          })
          const { brand_array } = getBrand.data.data;
          let current_brand = brand_array[0];
          if (brand_array?.length) {
            let currentBrandInLocal = localStorage.getItem("currentBrand");
            if (currentBrandInLocal) {
              for (let b of brand_array) {
                if (b._id === currentBrandInLocal) {
                  current_brand = b;
                  break;
                }
              }
            }
          }
          dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
          if (brand_array.length === 0) {
            navigate("/inactiveUser");
          } else {
            navigate("/home/app");
          }
        }
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error(error.response.data.data.message, "Error", 3000);
    }

    // axios
    //   .post(`${BASE_URL}auth/login`, state)
    //   .then(function (response) {
    //     // console.log(response.data.data);
    //     const { token, user, brand_array } = response.data.data;
    //     dispatch(saveUserData(user));
    //     let current_brand = brand_array[0];
    //     if (brand_array?.length) {
    //       let currentBrandInLocal = localStorage.getItem("currentBrand");
    //       if (currentBrandInLocal) {
    //         for (let b of brand_array) {
    //           if (b._id === currentBrandInLocal) {
    //             current_brand = b;
    //             break;
    //           }
    //         }
    //       }
    //     }
    //     dispatch(saveAppParamsData({ current_brand: current_brand, brand_array }));
    //     localStorage.setItem("token", token);
    //     if (brand_array.length === 0) {
    //       navigate("/inactiveUser");
    //     } else {
    //       navigate("/home/app");
    //     }
    //     setLoading(false);
    //   })
    //   .catch(function (error) {
    //     //console.log(error);
    //     setLoading(false);
    //     NotificationManager.error(
    //       error.response.data.data.message,
    //       "Error",
    //       3000
    //     );
    //   });

    e.preventDefault();
  };


  return (
    <div className="mainLoginPage">
      <div className="headerLogin">
        <div>
          <img src={EnlyticalLogo} alt="logo" height={"60px"} width={'auto'} />
        </div>
      </div>
      <div className="mainLoginContainer">
        {/* <div className="leftLoginPage">
          <div className="leftText">
            <h1 className="mb-0">
              <strong>AI enabled </strong>Growth
            </h1>
            <h1 className="mb-0">We make E-commerce Faster,</h1>
            <h1 className="mb-0">Simpler and Better</h1>
            <img src="/loginPageImg.jpg" alt="img" />
          </div>
        </div> */}
        <div className="rightLoginPage">
          {
            loading && <div className="rightLoginPageLoader" >
              <Loader />
            </div>
          }

          <div className="loginContainer">
            {/* <div style={{ display: "flex", justifyContent: 'center',marginBottom:'20px' }}>
              <img src={EnlyticalLogo} alt="logo" height={"40px"} />
            </div> */}
            <div className="loginContainerHeader">
              <h4 className="mb-1">Login</h4>
              <h5 className="mb-2">to continue with <span style={{ color: "var(--active-color)" }}>enlytical.ai</span></h5>
            </div>
            <hr />
            <form className="form">
              <div className="mb-3">
                <label className="mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control inputStyle"
                  id="email"
                  placeholder="name@example.com"
                  onChange={handleInputChange}
                  value={state.email}
                ></input>
              </div>
              {formErrors.email && (
                <p className="errorContainer">Email is required!</p>
              )}
              {formErrors.invalidEmail && (
                <p className="errorContainer">Invalid Email!</p>
              )}
              <div className="mb-3">
                <label className="mb-1">Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control inputStyle"
                  id="password"
                  placeholder="Password"
                  onChange={handleInputChange}
                  value={state.password}
                ></input>
              </div>
              {formErrors.password && (
                <p className="errorContainer">Password is required!</p>
              )}
              <div>

                <div>
                  <button
                    style={{ backgroundColor: "var(--active-color)", color: "#fff", fontSize: '17px' }}
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-m mt-4 mb-3 col-lg-12"
                    disabled={loading ? true : false}
                  >
                    Sign In <i style={{ WebkitTextStroke: "0.6px", fontSize: '20px' }} className="bi bi-box-arrow-in-right"></i>
                  </button>
                </div>

                <div style={{ color: "var(--active-color)", marginTop: "10px" }}>
                  <Link to={"/user/forgetPassword"}>Reset Password</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;
