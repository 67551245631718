import React, { useState, useMemo, useEffect } from 'react'
import './MarketingContainer.scss';
import { AgGridReact } from 'ag-grid-react';
import Loader from '../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import { AG_AR, BASE_URL } from '../../../appConstants';
import NestedMenu from '../../commonComponent/NestedMenu/NestedMenu';
import axios from 'axios';


const MarketingContainer = () => {
  const [rowData, setRowData] = useState([]);

  const createKpi = () => {
    const uniqueCat = [...new Set(rowData?.map((el) => el.category))]?.map(category => ({ category, checked: false }))
    const uniqueCampaign = [...new Set(rowData?.map((el) => el.campaignType))]?.map(campaignType => ({ campaignType, checked: false }))
    const uniqueTarget = [...new Set(rowData?.map((el) => el.targeting_type))]?.map(targeting_type => ({ targeting_type, checked: false }))
    const uniqueKeyword = [...new Set(rowData?.map((el) => el.keyword_type))]?.map(keyword_type => ({ keyword_type, checked: false }))
    const uniquePlacement = [...new Set(rowData?.map((el) => el.placement))]?.map(placement => ({ placement, checked: false }))
    return [
      { label: 'category', values: uniqueCat },
      { label: 'campaignType', values: uniqueCampaign },
      { label: 'targeting_type', values: uniqueTarget },
      { label: 'keyword_type', values: uniqueKeyword },
      { label: 'placement', values: uniquePlacement },
    ]
  }
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem('token');
  const [kpis, setKpis] = useState([]);
  console.log(kpis);

  useEffect(() => {
    setKpis(createKpi());
  }, [rowData])

  const [kpi, setKpi] = useState({})
  const [filterArr, setFilterArr] = useState({
    category: [],
    campaignType: [],
    targeting_type: [],
    keyword_type: [],
    placement: []
  });

  useEffect(() => {
    axios.get(`${BASE_URL}marketingStream`,)
      .then((res) => {
        console.log(res.data.data.data);
        setRowData(res.data.data.data)
      })
  }, [])

  const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, flex: 1, }), []);
  const columnDefs = useMemo(() => ([
    { headerName: 'Time', field: 'time_stamp', filter: true, },
    { headerName: "Impressions", field: "impressions", },
    { headerName: "Clicks", field: "clicks", },
    { headerName: "CTR", field: "ctr", },
    { headerName: "CPC", field: "cpc", },
    { headerName: "Orders", field: "attributedUnitsOrdered14d" },
    { headerName: "Conversion", field: "attributedConversions14d", },
    { headerName: "ACOS", field: "acos", },
    { headerName: "Spend", field: "cost", },
    { headerName: "Sales", field: 'attributedSales14d', },
    { headerName: "Units", field: 'unit', },
  ]), [])

  const kpiArr = ['category', 'campaignType', 'targeting_type', 'keyword_type', 'placement'];
  const val = kpiArr.filter((el) => el === kpi.label);

  const handleChange = (e) => {
    const arr = [...kpis];
    arr.forEach(ele => {
      ele.values?.forEach((el) => {
        if (el[val] === e.target.name) {
          el.checked = !el.checked;
        }
      })
    });
    // filterArr[val].push(el[val])
    setKpis(arr);
    console.log(filterArr);

  }

  return (
    <div className='marketing_container'>
      <div className='filter_box'>
        <div className='filter_box-box1'>
          <div className='filter_box-subBox1'>Filters</div>
          <div className='filter_box-subBox2'>
            {
              kpis?.map((el) => {
                return (
                  <div onClick={() => setKpi(el)}>{el.label}</div>
                )
              })
            }
          </div>
        </div>
        <div className='filter_box-box2'>
          <div className='filter_box-subBox3'>
            {
              kpi?.values?.map((value) => {
                return <div>
                  <input onChange={handleChange} type="checkbox" id={value[val]} name={value[val]} checked={value.checked}></input>
                  <label>{value[val]}</label>
                </div>
              })
            }
          </div>
          <div className='filter_box-subBox4'>
            {
              filterArr[val]?.map((filter) => {
                return (
                  <div>{filter}</div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 570, width: '100%' }}>
        {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
          <AgGridReact
            rowData={rowData}
            rowHeight={AG_AR.rH}
            headerHeight={AG_AR.hH}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressNoRowsOverlay={true}
            suppressDragLeaveHidesColumns={true}
            suppressMenuHide={true}
          >
          </AgGridReact>
        }
        {
          isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
        }
      </div>
    </div>
  )
}

export default MarketingContainer