import React from 'react';
import './InactiveUserPage.css';
import { useNavigate } from 'react-router-dom';

const InactiveUserPage = () => {
    const nevigate = useNavigate();
    const handleClick = () => {
        localStorage.removeItem("token");
        nevigate("/user/login");
    }
    return (
        <div className='inactive_container'>
            <div className='inactive_user_container'>
                <h2 >Sorry!! You do not have access to this brand. Your Brand Status is Inactive.</h2>
                <h5>Please ask administrator for permissions.</h5>
                <button type='button' className='btn btn-m inactive_user_btn' onClick={handleClick}>Back to login</button>
            </div>
        </div>
    )
}
export default InactiveUserPage;