

const addNumbersInArray = (arr) => {
    var sum = arr.reduce(function (x, y) {
        return x + y;
    }, 0);
    return sum;
}

const calculatePercentageChange = (initial, final) => {

    if (initial === final) {
        return 0
    } else if (final > 0 && initial === 0) {
        return final * 100;
    } else if (final === 0 && initial > 0) {
        return -100
    } else {
        return (final - initial) / initial * 100
    }
}

const divTwoNum = (num, den) => {
    if (num === "" || den === "") {
        return "error"
    } else if (num === 0 && den === 0) {
        return 0;
    } else if (num === 0 && den > 0) {
        return 0;
    } else if (num > 0 && den === 0) {
        return num / 1;
    } else {
        return num / den;
    }
}

const getYesterdayAndFirstDayOfMonth = (time_stamp) => {
    const yesterday = new Date(time_stamp);
    yesterday.setDate(yesterday.getDate() - 1);
    let firstDayOfMonth;
    if (yesterday.getDate() === 1) {
        firstDayOfMonth = new Date(yesterday);
    } else {
        const date = new Date(yesterday)
        firstDayOfMonth = new Date(date.setDate(date.getDate() - (date.getDate() - 1)));
    }
    return { yesterday, firstDayOfMonth }
}

const getDaysInMonth = (time_stamp) => {
    const date = new Date(time_stamp)
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    ////console.log(year, month)
    return new Date(year, month, 0).getDate()
}

const getFlowColor = (flow) => {
    let color;
    if (flow === "positive") {
        color = "#4CAF50";
    } else if (flow === "negative") {
        color = "#F44336";
    } else if (flow === "zero") {
        color = "#f5bf42"
    }
    return color;
}
const getFlowColorReverse = (flow) => {
    let color;
    if (flow === "positive") {
        color = "#F44336";
    } else if (flow === "negative") {
        color = "#4CAF50";
    } else if (flow === "zero") {
        color = "#f5bf42"
    }
    return color;
}

const getFlowColorForMetric = (flow, metric) => {
    if (["Spend", "ACOS", "CPC", "TACOS", "Ad Spend", "Organic Sales Total"].includes(metric)) {
        if (flow === "positive") {
            return "#F44336";//red
        } else if (flow === "negative") {
            return "#4CAF50"; //green
        } else if (flow === "zero") {
            return "#f5bf42"//yellow
        }
    } else {
        if (flow === "positive") {
            return "#4CAF50";
        } else if (flow === "negative") {
            return "#F44336";
        } else if (flow === "zero") {
            return "#f5bf42"
        }
    }
}

const getFirstLetterUpper = (str) => {
    let arr = str.replace("_", " ").split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str2 = arr.join(" ");
    return str2;
}

const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}

const formatDateFn = (startDate, endDate) => {
    const startMonth = startDate.toLocaleString('default', { month: 'short' });
    const endMonth = endDate.toLocaleString('default', { month: 'short' });
    return `${startDate.getDate()} ${startMonth} - ${endDate.getDate()} ${endMonth}, ${endDate.getFullYear()}`;
}
const formatSingleDateFn = (date) => {
    const dateNew = new Date(date);
    const month = dateNew.toLocaleString('default', { month: 'short' });
    return `${dateNew.getDate()} ${month}`;
}
const formatSingleDateFnWithYear = (date) => {
    const dateNew = new Date(date);
    const month = dateNew.toLocaleString('default', { month: 'short' });
    return `${dateNew.getDate()} ${month}, ${dateNew.getFullYear()}`;
}

const getMontheInText = (monthInNumber) => {
    const monthObj = {
        "1": "Jan",
        "2": "Feb",
        "3": "Mar",
        "4": "Apr",
        "5": "May",
        "6": "Jun",
        "7": "Jul",
        "8": "Aug",
        "9": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
    }
    const month = `${monthInNumber}`;
    return monthObj[month];
}
const getWeekdaysInText = (daysNum) => {
    const daysObj = {
        '0': "Sun",
        '1': "Mon",
        '2': "Tue",
        '3': "Wed",
        '4': "Thu",
        '5': "Fri",
        '6': "Sat",
    }
    const day = daysNum;
    return daysObj[day];
}

const getMonthYearArray = (prevMonth, agg) => {
    let date = new Date();
    let monthArray = [];
    for (let i = 0; i < prevMonth; i++) {
        let selected = false;
        if (agg === "weekly" && i < 2) {
            selected = true;
        }
        if (agg === "monthly") {
            selected = true;
        }
        let month = date.getMonth();
        let year = date.getFullYear();
        monthArray.push({ month_year: `${month + 1}-${year}`, selected });
        date.setMonth(date.getMonth() - 1);
    }
    monthArray.reverse();
    return monthArray;
}

// const getWeeks = (sd, ed) => {
//     let startDate = new Date(sd);
//     let endDate = new Date(ed);

//     let loopsd = new Date(sd);
//     let resultDataArray = [];
//     while (new Date(loopsd.getFullYear(), (loopsd.getMonth() + 1), 0) <= new Date(endDate.getFullYear(), (endDate.getMonth() + 1), 0)) {
//         let weekArray = []
//         let month = loopsd.getMonth() + 1;
//         let year = loopsd.getFullYear();
//         const monthInText = getMontheInText(month);

//         //This condition exclude future weeks
//         if ((startDate.getMonth() + 1) === month && (endDate.getMonth() + 1) === month && startDate.getFullYear() === year && endDate.getFullYear() === year) {
//             // console.log("start and end month same");
//             let start = startDate.getDate();
//             let end = endDate.getDate();
//             if (start <= 7 && end > 0) { weekArray.push(`W1 - ${monthInText}`) }
//             if (start <= 14 && end > 7) { weekArray.push(`W2 - ${monthInText}`) }
//             if (start <= 21 && end > 14) { weekArray.push(`W3 - ${monthInText}`) }
//             if (start <= 28 && end > 21) { weekArray.push(`W4 - ${monthInText}`) }
//             if (start <= 31 && end > 28) { weekArray.push(`W5 - ${monthInText}`) }
//         } else if (month === (startDate.getMonth() + 1) && year === startDate.getFullYear()) {
//             let start = startDate.getDate();
//             if (start <= 7) {
//                 weekArray.push(`W1 - ${monthInText}`, `W2 - ${monthInText}`, `W3 - ${monthInText}`, `W4 - ${monthInText}`, `W5 - ${monthInText}`);
//             } else if (start <= 14) {
//                 weekArray.push(`W2 - ${monthInText}`, `W3 - ${monthInText}`, `W4 - ${monthInText}`, `W5 - ${monthInText}`);
//             } else if (start <= 21) {
//                 weekArray.push(`W3 - ${monthInText}`, `W4 - ${monthInText}`, `W5 - ${monthInText}`);
//             } else if (start <= 28) {
//                 weekArray.push(`W4 - ${monthInText}`, `W5 - ${monthInText}`);
//             } else {
//                 weekArray.push(`W5 - ${monthInText}`);
//             }
//             //console.log("start month")
//         } else if (month === (endDate.getMonth() + 1) && year === endDate.getFullYear()) {
//             //console.log("end month")
//             let end = endDate.getDate();
//             if (end > 28) {
//                 weekArray.push(`W1 - ${monthInText}`, `W2 - ${monthInText}`, `W3 - ${monthInText}`, `W4 - ${monthInText}`, `W5 - ${monthInText}`);
//             } else if (end > 21) {
//                 weekArray.push(`W1 - ${monthInText}`, `W2 - ${monthInText}`, `W3 - ${monthInText}`, `W4 - ${monthInText}`);
//             } else if (end > 14) {
//                 weekArray.push(`W1 - ${monthInText}`, `W2 - ${monthInText}`, `W3 - ${monthInText}`);
//             } else if (end > 7) {
//                 weekArray.push(`W1 - ${monthInText}`, `W2 - ${monthInText}`);
//             } else {
//                 weekArray.push(`W1 - ${monthInText}`);
//             }
//         } else {
//             weekArray.push(`W1 - ${monthInText}`, `W2 - ${monthInText}`, `W3 - ${monthInText}`, `W4 - ${monthInText}`);
//             const daysInMonth = new Date(year, month, 0).getDate();
//             if (daysInMonth > 28) {
//                 weekArray.push(`W5 - ${monthInText}`)
//             }
//         }
//         resultDataArray.push(...weekArray);

//         loopsd.setMonth(loopsd.getMonth() + 1);
//     }
//     return resultDataArray;
// }

const getWeeks = (m_y_array) => {
    let resultArray = [];
    for (let el of m_y_array) {
        let { month_year, selected } = el;
        if (selected) {
            let arr;
            let m_y_a = month_year.split("-");
            let month = parseInt(m_y_a[0]);
            let year = parseInt(m_y_a[1]);
            let monthInText = getMontheInText(month);
            let daysInMonth = new Date(year, month, 0).getDate();


            let dateObj = new Date();
            let currentMonth = dateObj.getMonth() + 1;
            let currentDate = dateObj.getDate();

            if (currentMonth === month) {
                if (currentDate > 28) {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`, `W2-${monthInText}-${m_y_a[1].slice(-2)}`, `W3-${monthInText}-${m_y_a[1].slice(-2)}`, `W4-${monthInText}-${m_y_a[1].slice(-2)}`, `W5-${monthInText}-${m_y_a[1].slice(-2)}`];
                } else if (currentDate > 21) {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`, `W2-${monthInText}-${m_y_a[1].slice(-2)}`, `W3-${monthInText}-${m_y_a[1].slice(-2)}`, `W4-${monthInText}-${m_y_a[1].slice(-2)}`];
                } else if (currentDate > 14) {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`, `W2-${monthInText}-${m_y_a[1].slice(-2)}`, `W3-${monthInText}-${m_y_a[1].slice(-2)}`];
                } else if (currentDate > 7) {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`, `W2-${monthInText}-${m_y_a[1].slice(-2)}`];
                } else {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`];
                }
            } else {
                if (daysInMonth > 28) {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`, `W2-${monthInText}-${m_y_a[1].slice(-2)}`, `W3-${monthInText}-${m_y_a[1].slice(-2)}`, `W4-${monthInText}-${m_y_a[1].slice(-2)}`, `W5-${monthInText}-${m_y_a[1].slice(-2)}`]
                } else {
                    arr = [`W1-${monthInText}-${m_y_a[1].slice(-2)}`, `W2-${monthInText}-${m_y_a[1].slice(-2)}`, `W3-${monthInText}-${m_y_a[1].slice(-2)}`, `W4-${monthInText}-${m_y_a[1].slice(-2)}`]
                }
            }
            resultArray.push(...arr);
        }
    }
    return resultArray;
}

// const getMonths = (sd, ed) => {
//     let startDate = new Date(sd);
//     let endDate = new Date(ed);
//     let resultDataArray = [];
//     while (new Date(startDate.getFullYear(), (startDate.getMonth() + 1), 0) <= new Date(endDate.getFullYear(), (endDate.getMonth() + 1), 0)) {
//         let month = startDate.getMonth() + 1;
//         let year = startDate.getFullYear();
//         const monthInText = getMontheInText(month);
//         resultDataArray.push(`${monthInText}-${year}`);
//         startDate.setMonth(startDate.getMonth() + 1);
//     }
//     return resultDataArray;
// }

const getMonths = (m_y_array) => {
    let resultArray = [];
    for (let el of m_y_array) {
        let { month_year, selected } = el;
        if (selected) {
            let m_y_a = month_year.split("-");
            let month = parseInt(m_y_a[0]);
            let year = parseInt(m_y_a[1]);
            let monthInText = getMontheInText(month);
            resultArray.push(`${monthInText}-${year}`);
        }
    }
    return resultArray;
}
const getMonthInTextArray = (m_y_array) => {
    let str = "";
    for (let el of m_y_array) {
        if (el.selected) {
            let m_y_a = el.month_year.split("-");
            let month = parseInt(m_y_a[0]);
            let monthInText = getMontheInText(month);
            str += `${monthInText}, `
        }
    }
    return str
}


const getDynamicDateRange = (date, type) => {
    let today = new Date(date);
    let day = today.getDate();
    let startDate = new Date(date);
    let endDate = new Date(date);
    if (type === "lastsevendays") {
        startDate.setDate(startDate.getDate() - 6);
        return {
            startDate,
            endDate
        }
    } else if (type === "lastweek") {
        if (day >= 1 && day < 8) {
            if (today.getMonth() === 2) {
                startDate.setMonth(1);
                startDate.setDate(22);
                endDate.setMonth(1);
                endDate.setDate(28);
            } else {
                startDate.setMonth(startDate.getMonth() - 1);
                startDate.setDate(29);
                endDate.setMonth(endDate.getMonth() - 1);
                let lastDate = new Date(today.getFullYear(), today.getMonth(), 0).getDate()
                endDate.setDate(lastDate);
            }
        } else if (day >= 8 && day < 15) {
            startDate.setDate(1);
            endDate.setDate(7);
        } else if (day >= 15 && day < 22) {
            startDate.setDate(8);
            endDate.setDate(14);
        } else if (day >= 22 && day < 29) {
            startDate.setDate(15);
            endDate.setDate(21);
        } else if (day >= 29 && day <= 31) {
            startDate.setDate(22);
            endDate.setDate(28);
        }
    } else if (type === "thisweek") {
        if (day >= 1 && day < 8) {
            startDate.setDate(1);
        } else if (day >= 8 && day < 15) {
            startDate.setDate(8);
        } else if (day >= 15 && day < 22) {
            startDate.setDate(15);
        } else if (day >= 22 && day < 29) {
            startDate.setDate(22);
        } else if (day >= 29 && day <= 31) {
            startDate.setDate(29);
        }
    } else if (type === "thismonth") {
        startDate.setDate(1);
    } else if (type === "lastmonth") {
        startDate.setMonth(startDate.getMonth() - 1);
        startDate.setDate(1);
        endDate.setMonth(endDate.getMonth() - 1);
        let lastDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
        endDate.setDate(lastDate);
    }
    return {
        startDate,
        endDate
    }
}

const getPrevMonthFirstDate = (d) => {
    let prevMonthDateTime = new Date(d);
    prevMonthDateTime.setMonth(prevMonthDateTime.getMonth() - 1);
    prevMonthDateTime.setDate(1);
    return prevMonthDateTime;
}

const getForthMonthFirstDateFromThisMonth = (d) => {
    let prevMonthDateTime = new Date(d);
    prevMonthDateTime.setMonth(prevMonthDateTime.getMonth() - 3);
    prevMonthDateTime.setDate(1);
    return prevMonthDateTime;
}

const convertDateFormat = (dateString) => {
    const dateParts = dateString.split(' ');
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];
    const monthMap = {
        'Jan,': '01',
        'Feb,': '02',
        'Mar,': '03',
        'Apr,': '04',
        'May,': '05',
        'Jun,': '06',
        'Jul,': '07',
        'Aug,': '08',
        'Sep,': '09',
        'Oct,': '10',
        'Nov,': '11',
        'Dec,': '12'
    };

    const monthNumber = monthMap[month]; // 4 Jun, 2023 to 2023-06-4 
    if (day.length < 2) {
        return `${year}-${monthNumber}-0${day}`
    }

    return `${year}-${monthNumber}-${day}`;
}


const addTotalInDWM = (obj, property, addEl) => {
    return obj.hasOwnProperty(property) ? obj[property] + addEl : addEl;
}
const roundOffToTwoDecimal = (num) => {
    const result = Math.round(num * 100) / 100;
    return result;
}

// const checkBrandUserType = (brandArray) => {
//     let brandUserType = "";
//     brandArray.map((el) => {
//         if (el.brand_user_type === "internal_admin") {
//             return brandUserType = el.brand_user_type;
//         } else if (el.brand_user_type === "internal_viewer") {
//             brandUserType = el.brand_user_type;
//         } else if (el.brand_user_type === "client_admin") {
//             brandUserType = el.brand_user_type;
//         } else if (el.brand_user_type === "client_viewer") {
//             brandUserType = el.brand_user_type;
//         }
//     })
//     return brandUserType;
// }

function debounce(func, delay) {
    let timeout;
    return function (...args) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

function getDayOfDate(date){
    const dayIndex = new Date(date).getDay();
    if(dayIndex===0) return "Sun"
    else if(dayIndex===1) return "Mon"
    else if(dayIndex===2) return "Tue"
    else if(dayIndex===3) return "Wed"
    else if(dayIndex===4) return "Thu"
    else if(dayIndex===5) return "Fri"
    else if(dayIndex===6) return "Sat"
}

function lastDayInMonth (month, year) {
    const monthMap = {
        'Jan': 1,
        'Feb': 2,
        'Mar': 3,
        'Apr': 4,
        'May': 5,
        'Jun': 6,
        'Jul': 7,
        'Aug': 8,
        'Sep': 9,
        'Oct': 10,
        'Nov': 11,
        'Dec': 12
    };
    return new Date(year, monthMap[month], 0).getDate();
}

function getDateOfWeek(week){
    const weekNumber = Number(week.substring(1,2));
    const month = week.substring(3, 6)
    const year = Number(`20${week.slice(-2)}`);
    const startDate = ((weekNumber-1) * 7)+1;
    const endDate = weekNumber !== 5 ? weekNumber * 7 : lastDayInMonth(month, year)
    const weekStart = getDayOfDate(new Date(`${startDate} ${month} ${year}`));
    const weekEnd = getDayOfDate(new Date(`${endDate} ${month} ${year}`));
    return `${startDate},${weekStart}-${endDate},${weekEnd}`
}

export {
    roundOffToTwoDecimal,
    addNumbersInArray,
    calculatePercentageChange,
    divTwoNum,
    getYesterdayAndFirstDayOfMonth,
    getDaysInMonth,
    getFlowColor,
    getFlowColorReverse,
    getFlowColorForMetric,
    getFirstLetterUpper,
    formatDate,
    formatDateFn,
    formatSingleDateFn,
    formatSingleDateFnWithYear,
    getWeeks,
    getMonths,
    getDynamicDateRange,
    getPrevMonthFirstDate,
    getForthMonthFirstDateFromThisMonth,
    convertDateFormat,
    getMonthYearArray,
    getMonthInTextArray,
    getWeekdaysInText,
    addTotalInDWM,
    debounce, 
    getMontheInText,
    getDayOfDate,
    getDateOfWeek
}


