import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    registerables,
    Tooltip,
} from 'chart.js';

import { NotificationContainer } from 'react-notifications';


const LineGraph = (props) => {
    const { data, yAxesColor, y1AxesColor, y2AxesColor, y3AxesColor } = props;
    const axesId = data.datasets.map((el) => el.yAxisID);
    // const axesLabel = data.datasets.map((el) => el.label);
    // console.log(axesLabel);
    // const axis = axesLabel[axesLabel.length - 1]
    // console.log(axis);
    const [yAxisObj] = data.datasets?.filter(el => el.yAxisID === "y");
    // console.log(yAxisObj)
    const [y1AxisObj] = data.datasets?.filter(el => el.yAxisID === "y1");
    const [y2AxisObj] = data.datasets?.filter(el => el.yAxisID === "y2");
    const [y3AxisObj] = data.datasets?.filter(el => el.yAxisID === "y3");
    const getLabel = (axisName) => {

        if (axisName === "y") {
            return yAxisObj?.label
        } else if (axisName === "y1") {
            return y1AxisObj?.label
        } else if (axisName === "y2") {
            return y2AxisObj?.label
        } else if (axisName === "y3") {
            return y3AxisObj?.label
        }
    }
    // console.log(data.labels);

    ChartJS.register(
        ...registerables,
        Tooltip,
        {
            id: 'line',
            afterDraw: function (chart) {
                if (chart.config.type === 'line') {
                    if (chart.tooltip?._active && chart.tooltip?._active.length) {
                        const activePoint = chart.tooltip?._active[0];
                        const { ctx } = chart;
                        const x = activePoint.element.x;
                        const topY = chart.scales.y.top;
                        const bottomY = chart.scales.y.bottom;
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 1;
                        ctx.strokeStyle = 'lightgrey';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        }
    );


    // const xAxisDataArray = [...data.labels];
    // const chartRef = useRef();
    // const [startXAxisDateArray, setStartXAxisDateArray] = useState([]);
    // const [endXAxisDateArray, setEndXAxisDateArray] = useState([]);
    // const [mouseDownValue, setMouseDownValue] = useState(null);

    // const [startLine, setStartLine] = useState([]);
    // const [endLine, setEndLine] = useState([]);

    // const [lineStartEndPoints, setLineStartEndPoints] = useState([]);

    // {
    //     startX: null,
    //     startY: null,
    //     endX: null,
    //     endY: null
    // }
    // const onClick = function (e) {
    // //console.log(getElementsAtEvent(chartRef.current, e));
    // const { ctx } = chartRef.current
    // ctx.fillStyle = "lightgreen";
    // ctx.fillRect(30, 200, 955, 50);
    // }
    // useEffect(() => {
    //     props.graphAreaSelectFn({
    //         startDateArray: startXAxisDateArray,
    //         endDateArray: endXAxisDateArray
    //     })
    // }, [startXAxisDateArray, endXAxisDateArray]);


    // const onMouseDown = function (e) {
    //     //  window.addEventListener("mousemove", onMouseMove)

    //     setMouseDownValue(null);
    //     const el = getElementsAtEvent(chartRef.current, e)[0];

    //     if (el) {
    //         if (startXAxisDateArray.length < 2 && endXAxisDateArray.length < 2) {
    //             const index = el.index;
    //             setMouseDownValue(xAxisDataArray[index]);
    //             const { x, y } = el.element;
    //             setStartLine([{
    //                 x, y
    //             }]);
    //             setEndLine([{
    //                 x, y
    //             }])
    //             //console.log("onMouseDow", xAxisDataArray[index]);
    //         } else {
    //             NotificationManager.error("You cannot select more than two areas.", 'Warning', 3000);
    //         }

    //     }
    // }
    // const onMouseUp = function (e) {
    //     // window.removeEventListener("mousemove", onMouseMove);
    //     setEndLine([]);
    //     setStartLine([]);
    //     const el = getElementsAtEvent(chartRef.current, e)[0];
    //     if (el && mouseDownValue) {
    //         const index = getElementsAtEvent(chartRef.current, e)[0].index;
    //         //console.log("onMouseUp", xAxisDataArray[index]);
    //         setEndXAxisDateArray(prevState => ([...prevState, xAxisDataArray[index]]));
    //         setStartXAxisDateArray(prevState => ([...prevState, mouseDownValue]));
    //     }
    //     setMouseDownValue(null);
    // }

    // const onMouseMove = function (e) {
    //     // const el = (chartRef.current, e)[0];
    //     // //console.log(el);   
    //     ////console.log(chartRef.current)   

    //     const { layerX, layerY } = e.nativeEvent
    //     // //console.log(layerX, layerY);
    //     setEndLine([{
    //         x: layerX,
    //         y: layerY
    //     }])
    // }

    // const onDoubleClick = () => {
    //     const startXAxisArray = [...startXAxisDateArray];
    //     const endXAxisArray = [...endXAxisDateArray];
    //     startXAxisArray.pop();
    //     endXAxisArray.pop();
    //     setStartXAxisDateArray(startXAxisArray);
    //     setEndXAxisDateArray(endXAxisArray);
    // }

    // const getSelectedAreaDate = (startDate, endDate) => {
    //     const startArray = startDate.split("-");
    //     if (startDate === endDate) {
    //         return `${startArray[2]} ${getMontheInText(startArray[1])}`
    //     } else {
    //         const endArray = endDate.split("-");
    //         return `${startArray[2]} ${getMontheInText(startArray[1])} - ${endArray[2]} ${getMontheInText(endArray[1])}`
    //     }
    // }

    // const dateHighlighter = {
    //     id: "dateHighlighter",
    //     afterDatasetDraw(chart, args, pluginOptions) {
    //         const { ctx } = chart;
    //         const { x, y } = chart.scales;
    //         const angle = Math.PI / 180;
    //         ctx.save();
    //         const { top, left, bottom, right, width, height } = chart.chartArea

    //         for (let i = 0; i < pluginOptions.startLine.length; i++) {
    //             ctx.beginPath();
    //             ctx.moveTo(pluginOptions.startLine[i].x, pluginOptions.startLine[i].y);
    //             ctx.lineTo(pluginOptions.endLine[i].x, pluginOptions.endLine[i].y);
    //             ctx.stroke();
    //         }

    //         for (let i = 0; i < pluginOptions.startDate.length; i++) {
    //             const startDate = pluginOptions.startDate[i];
    //             const endDate = pluginOptions.endDate[i];
    //             ctx.fillStyle = "rgba(0, 0, 0, 0.2)";
    //             ctx.fillRect(x.getPixelForValue(startDate), top, x.getPixelForValue(endDate) - x.getPixelForValue(startDate), height);

    //         }
    //         ctx.rotate(90 * angle)
    //         for (let j = 0; j < pluginOptions.startDate.length; j++) {
    //             const startDate = pluginOptions.startDate[j];
    //             const endDate = pluginOptions.endDate[j];
    //             const diff = (x.getPixelForValue(endDate) - x.getPixelForValue(startDate)) / 2;
    //             ctx.translate(0, 0);
    //             ctx.fillStyle = "#666";
    //             ctx.textAlign = "center";
    //             ctx.font = "bold 12px sans-serif";
    //             ctx.fillText(getSelectedAreaDate(startDate, endDate), height / 2 + top, (x.getPixelForValue(startDate) + diff) * -1);
    //         }
    //         ctx.restore();
    //     }
    // }


    // const annotationLine = {
    // afterDraw: function (chart) {

    //     if (chart.tooltip._active && chart.tooltip._active.length) {
    //         const activePoint = chart.tooltip._active[0];
    //         const {ctx} = chart
    //         //console.log(ctx);
    //         const x = activePoint.element.x;
    //         const topY = chart.scales.y.top;
    //         const bottomY = chart.scales.y.bottom;
    //         ctx.save();
    //         ctx.beginPath();
    //         ctx.moveTo(x, topY);
    //         ctx.lineTo(x, bottomY);
    //         ctx.lineWidth = 2;
    //         ctx.strokeStyle = 'red';
    //         ctx.stroke();
    //         ctx.restore();
    //     }
    // }
    // }


    const ticksForGraterThanThousand = (value, label) => {
        let num = ((value) / 1000);
        if (label === 'Sales') {
            return '₹' + num + "k";
        } else if (label === 'Spend') {
            return '₹' + num + "k";
        } else if (label === 'ACOS') {
            return num * 1000 + '%';
        } if (label === 'Orders') {
            return num + "k";
        } if (label === 'Clicks') {
            return num + "k";
        } if (label === 'CPC') {
            return '₹' + num + "k";
        } if (label === 'Impressions') {
            return num + "k";
        } if (label === 'CTR') {
            return num * 1000 + '%';
        } if (label === 'Conversion') {
            return num * 1000 + '%';
        } if (label === 'ROAS') {
            return num + 'k';
        } if (label === 'TOS_IS') {
            return num + 'k';
        }
    }
    const ticksForLessThanThousand = (value, label) => {
        if (label === ('Sales')) {
            return '₹' + value;
        } else if (label === ('Spend')) {
            return '₹' + value;
        } else if (label === 'ACOS') {
            return value + '%';
        } if (label === 'Orders') {
            return value;
        } if (label === 'Clicks') {
            return value;
        } if (label === 'CPC') {
            return '₹' + value;
        } if (label === 'Impressions') {
            return value;
        } if (label === 'CTR') {
            return value + '%';
        } if (label === 'Conversion') {
            return value + '%';
        } if (label === 'ROAS') {
            return value;
        } if (label === 'TOS_IS') {
            return value;
        }
    };

    // const xAxisTicksCallback = (value, index, values)=>{
    //     console.log(value);
    //     const date = new Date(value);
    //     console.log(date);
    //     const month = date.toLocaleString('default', {month:'short'});
    //     return `${date.getDate()} ${month}, ${date.getFullYear()}`;
    // }


    const yAxisTickscallback = (value, index, ticks, axisName) => {
        // console.log(value);
        const label = getLabel(axisName);
        // console.log(label);
        if (ticks.length <= 2) {
            let mid = (ticks[0].value + ticks[1]?.value) / ticks.length;
            // console.log(mid);
            ticks.push({
                value: mid,
                label: mid >= 1000 ? ticksForGraterThanThousand(mid, label) : ticksForLessThanThousand(mid, label)
            })
        }
        if ((value) >= 1000) {
            return ticksForGraterThanThousand(value, label);
        } else {
            return ticksForLessThanThousand(value, label);
        }
    }

    const tooltipCallback = (tooltipItem, data) => {
        const label = data.datasets[tooltipItem.datasetIndex].label || '';
        const dataValue = (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex] || 0);
        // console.log(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex]);

        if (label === 'Sales') {
            return `${label}:  ₹${dataValue.toLocaleString("en-IN")}`
        } else if (label === 'Spend') {
            return `Spend:  ₹${dataValue.toLocaleString("en-IN")}`;
        } else if (label === 'ACOS') {
            return `${label}:  ${dataValue}%`;
        } if (label === 'Orders') {
            return `${label}:  ${dataValue}`;
        } if (label === 'Clicks') {
            return `${label}:  ${dataValue}`;
        } if (label === 'CPC') {
            return `${label}:  ₹${dataValue.toLocaleString("en-IN")}`;
        } if (label === 'Impressions') {
            return `${label}:  ${dataValue}`;
        } if (label === 'CTR') {
            return `${label}:  ${dataValue}%`;
        } if (label === 'Conversion') {
            return `${label}:  ${dataValue}%`;
        } if (label === 'ROAS') {
            return `${label}:  ${dataValue}`;
        } if (label === 'TOS_IS') {
            return `${label}:  ${dataValue}%`;
        }
    }

    return (
        <div>
            <div>
                <  Chart
                    data={data}
                    height={300}
                    //  onClick={onClick}
                    //onMouseDow={onMouseDow}
                    // onDoubleClick={onDoubleClick}
                    // onMouseDownCapture={onMouseDown}
                    // onMouseUp={onMouseUp}
                    // onMouseMove={onMouseMove}
                    // ref={chartRef}
                    // plugins={[dateHighlighter]}
                    type='line'
                    options=
                    {

                        {
                            elements: {
                                point: {
                                    radius: 0,
                                }
                            },
                            tension: 0.4,
                            borderWidth: 1,
                            maintainAspectRatio: false,
                            interaction: {
                                mode: "index",
                                intersect: false
                            },
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                    display: true,
                                },
                                title: {
                                    display: true,
                                    //  text: 'Chart.js Line Chart',
                                },
                                tooltip: {
                                    usePointStyle: true,
                                    backgroundColor: 'rgba(255, 255, 255,1)',
                                    bodyColor: 'rgba(0, 0, 0 , 0.8)',
                                    titleColor: 'rgba(0, 0, 0 , 0.6)',
                                    titleFont: {
                                        size: 14,
                                        weight: 'bold'
                                    },
                                    titleMarginBottom: 6,
                                    padding: 10,
                                    bodySpacing: 8,
                                    bodyFont: {
                                        weight: "bold",
                                        size: 14
                                    },
                                    borderColor: "rgba(189, 189, 189, 1)",
                                    borderWidth: 1,
                                    boxWidth: 40,
                                    boxPadding: 10,
                                    bodyAlign: 'right',
                                    // intersect: false
                                    // mode: "x"
                                    callbacks: {
                                        label: (tooltipItem) => tooltipCallback(tooltipItem, data)
                                    }
                                },

                            },

                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    },
                                    // ticks: {
                                    //     callback: function (val, index) {
                                    //         // Hide every 2nd tick label
                                    //         if (graphDataType === "daily") {
                                    //             const time_stamp = this.getLabelForValue(val);
                                    //             const day = new Date(`${time_stamp}T00:00:00.000+00:00`).getDay();
                                    //             if (day === 0) return "Sunday";
                                    //             if (day === 6) return "Saturday";
                                    //             return this.getLabelForValue(val)
                                    //         } else {
                                    //             return this.getLabelForValue(val);
                                    //         }
                                    //     },
                                    // }
                                    ticks: {
                                        maxTicksLimit: 8,
                                        // autoSkip:true
                                        maxRotation: 0,
                                        minRotation: 0,

                                    }
                                },
                                y: {
                                    grid: {
                                        display: true,
                                        // borderColor: yAxesColor ? yAxesColor : "#e0e0e0",
                                        borderWidth: 0
                                    },
                                    ticks: {
                                        maxTicksLimit: 3,
                                        beginAtZero: true,
                                        // stepSize: 4,
                                        // suggestedMin: 0,
                                        // suggestedMax: getMaxValue(yAxisObj?.data),
                                        color: yAxesColor,
                                        display: axesId.includes('y') ? true : false,
                                        callback: (value, index, values) => yAxisTickscallback(value, index, values, "y")
                                    },

                                },
                                y1: {
                                    position: 'right',
                                    grid: {
                                        display: false,
                                        // borderColor: y1AxesColor ? y1AxesColor : "#e0e0e0",
                                        borderWidth: 0
                                    },
                                    ticks: {
                                        maxTicksLimit: 3,
                                        beginAtZero: true,
                                        // stepSize: 4,
                                        // suggestedMin: 0,
                                        // max: {arrMaxVAlue},
                                        // max: getMaxValue(y1AxisObj?.data),
                                        // suggestedMax:80,
                                        color: y1AxesColor,
                                        display: axesId.includes('y1') ? true : false,
                                        callback: (value, index, values) => yAxisTickscallback(value, index, values, "y1")
                                    }
                                },
                                y2: {
                                    position: 'right',
                                    grid: {
                                        display: false,
                                        // borderColor: y2AxesColor ? y2AxesColor : "#e0e0e0",
                                        borderWidth: 0
                                    },
                                    ticks: {
                                        maxTicksLimit: 3,
                                        beginAtZero: true,
                                        // stepSize: 4,
                                        // suggestedMin: 0,
                                        // max: {arrMaxVAlue},
                                        // suggestedMax: getMaxValue(y2AxisObj?.data),
                                        color: y2AxesColor,
                                        display: axesId.includes('y2') ? true : false,
                                        callback: (value, index, values) => yAxisTickscallback(value, index, values, "y2")
                                    }
                                },
                                y3: {
                                    position: 'left',
                                    grid: {
                                        display: false,
                                        // borderColor: y3AxesColor ? y3AxesColor : "#e0e0e0",
                                        borderWidth: 0
                                    },
                                    ticks: {
                                        maxTicksLimit: 3,
                                        beginAtZero: true,
                                        // stepSize: 4,
                                        // suggestedMin: 0,
                                        // max: {arrMaxVAlue},
                                        // suggestedMax: getMaxValue(y3AxisObj?.data),
                                        color: y3AxesColor,
                                        display: axesId.includes('y3') ? true : false,
                                        callback: (value, index, values) => yAxisTickscallback(value, index, values, "y3")

                                    }
                                },

                            },

                        }
                    }

                />
            </div>
            <NotificationContainer />
        </div >
    )
}

export default LineGraph;