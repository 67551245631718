import React from 'react';
import './TooltipComponent.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TooltipComponent = ({text,placement,children}) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id="tooltip">{text}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    )
}

export default TooltipComponent;