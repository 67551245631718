import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { AgGridReact } from "ag-grid-react";
import ProductUpload from './subComponents/ProductUpload';
// import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { getProductMaster } from '../apiOnboarding';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL, product_master_headers } from '../../../../appConstants';
import { NotificationManager } from 'react-notifications';
import Loader from '../.././../commonComponent/Loader/Loader';
import { debounce } from '../../../../commonFunction/commomFunction';
import NumericEditor from '../../../agGridCommonComponent/NumericEditor';
import { Alert } from '@mui/material';
import NotifyBoxProduct from './subComponents/NotifyBoxProduct';


function ProductMaster() {
    const gridRef = useRef();
    const popupRef = useRef();
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { _id, user_type } } = appParams;
    const [rowData, setRowData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const [notificationArr, setNotificationArr] = useState([]);
    const [toggle, setToggle] = useState(false);
    const platforms = ["Amazon", "Other Platforms"]

    const [platform, setPlateform] = useState(platforms[0]);

    const token = localStorage.getItem('token');

    const HEADER_DISPLAY = {
        Product_Name: "Product Name",
        Last_Node_Amazon_PDP: 'Last Node Amazon PDP',
        Internal_Category: 'Internal Category',
        Short_Name: "Short Name",
        Sub_Brand: "Sub Brand",
        Main_Category: 'Main Category',
        Variation_Type: 'Variation Type',
        Item_Package_Quantity: 'Item Package Quantity',
        Business_Contribution: 'Business Contribution',
        EAN_code: 'EAN code',
        Item_Dimension: 'Item Dimension',
        Item_Weight_Unit: 'Item Weight Unit',
        Item_Weight: 'Item Weight'
    }

    useEffect(() => {
        const handleClickOutside = ((event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setToggle(false);
            }
        })
        window.addEventListener('mousedown', handleClickOutside);
        return (() => {
            window.removeEventListener('mousedown', handleClickOutside)
        })
    }, [])



    useEffect(() => {
        const url1 = `${BASE_URL}clientProductDetail/master?brandId=${_id}`
        const url2 = `${BASE_URL}productDetail/masterOtherPlatform?brandId=${_id}&platform=${platform}`
        setIsLoading(true);
        axios.get(platform === 'Amazon' ? url1 : url2, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data?.data;
            // console.log(data);
            setRowData(data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, platform,])


    const AsinComp = (props) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                <a href={`https://www.amazon.in/dp/${props.value}`} target="_blank" style={{ color: "rgb(0, 115, 199)", cursor: "pointer", fontWeight: "600" }} >{props.value}</a>
            </div>
        )
    }

    const asinValueSetter = (params) => {
        let newValue = params.newValue;
        // console.log(params);
        if ((newValue?.startsWith('B0')) && newValue?.length === 10) {
            params.data.ad_asin = newValue;
        } else {
            // console.log(newValue);
            NotificationManager.error("Please enter a valid ASIN", 'Error', 5000)
        }
    }

    const advertisedSetter = (params) => {
        const newValue = params.newValue;
        if (newValue === 'yes' || newValue?.toLowerCase() === 'yes' || newValue === 'no' || newValue?.toLowerCase() === 'no') {
            params.data.advertised = newValue;
        } else {
            NotificationManager.error("Advertised should be 'yes' or 'no'", 'Error', 5000)
        }
    }

    // const mrpSetter = (params) => {
    //     const newValue = parseInt(params.newValue);
    //     // console.log(params);
    //     if (newValue === Number) {
    //         params.data.mrp = newValue;
    //     } else {
    //         NotificationManager.error('MRP should be a number', 'Error', 5000)
    //     }
    // }

    // const serialNo = (props) => {
    //     let serialNum = props.rowIndex + 1;
    //     return (<div>{serialNum}</div>)
    // }

    const defaultColDef = useMemo(() => ({
        sortable: true, resizable: true, filter: true,
        // singleClickEdit: true
    }), []);

    const editFn = () => {
        if (user_type && user_type === 'brand_viewer') {
            return false;
        }
        return true;
    }

    // const [columnState, setColumnState] = useState([
    //     {
    //         headerName: 'ASIN', field: 'ad_asin', width: 150, cellRenderer: AsinComp, editable: editFn, cellEditor: 'agTextCellEditor',
    //         valueSetter: asinValueSetter, checked: true
    //     },
    //     {
    //         headerName: HEADER_DISPLAY['Product_Name'], field: 'pname', width: 300, editable: editFn,
    //         cellEditor: 'agTextCellEditor', checked: true
    //     },
    //     { headerName: 'MRP', field: 'mrp', width: 120, filter: 'agNumberColumnFilter', editable: editFn, cellEditor: NumericEditor, checked: true },
    //     { headerName: HEADER_DISPLAY['Main_Category'], field: 'main_cat', width: 120, editable: editFn, checked: true },
    //     { headerName: HEADER_DISPLAY['Last_Node_Amazon_PDP'], field: 'last_node_amazon_pdp', width: 200, editable: editFn, checked: true },
    //     { headerName: HEADER_DISPLAY['Internal_Category'], field: 'internal_category', width: 200, editable: editFn, checked: true },
    //     { headerName: 'Brand', field: 'brand', width: 120, editable: editFn, checked: true },
    //     { headerName: HEADER_DISPLAY['Sub_Brand'], field: 'sub_brand', width: 120, editable: editFn, checked: true },
    //     { headerName: 'Advertised', field: 'advertised', width: 120, editable: editFn, checked: true, valueSetter: advertisedSetter },
    //     { field: "type", headerName: "Type", checked: false, editable: editFn, width: 120 },
    //     { field: "range", headerName: "Range", checked: false, editable: editFn, width: 120 },
    //     { field: "color", headerName: "Color", checked: false, editable: editFn, width: 120 },
    //     { field: "item_package_qty", headerName: "Item Package Quantity", checked: false, editable: editFn, width: 120 },
    //     { field: "item_weight", headerName: "Item Weight", checked: false, editable: editFn, width: 120 },
    //     { field: "item_weight_unit", headerName: "Item Weight Unit", checked: false, editable: editFn, width: 120 },
    //     { field: "SKU_code", headerName: "SKU", checked: false, editable: editFn, width: 120 },
    //     { field: "short_name", headerName: "Short Name", checked: false, editable: editFn, width: 120 },
    //     { field: "variation_type", headerName: "Variation Type", checked: false, editable: editFn, width: 120 },
    //     { field: "status", headerName: "Status", checked: false, editable: editFn, width: 120 },
    //     { field: "item_dimension", headerName: "Item Dimension", checked: false, editable: editFn, width: 120 },
    // ])

    const columnDefs = useMemo(() => {
        if (platform === 'Amazon') {
            return ([
                // { headerName: 'No.', field: '', width: 50, filter: false, cellRenderer: serialNo },
                {
                    headerName: 'ad_asin', field: 'ad_asin', width: 150, cellRenderer: AsinComp, editable: editFn, cellEditor: 'agTextCellEditor',
                    valueSetter: asinValueSetter
                },
                {
                    headerName: "pname", field: 'pname', width: 300, editable: editFn,
                    cellEditor: 'agTextCellEditor'
                },
                { headerName: 'mrp', field: 'mrp', width: 120, filter: 'agNumberColumnFilter', editable: editFn, cellEditor: NumericEditor },
                { headerName: 'last_node_amazon_pdp', field: 'last_node_amazon_pdp', width: 200, editable: editFn, },
                { headerName: 'internal_category', field: 'internal_category', width: 200, editable: editFn, },
                { headerName: 'brand', field: 'brand', width: 120, editable: editFn, },
                { headerName: 'sub_brand', field: 'sub_brand', width: 120, editable: editFn, },
                { headerName: "region", field: 'region', width: 120, editable: editFn, },
                { headerName: 'advertised', field: 'advertised', width: 120, editable: editFn, valueSetter: advertisedSetter },
                { headerName: 'npd', field: 'npd', width: 120, editable: editFn, },
                { headerName: 'planned', field: 'planned', width: 120, editable: editFn, },
                { headerName: 'priority', field: 'priority', width: 120, editable: editFn, },
                { headerName: 'business_contribution', field: 'business_contribution', width: 120, editable: editFn, },
                { headerName: "SKU_code", field: "SKU_code", editable: editFn, width: 120 },
                { headerName: 'short_name', field: "short_name", editable: editFn, width: 120 },
                { headerName: 'main_cat', field: 'main_cat', width: 120, editable: editFn, },
                { headerName: 'variation_type', field: "variation_type", editable: editFn, width: 120 },
                { headerName: "type", field: "type", editable: editFn, width: 120 },
                { headerName: "range", field: "range", editable: editFn, width: 120 },
                { headerName: "color", field: "color", editable: editFn, width: 120 },
                { headerName: 'item_package_qty', field: "item_package_qty", editable: editFn, width: 120 },
                { headerName: 'item_weight', field: "item_weight", editable: editFn, width: 120 },
                { headerName: 'item_weight_unit', field: "item_weight_unit", editable: editFn, width: 120 },
                { headerName: "status", field: "status", editable: editFn, width: 120 },
                { headerName: 'item_dimension', field: "item_dimension", editable: editFn, width: 120 },
                { headerName: "Miscellaneous1", field: "Miscellaneous1", editable: editFn, width: 120 },
                { headerName: "Miscellaneous2", field: "Miscellaneous2", editable: editFn, width: 120 },
                { headerName: 'EAN_code', field: "EAN_code", editable: editFn, width: 120 },
                { headerName: "Miscellaneous3", field: "Miscellaneous3", editable: editFn, width: 120 },
            ])
        } else {
            return ([
                {
                    headerName: 'platform_code', field: 'platform_code', width: 150, editable: editFn, cellEditor: 'agTextCellEditor',
                },
                {
                    headerName: "pname", field: 'pname', width: 300, editable: editFn,
                    cellEditor: 'agTextCellEditor'
                },
                { headerName: 'mrp', field: 'mrp', width: 120, filter: 'agNumberColumnFilter', editable: editFn, cellEditor: NumericEditor },
                { headerName: 'last_node_amazon_pdp', field: 'last_node_amazon_pdp', width: 200, editable: editFn, },
                { headerName: 'internal_category', field: 'internal_category', width: 200, editable: editFn, },
                { headerName: 'brand', field: 'brand', width: 120, editable: editFn, },
                { headerName: 'sub_brand', field: 'sub_brand', width: 120, editable: editFn, },
                { headerName: "region", field: 'region', width: 120, editable: editFn, },
                { headerName: "platform", field: 'platform', width: 120, editable: editFn, },
                { headerName: 'short_name', field: "short_name", editable: editFn, width: 120 },
                { headerName: 'npd', field: 'npd', width: 120, editable: editFn, },
                { headerName: 'planned', field: 'planned', width: 120, editable: editFn, },
                { headerName: 'advertised', field: 'advertised', width: 120, editable: editFn, valueSetter: advertisedSetter },
                { headerName: 'priority', field: 'priority', width: 120, editable: editFn, },
                { headerName: 'business_contribution', field: 'business_contribution', width: 120, editable: editFn, },
                { headerName: "SKU_code", field: "SKU_code", editable: editFn, width: 120 },
                { headerName: "Miscellaneous1", field: "Miscellaneous1", editable: editFn, width: 120 },
                { headerName: "Miscellaneous2", field: "Miscellaneous2", editable: editFn, width: 120 },
                { headerName: "Miscellaneous3", field: "Miscellaneous3", editable: editFn, width: 120 },
                { headerName: 'EAN_code', field: "EAN_code", editable: editFn, width: 120 },
            ])
        }
    }, [platform])

    const handleCancel = () => {
        // if (upload) {
        setIsLoading(true);
        const url1 = `${BASE_URL}clientProductDetail/master?brandId=${_id}`
        const url2 = `${BASE_URL}productDetail/masterOtherPlatform?brandId=${_id}&platform=${platform}`
        axios.get(platform === 'Amazon' ? url1 : url2, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data?.data;
            // console.log(data);
            setRowData(data);
            setIsLoading(false);
            // setUpload(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
            const errMsg = err?.response?.data?.data?.message || 'Somthing went wrong.'
            NotificationManager.error(errMsg, 'error', 3000)
        })
        // } else {
        //     NotificationManager.success('Products already updated', 'success', 3000);
        // }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleSubmit = () => {
        // if (upload) {
        setIsLoading(true);
        const url1 = `${BASE_URL}clientProductDetail/master/${rowData?.product_master?._id}?brandId=${_id}`
        const url2 = `${BASE_URL}productDetail/masterOtherPlatform/${rowData?.product_master?._id}?brandId=${_id}&platform=${platform}`

        axios.put(platform === 'Amazon' ? url1 : url2,
            {
                product_array: rowData?.product_master?.product_array
            },
            {
                headers: {
                    token
                }
            }).then((res) => {
                // console.log(res.data);
                // setUpload(false);
                setIsLoading(false);
                NotificationManager.success('Products submited successfully', 'success', 3000)
            }).catch((err) => {
                // console.log(err);
                setIsLoading(false);
                const errMsg = err?.response?.data?.data?.message || 'You have viewer access. You can not submit'
                NotificationManager.error(errMsg, 'error', 3000)
            })
        // } else {
        //     NotificationManager.success('Products already updated', 'success', 3000)
        // }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const onBtnExport = useCallback(() => {
        // console.log(gridRef.current.api);
        const params = {
            fileName: `ProductMaster-${platform}-${appParams?.current_brand?.client_name}.csv`,
        };
        gridRef.current.api.exportDataAsCsv(params);
    }, [_id, platform])

    // const togglePopup = () => {
    //     setToggle(prevState => !prevState);
    // }

    // const onColumnSelect = (e) => {
    //     const arr = [...columnState];
    //     arr.forEach(el => {
    //         if (el.field === e.target.name) {
    //             el.checked = !el.checked
    //         }
    //     });
    //     setColumnState(arr);

    //     let filterArr = [];
    //     for (let obj of arr) {
    //         if (obj.checked === true) {
    //             filterArr.push(obj);
    //         }
    //     }
    //     setColumnDefs(filterArr);
    // }

    // const [notifyOpen, setNotifyOpen] = useState(false);
    // const handleOpenNotification = () => {
    //     setNotifyOpen(true);
    // }

    const handlePlatform = (e) => {
        setPlateform(e.target.value)
    }

    return (
        <div className='product_master_container'>
            {isLoading && <div style={{ zIndex: 1, position: "absolute", left: "50%", top: "50%" }}><Loader /></div>}
            {/* <div className='error_box'>
                {
                    notificationArr.length > 0 && notificationArr?.map((el, i) => {
                        return (
                            <Alert severity="error" key={i}>{el}</Alert>
                        )
                    })
                }
            </div> */}
            <div style={{ display: 'flex' }}>
                <div className='instruction_header'>
                    <span style={{ color: '#b8b6b6', fontSize: "15px", paddingLeft: '18px' }}>Instructions:</span>
                    <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
                        <li>Please choose the .xlsx, .xls and .csv file. Please upload excel with exact headers and their order.</li>
                        <li>Please double click on any cell to edit in-line.</li>
                        <li>Please confirm with all the details, then submit the excel file.</li>
                    </ul>
                </div>
                <div className='product_master_right_header'>
                    {/* <div className='notification_div' onClick={handleOpenNotification}>
                        <i>Notification</i>
                    </div>
                    {notifyOpen && <NotifyBoxProduct notifyOpen={notifyOpen} setNotifyOpen={setNotifyOpen} />} */}
                    {/* <div>
                        <select value={platform} onChange={handlePlatform} id="" className='form-select form-select-sm'>
                            {
                                platforms.map((el) => {
                                    return (
                                        <option value={el}>{el}</option>
                                    )
                                })
                            }
                        </select>
                    </div> */}
                    <div className='product_master_header'>
                        <ProductUpload
                            rowData={rowData}
                            setRowData={setRowData}
                            setUpload={setUpload}
                            columnDefs={columnDefs}
                            platform={platform}
                        // setNotificationArr={setNotificationArr}
                        // notificationArr={notificationArr}
                        />
                        <button style={{ minHeight: "30px" }} type='button' className='submit_btn' onClick={onBtnExport}><i className="bi bi-download"></i></button>
                        {/* <div className="select_column" ref={popupRef}>
                            <div onClick={togglePopup}>Columns <i style={{ fontSize: '12px' }} className="bi bi-chevron-down"></i></div>
                            <div style={{ display: toggle ? "block" : "none" }} className="column_options">
                                {
                                    columnState?.map(el => {
                                        return (
                                            <div key={el.id} className="filterOptionElement" >
                                                <input onChange={onColumnSelect} type="checkbox" id={el.field} name={el.field} checked={el.checked}></input>
                                                <label className="filterOptionElementLabel" htmlFor={el.field}>{el.headerName}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}

                    </div>

                </div>

            </div>



            <div className="ag-theme-alpine" style={{ height: 500, margin: "10px 0 10px 0" }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData?.product_master?.product_array}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowHeight={40}
                    headerHeight={50}
                    suppressLoadingOverlay={true}
                    suppressNoRowsOverlay={true}
                    suppressMovableColumns={true}
                    suppressDragLeaveHidesColumns={true}
                    suppressMenuHide={true}
                // editType='fullRow'
                />
            </div>
            <div className='product_master_footer'>
                <button type='button' className={`${user_type && user_type === 'brand_viewer' ? 'cancel_btn disabled-button' : 'cancel_btn'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={debounce(handleCancel, 200)}>Cancel</button>
                <button type='button' className={`${user_type && user_type === 'brand_viewer' ? 'submit_btn disabled-button' : 'submit_btn'}`} disabled={user_type && user_type === 'brand_viewer'} onClick={debounce(handleSubmit, 200)}>Submit</button>
            </div>
        </div>
    )
}

export default ProductMaster;