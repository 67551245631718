import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import './PausedBid.css';
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../../appConstants';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { DateComponent, AddRsComponent, HourlyTimeComponent, SRNOComponent, ACOSColor } from '../ARComponents/cellRenderer';
import { formatSingleDateFnWithYear } from '../../../../commonFunction/commomFunction';
import { HEADER } from '../../../../appUiConstants';
import Loader from '../../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../../errorComponent/ReportsErrorPage';
import ModalComponent from '../../../commonComponent/Modal/ModalComponent';
import BrandGenricFilter from '../../../agGridCommonComponent/BrandGenricFilter';
import { NotificationManager } from 'react-notifications';
import { saveAppParamsData } from '../../../../redux/appParams/appParamsActions';

function PausedBid() {
    const user = useSelector(state => state.user);
    // console.log(user);
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;
    const gridRef = useRef();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalClose = () => setIsModalOpen(false);
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(true);


    const token = localStorage.getItem('token');

    function fetchData() {
        setLoading(true);
        axios.get(`${BASE_URL}bidChanges/getHourlyPausedBids?brandId=${current_brand._id}`, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data.data.data;
            // console.log(data);
            if (current_brand.automation_status === 'no') {
                setRowData(data);
                throw Error('This brand is not automated yet!')
            }
            if (res.status === 200 && data.length === 0) {
                setRowData(data);
                throw Error('No data available for this brand!')
            }
            setError(false);
            setRowData(data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setError(error.message);
        })
    }

    useEffect(() => {
        fetchData();
    }, [current_brand._id])

    //To get the height for grid
    const [containerHeight, setContainerHeight] = useState();
    useEffect(() => {
        const height = window.innerHeight;
        const netHeight = height - HEADER.singleTableHeight;
        setContainerHeight(netHeight);
    }, []);
    window.addEventListener("resize", () => {
        const height = window.innerHeight;
        const netHeight = height - HEADER.singleTableHeight;
        setContainerHeight(netHeight);
    });
    //

    const ParentAsinComponent = (props) => {
        return (
            <a href={`https://www.amazon.in/dp/${props.value}`} style={{ color: 'rgb(0, 115, 199)' }} target="_blank">{props.value}</a>
        )
    }

    const ChangedSysStateComponent = (props) => {
        return (
            <div>
                {props.value ? props.value : 'NA'}
            </div>
        )
    }
    const TargetingComponent = (props) => {
        // const { parent_asin, targeting } = props.data;
        if (props.value?.startsWith('B0')) {
            return (
                <a href={`https://www.amazon.in/dp/${props.value}`} style={{ color: 'rgb(0, 115, 199)' }} target="_blank">{props.value}</a>
            )
        }
        return (
            <div>{props.value}</div>
        )
    }


    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, }), []);
    const columnDefs = useMemo(() => [
        { headerName: 'Sr No.', field: '', width: 60, cellRenderer: SRNOComponent, filter: false, cellClass: 'color-column' },
        // { headerName: 'Date', field: 'time_stamp', width: 90, cellClass: 'color-column', cellRenderer: DateComponent, },
        { headerName: 'Product Name', field: 'product_name', width: 200, cellClass: 'color-column' },
        { headerName: 'Campaign Name', field: 'campaign_name', width: 150, cellClass: 'color-column' },
        { headerName: 'Targeting', field: 'targeting', width: 150, cellRenderer: TargetingComponent },
        { headerName: 'Targeting Type', field: 'targeting_type', width: 120 },
        { headerName: 'Keyword Type', field: 'keyword_type', width: 120, filter: BrandGenricFilter },
        { headerName: 'Category', field: 'category', width: 120 },
        { headerName: 'Campaign Type', field: 'campaign_type', width: 60 },
        { headerName: 'Spend(₹)', field: 'spend', width: 120, filter: 'agNumberColumnFilter' },
        { headerName: 'Sales(₹)', field: 'sales', width: 120, filter: 'agNumberColumnFilter' },
        { headerName: 'CPC(₹)', field: 'cpc', width: 120, filter: 'agNumberColumnFilter' },
        { headerName: 'ACOS(%)', field: 'acos', width: 100, filter: 'agNumberColumnFilter', cellRenderer: ACOSColor },
        { headerName: 'Last Bid(₹)', field: 'last_bid', width: 120, filter: 'agNumberColumnFilter', },
        { headerName: 'Last State', field: 'last_state', width: 120, },
        { headerName: 'System State', field: 'sys_state', width: 120, },
        {
            headerName: 'Revised State', field: 'changed_sys_state', width: 120,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['NA', 'PAUSED', 'ENABLED'],
            },
            cellRenderer: ChangedSysStateComponent
        },
        { headerName: 'Comment', field: 'comment', width: 120 },
        { headerName: 'Current Rank', field: 'current_rank', width: 120 },
        { headerName: 'Desired Rank', field: 'desired_rank', width: 120 },
        { headerName: 'Time Window', field: 'time_window', width: 120 },
        { headerName: 'Match Type', field: 'match_type', width: 120 },
        { headerName: 'Frequency', field: 'frequency', width: 120 },
        { headerName: 'Hygiene Score', field: 'hygiene_score', width: 120 },
        { headerName: 'Bucket', field: 'bucket', width: 120 },
        { headerName: 'Parent Asin', field: 'parent_asin', width: 150, cellRenderer: ParentAsinComponent },
        { headerName: 'Impressions', field: 'impressions', width: 120 },
        { headerName: 'Clicks', field: 'clicks', width: 120 },
        { headerName: 'CTR(%)', field: 'ctr', width: 120, },
        { headerName: 'Target ACOS(%)', field: 'target_acos', width: 150, },
        { headerName: 'Time', field: 'hour', width: 120, cellClass: 'color-column', cellRenderer: HourlyTimeComponent },

    ], [])

    const date = rowData[0]?.data_push_time.split('.')[0].split('T')[0];
    const formatedDate = formatSingleDateFnWithYear(date);
    const time = rowData[0]?.data_push_time.split('.')[0].split('T')[1];

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const onCellEditingStopped = (event) => {
        console.log(event.data);
        const { _id, changed_sys_state } = event.data;
        axios.put(`${BASE_URL}bidChanges/getHourlyPausedBids/${_id}?brandId=${current_brand._id}`, {
            changed_sys_state: changed_sys_state
        }, {
            headers: {
                token
            }
        }).then((res) => {
            // console.log(res.data.data.data);
        }).catch((err) => {
            fetchData();
            NotificationManager.error(err.response.data.data.message, 'error', 3000);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleLock = async () => {
        setLoading(true);
        try {
            await axios.get(`${BASE_URL}bidChanges/lockHourlyPausedChanges?brandId=${current_brand._id}`, {
                headers: {
                    token
                }
            })
            NotificationManager.success('Your revised state updated successfully', 'success', 3000)
            if (user.user_domain === 'enlytical_admin') {
                const getAgency = await axios.get(`${BASE_URL}agency`, {
                    headers: {
                        token
                    }
                })
                const { agency_array } = getAgency.data.data;
                const { _id } = agency_array[0];
                const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
                    headers: {
                        token
                    }
                })
                const { brand_array } = getBrand.data.data;
                let current_brand = brand_array[0];
                if (brand_array?.length) {
                    let currentBrandInLocal = localStorage.getItem("currentBrand");
                    if (currentBrandInLocal) {
                        for (let b of brand_array) {
                            if (b._id === currentBrandInLocal) {
                                current_brand = b;
                                break;
                            }
                        }
                    }
                }
                dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));

            } else if (user.user_domain === "user") {
                const getAgency = await axios.get(`${BASE_URL}agency`, {
                    headers: {
                        token
                    }
                })
                const { agency_array } = getAgency.data.data;
                if (agency_array.length === 0) {
                    const getBrand = await axios.get(`${BASE_URL}brand`, {
                        headers: {
                            token
                        }
                    })
                    const { brand_array } = getBrand.data.data;
                    let current_brand = brand_array[0];
                    if (brand_array?.length) {
                        let currentBrandInLocal = localStorage.getItem("currentBrand");
                        if (currentBrandInLocal) {
                            for (let b of brand_array) {
                                if (b._id === currentBrandInLocal) {
                                    current_brand = b;
                                    break;
                                }
                            }
                        }
                    }
                    dispatch(saveAppParamsData({ agency_array, current_agency: [], brand_array, current_brand: current_brand }));
                } else if (agency_array.length > 0) {
                    const { _id } = agency_array[0];
                    const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
                        headers: {
                            token
                        }
                    })
                    const { brand_array } = getBrand.data.data;
                    let current_brand = brand_array[0];
                    if (brand_array?.length) {
                        let currentBrandInLocal = localStorage.getItem("currentBrand");
                        if (currentBrandInLocal) {
                            for (let b of brand_array) {
                                if (b._id === currentBrandInLocal) {
                                    current_brand = b;
                                    break;
                                }
                            }
                        }
                    }
                    dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
                }
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            NotificationManager.error('Something went wrong', 'error', 3000);
            setLoading(false);
        }
        setIsModalOpen(false);
    }

    const handleNotification = () => {
        setNotification(false);
    }

    useEffect(() => {
        setNotification(true);
    }, [current_brand._id])

    return (
        <div className='hourly_bids_container'>
            <div className='hourly_bids_header'>
                <h1>Paused Targets</h1>
                {
                    notification &&
                    (current_brand?.hourly_paused_bid_changes_edit_status ? <div className="notification_bar_green col-6">
                        <span>Paused targets is open for now. You can update the Revised State.</span>
                        <button className='btn_green' onClick={handleNotification}><i className="bi bi-x-lg"></i></button>
                    </div> :
                        <div className="notification_bar_red col-6">
                            <span>Paused targets is locked for now. You can update it when it get open.</span>
                            <button className='btn_red' onClick={handleNotification}><i className="bi bi-x-lg"></i></button>
                        </div>
                    )
                }
            </div>
            <div>
                <span style={{ color: '#b8b6b6', fontSize: "15px" }}>Instructions:</span>
                <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
                    <li>You can select the <strong>Revised State</strong> value by double click and select an option and press enter to save it.</li>
                    <li>Please confirm with all <strong>Revised State</strong> and Press <strong>Submit</strong> button to push your revised state.</li>
                </ul>
            </div>
            <div className='hourly_bids_table_container'>
                <div className='hourly_bids_filter'>
                    <span className='gridDownloadBtn' onClick={onBtnExport}><i style={{ WebkitTextStroke: "0.4px" }} className="bi bi-download"></i></span>
                </div>
                {
                    error ? <div style={{ height: containerHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                        <div className="ag-theme-alpine" style={{ height: containerHeight, width: '100%' }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                rowHeight={AG_AR.rH}
                                headerHeight={AG_AR.hH}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                suppressNoRowsOverlay={true}
                                suppressDragLeaveHidesColumns={true}
                                onCellEditingStopped={onCellEditingStopped}
                                suppressMenuHide={true}
                            ></AgGridReact>
                        </div>
                }
            </div>
            <div className='hourly_button_container'>
                <div className='hourly_date_container_left'>
                    <div className='hourly_date_container'>
                        <span className='date_container'>Data_push_time:  <span style={{ color: '#000' }}>{formatedDate} | {time}</span></span>
                    </div>
                    <div className='hourly_date_container'>
                        <span className='count_container'>Tatal Rows Count: {rowData.length ? rowData.length : 0}</span>
                    </div>
                </div>
                <button className='hourly_approve_btn'
                    onClick={() => setIsModalOpen(true)}
                    disabled={current_brand.hourly_paused_bid_changes_edit_status ? false : true}
                    >
                    {current_brand.hourly_paused_bid_changes_edit_status ? <span><i className="bi bi-unlock-fill"></i> SUBMIT</span> : <span><i className="bi bi-lock-fill"></i> SUBMIT</span>}
                </button>
            </div>
            {
                isModalOpen &&
                <ModalComponent open={isModalOpen} modalClose={modalClose}>
                    <div className="hourly_modal_text">Once you confirm, you can not edit <strong>Revised State</strong> further.<br /> Do you really want to confirm ?</div>
                    <div className="hourly_modal_button_container">
                        <button className="modal_button_cancel" onClick={modalClose}>Cancel</button>
                        <button className="modal_button_ok" onClick={handleLock}>Confirm</button>
                    </div>
                </ModalComponent>
            }
            {
                loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
            }
        </div>
    )
}

export default PausedBid;