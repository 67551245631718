import React, { useState } from 'react'
import './MainContainer.scss';
import KeywordCatContainer from './KeywordCatContainer';
import SearchVolume from './SearchVolume';
import KeywordIndexing from './KeywordIndexing';
import KeywordTypeReport from './KeywordTypeReport';
import VisibilityMain from './VisibilityMain';

const MainContainer = () => {
    const [tabs, setTabs] = useState('keyword');

    const handleTabs = (tab) => {
        setTabs(tab);
    }

    return (
        <div className='main_box'>
            <div className='tabs_box'>
                <button className={tabs === 'keyword' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}
                    onClick={() => handleTabs('keyword')}>Keyword Categorization</button>
                <button className={tabs === 'keywordTypeReport' ? 'tabs_box-button_mid tabs_box-active' : 'tabs_box-button_mid'}
                    onClick={() => handleTabs('keywordTypeReport')}>Keyword Type Report</button>
                <button className={tabs === 'searchVolume' ? 'tabs_box-button_mid tabs_box-active' : 'tabs_box-button_mid'}
                    onClick={() => handleTabs('searchVolume')}>Search Volume Comparision</button>
                <button className={tabs === 'indexing' ? 'tabs_box-button_mid tabs_box-active' : 'tabs_box-button_mid'}
                    onClick={() => handleTabs('indexing')}>Keyword-ASIN-Indexes</button>
                <button className={tabs === 'visibility' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}
                    onClick={() => handleTabs('visibility')}>Visibility-Checker</button>
            </div>
            <div>
                {tabs === 'keyword' && <KeywordCatContainer />}
                {tabs === 'keywordTypeReport' && <KeywordTypeReport />}
                {tabs === 'searchVolume' && <SearchVolume />}
                {tabs === 'indexing' && <KeywordIndexing />}
                {tabs === 'visibility' && <VisibilityMain />}
            </div>
        </div>
    )
}

export default MainContainer;