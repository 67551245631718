import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import "./DateRangePicker.css"
import { useEffect } from 'react';
import { addDays, isSameDay } from 'date-fns';
import { getDynamicDateRange } from '../../../commonFunction/commomFunction';
const DateRangePickerComponent = (props) => {
    const styles = {
        dateRangeWrapper: {
            width: 250,
            height: 280,
            display: 'inline-block',
        },
    };

    const [dateRange, setDateRange] = useState([]);
    const [yesterday, setYesterday] = useState(null);
    useEffect(() => {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1)
        setYesterday(yesterday)
        setDateRange(props.dateRange)
    }, [props.dateRange])
    // console.log(dateRange);

    const onDateRangeChange = (item) => {
        // console.log(item.selection);
        setDateRange([item.selection]);
    }

    const apply = () => {
        props.onDateRangeApply(dateRange)
    }

    return (
        <div className='dateRangePickerContainer'>
            {
                dateRange.length > 0 && yesterday && <>
                    <DateRangePicker
                        maxDate={yesterday}
                        ranges={dateRange}
                        onChange={(e) => onDateRangeChange(e)}
                        showSelectionPreview={true}
                        // moveRangeOnFirstSelection={true}
                        showDateDisplay={false}
                        style={styles.dateRangeWrapper}
                        inputRanges={[]}
                        months={2}
                        direction="horizontal" rangeColors={['#03A0E7', '#347491']}
                        theme={{
                            Calendar: {
                                width: 200,
                                height: 100,
                            },
                        }}
                        staticRanges={[
                            //...defaultStaticRanges,
                            // {
                            //     label: "Last year",
                            //     range: () => ({ startDate: startOfYear(addYears(new Date(), -1)), endDate: endOfYear(addYears(new Date(), -1)) }),
                            //     isSelected(range) {
                            //         const definedRange = this.range();
                            //         return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                            //     }
                            // },
                            // {
                            //     label: "Yesterday",
                            //     range: () => ({ startDate: defineDate.endOfToday, endDate: defineDate.endOfToday }),
                            //     isSelected(range) {
                            //         const definedRange = this.range();
                            //         return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                            //     }
                            // },
                            // {
                            //     label: "This Week",
                            //     range: () => ({ startDate: defineDate.startOfThisSevenDay, endDate: defineDate.endOfToday }),
                            //     isSelected(range) {
                            //         const definedRange = this.range();
                            //         return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                            //     }
                            // },
                            // {
                            //     label: "Last Week",
                            //     range: () => ({ startDate: defineDate.startOfLastSevenDay, endDate: defineDate.endOfLastSevenDay }),
                            //     isSelected(range) {
                            //         const definedRange = this.range();
                            //         return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                            //     }
                            // },
                            {
                                label: "T-1",
                                range: () => ({ startDate: addDays(new Date(), - 1), endDate: addDays(new Date(), - 1) }),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                            {
                                label: "T-2",
                                range: () => ({ startDate: addDays(new Date(), - 2), endDate: addDays(new Date(), - 2) }),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                            {
                                label: "This Week",
                                range: () => getDynamicDateRange(addDays(new Date(), - 1), "thisweek"),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                            {
                                label: "Last 7 Days",
                                range: () => getDynamicDateRange(addDays(new Date(), - 1), "lastsevendays"),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                            {
                                label: "Last Week",
                                range: () => getDynamicDateRange(addDays(new Date(), - 1), "lastweek"),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                            {
                                label: "This Month",
                                range: () => getDynamicDateRange(addDays(new Date(), - 1), "thismonth"),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                            {
                                label: "Last Month",
                                range: () => getDynamicDateRange(addDays(new Date(), - 1), "lastmonth"),
                                isSelected(range) {
                                    const definedRange = this.range();
                                    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate));
                                }
                            },
                        ]}
                    >
                    </DateRangePicker>
                </>
            }

            <div className='dateRangePickerButtonContainer' >
                <div onClick={props.dateRangePickerClose} className='dateRangePickerCancelButton'  >
                    Cancel
                </div>
                <div onClick={apply} className='dateRangePickerApplyButton' >
                    Apply
                </div>
            </div>
        </div >
    )
}
export default DateRangePickerComponent;