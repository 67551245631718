import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import axios from 'axios';
import { ToggleButton, ToggleButtonGroup, } from '@mui/material';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../appConstants';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import Loader from '../../commonComponent/Loader/Loader';
import { getLast31Days, getLast7Days, getTminus3date } from '../../../utils/commonFunction';
import { DateComponentWithYear } from '../advertisingReport/ARComponents/cellRenderer';
import Pagination from '../../commonComponent/pagination/Pagination';

const KeywordTypeReport = () => {
    const gridRef = useRef(null);
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { client_profile_id, main_category, _id } } = appParams;
    const cat = main_category.split('|');
    const [keywordCategories, setKeywordCategories] = useState([])
    const [category, setCategory] = useState();

    const [rowData, setRowData] = useState([]);
    const [dateType, setDateType] = useState('Daily');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [state, setState] = useState({
        brand: [],
        generic: [],
        competition: []
    })
    const [keywordType, setKeywordType] = useState('generic');

    const [dateTypeArr, setDateTypeArr] = useState([
        { name: 'Daily', status: true },
        { name: 'Weekly', status: false },
        { name: 'Monthly', status: false },
    ])

    const [currentPage, setCurrentPage] = useState(1);
    const [docCount, setDocCount] = useState();
    const [rowPerPage, setRowPerPage] = useState(50);

    useEffect(() => {
        setKeywordCategories(cat);
        setCategory(cat[0])
    }, [client_profile_id])

    const dateFn = (dateType) => {
        if (dateType === 'Daily') {
            return getTminus3date()
        } else if (dateType === 'Weekly') {
            return getLast7Days()
        } else if (dateType === 'Monthly') {
            return getLast31Days()
        }
    }

    useEffect(() => {
        setIsLoading(true);
        if (!category) {
            setRowData([]);
            setIsLoading(false);
            return;
        }
        axios.post(`${BASE_URL}keywordTypeReport`, {
            main_category: category,
            view: dateType,
            time_stamp: dateFn(dateType),
            keyword_type: keywordType,
            client_profile_id: client_profile_id
        }).then((res) => {
            const data = res.data.data.keywordTypeReportData;
            console.log(res.data);
            if (res.status === 200 && data.length === 0) {
                setRowData(data);
                throw Error(`Today, No Keyword type data for ${category} in ${keywordType}.`)
            }
            const generic = data?.filter((el) => el?.keyword_type === 'generic')
            const brand = data?.filter((el) => el?.keyword_type === 'brand')
            const competition = data?.filter((el) => el?.keyword_type === 'competition');
            setState((prevState) => ({
                ...prevState,
                brand: brand,
                generic: generic,
                competition: competition
            }))
            setError(false);
            setRowData(data);
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }, [category, dateType, keywordType])

    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1 }), []);
    const columnDefs = useMemo(() => ([
        { headerName: 'Date', field: 'time_stamp', cellRenderer: DateComponentWithYear },
        { headerName: 'Main Category', field: 'main_category' },
        { headerName: 'Search Term', field: 'search_term' },
        { headerName: 'Keyword Type', field: 'keyword_type' },
        { headerName: 'Brand', field: 'brand' },
        { headerName: 'Total Search Volume', field: 'total_search_volume' },

    ]), [dateType])

    const handleDWMChange = (e) => {
        const { name, value } = e.target;
        // console.log(name,value);
        setDateType(value);
        const newArr = dateTypeArr?.map((el) => {
            if (name === el.name) {
                return {
                    name: el.name,
                    status: !el.status
                }
            } else {
                return {
                    name: el.name,
                    status: false
                }
            }
        })

        setDateTypeArr(newArr);
    }

    const handleTabs = (keyword) => {
        setKeywordType(keyword);
    }
    const onBtnExport = useCallback(() => {
        // console.log(gridRef.current.api);
        const params = {
            fileName: `KeywordTypeReport-${category}-${keywordType}-${appParams?.current_brand?.client_name}.csv`,
        };
        gridRef.current.api.exportDataAsCsv(params);
    }, [_id, category, keywordType])

    return (
        <div>
            <div className='keyword_cat_container-content' >
                <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: '14px', marginRight: '10px', paddingTop: '3px' }}>Category: </div>
                    <select value={category} onChange={(e) => setCategory(e.target.value)} name="" id="" className='form-select form-select-sm keyword_cat_container-select'>
                        {keywordCategories?.map((category, index) => {
                            return (
                                <option key={index} value={category}>{category}</option>
                            )
                        })}
                    </select>
                </div>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        onChange={handleDWMChange}
                        aria-label="Platform"
                        sx={{ maxHeight: 28, boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px" }}
                    >
                        {
                            dateTypeArr?.map((el) => {
                                return (
                                    <ToggleButton key={el.name} name={el.name} value={el.name}>{el.name[0]}</ToggleButton>
                                )
                            })
                        }
                    </ToggleButtonGroup>
                </div>
            </div>
            <div style={{ background: '#ffffff', borderRadius: '5px', padding: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'flex-end' }}>
                    <button
                        style={{ border: 'none', borderRadius: '20px', color: '#ffffff', backgroundColor: 'rgb(59, 124, 149)', padding: '0px 20px' }}
                        type='button' onClick={onBtnExport}><i className="bi bi-download"></i>
                    </button>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button className={keywordType === 'generic' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}
                            onClick={() => handleTabs('generic')}>Generic</button>
                        <button className={keywordType === 'brand' ? 'tabs_box-button_mid tabs_box-active' : 'tabs_box-button_mid'}
                            onClick={() => handleTabs('brand')}>Brand</button>
                        <button className={keywordType === 'competition' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}
                            onClick={() => handleTabs('competition')}>Competition</button>
                    </div>
                </div>
                <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
                    {error ? <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            rowHeight={AG_AR.rH}
                            headerHeight={40}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            suppressNoRowsOverlay={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressMenuHide={true}
                        >
                        </AgGridReact>
                    }
                    {/* <Pagination
                        docCount={docCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        rowPerPage={rowPerPage}
                        setRowPerPage={setRowPerPage}
                    /> */}
                    {
                        isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                    }
                </div>
                {/* <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Generic</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                                <AgGridReact
                                    rowData={state.generic}
                                    rowHeight={AG_AR.rH}
                                    headerHeight={40}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    suppressNoRowsOverlay={true}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressMenuHide={true}
                                >
                                </AgGridReact>
                            }

                            {
                                isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>Brand</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                                <AgGridReact
                                    rowData={state.brand}
                                    rowHeight={AG_AR.rH}
                                    headerHeight={40}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    suppressNoRowsOverlay={true}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressMenuHide={true}
                                >
                                </AgGridReact>
                            }

                            {
                                isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <Typography>Competition</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                            {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                                <AgGridReact
                                    rowData={state.competition}
                                    rowHeight={AG_AR.rH}
                                    headerHeight={40}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    suppressNoRowsOverlay={true}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressMenuHide={true}
                                >
                                </AgGridReact>
                            }
                            {
                                isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion> */}
            </div>
        </div >
    )
}

export default KeywordTypeReport;