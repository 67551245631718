import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import './DetailedBidTable.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AG_AR } from '../../../../../appConstants';
import Loader from '../../../../commonComponent/Loader/Loader';
import { AddRsComponent, AddPercentageComponent, TimeComponent, DateComponent, BidComponent, BidDiffComponent } from '../../ARComponents/cellRenderer';
import ReportsErrorPage from '../../../../errorComponent/ReportsErrorPage';
import Pagination from '../../../../commonComponent/pagination/Pagination';

const DetailedBidTable = (props) => {
    const gridRef = useRef();
    const popupRef = useRef();
    const {
        resetColumn,
        activeBtn,
        setActiveBtn,
        setFilterObj,
        rowData,
        loading,
        popup,
        setPopup,
        error,
        currentPage,
        setCurrentPage,
        rowPerPage,
        setRowPerPage, docCount
    } = props;

    const [columns, setColumns] = useState([
        { headerName: 'Date', field: 'time_stamp', width: 100, cellRenderer: DateComponent, cellClass: 'color-column', checked: true, pinned: true, },
        { headerName: 'Time', field: '', width: 80, cellRenderer: TimeComponent, cellClass: 'color-column', checked: true, pinned: true, },
        { headerName: 'Targeting', field: 'targeting', width: 150, cellClass: 'color-column', checked: true, pinned: true, },
        { headerName: 'Match Type', field: 'match_type', width: 100, checked: true },
        { headerName: 'Previous Value', field: 'previous_value', width: 100, cellRenderer: BidComponent, checked: true },
        { headerName: 'New Value', field: 'new_value', width: 100, cellRenderer: BidComponent, checked: true },
        { headerName: 'Bid Difference', field: 'bid_difference', width: 100, filter: 'agNumberColumnFilter', cellRenderer: BidDiffComponent, checked: true },
        { headerName: 'Type', field: 'campaign_type', width: 50, checked: false },
        { headerName: 'L7D CPC', field: 'cpc_last_7_d', width: 100, cellRenderer: AddRsComponent, checked: false },
        { headerName: 'L7D ACOS', field: 'acos_last_7_d', width: 100, cellRenderer: AddPercentageComponent, checked: false },
        { headerName: 'L7D CTR', field: 'ctr_last_7_d', width: 100, cellRenderer: AddPercentageComponent, checked: false },
        { headerName: 'L7D CVR', field: 'cvr_last_7_d', width: 100, cellRenderer: AddPercentageComponent, checked: false },
        { headerName: 'L7D Sales', field: 'sales_last_7_d', width: 100, cellRenderer: AddRsComponent, checked: false },
        { headerName: 'L7D Spend', field: 'cost_last_7_d', width: 100, cellRenderer: AddRsComponent, checked: false },
        { headerName: 'L7D TOS_IS', field: 'tos_is_7_d', width: 100, cellRenderer: AddPercentageComponent, checked: false },
        { headerName: 'SB Visibility', field: 'sb_visibility', width: 100, checked: false },
        { headerName: 'SP Visibility', field: 'sp_visibility', width: 100, checked: false },
        { headerName: 'SV Visibility', field: 'sv_visibility', width: 100, checked: false },
        { headerName: 'Campaign Name', field: 'campaign_name', width: 200, checked: false },
        { headerName: 'Adgroup Name', field: 'ad_group_name', width: 200, checked: false }
    ]);
    const [columnDefs, setColumnDefs] = useState([]);

    // // pagination logic
    // const [page, setPage] = useState("");
    // const rowPerPageArr = [100, 500, 1000, 2500];

    const bidChangeType = (e) => {
        setFilterObj(prevState => ({
            ...prevState,
            change_type: e,
            entity_type: e
        }));
        setActiveBtn(e);
        setCurrentPage(1);
        // setPage('');
    }

    useEffect(() => {
        let selectedColumn = ["Date", "Time", "Targeting", "Match Type", "Previous Value", "New Value", "Bid Difference"]
        let arr = []
        let clickedColumnName = []
        let defCol = []
        for (let el of columns) {
            if (selectedColumn.includes(el.headerName)) {
                clickedColumnName.push(el.headerName);
                el.checked = true;
                arr.push(el)
                defCol.push(el);
            } else {
                el.checked = false;
                defCol.push(el);
            }
        }
        setColumns(defCol);
        props.setClickedColumn(clickedColumnName);
        setColumnDefs(arr);
    }, [resetColumn]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopup(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])


    const handleColumns = (e) => {
        // console.log(e.target.name);
        let clickedColumnName = [];
        let arr = []
        columns.forEach(el => {
            if (el.field === e.target.name) {
                el.checked = e.target.checked;
            }
            if (el.checked) {
                arr.push(el);
            }
        });
        for (let el of arr) {
            clickedColumnName.push(el.headerName)
        }
        setColumns(columns)
        props.setClickedColumn(clickedColumnName);
        setColumnDefs(arr);
    };


    // const modifiedColumnDefs = [...columnDefs];
    // const columnIndex1 = modifiedColumnDefs.findIndex((column) => column.field === 'bid_difference');
    // const columnIndex2 = modifiedColumnDefs.findIndex((column) => column.field === 'previous_value');
    // if (columnIndex1 !== -1 && filterObj.change_type === 'STATUS') {
    //     modifiedColumnDefs.splice(columnIndex1, 1)
    // }
    // if (columnIndex1 !== -1 && columnIndex2 !== -1 && filterObj.change_type === 'CREATED') {
    //     modifiedColumnDefs.splice(columnIndex1, 1)
    //     modifiedColumnDefs.splice(columnIndex2, 1)
    // }

    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1, minWidth: 100 }), []);

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const togglePopup = () => {
        setPopup(prevState => !prevState);
    };



    return (
        <div style={{ position: 'relative' }}>
            <div className="button-group">
                <div ref={popupRef}>
                    <div onClick={togglePopup} className="columnFilterBtn">Select Columns <i style={{ fontSize: '12px' }} className="bi bi-chevron-down"></i></div>
                    <div className='filterOptionContainer' style={{ display: popup ? "block" : "none" }}>
                        {
                            columns.map((el) => {
                                return (
                                    <div key={el.field} className="filterOptionElement" >
                                        <input onChange={handleColumns} type="checkbox" id={el.headerName} name={el.field} checked={el.checked}></input>
                                        <label className="filterOptionElementLabel">{el.headerName}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <button className={`bid-btn-${activeBtn === 'BID_AMOUNT' ? 'active' : ""}`} onClick={() => bidChangeType("BID_AMOUNT")}>Bid Changes</button>
                <button className={`bid-btn-${activeBtn === 'STATUS' ? 'active' : ""}`} onClick={() => bidChangeType("STATUS")}>Paused Targets</button>
                <button className={`bid-btn-${activeBtn === 'CREATED' ? 'active' : ""}`} onClick={() => bidChangeType("CREATED")}>Added Targets</button>
                <button className={`bid-btn-${activeBtn === 'NEGATIVE_KEYWORD' ? 'active' : ""}`} onClick={() => bidChangeType("NEGATIVE_KEYWORD")}>Negative Keyword</button>

            </div>
            <div className='grid-download-container'>
                <span className='gridDownloadBtn' onClick={onBtnExport}><i style={{ WebkitTextStroke: "0.4px" }} className="bi bi-download"></i></span>
            </div>
            {
                error ? <div style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: '4px' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            rowHeight={AG_AR.rH}
                            headerHeight={AG_AR.hH}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            suppressNoRowsOverlay={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressMenuHide={true}
                        // pagination={true}
                        // paginationPageSize={15}
                        // paginationAutoPageSize={true}
                        ></AgGridReact>
                    </div>
            }
            <Pagination
                docCount={docCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowPerPage={rowPerPage}
                setRowPerPage={setRowPerPage}
            />
            {
                loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} > < Loader /></div>
            }
        </div>
    )
}

export default DetailedBidTable;