import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import './DateWiseBidTable.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AG_AR } from '../../../../../appConstants';
import axios from 'axios';
import { BASE_URL } from '../../../../../appConstants';
import { useSelector } from 'react-redux';
import { getAppStandardDate } from '../../../../../utils/commonFunction';
import { DefaultComponent } from '../../ARComponents/cellRenderer';
import Loader from '../../../../commonComponent/Loader/Loader';
import { formatSingleDateFn } from '../../../../../commonFunction/commomFunction';
import ReportsErrorPage from '../../../../errorComponent/ReportsErrorPage';

const DateWiseBidTable = (props) => {
    const { group_by, dateRange, startingColumn, selectRow, setFs, selectFsName, filterObj, dependancyArr } = props;
    // console.log(group_by);
    const getRowStyle = params => {
        if (params.node.rowIndex === 0) {
            return { background: '#E8EDF5','font-weight':'600' };
        }
    };
    const group_by_value = group_by[0];
    const gridRef = useRef();
    const token = localStorage.getItem('token');
    const appParams = useSelector(state => state.appParams);
    const { current_brand,current_agency } = appParams;
    const [rowData, setRowData] = useState([]);
    // console.log(rowData[0][group_by] === "Total");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true,filter:true, flex: 1, minWidth: 110, }), []);
    const [columnDefs, setColumnDefs] = useState();

    const start_date = getAppStandardDate(dateRange.startDate);
    const end_date = getAppStandardDate(dateRange.endDate);

    useEffect(() => {
        setLoading(true);
        axios.post(`${BASE_URL}bidChanges/getDateWiseBidChanges?brandId=${current_brand._id}`, {
            start_date,
            end_date,
            group_by,
            ...filterObj
        }, {
            headers: {
                token
            }
        }).then((response) => {
            const { bid_changes } = response.data.data
            if (response.status === 200 && bid_changes.length === 0) {
                throw Error(`no data available for ${group_by_value} in this brand`);
            }
            setError(false);
            const data = [...bid_changes];
            let sd = new Date(start_date);
            let ed = new Date(end_date);
            let otherColumns = [];
            while (sd <= ed) {
                let strDate = sd.toISOString().split("T")[0]
                let obj = {}
                obj["headerName"] = formatSingleDateFn(strDate);
                obj["field"] = strDate;
                obj["cellRenderer"] = DefaultComponent;
                otherColumns.push(obj);
                sd.setDate(sd.getDate() + 1);
            }
            setColumnDefs([...startingColumn, ...otherColumns])
            let arr = [];
            let totalObj = { [group_by[0]]: "Total" }
            // console.log(totalObj);
            for (let el of data) {
                const { data_array } = el;
                let obj = {
                    [group_by[0]]: el[group_by[0]],
                };
                for (let ele of data_array) {
                    let key = ele.time_stamp.split("T")[0]
                    obj[key] = ele.total_bid_changes;
                    //Total Obj logic
                    if (key in totalObj) {
                        totalObj[key] = totalObj[key] + ele.total_bid_changes
                    } else {
                        totalObj[key] = ele.total_bid_changes;
                    }
                }
                // console.log(obj);
                arr.push(obj);
            }
            arr.unshift(totalObj);
            setRowData(arr);
            gridAutoSelect();
            scrollToRight();
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
            setError(error.message);
        });
    }, [current_brand._id, start_date, end_date, ...dependancyArr]);

    const scrollToRight = () => {
        setTimeout(() => {
            if (gridRef.current) {
                const api = gridRef.current?.api;
                const columnApi = gridRef.current.columnApi;

                const lastColumn = columnApi.getColumns().slice(-1)[0];
                if (lastColumn) {
                    api.ensureColumnVisible(lastColumn);
                }
            }
        }, 500);
    };

    useEffect(() => {
        gridAutoSelect();
    }, [selectRow]);

    function gridAutoSelect() {
        setTimeout(() => {
            gridRef.current?.api.forEachNode(function (node) {
                node.setSelected(selectRow?.includes(node.data[group_by_value]));
            });
        }, 500);
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);


    const onRowClicked = (e) => {
        if (e.data[group_by_value] === "Total") {
            gridRef.current.api.forEachNode(function (node) {
                if (node.data[group_by_value] === "Total") {
                    node.setSelected(false);
                }
            });
            return;
        }
        const arr = [...selectRow];
        if (!arr.includes(e.data[group_by_value])) {
            arr.push(e.data[group_by_value])
            setFs(prevState => ({ ...prevState, [selectFsName]: arr }));
        } else {
            const index = arr.indexOf(e.data[group_by_value])
            arr.splice(index, 1);
            setFs(prevState => ({ ...prevState, [selectFsName]: arr }));
        }
    }

    return (
        <div style={{ position: "relative" }}>
            {
                error ? <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <>
                        <div className='grid-download-container'>
                            <span className='gridDownloadBtn' onClick={onBtnExport}><i style={{ WebkitTextStroke: "0.4px" }} className="bi bi-download"></i></span>
                        </div>
                        <div className="ag-theme-alpine" style={{ height: 200, width: '100%', marginTop: '4px' }}>
                            <AgGridReact
                                ref={gridRef}
                                onRowClicked={onRowClicked}
                                rowData={rowData}
                                rowHeight={AG_AR.rH}
                                headerHeight={AG_AR.hH}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection={'multiple'}
                                rowMultiSelectWithClick={true}
                                suppressNoRowsOverlay={true}
                                suppressDragLeaveHidesColumns={true}
                                getRowStyle={getRowStyle}
                                suppressMenuHide={true}
                            ></AgGridReact>
                        </div>
                    </>
            }
            {
                loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} > < Loader /></div>
            }
        </div>
    )
}

export default DateWiseBidTable;