import React, { useState } from 'react'
import SPCampaigns from './SPCampaigns';
import AddTarget from './AddTarget';
import NegativeTargets from './NegativeTargets';

const CampaignContainer = () => {

    const [tabs, setTabs] = useState('spCampaigns');
    const handleTabs = (tab) => {
        setTabs(tab);
    }

    const options = [
        { name: 'Sys Approved', value: 'sys_approved' },
        { name: 'Rejected', value: 'rejected' },
        { name: 'Pending', value: 'pending' },
        { name: 'Approved', value: 'approved' }
    ];


    const approvalColor = (value) => {
        if (value === 'pending') {
            return 'rgba(245, 191, 66, 0.311)'
        } else if (value === 'rejected') {
            return 'rgba(244, 67, 54, 0.311)'
        } else if (value === 'approved') {
            return 'rgba(76, 175, 80, 0.311)'
        } else {
            return 'rgb(255,255,255)'
        }
    }

    const asinComponent = (props) => {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
                <a href={`https://www.amazon.in/dp/${props.value}`} target="_blank" style={{ color: "var(--active-color)", cursor: "pointer", fontWeight: "600" }} >{props.value}</a>
            </div>
        )
    }


    return (
        <div className='main_box'>
            <div className='tabs_box'>
                <button className={tabs === 'spCampaigns' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'}
                    onClick={() => handleTabs('spCampaigns')}>SP Campaigns</button>
                <button className={tabs === 'addTarget' ? 'tabs_box-button_mid tabs_box-active' : 'tabs_box-button_mid'}
                    onClick={() => handleTabs('addTarget')}>Add Targets</button>
                <button className={tabs === 'negativeTarget' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'}
                    onClick={() => handleTabs('negativeTarget')}>Negative Targets</button>
            </div>
            <div>
                {tabs === 'spCampaigns' && <SPCampaigns
                    approvalColor={approvalColor}
                    asinComponent={asinComponent}
                    options={options}
                />}
                {tabs === 'addTarget' && <AddTarget
                    approvalColor={approvalColor}
                    asinComponent={asinComponent}
                    options={options}
                />}
                {tabs === 'negativeTarget' && <NegativeTargets
                    approvalColor={approvalColor}
                    asinComponent={asinComponent}
                    options={options}
                />}
            </div>

        </div>
    )
}

export default CampaignContainer