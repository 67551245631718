import React, { useState, useMemo, useEffect } from 'react'
import './CampaignContainer.scss';
import { AgGridReact } from 'ag-grid-react';
import Loader from '../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import { AG_AR, BASE_URL } from '../../../appConstants';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

const NegativeTargets = ({ approvalColor, asinComponent, options }) => {
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { client_profile_id } } = appParams;
    const [rowData, setRowData] = useState([]);

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const token = localStorage.getItem('token');
    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, }), []);

    useEffect(() => {
        setIsLoading(true)
        axios.get(`${BASE_URL}onboarding/add-negative/${client_profile_id}`, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data.negatives;
            // console.log(data);
            setRowData(data);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false)
        })
    }, [client_profile_id])


    const StatusComp = (props) => {
        const { data: { _id, approval }, onChange } = props;

        const handleChange = (e) => {
            onChange(e.target.value, _id);
        };
        return (
            <div>
                <select value={approval} onChange={handleChange} style={{ fontSize: '12px', width: "100px", backgroundColor: approvalColor(approval) }}
                    className="form-select form-select-sm"
                >
                    {
                        options?.map((option, i) => {
                            return (
                                <option style={{ backgroundColor: '#fff' }} key={i} disabled={i === 0} value={option.value}>{option.name}</option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }



    const columnDefs = useMemo(() => ([
        {
            headerName: 'Approval', field: 'approval', filter: true, width: 150,
            cellRendererFramework: StatusComp,
            cellRendererParams: {
                onChange: (newValue, rowId) => {
                    setIsLoading(true);
                    axios.patch(`${BASE_URL}onboarding/add-negative/${rowId}`,
                        {
                            approval: newValue
                        },
                        {
                            headers: {
                                token
                            }
                        },
                    ).then((res) => {
                        const data = res.data.negative;
                        // console.log(data);
                        if (rowId === data._id) {
                            data.approval = newValue
                        }
                        const updatedData = rowData.map((row) => row._id === data._id ? data : row);
                        setRowData(updatedData)
                    }).catch(error => {
                        console.log(error);
                        NotificationManager.error('Unable to Update Row', 'Error', 3000)
                    }).finally(() => {
                        setIsLoading(false)
                    })
                }
            },
        },
        { headerName: 'Campaign Name', field: 'campaignName', width: 300 },
        { headerName: 'Date', field: 'date', width: 100, },
        { headerName: 'Negative Target', field: 'negativeTarget', filter: true, width: 150, },
        { headerName: 'Ad Group Id', field: 'adGroupId', filter: true, width: 120 },
        { headerName: 'Campaign Id', field: 'campaignId', filter: true, width: 120 },
        { headerName: 'Match Type', field: 'matchtype', width: 150 },
        { headerName: 'Ad Group Name', field: 'adGroupName', width: 150 },
        { headerName: 'Campaign Type', field: 'campaignType', width: 100 },
        { headerName: 'Target Type', field: 'targeting_type', width: 100 },
    ]), [rowData])

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const res = await axios.post(`${BASE_URL}onboarding/add-negative/${client_profile_id}`, {
                headers: {
                    token
                }
            })
            setIsLoading(false);
            if (res.status === 201) {
                NotificationManager.success('Data Successfully Submited', 'Success', 3000)
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            NotificationManager.error('Unable to Submit Data', 'Error', 3000)
        }

    }

    return (
        <div>
            <div className="ag-theme-alpine" style={{ height: 580, width: '100%' }}>
                {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <AgGridReact
                        rowData={rowData}
                        rowHeight={AG_AR.rH}
                        headerHeight={AG_AR.hH}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressNoRowsOverlay={true}
                        suppressDragLeaveHidesColumns={true}
                        suppressMenuHide={true}
                    >
                    </AgGridReact>
                }
                {
                    isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                }
            </div>
            <div className='button-contain'>
                <button className='keyword-button' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}

export default NegativeTargets;