
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../appConstants';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import Loader from '../../commonComponent/Loader/Loader';
import './MainContainer.scss';
import { useSelector } from 'react-redux';
import ModalComponent from '../../commonComponent/Modal/ModalComponent';
import VisibilityUpload from './visibilityComponents/VisibilityUpload';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';


const VisibilityCampaigns = () => {
    const appParams = useSelector(state => state.appParams);
    const [rowData, setRowData] = useState([]);
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const { current_brand: { client_profile_id } } = appParams;
    // const [openModifier, setOpenModifier] = useState(false);
    const [open, setOpen] = useState(false);

    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, }), []);
    const columnDefs = useMemo(() => ([
        // {
        //     headerName: 'Check', field: '', width: 100,
        //     cellRendererFramework: StatusComp,
        //     cellRendererParams: {
        //         onChange: (newValue, rowId) => {
        //             if (newValue && !visibilityRecords.includes(rowId)) {
        //                 visibilityRecords.push(rowId)
        //             } else {
        //                 visibilityRecords.pop();
        //             }
        //         }
        //     },
        // },
        { headerName: 'Marked', field: 'marked', width: 150 },
        { headerName: 'Date', field: 'Date', width: 150 },
        { headerName: 'Targeting', field: 'Targeting', width: 200 },
        { headerName: 'Asins', field: 'ad_asins', width: 200 },
        
        { headerName: 'Match Type', field: 'Match_Type', width: 150 },
        { headerName: 'Targeting Type', field: 'targeting_type', width: 150 },
        { headerName: 'Keyword Type', field: 'keyword_type', width: 150 },
        // { headerName: 'Campaign ID', field: 'campaignId', width: 150, },
        { headerName: 'Campaign Name', field: 'campaignName', width: 200, },
        { headerName: 'Campaign Type', field: 'campaignType', width: 100 },
        { headerName: 'Category', field: 'category', width: 120 },
        // { headerName: 'Ad Group ID', field: 'adGroupId', width: 120 },
        { headerName: 'Ad Group Name', field: 'adGroupName', width: 120 },
        { headerName: 'Impressions', field: 'impressions', width: 120 },
        { headerName: 'Clicks', field: 'clicks', width: 120 },
        { headerName: 'Spend', field: 'cost', width: 120, },
        { headerName: 'Sales', field: 'attributedSales14d', width: 120, },
        { headerName: 'Orders', field: 'attributedUnitsOrdered14d', width: 120, },
        { headerName: 'Conversion', field: 'Conversion', width: 120, },
        { headerName: 'AM-bid', field: 'AM-bid', width: 120, },
        { headerName: 'PM-bid', field: 'PM-bid', width: 120, },
        // { headerName: 'AM-state', field: 'AM-state', width: 120, },
        // { headerName: 'PM-state', field: 'PM-state', width: 120, },
        // { headerName: 'Campaign State', field: 'campState', width: 120, },
        // { headerName: 'AdGroup State', field: 'adGroup_state', width: 120, },
        { headerName: 'TOS_IS', field: 'tos_is', width: 120, },
        { headerName: 'Created At', field: 'creationDateTime', width: 120, },
        { headerName: 'Status', field: 'status', width: 150 },
        { headerName: 'Visibility', field: 'visibility', width: 150 },
        // { headerName: 'Targeting ID', field: 'Targeting_ID', width: 150 },
        { headerName: 'SFR', field: 'sfr', width: 120 },
        { headerName: 'Search Volume', field: 'search_volume', width: 120 },
    ]), []);



    const fetchData = () => {
        setIsLoading(true)
        axios.get(`${BASE_URL}ads-visibility-check/${client_profile_id}`, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data.data.visibilityCheckRecords;
            // console.log(data);
            setRowData(data);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [client_profile_id])

    // const handleBid = () => {

    // }

    // const handleModifier = async () => {
    //     // checked row first to increase modifier
    //     setIsLoading(true);
    //     // if (visibilityRecords.length === 0) {
    //     //     setOpenModifier(false);
    //     //     return NotificationManager.error('Select any row to send', 'Error', 3000)
    //     // }
    //     // api call and status pending than and again updated within 3 hours 
    //     try {

    //     } catch (error) {

    //     }
    // }
    return (
        <div>
            <div className="ag-theme-alpine" style={{ height: 525, width: '100%' }}>
                {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <AgGridReact
                        rowData={rowData}
                        rowHeight={AG_AR.rH}
                        headerHeight={AG_AR.hH}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressNoRowsOverlay={true}
                        suppressDragLeaveHidesColumns={true}
                        suppressMenuHide={true}
                        editType={"fullRow"}
                        rowSelection='multiple'
                    >
                    </AgGridReact>
                }
                {
                    isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                }
            </div>
            {/* <div>
                {
                    open &&
                    <div className="ag-theme-alpine" style={{ height: 525, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            rowHeight={AG_AR.rH}
                            headerHeight={AG_AR.hH}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            suppressNoRowsOverlay={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressMenuHide={true}
                            editType={"fullRow"}
                            rowSelection='multiple'
                        >
                        </AgGridReact>
                    </div>
                }
            </div>
            <div className='button-check'>
                <button className='keyword-button' onClick={() => setOpen(true)}>Selected Campaigns</button>
            </div> */}
            {/* <ModalComponent
                open={openModifier}
                modalClose={() => setOpenModifier(false)}
            >
                <div>
                    <span><i class="bi bi-info-circle-fill"></i> If you want to increse the bid modifier,<br /> please enter value</span>
                    <input className='form-control form-control-sm' type="number" />
                    <button className='keyword-button' onClick={handleModifier}>Save</button>
                </div>
            </ModalComponent>
            <ModalComponent
                open={openBid}
                modalClose={() => setOpenBid(false)}
            >
                <div>
                    <span><i class="bi bi-info-circle-fill"></i> If you want to increse the bid,<br /> please enter value</span>
                    <input className='form-control form-control-sm' type="number" />
                    <button className='keyword-button' onClick={handleBid}>Save</button>
                </div>
            </ModalComponent> */}
        </div>
    )
}

export default VisibilityCampaigns;