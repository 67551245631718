import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ServiceCommandUnit2 from "./ServiceCommandUnit2";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "10px",
    color: "#003769",
    fontSize: "16px",
    fontWeight: "500",
    marginTop: "10px",

    // change background colour if dragging
    background: isDragging ? "#f0f0f0" : "#ececec",
    padding: "10px",
    marginBottom: '5px',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#ececec" : "#ffffff",
    padding: 4,
    // margin: "10px 0",

});

export default class ServiceCommandUnit extends React.Component {
    render() {
        return (
            <Droppable droppableId={this.props.type} type={`droppableSubItem`}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {this.props.parent_asin_array.map((item, index) => (
                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (
                                    <div >
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            {...provided.dragHandleProps}
                                        >
                                            {item.name}
                                            {/* <span
                                                {...provided.dragHandleProps}
                                                style={{
                                                    float: "right"
                                                }}
                                            >
                                                <i class="bi bi-arrows-move"></i>
                                            </span> */}
                                            <ServiceCommandUnit2
                                                parent_asin_array={item.child_asin_array}
                                                type={item._id}
                                            />
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }
}