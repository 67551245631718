import React, { useState, useMemo, useCallback } from 'react'
import './MTDAchievement.scss';
import { AgGridReact } from 'ag-grid-react';
import Loader from '../../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../../errorComponent/ReportsErrorPage';

function MTDAchievement(props) {
    const { achievements, setAchievements, isLoading, achivView, dth_achievement } = props;
    const defaultColDef = useMemo(() => ({ sortable: true, flex: 1, resizable: true, filter: true }), [])

    const getRowStyle = params => {
        if (params.node.data.category === 'Total') {
            return { background: '#caccd1', fontWeight: '600' };
        }
    };


    const totalObjForDth = (rowData) => {
        let obj = {
            category: 'Total',
            asins: [],
        };
        let acos = 0;
        let sales = 0;
        let spend = 0;
        let target_acos = 0;
        let target_sales = 0;
        let target_spend = 0;
        for (let el of rowData) {
            target_sales += el.target_sales;
            target_spend += el.target_spend;
            target_acos = Math.trunc((target_spend / target_sales) * 100)
            sales += el.sales;
            spend += el.spend;
            acos = sales ? Math.trunc((spend / sales) * 100) : 0
        }
        obj["target_sales"] = target_sales;
        obj["target_spend"] = target_spend;
        obj["target_acos"] = target_acos;
        obj["sales"] = sales;
        obj["spend"] = spend;
        obj["acos"] = acos;
        obj["time_period"] = rowData[0]?.time_period
        return obj;
    }
    const hourlyAchievements = [totalObjForDth(dth_achievement), ...dth_achievement.sort((a, b) => a.category.localeCompare(b.category))]

    const totalObj = (rowData) => {
        let obj = {
            category: 'Total',
            asins: [],
        };
        let mtd_acos = 0;
        let mtd_sales = 0;
        let mtd_spend = 0;
        let target_acos = 0;
        let target_sales = 0;
        let target_spend = 0;
        for (let el of rowData) {
            target_sales += el.target_sales;
            target_spend += el.target_spend;
            target_acos = Math.trunc((target_spend / target_sales) * 100)
            mtd_sales += el.mtd_sales;
            mtd_spend += el.mtd_spend;
            mtd_acos = mtd_sales ? Math.trunc((mtd_spend / mtd_sales) * 100) : 0
        }
        obj["target_sales"] = target_sales;
        obj["target_spend"] = target_spend;
        obj["target_acos"] = target_acos;
        obj["mtd_sales"] = mtd_sales;
        obj["mtd_spend"] = mtd_spend;
        obj["mtd_acos"] = mtd_acos;
        return obj;
    }
    // console.log(totalObj(achievements));
    const total = totalObj(achievements);
    const newAchievements = [total, ...achievements]

    function salesColor(value) {
        if (parseInt(value) > 95) {
            return '#05802ea7'
        } else if (parseInt(value) < 95 && parseInt(value) >= 90) {
            return '#50a76da7'
        } else if (parseInt(value) >= 85 && parseInt(value) < 90) {
            return '#d4cc3b'
        } else if (parseInt(value) < 85) {
            return '#bf3d1996'
        }
    }
    function spendColor(value) {
        if (parseInt(value) > 95) {
            return '#05802ea7'
        } else if (parseInt(value) < 95 && parseInt(value) >= 90) {
            return '#50a76da7'
        } else if (parseInt(value) >= 85 && parseInt(value) < 90) {
            return '#d4cc3b'
        } else if (parseInt(value) < 85) {
            return '#bf3d1996'
        }
    }
    function acosColor(value) {
        if (value <= 5) {
            return '#05802ea7'
        } else if (value > 5 && value <= 10) {
            return '#50a76da7'
        } else if (value > 10 && value < 15) {
            return '#d4cc3b'
        } else if (value > 15) {
            return '#bf3d1996'
        }
    }

    function getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate()
    }

    function getMTDPercent(achivement, target) {
        let today = new Date()
        const todayDate = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear()
        const daysInMonth = getDaysInMonth(month, year)
        const mtdPercent = Math.round(((achivement / target) / (todayDate / daysInMonth)) * 100)
        if (isNaN(mtdPercent)) {
            return null;
        }
        if (mtdPercent === Infinity) {
            return null;
        }
        return `${mtdPercent}%`;
    }

    const MTDSpend = (props) => {
        const { target_spend, mtd_spend } = props.data;
        return (
            <div style={{ backgroundColor: `${spendColor(getMTDPercent(mtd_spend, target_spend))}`, padding: '0px 20px', minWidth: '80%' }}>{getMTDPercent(mtd_spend, target_spend)}</div>
        )
    }

    const MTDSales = (props) => {
        const { target_sales, mtd_sales } = props.data;
        return (
            <div style={{ backgroundColor: `${salesColor(getMTDPercent(mtd_sales, target_sales))}`, padding: '0px 20px', minWidth: '80%' }}>{getMTDPercent(mtd_sales, target_sales)}</div>
        )
    }
    const MTDAcos = (props) => {
        const { target_acos, mtd_acos } = props.data;
        const acosPercent = target_acos && Math.round(((target_acos - mtd_acos) / target_acos) * 100);
        return (
            <div style={{ backgroundColor: `${acosColor(acosPercent)}`, padding: '0px 20px', minWidth: '80%' }}>{!acosPercent ? null : `${acosPercent}%`}</div>
        )
    }

    const genComp = ({ value }) => {
        return (
            <div>{Math.trunc(value)}</div>
        )
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Category',
            children: [
                { headerName: '', field: 'category', cellStyle: { 'borderRightColor': '#bdbdbd' } }
            ]
        },
        {
            headerName: 'ASINs',
            children: [
                { headerName: '', field: 'asins', cellStyle: { 'borderRightColor': '#bdbdbd' } }
            ]
        },
        {
            headerName: 'Targets',
            children: [
                { headerName: 'Spend', field: 'target_spend' },
                { headerName: 'Sales', field: 'target_sales' },
                { headerName: 'ACOS', field: 'target_acos', cellStyle: { 'borderRightColor': '#bdbdbd' } },
            ]
        },
        {
            headerName: 'Achievements',
            children: [
                { headerName: 'Spend', field: 'mtd_spend' },
                { headerName: 'Sales', field: 'mtd_sales' },
                { headerName: 'ACOS', field: 'mtd_acos', cellStyle: { 'borderRightColor': '#bdbdbd' } },
            ]
        },
        {
            headerName: 'MTD Achievements %',
            children: [
                { headerName: 'Spend', field: '', cellRenderer: MTDSpend },
                { headerName: 'Sales', field: '', cellRenderer: MTDSales },
                { headerName: 'ACOS', field: '', cellRenderer: MTDAcos, cellStyle: { 'borderRightColor': '#bdbdbd' } },
            ]
        },
    ])
    const [columnDefs1, setColumnDefs1] = useState([
        {
            headerName: 'Category',
            children: [
                { headerName: '', field: 'category', cellStyle: { 'borderRightColor': '#bdbdbd' } }
            ]
        },
        {
            headerName: 'ASINs',
            children: [
                { headerName: '', field: 'asins', cellStyle: { 'borderRightColor': '#bdbdbd' } }
            ]
        },
        {
            headerName: 'Bucket',
            children: [
                { headerName: '', field: 'bucket', cellStyle: { 'borderRightColor': '#bdbdbd' } }
            ]
        },
        {
            headerName: 'Time',
            children: [
                { headerName: '', field: 'time_period', cellStyle: { 'borderRightColor': '#bdbdbd' } }
            ]
        },
        {
            headerName: 'Daily Targets',
            children: [
                { headerName: 'Spend', field: 'target_spend', cellRenderer: genComp },
                { headerName: 'Sales', field: 'target_sales', cellRenderer: genComp },
                { headerName: 'ACOS', field: 'target_acos', cellRenderer: genComp, cellStyle: { 'borderRightColor': '#bdbdbd' } },
            ]
        },
        {
            headerName: 'Dth Achievements',
            children: [
                { headerName: 'Spend', field: 'spend', cellRenderer: genComp },
                { headerName: 'Sales', field: 'sales', cellRenderer: genComp },
                { headerName: 'ACOS', field: 'acos', cellRenderer: genComp, cellStyle: { 'borderRightColor': '#bdbdbd' } },
            ]
        },
    ])

    return (
        <div className='mtd_container'>
            {isLoading && <div style={{ zIndex: 1, position: "absolute", left: "50%", top: "50%" }}><Loader /></div>}
            {achivView === 'MTD' &&
                <div className="ag-theme-alpine" style={{ height: 500, margin: "10px 0 10px 0" }}>
                    {newAchievements.length === 1 ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={'This brand has not been automated yet!'} /></div> :
                        <AgGridReact
                            rowData={newAchievements}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            rowHeight={40}
                            headerHeight={50}
                            suppressLoadingOverlay={true}
                            suppressNoRowsOverlay={true}
                            suppressMovableColumns={true}
                            suppressDragLeaveHidesColumns={true}
                            getRowStyle={getRowStyle}
                            suppressMenuHide={true}

                        />
                    }
                </div>}
            {achivView === 'Hourly' &&
                <div className="ag-theme-alpine" style={{ height: 500, margin: "10px 0 10px 0" }}>
                    {hourlyAchievements.length === 1 ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={'This brand has not been automated yet!'} /></div> :
                        <AgGridReact
                            rowData={hourlyAchievements}
                            columnDefs={columnDefs1}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            rowHeight={40}
                            headerHeight={50}
                            suppressLoadingOverlay={true}
                            suppressNoRowsOverlay={true}
                            suppressMovableColumns={true}
                            suppressDragLeaveHidesColumns={true}
                            getRowStyle={getRowStyle}
                            suppressMenuHide={true}

                        />
                    }
                </div>}
            {achivView === 'MTD' &&
                <div className='mtd_formula'>
                    <div className='mtd_formula_div'>
                        <span>
                            MTD Achievement % = ((Actual/Target) / (Today/Days of month)) * 100
                        </span>
                        <span>
                            MTD ACOS % = ((Target ACOS - Actual ACOS) / Target ACOS) * 100
                        </span>
                    </div>
                    <div className='mtd_color_div'>
                        <div className='mtd_sales'>
                            <div>Sales</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % > 95%"}</div>
                                <div style={{ backgroundColor: '#05802ea7', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % = 90% - 95%"}</div>
                                <div style={{ backgroundColor: '#50a76da7', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % = 85% - 90%"}</div>
                                <div style={{ backgroundColor: '#d4cc3b', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % < 85%"}</div>
                                <div style={{ backgroundColor: '#bf3d1996', width: '60px' }}></div>
                            </div>
                        </div>
                        <div className='mtd_sales'>
                            <div>Spend</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % > 95%"}</div>
                                <div style={{ backgroundColor: '#05802ea7', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % = 90% - 95%"}</div>
                                <div style={{ backgroundColor: '#50a76da7', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % = 85% - 90%"}</div>
                                <div style={{ backgroundColor: '#d4cc3b', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"MTD Ach % < 85%"}</div>
                                <div style={{ backgroundColor: '#bf3d1996', width: '60px' }}></div>
                            </div>
                        </div>
                        <div className='mtd_sales'>
                            <div>ACOS</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"ACOS % < 5%"}</div>
                                <div style={{ backgroundColor: '#05802ea7', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"ACOS % = 5% - 10%"}</div>
                                <div style={{ backgroundColor: '#50a76da7', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"ACOS % = 10% - 15%"}</div>
                                <div style={{ backgroundColor: '#d4cc3b', width: '60px' }}></div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{"ACOS % > 15%"}</div>
                                <div style={{ backgroundColor: '#bf3d1996', width: '60px' }}></div>
                            </div>
                        </div>

                    </div>
                </div>}

        </div>
    )
}

export default MTDAchievement;