import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './Keywords.css';
import '../../keywordCategorization/MainContainer.scss'
import MonthlyBudgetProjection from './MonthlyBudgetProjection';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from '../../../../appConstants';
import { NotificationManager } from 'react-notifications';
import Loader from '../../../commonComponent/Loader/Loader';
import { getMontheInText } from '../../../../utils/commonFunction';
import './Keywords.css';
import "./MonthlyBudgets.scss";
import MonthlyBudgetDistributionWeight from './MonthlyBudgetDistributionWeight';
import MonthlyBudgetDistributionSpend2 from './MonthlyBudgetDistributionSpend';
import MTDAchievement from './MTDAchievement';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ViewEnum = Object.freeze({
    // SPEND: "Spend",
    WEIGHT: "Weight"
})

function MonthlyBudget() {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const appParams = useSelector(state => state.appParams);
    const { current_brand: { _id } } = appParams;
    const [rowData, setRowData] = useState();
    const [projections, setProjections] = useState();
    const [achievements, setAchievements] = useState();
    const [dth_achievement, set_dth_achievement] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [achivView, setAchivView] = useState('MTD');
    // const [upload, setUpload] = useState(false);
    const [type, setType] = useState("CATEGORY");
    const token = localStorage.getItem('token');

    function getThreeMonthRange() {
        let current = new Date();
        let prevMonth = new Date(current);
        let nextMonth = new Date(current);
        // console.log(nextMonth, nextMonth.getUTCMonth());
        prevMonth.setDate(1);
        nextMonth.setDate(1);

        prevMonth.setMonth(prevMonth.getMonth() - 1);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        // console.log(nextMonth.getUTCMonth());
        return [prevMonth, current, nextMonth];
    }

    const [range, setRange] = useState(getThreeMonthRange());
    const [selectedDate, setSelectedDate] = useState(range[1]);

    const [view, setView] = useState(ViewEnum.WEIGHT)

    const handleViewChange = ({ target: { name, value } }) => {
        setView(value)
    }

    const fetchdata = () => {
        axios.post(`${BASE_URL}clientBudgetDetail/?brandId=${_id}&budgetLevel=${type}`, {
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear()
        }, {
            headers: {
                token
            }
        }).then((res) => {
            // console.log(res.data.data.mtd_achievement);
            const data = res.data?.data?.clientBudgets;
            console.log(data);
            setRowData(data);
            setProjections(res.data?.data?.projections)
            setAchievements(res.data?.data?.mtd_achievement)
            set_dth_achievement(res.data?.data?.dth_achievement)
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
            const errMsg = err?.response?.data?.data?.message || 'Somthing went wrong.'
            NotificationManager.error(errMsg, 'error', 3000)
        })
    }

    useEffect(() => {
        setIsLoading(true);
        if (selectedDate) {
            fetchdata()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, selectedDate, type])

    const onMonthChange = useCallback(({ target: { value } }) => {
        // setSelectedDate(new Date(value));
        let newDate;
        if (new Date(value).toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            newDate = range[0];
        } else if (new Date(value).toLocaleDateString() === new Date(range[2]).toLocaleDateString()) {
            newDate = range[2];
        } else if (new Date(value).toLocaleDateString() === new Date(range[1]).toLocaleDateString()) {
            newDate = range[1];
        }
        setSelectedDate(newDate)
    }, [selectedDate])

    const handleCancel = useCallback(() => {
        // if (upload) {
        setIsLoading(true);
        axios.post(`${BASE_URL}clientBudgetDetail/?brandId=${_id}&budgetLevel=${type}`,
            {
                month: selectedDate.getMonth() + 1,
                year: selectedDate.getFullYear()
            }, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data?.data;
            // console.log(data);
            // setRowData(data);
            setIsLoading(false);
            // setUpload(false);
        }).catch(err => {
            // console.log(err);
            setIsLoading(false);
            const errMsg = err?.response?.data?.data?.message || 'Somthing went wrong.'
            NotificationManager.error(errMsg, 'error', 3000)
        })
        // } else {
        //     NotificationManager.success('Products already updated', 'success', 3000);
        // }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData])


    const handleSubmit = () => {
        if (selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            NotificationManager.error("You can not upload previous month budget", "error", 3000)
            return;
        }

        // console.log(rowData);

        rowData.forEach(row => {
            if (!row.sales || !row.spend) {
                row.sales = 0;
                row.spend = 0;
                row.acos = 0;
            }
        });

        // if (upload) {
        setIsLoading(true);
        axios.patch(`${BASE_URL}clientBudgetDetail/?brandId=${_id}&month=${selectedDate.getMonth() + 1}&year=${selectedDate.getFullYear()}`,
            {
                budgetLevel: type,
                budgets: rowData
            },
            {
                headers: {
                    token
                }
            }).then((res) => {
                // console.log(res.data.data.updatedClientBudget.budgets);
                const data = res.data.data.updatedClientBudget.budgets;
                // setRowData(data);
                // setIsLoading(false);
                fetchdata();
                NotificationManager.success('Budgets submited successfully', 'success', 3000)
            }).catch((err) => {
                // console.log(err);
                setIsLoading(false);
                const errMsg = err?.response?.data?.data?.message || 'You have viewer access. You can not submit'
                NotificationManager.error(errMsg, 'error', 3000)
            })
        // } else {
        //     NotificationManager.success('Budgets already updated', 'success', 3000)
        // }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }


    const handleSubmitProjections = (projections) => {
        if (selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString()) {
            NotificationManager.error("You can not upload previous month budget", "error", 3000)
            return;
        }
        setIsLoading(true);
        axios.patch(`${BASE_URL}clientBudgetDetail/?brandId=${_id}&month=${selectedDate.getMonth() + 1}&year=${selectedDate.getFullYear()}`,
            {
                projections
            },
            {
                headers: {
                    token
                }
            }).then((res) => {
                setIsLoading(true);
                axios.post(`${BASE_URL}clientBudgetDetail/?brandId=${_id}&budgetLevel=${type}`, {
                    month: selectedDate.getMonth() + 1,
                    year: selectedDate.getFullYear()
                }, {
                    headers: {
                        token
                    }
                }).then((res) => {
                    const data = res.data?.data?.clientBudgets;
                    setRowData(data);
                    setProjections(res.data?.data?.projections)
                    setIsLoading(false);
                }).catch(err => {
                    // console.log(err);
                    setIsLoading(false);
                })
                NotificationManager.success('Products submited successfully', 'success', 3000)
            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const handleAchivView = (e) => {
        setAchivView(e);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab sx={{ fontSize: '11px' }} label="Monthly Budget Projection" {...a11yProps(0)} />
                    <Tab sx={{ fontSize: '11px' }} label="Monthly Budget Distribution" {...a11yProps(1)} />
                    <Tab sx={{ fontSize: '11px' }} label="Achievements" {...a11yProps(2)} />
                </Tabs>
            </Box>

            <div className='monthBudget-date'>
                {value !== 2 ?
                    <>
                        {selectedDate.toLocaleDateString() === new Date(range[0]).toLocaleDateString() &&
                            <div className="notification_bar_red col-6">
                                <span><i className="bi bi-exclamation-circle"></i> This is previous month budget.This is for view only.You can not update it.</span>
                                {/* <button className='btn_red'><i className="bi bi-x-lg"></i></button> */}
                            </div>
                        }
                        {selectedDate.toLocaleDateString() === new Date(range[1]).toLocaleDateString() &&
                            <div className="notification_bar_green col-6">
                                <span><i className="bi bi-check2-circle"></i> This is current month budget.You can update and submit it.</span>
                                {/* <button className='btn_red'><i className="bi bi-x-lg"></i></button> */}
                            </div>
                        }
                        {selectedDate.toLocaleDateString() === new Date(range[2]).toLocaleDateString() &&
                            <div className="notification_bar_green col-6">
                                <span><i className="bi bi-check2-circle"></i> This is upcoming month budget.You can update and submit it.</span>
                                {/* <button className='btn_red'><i className="bi bi-x-lg"></i></button> */}
                            </div>
                        }
                    </> : <div>
                    <button className={achivView === 'MTD' ? 'tabs_box-button_first tabs_box-active' : 'tabs_box-button_first'} onClick={() => handleAchivView('MTD')}>MTD</button>
                        <button className={achivView === 'Hourly' ? 'tabs_box-button_last tabs_box-active' : 'tabs_box-button_last'} onClick={() => handleAchivView('Hourly')}>Hourly</button>
                    </div>
                }
                {(achivView === 'Hourly' && value === 2) ? <div></div> :
                    <select value={selectedDate} name="Month" style={{ width: "min-content" }} className="form-select form-select-sm" onChange={onMonthChange}>
                        {
                            range.map(el => {
                                return <option value={el} key={el}>{
                                    `${getMontheInText(new Date(el).getMonth() + 1)}-${new Date(el).getFullYear()}`
                                }</option>
                            })
                        }
                    </select>}
            </div>



            <CustomTabPanel value={value} index={0}>
                {isLoading ? <div style={{ zIndex: 1, position: "absolute", left: "50%", top: "50%" }}><Loader /></div> :
                    <MonthlyBudgetProjection
                        onMonthChange={onMonthChange}
                        handleCancel={handleCancel}
                        handleSubmit={handleSubmit}
                        range={range}
                        rowData={rowData}
                        setRowData={setRowData}
                        // setUpload={setUpload}
                        types={type}
                        setType={setType}
                        selectedDate={selectedDate}
                    />
                }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {
                    isLoading ? <div style={{ zIndex: 1, position: "absolute", left: "50%", top: "50%" }}><Loader /></div> :
                        <>
                            {rowData?.reduce((total, curr) => total + curr?.spend || 0, 0) === 0 ?
                                <div className=''>
                                    <h1>Please Fill Monthly Budget Projection First</h1>
                                </div> :
                                <>
                                    {/* {
                                        view === ViewEnum.SPEND &&
                                        <MonthlyBudgetDistributionSpend2
                                            rowData={rowData}
                                            handleSubmitProjections={handleSubmitProjections}
                                            projections={projections}
                                            monthIndex={selectedDate.getMonth() + 1}
                                            year={selectedDate.getFullYear()}
                                            totalSpend={rowData?.reduce((total, curr) => total + curr.spend || 0, 0)}
                                            view={view}
                                            handleViewChange={handleViewChange}
                                        />
                                    } */}
                                    {
                                        view === ViewEnum.WEIGHT &&
                                        <MonthlyBudgetDistributionWeight
                                            rowData={rowData}
                                            handleSubmitProjections={handleSubmitProjections}
                                            projections={projections}
                                            monthIndex={selectedDate.getMonth() + 1}
                                            year={selectedDate.getFullYear()}
                                            totalSpend={rowData?.reduce((total, curr) => total + curr.spend || 0, 0)}
                                            view={view}
                                            handleViewChange={handleViewChange}
                                        />
                                    }
                                </>

                            }
                        </>
                }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <MTDAchievement
                    achievements={achievements}
                    setAchievements={setAchievements}
                    isLoading={isLoading}
                    achivView={achivView}
                    dth_achievement={dth_achievement}
                />
            </CustomTabPanel>
        </div>
    )
}

export default MonthlyBudget;