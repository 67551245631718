import { getFlowColor, getFlowColorReverse, getFlowColorForMetric, formatSingleDateFnWithYear } from "../../../../commonFunction/commomFunction";
import './cellRenderer.css';
import { formatSingleDateFn } from "../../../../commonFunction/commomFunction";



const SalesCellComponent = (props) => {
    const { sales_flow, sales_percentage_change } = props.data
    //  console.log(props.data)
    const arrowDirSales = function () {
        if (sales_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (sales_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleConversion">
            <span style={{ marginRight: '2px' }}>₹{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(sales_flow), fontSize: 10 }} >({sales_percentage_change}%{arrowDirSales()})</span>
        </div>
    )
}

const AcosCellComponent = (props) => {
    const { acos_flow, acos_percentage_change } = props.data
    const arrowDirACOS = function () {
        if (acos_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (acos_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}%</span>
            <span style={{ color: getFlowColorReverse(acos_flow), fontSize: 10 }} >({acos_percentage_change}%{arrowDirACOS()})</span>
        </div>
    )
}
const CostCellComponent = (props) => {
    const { cost_flow, cost_percentage_change } = props.data
    const arrowDirCost = function () {
        if (cost_percentage_change < 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else if (cost_percentage_change > 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyle">
            <span style={{ marginRight: '2px' }}>₹{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(cost_flow), fontSize: 10 }} >({cost_percentage_change}%{arrowDirCost()})</span>
        </div>
    )
}
const ImpressionsCellComponent = (props) => {
    const { impressions_flow, impressions_percentage_change } = props.data
    const arrowDirImpressions = function () {
        if (impressions_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (impressions_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleConversion">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(impressions_flow), fontSize: 10 }} >({impressions_percentage_change}%{arrowDirImpressions()})</span>
        </div>
    )
}
const CPCCellComponent = (props) => {
    const { cpc_flow, cpc_percentage_change } = props.data
    //  console.log(props.data)
    const arrowDirCPC = function () {
        if (cpc_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (cpc_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleConversion">
            <span style={{ marginRight: '2px' }}>₹{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColorReverse(cpc_flow), fontSize: 10 }} >({cpc_percentage_change}%{arrowDirCPC()})</span>
        </div>
    )
}

const CTRCellComponent = (props) => {
    const { ctr_flow, ctr_percentage_change } = props.data
    //  console.log(props.data)
    const arrowDirCTR = function () {
        if (ctr_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (ctr_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}%</span>
            <span style={{ color: getFlowColor(ctr_flow), fontSize: 10 }} >({ctr_percentage_change}%{arrowDirCTR()})</span>
        </div>
    )
}

const ClicksCellComponent = (props) => {
    const { clicks_flow, clicks_percentage_change } = props.data
    //  console.log(props.data)
    const arrowDirClicks = function () {
        if (clicks_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (clicks_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(clicks_flow), fontSize: 10 }} >({clicks_percentage_change}%{arrowDirClicks()})</span>
        </div>
    )
}

const OrderComponent = (props) => {
    const { orders_flow, orders_percentage_change } = props.data
    //  console.log(props.data)
    const arrowDirOrders = function () {
        if (orders_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (orders_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(orders_flow), fontSize: 10 }} >({orders_percentage_change}%{arrowDirOrders()})</span>
        </div>
    )
}
const ConversionComponent = (props) => {
    const { conversion_flow, conversion_percentage_change } = props.data
    // console.log(props.data)
    const arrowDirConversion = function () {
        if (conversion_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (conversion_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleConversion">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}%</span>
            <span style={{ color: getFlowColor(conversion_flow), fontSize: 10 }} >({conversion_percentage_change}%{arrowDirConversion()})</span>
        </div>
    )
}
const ROASCellComponent = (props) => {
    const { roas_flow, roas_percentage_change } = props.data
    // console.log(props.data)
    const arrowDirROAS = function () {
        if (roas_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (roas_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(roas_flow), fontSize: 10 }} >({roas_percentage_change}%{arrowDirROAS()})</span>
        </div>
    )
}
const TOSCellComponent = (props) => {
    const { tos_is_flow, tos_is_percentage_change } = props.data
    const arrowDirTOS = function () {
        if (tos_is_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (tos_is_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props?.value?.toLocaleString("en-IN")}%</span>
            <span style={{ color: getFlowColor(tos_is_flow), fontSize: 10 }} >({tos_is_percentage_change}%{arrowDirTOS()})</span>
        </div>
    )
}
const ST_IRCellComponent = (props) => {
    const { search_term_impression_rank_flow, search_term_impression_rank_flow_percentage_change } = props.data
    const arrowDirTOS = function () {
        if (search_term_impression_rank_flow_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (search_term_impression_rank_flow_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props?.value?.toLocaleString("en-IN")}</span>
            <span style={{ color: getFlowColor(search_term_impression_rank_flow), fontSize: 10 }} >({search_term_impression_rank_flow_percentage_change}%{arrowDirTOS()})</span>
        </div>
    )
}
const ST_ISCellComponent = (props) => {
    const { search_term_impression_share_flow, search_term_impression_share_flow_percentage_change } = props.data
    const arrowDirTOS = function () {
        if (search_term_impression_share_flow_percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        }
        else if (search_term_impression_share_flow_percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        }
        else {
            return;
        }
    }
    return (
        <div className="cellStyleRoas">
            <span style={{ marginRight: '2px' }}>{props?.value?.toLocaleString("en-IN")}%</span>
            <span style={{ color: getFlowColor(search_term_impression_share_flow), fontSize: 10 }} >({search_term_impression_share_flow_percentage_change}%{arrowDirTOS()})</span>
        </div>
    )
}


const AsinComponent = (props) => {
    // console.log(props.data);
    const { ad_asin } = props.data;
    if (ad_asin === "Total") {
        return (
            <div  >
                <a style={{ color: "rgb(0, 115, 199)", cursor: "pointer" }} >{props.value}</a>
            </div>
        )
    }
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
            <div className="imgContainer">
                <img src={props.data.image_url} height={"100%"} width={"100%"} alt="" ></img>
            </div>
            <a href={`https://www.amazon.in/dp/${props.value}`} target="_blank" style={{ color: "var(--active-color)", cursor: "pointer", fontWeight: "600" }} >{props.value}</a>
        </div>
    )
}

const ProductNameComponent = (props) => {
    return (
        <div>
            {props.data.product_name}
        </div>
    )
}

const L7DSales = (props) => {
    // console.log(props.data);
    return (
        <div>₹{props.value} </div>
    )
}
const L7DACOS = (props) => {
    // const {from_day_before_yesterday_pre_seven_days_avg_sales} = props.data;
    return (
        <div>{props.value}% </div>
    )
}
const L7DCost = (props) => {
    // const {from_day_before_yesterday_pre_seven_days_avg_sales} = props.data;
    return (
        <div>₹{props.value} </div>
    )
}
const L7Dcpc = (props) => {
    // const {from_day_before_yesterday_pre_seven_days_avg_sales} = props.data;
    return (
        <div>₹{props.value} </div>
    )
}
const L7Dctr = (props) => {
    // const {from_day_before_yesterday_pre_seven_days_avg_sales} = props.data;
    return (
        <div>{props.value}% </div>
    )
}
const L7DClicks = (props) => {
    // const {from_day_before_yesterday_pre_seven_days_avg_sales} = props.data;
    return (
        <div>{props.value}% </div>
    )
}

const AddRsComponent = (props) => {
    return (
        <div style={{ width: "100%", textAlign: "center" }}>{props.value !== undefined ? `₹${props.value.toLocaleString('en-IN')}` : "NA"} </div>
    )
}
const AddPercentageComponent = (props) => {
    // console.log(props.data);
    return (
        <div>{props.value ? props.value.toLocaleString('en-IN') : 0}% </div>
    )
}

const DefaultComponent = (props) => {
    return (
        <div>{props.value ? props.value.toLocaleString('en-IN') : 0}</div>
    )
}
const AddCommas = (props) => {
    return (
        <div>{props.value.toLocaleString('en-IN')} </div>
    )
}

const DynamicComponent = (props) => {
    const { metric } = props.data;
    if (metric === 'Impressions') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Clicks') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Ad Orders') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Ad Units') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'CTR') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}%</div>
        )
    } else if (metric === 'CVR') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}%</div>
        )
    } else if (metric === 'CPC') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'ACOS') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}%</div>
        )
    } else if (metric === 'Ad Spend') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Ad Sales') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Seller Central Sales') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Vendor Central Sales') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Organic Sales Ad Asins') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Organic Sales Total') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Total Sales') {
        return (
            <div>₹{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'TACOS') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}%</div>
        )
    } else if (metric === 'Seller Central Units') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Vendor Central Units') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Organic Units Ad Asins') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Organic Units Total') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Total Units') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Cancelled Orders Seller Central') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    } else if (metric === 'Cancelled Sales Seller Central') {
        return (
            <div>{props.value && props.value.toLocaleString('en-IN')}</div>
        )
    }
}

const DynamicFlowComponent = (props) => {
    const { table } = props.colDef.cellRendererParams;
    // console.log(props.data);
    const arrowDir = (percentage_change) => {
        if (percentage_change > 0) {
            return <i className="bi bi-arrow-up-short"></i>;
        } else if (percentage_change < 0) {
            return <i className="bi bi-arrow-down-short"></i>;
        } else if (percentage_change === 0) {
            return;
        }
    }
    if (table === "metric") {
        const { flow, percentage_change, metric, change } = props.data;
        // console.log(metric);
        if (metric === 'Total Sales' || metric === 'CPC' || metric === 'Organic Sales Total' || metric === 'Organic Sales Ad Asins' || metric === 'Vendor Central Sales' || metric === 'Seller Central Sales' || metric === 'Ad Sales' || metric === 'Ad Spend') {
            return (
                <div className="cellStyleMetric" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                    <div style={{ marginRight: 5 }} >
                        ₹{change.toLocaleString("en-IN")}
                    </div>
                    <div style={{ fontSize: 10, color: getFlowColorForMetric(flow, metric) }}>({props.value}%{arrowDir(percentage_change)})</div>
                </div>
            )
        }
        if (metric === 'CTR' || metric === 'ACOS' || metric === 'CVR' || metric === 'TACOS') {
            return (
                <div className="cellStyleMetric" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                    <div style={{ marginRight: 5 }} >
                        {change.toLocaleString("en-IN")}%
                    </div>
                    <div style={{ fontSize: 10, color: getFlowColorForMetric(flow, metric) }}>({props.value}%{arrowDir(percentage_change)})</div>
                </div>
            )
        }
        return (
            <div className="cellStyleMetric" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <div style={{ marginRight: 5 }} >
                    {change.toLocaleString("en-IN")}
                </div>
                <div style={{ fontSize: 10, color: getFlowColorForMetric(flow, metric) }}>({props.value}%{arrowDir(percentage_change)})</div>
            </div>
        )
    } else if (table === "other") {
        const { metric } = props.colDef.cellRendererParams;
        if (metric === 'Sales' || metric === 'Spend' || metric === 'CPC') {
            return (
                <div className="cellStyleOther" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ marginRight: 5 }} >
                        ₹{(props.data[`${metric}_change`])?.toLocaleString("en-IN")}
                    </div>
                    <div style={{ fontSize: 10, color: getFlowColorForMetric(props.data[`${metric}_flow`], metric) }}>
                        ({props.value}%{arrowDir(props.value)})
                    </div>
                </div>
            )
        }
        if (metric === 'CTR' || metric === 'Conversions' || metric === 'ACOS' || metric === 'TOS_IS') {
            return (
                <div className="cellStyleOther" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ marginRight: 5 }} >
                        {(props.data[`${metric}_change`])?.toLocaleString("en-IN")}%
                    </div>
                    <div style={{ fontSize: 10, color: getFlowColorForMetric(props.data[`${metric}_flow`], metric) }}>
                        ({props.value}%{arrowDir(props.value)})
                    </div>
                </div>
            )
        }
        return <div className="cellStyleOther" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ marginRight: 5 }} >
                {(props.data[`${metric}_change`])?.toLocaleString("en-IN")}
            </div>
            <div style={{ fontSize: 10, color: getFlowColorForMetric(props.data[`${metric}_flow`], metric) }}>
                ({props.value}%{arrowDir(props.value)})
            </div>
        </div>
    } else {
        return <div>Please provide table name</div>
    }
}


const TotalCellComponent = (props) => {
    return <div style={{ color: props.value === "Total" ? "black" : "" }} >
        {props.value}
    </div>
}

// component of bid changes detailed bid table
const DateComponent = (props) => {
    const { time_stamp } = props.data;
    const date = time_stamp.split('T')[0];
    return (
        <div>{formatSingleDateFn(date)}</div>
    )
}
const DateComponentWithYear = (props) => {
    const { time_stamp } = props.data;
    const date = time_stamp.split('T')[0];
    return (
        <div>{formatSingleDateFnWithYear(date)}</div>
    )
}

const TimeComponent = (props) => {
    // console.log(props.data);
    const { time_stamp } = props.data;
    const time = time_stamp.split('T')[1].split('.')[0];
    const hours = time.split(":")[0];
    const minute = time.split(":")[1];
    return (
        <div>{`${hours}:${minute}`}</div>
    )
}
const HourlyTimeComponent = (props) => {
    // console.log(props.data);
    const { hour } = props.data;
    // console.log(hour);
    const time = hour.split('.')[0];
    const hours = time.split(":")[0];
    const minute = time.split(":")[1];
    return (
        <div>{`${hours}:${minute}`}</div>
    )
}


const BidComponent = (props) => {
    // console.log(props.value);
    if (isNaN(props.value)) {
        return (
            <div>{props.value}</div>
        )
    } else {
        return (
            <div>₹{props.value ? props.value.toLocaleString('en-IN') : 0} </div>
        )
    }
}
const BidDiffComponent = (props) => {
    const { bid_difference } = props.data;
    if (bid_difference < 0) {
        return <div className='negativeBidDiff'>₹{bid_difference}</div>
    } else if (bid_difference > 0) {
        return <div className='positiveBidDiff'>₹{bid_difference}</div>
    } else if (bid_difference === 0) {
        return <div className='zeroBidDiff'>₹{bid_difference}</div>
    }
}
const BidDiffComponent2 = (props) => {
    const { bid_diff } = props.data;
    if (bid_diff < 0) {
        return <div className='negativeBidDiff'>₹{bid_diff}</div>
    } else if (bid_diff > 0) {
        return <div className='positiveBidDiff'>₹{bid_diff}</div>
    } else if (bid_diff === 0) {
        return <div className='zeroBidDiff'>₹{bid_diff}</div>
    }
}

const ACOSColor = (props) => {
    const { acos, target_acos } = props.data;
    var diff = acos - target_acos;
    if (diff < 0) {
        return <div className='negativeBidDiff'>{acos}</div>
    } else if (diff > 0) {
        return <div className='positiveBidDiff'>{acos}</div>
    } else if (diff === 0) {
        return <div className='zeroBidDiff'>{acos}</div>
    }
}

const AddSymbolComponent = (props) => {
    const { metric } = props.data;
    if (metric === "Sales") {
        return (
            <div>{props.value && (`₹${props.value?.toLocaleString('en-IN')}`)}</div>
        )
    } else if (metric === 'Spend') {
        return (
            <div>{props.value && (`₹${props.value?.toLocaleString('en-IN')}`)}</div>
        )
    } else if (metric === 'ACOS') {
        return (
            <div>{props.value && (`${props.value?.toLocaleString('en-IN')}%`)}</div>
        )
    }
}

const SRNOComponent = (props) => {
    return <span>{props.rowIndex + 1}</span>
}


export {
    AsinComponent,
    SalesCellComponent,
    AcosCellComponent,
    CostCellComponent,
    ImpressionsCellComponent,
    CPCCellComponent,
    CTRCellComponent,
    ClicksCellComponent,
    OrderComponent,
    ConversionComponent,
    ROASCellComponent,
    TOSCellComponent,
    ST_IRCellComponent,
    ST_ISCellComponent,
    L7DSales,
    L7DACOS,
    L7DCost,
    L7Dcpc,
    L7Dctr,
    L7DClicks,
    AddRsComponent,
    AddPercentageComponent,
    ProductNameComponent,
    DynamicComponent,
    DynamicFlowComponent,
    AddCommas,
    DefaultComponent,
    TotalCellComponent,
    DateComponent,
    DateComponentWithYear,
    TimeComponent,
    HourlyTimeComponent,
    BidComponent,
    BidDiffComponent,
    BidDiffComponent2,
    AddSymbolComponent,
    ACOSColor,
    SRNOComponent
}