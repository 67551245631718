import React, { useState, useRef } from 'react';
import '../ProductMaster.css';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { NotificationManager } from 'react-notifications';


function ProductUpload(props) {
    const fileInputRef = useRef(null);
    const { rowData, setRowData, platform, columnDefs } = props;
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { _id, client_profile_id, client_name } } = appParams;
    const [file, setFile] = useState(null);
    // console.log(columnDefs);

    const resetUpload = () => {
        fileInputRef.current.form.reset();
    }

    const validateData = (arrOfObjdata) => {
        if (platform === 'Amazon') {
            let arrOfAsin = [];
            for (let [i, el] of arrOfObjdata.entries()) {
                const { ad_asin, mrp, advertised } = el;
                // console.log(i, el);
                const trimmedAsin = ad_asin.trim();
                arrOfAsin.push(trimmedAsin);

                if (!(trimmedAsin?.startsWith('B0')) || trimmedAsin?.length !== 10) {
                    NotificationManager.error(`Please enter a valid ASIN, Invalid value of ASIN: ${trimmedAsin} on line no. ${i + 2}`, 'Error', 5000);
                    // notificationArr.push('Please enter a valid ASIN')
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }

                // if (Advertised === 'yes'.toLowerCase()) {
                //     for (let key in el) {
                //         if (el.hasOwnProperty(key) && !el[key]) {
                //             NotificationManager.error("Please fill all the row values with advertised 'yes'", 'Error', 5000);
                //             setTimeout(() => {
                //                 resetUpload();
                //             }, 1000)
                //             return;
                //         }
                //     }
                // }
                if (!advertised) {
                    NotificationManager.error(`Advertised column should not be blank. It should be 'yes' or 'no'. Please provide value Advertised value on line no. ${i + 2}`, 'Error', 5000);
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }
                if (!(advertised === 'yes' || advertised?.toLowerCase() === 'yes' || advertised === 'no' || advertised?.toLowerCase() === 'no')) {
                    NotificationManager.error(`Advertised value should be 'yes' or 'no'. Invalid value of Advertised: ${advertised} on line no. ${i + 2}`, 'Error', 5000);
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }
                if (mrp && isNaN(mrp)) {
                    NotificationManager.error(`MRP should be a number. Invalid value of MRP: ${mrp} on line no. ${i + 2}`, 'Error', 5000);
                    // notificationArr.push('MRP should be a number')
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }

            }
            const setOfAsin = new Set(arrOfAsin);
            if (arrOfAsin.length !== setOfAsin.size) {
                NotificationManager.error("ASINs are not distinct.", 'Error', 5000);
                setTimeout(() => {
                    resetUpload();
                }, 1000)
                return;
            }
            return true;
        } else {
            for (let [i, el] of arrOfObjdata.entries()) {
                const { mrp, advertised } = el;
                if (!advertised) {
                    NotificationManager.error(`Advertised column should not be blank. It should be 'yes' or 'no'. Please provide value Advertised value on line no. ${i + 2}`, 'Error', 5000);
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }
                if (!(advertised === 'yes' || advertised?.toLowerCase() === 'yes' || advertised === 'no' || advertised?.toLowerCase() === 'no')) {
                    NotificationManager.error(`Advertised value should be 'yes' or 'no'. Invalid value of Advertised: ${advertised} on line no. ${i + 2}`, 'Error', 5000);
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }
                if (mrp && isNaN(mrp)) {
                    NotificationManager.error(`MRP should be a number. Invalid value of MRP: ${mrp} on line no. ${i + 2}`, 'Error', 5000);
                    // notificationArr.push('MRP should be a number')
                    setTimeout(() => {
                        resetUpload();
                    }, 1000)
                    return;
                }
            }
            return true;
        }

    }

    const handleFileChange = (e) => {
        let extractedFile = e.target.files[0];
        if (extractedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const dataArr = jsonData.slice(1);

                if (jsonData.length > 0) {
                    let headers = jsonData[0];
                    // headers = headers.map(el => {
                    //     let newEl;
                    //     if (el.includes(' ')) {
                    //         newEl = el.replace(/ /g, '_')
                    //     } else {
                    //         newEl = el
                    //     }
                    //     return newEl
                    // })
                    // console.log(headers);
                    // const checkHeaders = columnDefs.map(el => {
                    // let newEl;
                    // if (el.headerName.includes(' ')) {
                    //     newEl = el.headerName.replace(/ /g, '_')
                    // } else {
                    //     newEl = el.headerName
                    // }
                    // return newEl
                    // })
                    const checkHeaders = platform === 'Amazon' ? ["ad_asin", "pname", "mrp", "main_cat", "last_node_amazon_pdp", "internal_category", "brand", "sub_brand", "advertised", "type", "range",
                        "color", "item_package_qty", "item_weight", "item_weight_unit", "SKU_code", "short_name", "variation_type", "status",
                        "item_dimension", "region", "Miscellaneous1", "Miscellaneous2", "Miscellaneous3", "EAN_code", "business_contribution",
                        "priority", "planned",
                        "npd"] :
                        ["platform_code", "pname", "mrp", "platform", "last_node_amazon_pdp", "internal_category", "brand", "sub_brand", "advertised",
                            "SKU_code", "short_name", "region", "Miscellaneous1", "Miscellaneous2", "Miscellaneous3", "EAN_code", "business_contribution",
                            "priority", "planned", "npd"]
                    // console.log(checkHeaders);
                    const checkAllElExist = checkHeaders.every(el => headers.includes(el));
                    if (headers.length === checkHeaders.length && checkAllElExist) {
                        const arrOfObjdata = dataArr.map((row) => {
                            const dataObj = {};
                            headers.forEach((header, index) => {
                                dataObj[header] = row[index];
                            });
                            return dataObj;
                        });
                        console.log(arrOfObjdata);
                        if (validateData(arrOfObjdata)) {
                            setFile(arrOfObjdata)
                        }
                    } else {
                        let header;
                        let temperdHeader;
                        checkHeaders.forEach((el) => {
                            if (!(headers.includes(el))) {
                                header = el;
                            }
                        })
                        headers.forEach((el) => {
                            if (!(checkHeaders.includes(el))) {
                                temperdHeader = el;
                            }
                        })
                        // console.log(header, temperdHeader);
                        NotificationManager.error(`File doesn't contain the required headers. ${header} got tempered with ${temperdHeader}`, 'Error', 5000);
                        setTimeout(() => {
                            resetUpload();
                        }, 1000)
                    }
                } else {
                    setFile([]);
                }

                // for (let el of dataArr) {
                //     if (!(el[el.length - 1] === 'yes' || el[el.length - 1] === 'no')) {
                //         NotificationManager.error("Advertised should be 'yes' or 'no'", 'Error', 5000);
                //         setTimeout(() => {
                //             resetUpload();
                //         }, 1000)
                //         return;
                //     }
                //     if (el[el.length - 1] === 'yes') {

                //         if (!(el[0] && el[1] && el[5] && el[6] && el[8] && el[9] && el[10] && el[11])) {
                //             NotificationManager.error("Please fill all the required row values with advertised 'yes'", 'Error', 5000);
                //             setTimeout(() => {
                //                 resetUpload();
                //             }, 1000)
                //             return;
                //         }
                //         // for (let e of el) {
                //         //     console.log(e);
                //         //     if (!e) {
                //         //         NotificationManager.error("Please fill all the row values with advertised 'yes'", 'Error', 5000);
                //         //         setTimeout(() => {
                //         //             resetUpload();
                //         //         }, 1000)
                //         //         return;
                //         //     }
                //         // }
                //     }
                //     if (!(el[0]?.startsWith('B0')) || el[0]?.length !== 10) {
                //         NotificationManager.error("Please enter a valid ASIN", 'Error', 5000);
                //         setTimeout(() => {
                //             resetUpload();
                //         }, 1000)
                //         return;
                //     }
                //     if (isNaN(el[8])) {
                //         NotificationManager.error("MRP should be a number", 'Error', 5000);
                //         setTimeout(() => {
                //             resetUpload();
                //         }, 1000)
                //         return;
                //     }
                //     if (el[8] === 0) {
                //         NotificationManager.error("MRP should be greater than zero", 'Error', 5000);
                //         setTimeout(() => {
                //             resetUpload();
                //         }, 1000)
                //         return;
                //     }
            };
            reader.readAsBinaryString(extractedFile);
        }
    }

    const handleUpload = () => {
        if (file) {
            let data;
            if (platform === 'Amazon') {
                data = {
                    product_master: {
                        _id: rowData?.product_master?._id,
                        client_profile_id: client_profile_id,
                        client_name: client_name,
                        brand_id: _id,
                        product_array: file.map((el) => {
                            const {
                                // ASIN, Product_Name, MRP, Last_Node_Amazon_PDP, Internal_Category,
                                // Brand, Sub_Brand, Main_Category, Variation_Type, Region, Advertised,
                                // Color, Range, Type, Item_Package_Quantity, Item_Weight, Item_Weight_Unit, SKU, Short_Name, Status, Item_Dimension,
                                // Miscellaneous1, Miscellaneous2, Miscellaneous3, EAN_code, Business_Contribution, Priority, Planned, NPD
                                ad_asin, pname, mrp, main_cat, last_node_amazon_pdp, internal_category, brand, sub_brand, advertised, type, range,
                                color, item_package_qty, item_weight, item_weight_unit, SKU_code, short_name, variation_type, status,
                                item_dimension, region, Miscellaneous1, Miscellaneous2, Miscellaneous3, EAN_code, business_contribution,
                                priority, planned,
                                npd
                            } = el;
                            return {
                                ad_asin,
                                pname,
                                mrp,
                                main_cat,
                                last_node_amazon_pdp,
                                internal_category,
                                brand,
                                sub_brand,
                                advertised,
                                type,
                                range,
                                color,
                                item_package_qty,
                                item_weight,
                                item_weight_unit,
                                SKU_code,
                                short_name,
                                variation_type,
                                status,
                                item_dimension,
                                region,
                                Miscellaneous1,
                                Miscellaneous2,
                                Miscellaneous3,
                                EAN_code,
                                business_contribution,
                                priority,
                                planned,
                                npd
                            }
                        })
                    }
                }
                setRowData(data);
            } else {
                data = {
                    product_master: {
                        _id: rowData?.product_master?._id,
                        client_profile_id: client_profile_id,
                        client_name: client_name,
                        brand_id: _id,
                        platform: rowData?.product_master?.platform,
                        product_array: file.map((el) => {
                            const {
                                platform_code, pname, mrp, platform, last_node_amazon_pdp, internal_category, brand, sub_brand, advertised,
                                SKU_code, short_name, region, Miscellaneous1, Miscellaneous2, Miscellaneous3, EAN_code, business_contribution,
                                priority, planned, npd
                            } = el;
                            return {
                                platform_code,
                                pname,
                                mrp,
                                last_node_amazon_pdp,
                                internal_category,
                                brand,
                                sub_brand,
                                region,
                                platform,
                                short_name,
                                npd,
                                planned,
                                advertised,
                                priority,
                                business_contribution,
                                SKU_code,
                                Miscellaneous1,
                                Miscellaneous2,
                                Miscellaneous3,
                                EAN_code,
                            }
                        })
                    }
                }
                setRowData(data);
            }

            // console.log(data);
        }
        else {
            NotificationManager.error("Somthing went wrong!! Unable to upload", 'Error', 3000);
            setTimeout(() => {
                resetUpload();
            }, 1000)
        }
        // setUpload(true);
        resetUpload();
    }
    return (
        <div className='upload_container'>
            <div className='selected_upload'>
                <form>
                    <input
                        ref={fileInputRef}
                        className='form-control form-control-sm'
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChange}
                    />
                </form>
            </div>
            <button type='button' className='cancel_btn' onClick={handleUpload}><i className="bi bi-upload"></i></button>
        </div>
    )
}

export default ProductUpload;