import React, { useState, useMemo, useEffect } from 'react';
import './MainContainer.scss';
import { AgGridReact } from 'ag-grid-react';
import { AG_AR } from '../../../appConstants';
import { useSelector } from 'react-redux';
import axios from "axios"
import { BASE_URL } from '../../../appConstants';
import Loader from '../../commonComponent/Loader/Loader';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import { NotificationManager } from 'react-notifications';
import ReactDOM from 'react-dom';

const ThumbnailCellRenderer = (props) => {
    const { ad_asin, image_url, pname } = props.data;
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }} >
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative', width: '100%', height: '100%' }}>
                {image_url ? <img src={image_url} alt="" style={{ width: '50px', height: '50px' }} /> : <div style={{ width: '50px' }}></div>}
                {isHovered && image_url && (
                    ReactDOM.createPortal(
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '5px', padding: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <img src={image_url} alt="" style={{ width: '10vw', height: '30vh' }} />
                            {/* <span>{pname}</span> */}
                        </div>,
                        document.body
                    )
                )}
            </div>
            <a href={`https://www.amazon.in/dp/${ad_asin}`} target="_blank" style={{ color: "var(--active-color)", cursor: "pointer", fontWeight: "600" }} >{ad_asin}</a>
        </div>
    );
};

const KeywordIndexing = () => {

    const appParams = useSelector(state => state.appParams);
    const { current_brand: { client_profile_id } } = appParams;
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const token = localStorage.getItem('token');


    const serialNo = (props) => {
        let serialNum = props.rowIndex + 1;
        return (<div>{serialNum}</div>)
    }


    const onCellValueChanged = (event) => {
        // console.log(event);
        const { data, colDef, newValue } = event;
        const updatedData = { ...data, [colDef.field]: newValue };


        // console.log(updatedData);
        setIsLoading(true);
        axios.patch(`${BASE_URL}indexing-check/${data._id}`, {
            manual_check: updatedData.manual_check
        }, {
            headers: {
                token
            }
        }).then((res) => {
            console.log(res.data);
            const updatedAllData = rowData.map((el) => el._id === updatedData._id ? updatedData : el)
            setRowData(updatedAllData);
        }).catch((err) => {
            console.log(err);
            NotificationManager.error('Unable to update data', 'Error', 3000)
        }).finally(() => {
            setIsLoading(false);
        })
    }


    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BASE_URL}indexing-check?client_profile_id=${client_profile_id}`)
            .then((res) => {
                const data = res.data.data.indexingCheckRecords;
                setRowData(data);
            }).catch((err) => {
                console.log(err);
                setError(err.message)
            }).finally(() => {
                setIsLoading(false);
            })
    }, [client_profile_id])

    const keywordComp = (props) => {
        const { target, ad_asin } = props.data;
        const link = encodeURIComponent(target + '+' + ad_asin);
        return (
            <div>
                <a href={`https://www.amazon.in/s?k=${link}`} style={{ color: "var(--active-color)", cursor: "pointer", fontWeight: "600" }} target="_blank">{target}</a>
            </div>
        )
    }

    const tooltipRenderer = ({ value }) => {
        return (
            <div title={value} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {value}
            </div>
        );
    };


    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, }), []);
    const columnDefs = useMemo(() => ([
        { headerName: 's.no', field: '', cellRenderer: serialNo, width: 80 },
        { headerName: 'Keyword', field: 'target', cellRenderer: keywordComp, width: 180 },
        { headerName: 'ASIN', field: 'ad_asin', cellRenderer: ThumbnailCellRenderer, width: 180 },
        { headerName: 'SFR', field: 'sfr',width: 120 },
        { headerName: 'Indexing', field: 'indexed',width: 120 },
        { headerName: 'Campaign Name', field: 'campaignName', width: 250, cellRenderer: tooltipRenderer },
        { headerName: 'Product Name', field: 'pname', width: 200, cellRenderer: tooltipRenderer },
        // { headerName: 'SFR Volume', field: 'sfr_volume' },
        { headerName: 'Last Checked On', field: 'last_checked_at', width: 120 },
        { headerName: 'Occurence', field: 'occurence', width: 120, cellRenderer: tooltipRenderer },
        { headerName: 'Created On', field: 'created_at', width: 120 },
        
        {
            headerName: 'Manual Check', field: 'manual_check', width: 120,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No'],
            },
        },
    ]), [])


    return (
        <div>
            <div className="ag-theme-alpine" style={{ height: 570, width: '100%' }}>
                {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <AgGridReact
                        // ref={gridRef}
                        rowData={rowData}
                        rowHeight={40}
                        headerHeight={50}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressNoRowsOverlay={true}
                        suppressDragLeaveHidesColumns={true}
                        onCellValueChanged={onCellValueChanged}
                        suppressMenuHide={true}
                    >
                    </AgGridReact>
                }
                {
                    isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                }
            </div>
            {/* <div className='button-contain'>
                <button className='keyword-button'>Submit</button>
            </div> */}
        </div>
    )
}

export default KeywordIndexing;