import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { HEADER } from '../../../appUiConstants';
import axios from 'axios';
import { AG_AR, BASE_URL } from '../../../appConstants';
import Loader from '../../commonComponent/Loader/Loader';
import { ACOSColor, HourlyTimeComponent, SRNOComponent } from '../advertisingReport/ARComponents/cellRenderer';
import BrandGenricFilter from '../../agGridCommonComponent/BrandGenricFilter';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';

function DailyTargets() {
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;
    const token = localStorage.getItem('token');
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    function fetchData() {
        setLoading(true);
        axios.get(`${BASE_URL}bidChanges/getDailyTarget?brandId=${current_brand._id}`, {
            headers: {
                token
            }
        }).then((res) => {
            const data = res.data.data.data;
            if (current_brand.automation_status === 'no') {
                setRowData(data);
                throw Error('This brand is not automated yet!')
            }
            if (res.status === 200 && data.length === 0) {
                setRowData(data);
                throw Error('No data available for this brand!')
            }
            setError(false);
            setRowData(data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setError(error.message);
        })
    }

    useEffect(() => {
        fetchData();
    }, [current_brand._id])

    //To get the height for grid
    const [containerHeight, setContainerHeight] = useState();
    useEffect(() => {
        const height = window.innerHeight;
        const netHeight = height - HEADER.singleTableHeight + 40;
        setContainerHeight(netHeight);
    }, []);
    window.addEventListener("resize", () => {
        const height = window.innerHeight;
        const netHeight = height - HEADER.singleTableHeight + 40;
        setContainerHeight(netHeight);
    });
    //

    const ParentAsinComponent = (props) => {
        return (
            <a href={`https://www.amazon.in/dp/${props.value}`} style={{ color: 'rgb(0, 115, 199)' }} target="_blank">{props.value}</a>
        )
    }

    const TargetingComponent = (props) => {
        // const { parent_asin, targeting } = props.data;
        if (props.value?.startsWith('B0')) {
            return (
                <a href={`https://www.amazon.in/dp/${props.value}`} style={{ color: 'rgb(0, 115, 199)' }} target="_blank">{props.value}</a>
            )
        }
        return (
            <div>{props.value}</div>
        )
    }

    const CurrentRankComponent = (props) => {
        return props.value === 'NaN' ? "" : props.value
    }

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        // floatingFilter: true,
    }), [])

    const columnDefs = useMemo(() => [
        { headerName: 'Sr No.', field: '', width: 60, cellRenderer: SRNOComponent, filter: false, cellClass: 'color-column' },
        { headerName: 'Product Name', field: 'product_name', width: 200, cellClass: 'color-column' },
        { headerName: 'Campaign Name', field: 'campaign_name', width: 200, cellClass: 'color-column' },
        { headerName: 'Targeting', field: 'targeting', width: 150, cellRenderer: TargetingComponent },
        { headerName: 'Targeting Type', field: 'targeting_type', width: 60 },
        { headerName: 'Keyword Type', field: 'keyword_type', width: 80, filter: BrandGenricFilter },
        { headerName: 'Category', field: 'category', width: 120 },
        { headerName: 'Campaign Type', field: 'campaign_type', width: 60 },
        { headerName: 'Spend(₹)', field: 'spend', width: 120, filter: 'agNumberColumnFilter' },
        { headerName: 'Sales(₹)', field: 'sales', width: 120, filter: 'agNumberColumnFilter' },
        { headerName: 'CPC(₹)', field: 'cpc', width: 120, filter: 'agNumberColumnFilter' },
        { headerName: 'ACOS(%)', field: 'acos', width: 100, filter: 'agNumberColumnFilter', cellRenderer: ACOSColor },
        // { headerName: 'Last Bid(₹)', field: 'last_bid', width: 120, filter: 'agNumberColumnFilter', },
        { headerName: 'System Bid(₹)', field: 'sys_bid', width: 120, filter: 'agNumberColumnFilter', },

        { headerName: 'Comment', field: 'comment', width: 120 },
        { headerName: 'Current Rank', field: 'current_rank', width: 120, cellRenderer: CurrentRankComponent },
        { headerName: 'Desired Rank', field: 'desired_rank', width: 120 },
        { headerName: 'Time Window', field: 'time_window', width: 120 },
        { headerName: 'Match Type', field: 'match_type', width: 120 },
        { headerName: 'Frequency', field: 'frequency', width: 120 },
        { headerName: 'Hygiene Score', field: 'hygiene_score', width: 120 },
        { headerName: 'Bucket', field: 'bucket', width: 120 },
        { headerName: 'Parent Asin', field: 'parent_asin', width: 150, cellRenderer: ParentAsinComponent },
        { headerName: 'Impressions', field: 'impressions', width: 120 },
        { headerName: 'Clicks', field: 'clicks', width: 120 },
        { headerName: 'CTR(%)', field: 'ctr', width: 120, },
        { headerName: 'Target ACOS(%)', field: 'target_acos', width: 150, },
        { headerName: 'Time', field: 'hour', width: 120, cellRenderer: HourlyTimeComponent, cellClass: 'color-column' },
    ], [])
    return (
        <div className='hourly_bids_container'>
            <div className='hourly_bids_header'>
                <h1>Daily Targets</h1>
            </div>
            <div className='hourly_bids_table_container'>
                {
                    error ? <div style={{ height: containerHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                        <div className="ag-theme-alpine" style={{ height: containerHeight, width: '100%' }}>
                            <AgGridReact
                                // ref={gridRef}
                                rowData={rowData}
                                rowHeight={AG_AR.rH}
                                headerHeight={AG_AR.hH}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                suppressNoRowsOverlay={true}
                                suppressDragLeaveHidesColumns={true}
                                suppressMenuHide={true}
                            ></AgGridReact>
                        </div>
                }
            </div>
            {
                loading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
            }
        </div>
    )
}

export default DailyTargets;
