import React from 'react';
import './ReportsErrorPage.css';

const ReportsErrorPage = (props) => {

  return (
    <div className='Reports_error_page'>
        <h6>{props.error}</h6>
    </div>
  )
}

export default ReportsErrorPage;