import React, { useMemo, useState, useEffect } from 'react';
import './NewUserModal.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { BASE_URL } from '../../../../../appConstants';
import axios from "axios";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import Loader from '../../../../commonComponent/Loader/Loader';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px",
    p: 2,
};

const NewUserModal = (props) => {
    const { ModalNewUser, setModalNewUser, } = props;
    const appParams = useSelector(state => state.appParams);
    let { brand_array, agency_array, current_brand } = appParams;
    const user = useSelector(state => state.user);
    const { user_domain } = user;
    const token = localStorage.getItem('token');


    const adminAccess = useMemo(() => [
        { id: 0, name: 'Agency Admin', value: "agency_admin" },
        { id: 1, name: 'Brand Admin', value: "brand_admin" },
        { id: 2, name: 'Brand Viewer', value: "brand_viewer" },
    ], []);

    const clientAccess = useMemo(() => [
        { id: 0, name: 'Brand Admin', value: "brand_admin" },
        { id: 1, name: 'Brand Viewer', value: "brand_viewer" },
    ], []);
    const brandAccess = useMemo(() => [
        { id: 0, name: 'Brand Viewer', value: "brand_viewer" },
    ], [])


    const [newUser, setNewUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        access: "",
        agencyArray: "",
        brandArray: ""
    });
    // console.log(newUser);
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        setBrands(brand_array);
    }, [brand_array]);

    const [loading, setLoading] = useState(false);
    const [formError, setFromError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        access: false,
        agencyArray: false,
        brandArray: false

    });

    const handleCloseModal = () => {
        setModalNewUser(false);
        setNewUser('');
        setFromError(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'agencyArray') {
            const current_agency = agency_array.find(agency => agency._id === value);
            axios.get(`${BASE_URL}brand?agencyId=${current_agency._id}`, {
                headers: {
                    token
                }
            }).then(res => {
                const { brand_array } = res.data.data;
                setBrands(brand_array);
            }).catch(err => {
                console.log(err);
            })
        }
        setNewUser((prevState) => {
            return ({
                ...prevState, [name]: value
            })
        });
        if (name === "email") {
            setFromError(prevState => ({
                ...prevState,
                email: false,
            }));
        } else {
            setFromError(prevState => ({
                ...prevState,
                [name]: false,
            }));
        }
    }

    const sendInvite = (e) => {
        // console.log(e);
        e.preventDefault();
        const { firstName, lastName, email, access, agencyArray, brandArray } = newUser;
        // console.log(firstName, lastName, email, access, agencyArray, brandArray);

        if (user_domain === 'enlytical_admin') {
            if (!firstName) {
                return setFromError(prevState => ({
                    ...prevState,
                    firstName: true,
                }));
            } else if (!lastName) {
                return setFromError(prevState => ({
                    ...prevState,
                    lastName: true,
                }));
            } else if (!email) {
                return setFromError(prevState => ({
                    ...prevState,
                    email: true,
                }));
            } else if (!access) {
                return setFromError(prevState => ({
                    ...prevState,
                    access: true
                }));
            }
            if (access === 'agency_admin') {
                if (!agencyArray) {
                    return setFromError(prevState => ({
                        ...prevState,
                        agencyArray: true
                    }));
                }
            }
            else if (access === 'brand_admin' || access === "brand_viewer") {
                if (!agencyArray) {
                    return setFromError(prevState => ({
                        ...prevState,
                        agencyArray: true
                    }));
                } else if (!brandArray) {
                    return setFromError(prevState => ({
                        ...prevState,
                        brandArray: true
                    }));
                }
            }
        } else if (user_domain === 'user') {
            if (!firstName) {
                return setFromError(prevState => ({
                    ...prevState,
                    firstName: true,
                }));
            } else if (!lastName) {
                return setFromError(prevState => ({
                    ...prevState,
                    lastName: true,
                }));
            } else if (!email) {
                return setFromError(prevState => ({
                    ...prevState,
                    email: true,
                }));
            } else if (!access) {
                return setFromError(prevState => ({
                    ...prevState,
                    access: true
                }));
            } else if (!brandArray) {
                return setFromError(prevState => ({
                    ...prevState,
                    brandArray: true
                }));
            }
        }

        setLoading(true);
        let bodyObj = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            user_type: access,
        }
        if (newUser.access === "agency_admin") {
            bodyObj["agency_array"] = [agencyArray]
        } else if (newUser.access === "brand_admin" || newUser.access === "brand_viewer") {
            bodyObj["brand_array"] = [brandArray]
        }
        // console.log(bodyObj);
        axios.post(`${BASE_URL}user/invite`, {
            ...bodyObj
        }, {
            headers: {
                token
            }
        }).then((response) => {
            // console.log(response.data.data);
            setLoading(false);
            NotificationManager.success(
                response.data.data.message,
                "Success",
                3000
            );
            props.setRefreshBrandUser(prevState => !prevState);

        }).catch((error) => {
            console.log(error);
            setLoading(false);
            NotificationManager.error(
                error.response.data.data.message,
                "Error",
                3000
            );
        })
        setNewUser("");
        setFromError(false);
        handleCloseModal();
    }

    if (agency_array.length === 0) {
        brand_array = brand_array.filter(el => el.user_type === 'brand_admin');
    }

    return (
        <div>
            <Modal
                open={ModalNewUser}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='modal-header'>
                        <div><i className="bi bi-envelope-fill"></i>  Invite User !</div>
                    </div>
                    <div className='new-user-container'>
                        <form action='/action_page.php'>
                            <div className="new-user-email">
                                {/* <label className='new-user-label'>First Name: </label> */}
                                <input
                                    type="text"
                                    name="firstName"
                                    className="form-control"
                                    id="email"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    value={newUser.firstName}
                                ></input>
                            </div>
                            {
                                formError.firstName && <p className='error-text'>First name is required !!</p>
                            }
                            <div className="new-user-email">
                                {/* <label className='new-user-label'>Last Name: </label> */}
                                <input
                                    type="text"
                                    name="lastName"
                                    className="form-control"
                                    id="email"
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    value={newUser.lastName}
                                ></input>
                            </div>
                            {
                                formError.lastName && <p className='error-text'>Last name is required !!</p>
                            }
                            <div className="new-user-email">
                                {/* <label className='new-user-label'>Email: </label> */}
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    onChange={handleChange}
                                    value={newUser.email}
                                ></input>
                            </div>
                            {
                                formError.email && <p className='error-text'>Email is required !!</p>
                            }
                            <div className="new-user-access">
                                {/* <label id="access" className='new-user-label'>Access: </label> */}
                                <select className="form-select"
                                    value={newUser.access}
                                    onChange={handleChange}
                                    name="access"
                                    required
                                >
                                    <option value=''>Select Access</option>
                                    {
                                        user_domain === 'enlytical_admin' ?
                                            adminAccess.map((el, i) => {
                                                return (
                                                    <option key={el.id} value={el.value}>{el.name}</option>
                                                )
                                            }) : (current_brand.user_type && current_brand.user_type === 'brand_admin' ?
                                                brandAccess.map((el) => {
                                                    return (
                                                        <option key={el.id} value={el.value}>{el.name}</option>
                                                    )
                                                }) :
                                                clientAccess.map((el) => {
                                                    return (
                                                        <option key={el.id} value={el.value}>{el.name}</option>
                                                    )
                                                })
                                            )
                                    }
                                </select>
                            </div>
                            {
                                formError.access && <p className='error-text'>Access selection is required !!</p>
                            }

                            <div>
                                {
                                    user_domain === 'enlytical_admin' ?
                                        <>
                                            {
                                                newUser.access === 'agency_admin' ?
                                                    <>
                                                        <div className="new-user-access">
                                                            <select className='form-select' value={newUser.agencyArray} onChange={handleChange} name="agencyArray" required>
                                                                <option value="">Select Agency</option>
                                                                {
                                                                    agency_array.map(el => {
                                                                        return <option key={el._id} value={el._id} >{el.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            formError.agencyArray && <p className='error-text'>Agency selection is required !!</p>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <div className="new-user-access">
                                                            <select className='form-select' value={newUser.agencyArray} onChange={handleChange} name="agencyArray" required>
                                                                <option value="">Select Agency</option>
                                                                {
                                                                    agency_array.map(el => {
                                                                        return <option key={el._id} value={el._id}>{el.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            formError.agencyArray && <p className='error-text'>Agency selection is required !!</p>
                                                        }
                                                        <div className="new-user-access">
                                                            <select className='form-select' value={newUser.brandArray} onChange={handleChange} name="brandArray" required>
                                                                <option value="">Select Brand</option>
                                                                {
                                                                    brands.map(el => {
                                                                        return <option key={el._id} value={el._id}>{el.client_name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            formError.brandArray && <p className='error-text'>Brand selection is required !!</p>
                                                        }
                                                    </>
                                            }
                                        </> :
                                        <>
                                            <div className="new-user-access">
                                                <select className='form-select' value={newUser.brandArray} onChange={handleChange} name="brandArray" required>
                                                    <option value="">Select Brand</option>
                                                    {
                                                        brands.map(el => {
                                                            return <option key={el._id} value={el._id}>{el.client_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {
                                                formError.brandArray && <p className='error-text'>Brand selection is required !!</p>
                                            }
                                        </>
                                }
                            </div>

                            <div className="new-user-btn-container">
                                <button className='btn btn-sm col-lg-3 new-user-btn-cancel' onClick={handleCloseModal}>Cancel</button>
                                <button className=' btn btn-sm col-lg-3 new-user-btn-invite' onClick={sendInvite}>Invite</button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
            {
                loading && (
                    <div style={{ position: "absolute", top: "50%", left: "50%", height: 0, zIndex: 1 }} >
                        <Loader />
                    </div>
                )
            }
            <NotificationContainer />
        </div>
    );
}

export default NewUserModal;