import "./DashboardsContainer.css";
import { HEADER } from "../../appUiConstants";
import DashboardsRight from "./DashboardsRight";
import DashboardsLeft from "./DashboardsLeft";
import { useEffect } from "react";
import React, { useState } from "react";
// import PowerBiDashboardContainer from "./powerBiDashboard/PowerBiDashboardContainer";
import { useSelector, useDispatch } from "react-redux";
import OnBoardingContainer from "./onBoarding/OnBoardingContainer";
import { NotificationContainer } from "react-notifications";
import AdvertisingReportContainer from "./advertisingReport/AdvertisingReportContainer";
import AdminContainer from "./admin/AdminContainer";
import ComparisonARContainer from "./advertisingReport/ComparisonARContainer";
import BidChangesContainer from "./advertisingReport/BidChangesContainer";
import TooltipComponent from "../commonComponent/Tooltip/TooltipComponent";
import { changeTab } from "../../redux/sidebarTabSlice";
import BenchmarkContainer from "./benchmarks/BenchmarkContainer";
import OnBoardingContainer2 from "./onBoarding/OnBoardingContainer2";
import { useParams } from "react-router-dom";
import HourlyBids from "./advertisingReport/BidChangesComponents/HourlyBids";
import PausedBid from "./advertisingReport/BidChangesComponents/PausedBid";
import PauseIcon from '@mui/icons-material/Pause';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import DailyTargets from "./dailyTargets/DailyTargets";
import HourlyBidsSummary from "./advertisingReport/BidChangesComponents/HourlyBidsSummary";
import KeywordCatContainer from "./keywordCategorization/KeywordCatContainer";
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import MainContainer from "./keywordCategorization/MainContainer";
import MarketingContainer from "./marketingStream/MarketingContainer";
import CampaignContainer from "./campaignApproval/CampaignContainer";


const DashboardsContainer = () => {
  const user = useSelector((state) => state.user);
  const { access, user_domain } = user;


  // console.log(user);
  // const appParams = useSelector((state)=> state.appParams);
  // const {current_brand} = appParams;
  // console.log(current_brand);
  const { id } = useParams();
  // console.log(id);
  const tabs = useSelector(state => state.tabs);
  const { container } = tabs;
  // console.log(tabs);
  const dispatch = useDispatch();

  const [tempContainer, setTempContainer] = useState('Portfolio');
  const [subMenu, setSubMenu] = useState({
    onBordingMenu: [
      "Portfolio",
      "Sellers Authentication",
      "Budget Set-Up",
      "Priority",
      "Parent-Child",
      "Competition",
    ],
    // ARmenu: [
    //   "Daily View",
    //   "Comparative View",
    //   "Daily Actions",
    // ]
  })
  const [currentEl, setCurrentEl] = useState('Portfolio')
  const [sidebarToggle, setSidebarToggle] = useState({
    onBoarding: false,
    advertisingReport: false,
  });

  // const [dashboardMenuArray, setDashboardMenuArray] = useState([]);
  // const [reportsMenuArray, setReportsMenuArray] = useState([]);
  // const [currentDashboard, setCurrentDashboard] = useState("");
  // const [currentReport, setCurrentReport] = useState("");

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   axios.get(
  //     `${BASE_URL}powerBi/getPowerBiDashboardMenuArray/dashboard?brandId=${current_brand._id}`,
  //     {
  //       headers: {
  //         token,
  //       },
  //     }
  //   ).then(function (response) {
  //     const { dashboard_menu_array } = response.data.data;
  //     if (dashboard_menu_array.length > 0) {
  //       setDashboardMenuArray(dashboard_menu_array);
  //       setCurrentDashboard(dashboard_menu_array[0]._id);
  //     } else {
  //       setDashboardMenuArray([]);
  //       setCurrentDashboard("");
  //     }
  //   }).catch(function (error) {
  //     //console.log(error);
  //   });
  //   axios.get(
  //     `${BASE_URL}powerBi/getPowerBiDashboardMenuArray/report?brandId=${current_brand._id}`,
  //     {
  //       headers: {
  //         token,
  //       },
  //     }
  //   ).then(function (response) {
  //     const { dashboard_menu_array } = response.data.data;
  //     console.log(dashboard_menu_array);

  //     if (dashboard_menu_array.length > 0) {
  //       setReportsMenuArray(dashboard_menu_array);
  //       setCurrentReport(dashboard_menu_array[0]._id);
  //     } else {
  //       setReportsMenuArray([]);
  //       setCurrentReport("");
  //     }
  //   })
  //     .catch(function (error) {
  //       //console.log(error);
  //     });
  // }, [current_brand]);
  // const changeDashboard = (e) => {
  //   setCurrentDashboard(e);
  // };

  //To get the height for grid
  const [containerHeight, setContainerHeight] = useState();
  useEffect(() => {
    const height = window.innerHeight;
    const netHeight = height - HEADER.height;
    setContainerHeight(netHeight + 10);
  }, []);
  window.addEventListener("resize", () => {
    const height = window.innerHeight;
    const netHeight = height - HEADER.height;
    setContainerHeight(netHeight + 10);
  });
  //


  const handleOnboarding = () => {
    setTempContainer('onBoarding');
    setSidebarToggle(prevState => ({ ...!prevState, onBoarding: !sidebarToggle.onBoarding }))
  }
  const handleSidebarTab = (e) => {
    // setTempContainer('advertisingReport');
    // setSidebarToggle(prevState => ({ ...!prevState, advertisingReport: !sidebarToggle.advertisingReport }))
    dispatch(changeTab(e));
    setSidebarToggle({
      onBoarding: false,
      // advertisingReport: false,
    })
    setCurrentEl("");

  }

  const changeCurrentEl = (e, container) => {
    // console.log(e,container);
    // setContainer(container);
    dispatch(changeTab(container))
    setCurrentEl(e);
  }

  const closeSidebar = () => {
    setSidebarToggle({
      onBoarding: false,
      advertisingReport: false,
    });
  }


  return (
    <div className="dashboardsContainer" style={{ height: containerHeight }}>
      <DashboardsLeft width={60}>
        {/* <TooltipComponent text={"OnBoarding"} placement={'right'} > */}
        <TooltipComponent text={"onBoarding"} placement={'right'} >
          <div className={`dashboardLeftIcon-${container === "onBoarding" ? 'active' : ''}`}
            //  onClick={handleOnboarding}
            onClick={() => handleSidebarTab("onBoarding")}
          >
            <i className="bi bi-grid-1x2 iconsStoke"></i>
          </div>
        </TooltipComponent>


        <>
          {access && access.includes('daily_report') &&
            <TooltipComponent text={"Daily Report"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Daily View" ? 'active' : ''}`} onClick={() => handleSidebarTab("Daily View")}><i className="bi bi-bar-chart iconsStoke"></i></div>
            </TooltipComponent>
          }
          {access && access.includes('comparative_report') &&
            <TooltipComponent text={"Comparative Report"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Comparative View" ? 'active' : ''}`} onClick={() => handleSidebarTab("Comparative View")}><i className="bi bi-file-earmark-spreadsheet iconsStoke"></i></div>
            </TooltipComponent>
          }
          {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Daily Actions"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Daily Actions" ? 'active' : ''}`} onClick={() => handleSidebarTab("Daily Actions")}><i className="bi bi-clock-history iconsStoke"></i></div>
            </TooltipComponent>
          }
          {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Hourly Bids"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Hourly Bids" ? 'active' : ''}`} onClick={() => handleSidebarTab("Hourly Bids")}><i className="bi bi-hourglass-split iconsStoke"></i></div>
            </TooltipComponent>
          }
          {/* {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Bid Changes History"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Bid Changes History" ? 'active' : ''}`} onClick={() => handleSidebarTab("Bid Changes History")}><i className="bi bi-archive iconsStoke"></i></div>
            </TooltipComponent>
          } */}
          {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Paused Targets"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Paused Targets" ? 'active' : ''}`} onClick={() => handleSidebarTab("Paused Targets")}><PauseIcon /></div>
            </TooltipComponent>
          }
          {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Daily Targets"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Daily Targets" ? 'active' : ''}`} onClick={() => handleSidebarTab("Daily Targets")}><EventRepeatIcon /></div>
            </TooltipComponent>
          }
          {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Tools"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Tools" ? 'active' : ''}`} onClick={() => handleSidebarTab("Tools")}><CategoryRoundedIcon /></div>
            </TooltipComponent>
          }
          {/* {access && access.includes('daily_actions_report') &&
            <TooltipComponent text={"Marketing Stream"} placement={'right'} >
              <div className={`dashboardLeftIcon-${container === "Marketing Stream" ? 'active' : ''}`} onClick={() => handleSidebarTab("Marketing Stream")}><i class="bi bi-cash-coin iconsStoke"></i></div>
            </TooltipComponent>
          } */}
          <TooltipComponent text={"Benchmarks"} placement={'right'} >
            <div className={`dashboardLeftIcon-${container === "Benchmarks" ? 'active' : ''}`} onClick={() => handleSidebarTab("Benchmarks")}><i className="bi bi-speedometer2 iconsStoke"></i></div>
          </TooltipComponent>
          <TooltipComponent text={"Campaigns Manager"} placement={'right'} >
            <div className={`dashboardLeftIcon-${container === "Campaigns Manager" ? 'active' : ''}`} onClick={() => handleSidebarTab("Campaigns Manager")}><i className="bi bi-book-half iconsStoke"></i></div>
          </TooltipComponent>
        </>

        {/* {access &&
          access.includes('home_admin') && */}
        <div className="dashboardLeftIconSettingsContainer" >
          {
            <TooltipComponent text={"Settings"} placement={'right'} >
              <div className={`dashboardLeftIconSettings-${container === "settings" ? 'active' : ''}`} onClick={() => handleSidebarTab("settings")}><i className="bi bi-gear-fill"></i></div>
            </TooltipComponent>
          }
        </div>
        {/* } */}
      </DashboardsLeft>

      {/* <div style={{ height: containerHeight }}>
        <div onClick={handleOnboarding}>
          {
            tempContainer === "onBoarding" &&
            <div onMouseLeave={closeSidebar} className={`sidebar ${sidebarToggle.onBoarding ? 'open' : ''}`}>
              <div className="sidebarContainer">
                <h5 className="sidebarHeader">Onboarding</h5>
                {subMenu.onBordingMenu.length > 0 &&
                  subMenu.onBordingMenu.map((el) => {
                    return (
                      <div className="sidebarContent" key={el} onClick={() => changeCurrentEl(el, "onBoarding")}>
                        <div className={`sidebarContentEl-${currentEl === el ? "active" : ""
                          }`}>
                          {el}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          }
        </div>

        <div onClick={handleSidebarTab}>
          {
            tempContainer === "advertisingReport" &&
            <div onMouseLeave={closeSidebar} className={`sidebar ${sidebarToggle.advertisingReport ? 'open' : ''}`}>
              <div className="sidebarContainer">
                <h5 className="sidebarHeader">Advertising Reports</h5>
                {subMenu.ARmenu.length > 0 && subMenu.ARmenu.map((el) => {
                  return (
                    <div className="sidebarContent" key={el} onClick={() => changeCurrentEl(el, "advertisingReport")}>
                      <div className={`sidebarContentEl-${currentEl === el ? "active" : ""
                        }`}>
                        {el}
                      </div>
                    </div>
                  );
                })
                }
              </div>
            </div>
          }
        </div>
      </div> */}

      <DashboardsRight>
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          {/* {container === "onBoarding" && <OnBoardingContainer
            changeCurrentEl={changeCurrentEl}
            currentEl={currentEl}
          />} */}
          {container === "onBoarding" && <OnBoardingContainer2 />}
          {container === "Daily View" && <AdvertisingReportContainer />}
          {container === "Comparative View" && <ComparisonARContainer />}
          {container === "Daily Actions" && <BidChangesContainer />}
          {container === "Hourly Bids" && <HourlyBids />}
          {container === "Paused Targets" && <PausedBid />}
          {container === "Daily Targets" && <DailyTargets />}
          {container === "Benchmarks" && <BenchmarkContainer />}
          {container === "Campaigns Manager" && <CampaignContainer />}

          {container === "Tools" && <MainContainer />}
          {/* {container === "Marketing Stream" && <MarketingContainer />} */}
          {container === "Campaign" && <MarketingContainer />}
          {container === "settings" && <AdminContainer />}
          {/* {container === "Bid Changes History" && <HourlyBidsSummary />} */}
        </div>
      </DashboardsRight>
      <NotificationContainer />

    </div>
  );
};

export default DashboardsContainer;
