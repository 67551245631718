import React, { useState, useMemo, useEffect } from 'react';
import './MainContainer.scss';
import { AgGridReact } from 'ag-grid-react';
import { AG_AR, BASE_URL } from '../../../appConstants';
import { useSelector } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import axios from 'axios';
import ReportsErrorPage from '../../errorComponent/ReportsErrorPage';
import Loader from '../../commonComponent/Loader/Loader';
import { formatSingleDateFnWithYear } from '../../../commonFunction/commomFunction';
import { getTodaysDate } from '../../../utils/commonFunction';

const SearchVolume = () => {
    const appParams = useSelector(state => state.appParams);
    const { current_brand: { client_profile_id, main_category } } = appParams;
    const cat = main_category.split('|');
    const [rowData, setRowData] = useState();
    const [dateType, setDateType] = useState('Daily');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const [dateTypeArr, setDateTypeArr] = useState([
        { name: 'Daily', status: true },
        { name: 'Weekly', status: false },
        { name: 'Monthly', status: false },
    ])

    const [keywordCategories, setKeywordCategories] = useState([])
    const [category, setCategory] = useState()

    useEffect(() => {
        setKeywordCategories(cat);
        setCategory(cat[0])
    }, [client_profile_id])

    useEffect(() => {
        setIsLoading(true);
        if (!category) {
            setRowData([]);
            setIsLoading(false);
            return;
        }
        axios.post(`${BASE_URL}searchVolume`, {
            main_category: category,
            view: dateType,
            time_stamp: getTodaysDate()
            // time_stamp:'2024-03-18T00:00:00.000+00:00'
        }).then((res) => {
            const data = res.data.data.searchVolumeData;
            // console.log(data);
            if (res.status === 200 && data.length === 0) {
                setRowData(data);
                throw Error('No Search Volume comparision for this category for today.')
            }
            setError(false);
            setRowData(data);
        }).catch((err) => {
            console.log(err);
            setError(err.message)
        }).finally(() => {
            setIsLoading(false);
        })
    }, [category, dateType])

    const trendComp = (props) => {
        const { trend_analysis } = props.data;
        if (trend_analysis === 'Growth') {
            return (
                <div style={{ minWidth: '100px' }}>
                    <span className='growth_box'>{trend_analysis}</span>
                </div>
            )
        }
        return (
            <div>
                <span className='depraciate_box'>{trend_analysis}</span>
            </div>
        )
    }
    const rateComp = (props) => {
        const { at_rate_perc, trend_analysis } = props.data;
        if (trend_analysis === 'Growth') {
            return (
                <div>
                    <span className='growth_box'>{at_rate_perc}<i className='bi bi-arrow-up-short'></i></span>
                </div>
            )
        }
        return (
            <div>
                <span className='depraciate_box'>{at_rate_perc}<i className='bi bi-arrow-down-short'></i></span>
            </div>
        )
    }

    const defaultColDef = useMemo(() => ({ sortable: true, resizable: true, filter: true, flex: 1 }), []);
    const prevVolumeHeader = (view) => {
        if (view === 'Daily') {
            const date = new Date();
            const tMinus3 = new Date(date);
            tMinus3.setDate(date.getDate() - 3)
            // const daterange = tMinus3.toDateString()
            const daterange = tMinus3.toLocaleDateString('en-IN')
            return daterange
        } else if (view === 'Weekly') {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            const tMinus10 = new Date(date);
            tMinus10.setDate(date.getDate() - 2);
            const tMinus16 = new Date(date);
            tMinus16.setDate(date.getDate() - 9)
            const weekrange = `${tMinus16.toLocaleDateString('en-IN')} - ${tMinus10.toLocaleDateString('en-IN')}`
            return (weekrange)
        } else if (view === 'Monthly') {
            const date = new Date();
            const tMinus32 = new Date(date);
            tMinus32.setDate(date.getDate() - 32)
            const tMinus62 = new Date(date);
            tMinus62.setDate(date.getDate() - 62)
            const monthrange = `${tMinus62.toLocaleDateString('en-IN')} - ${tMinus32.toLocaleDateString('en-IN')}`
            return (monthrange)
        }
    }
    const currVolumeHeader = (view) => {
        if (view === 'Daily') {
            const date = new Date();
            const tMinus2 = new Date(date);
            tMinus2.setDate(date.getDate() - 2)
            const daterange = tMinus2.toLocaleDateString('en-IN')
            return daterange
        } else if (view === 'Weekly') {
            const date = new Date();
            const tMinus2 = new Date(date);
            tMinus2.setDate(date.getDate() - 2);
            const tMinus9 = new Date(date);
            tMinus9.setDate(date.getDate() - 9)
            const weekrange = `${tMinus9.toLocaleDateString('en-IN')} - ${tMinus2.toLocaleDateString('en-IN')}`
            return (weekrange)
        } else if (view === 'Monthly') {
            const date = new Date();
            const tMinus2 = new Date(date);
            tMinus2.setDate(date.getDate() - 2);
            const tMinus32 = new Date(date);
            tMinus32.setDate(date.getDate() - 31)
            const monthrange = `${tMinus32.toLocaleDateString('en-IN')} - ${tMinus2.toLocaleDateString('en-IN')}`
            return (monthrange)
        }
    }
    const MultiLineHeader = (headerText) => {
        // console.log(headerText);
        // const { column, displayName, sort, setSort, filter, setFilter } = props;

        // const onSortChanged = () => {
        //   if (sort === 'asc') {
        //     setSort('desc');
        //   } else if (sort === 'desc') {
        //     setSort('');
        //   } else {
        //     setSort('asc');
        //   }
        // };

        //     <div className="ag-header-cell">
        //     <div className="ag-header-cell-label">
        //       <span className="ag-header-cell-text">{displayName}</span>
        //       {column.getSort() ? (
        //         <div className={`ag-icon ag-sort-${column.getSort()}`}></div>
        //       ) : null}
        //     </div>
        //     <div className="ag-header-cell-filter">
        //       {filter && (
        //         <input
        //           type="text"
        //           value={filter}
        //           onChange={(e) => setFilter(e.target.value)}
        //           placeholder="Filter..."
        //         />
        //       )}
        //     </div>
        //     <div className="ag-header-cell-menu-button" onClick={onSortChanged}></div>
        //   </div>
        return (
            <div >
                {headerText?.displayName?.split('\n').map((line, index) => (
                    <div style={{ color: '#303030d4' }} key={index}>{line}</div>
                ))}
            </div>
        )

    }

    const columnDefs = useMemo(() => ([
        { headerName: 'Main Category', field: 'main_category' },
        { headerName: 'Brands', field: 'brand', },
        { headerName: `Search Volume\n${prevVolumeHeader(dateType)}`, field: 'total_search_volume_previous', headerComponentFramework: MultiLineHeader, },
        { headerName: `Search Volume\n${currVolumeHeader(dateType)}`, field: 'total_search_volume_current', headerComponentFramework: MultiLineHeader, },
        { headerName: 'Trend Analysis', field: 'trend_analysis', cellRenderer: trendComp },
        { headerName: 'At Rate (%)', field: 'at_rate_perc', cellRenderer: rateComp },
    ]), [dateType])

    const handleDWMChange = (e) => {
        const { name, value } = e.target;
        // console.log(name,value);
        setDateType(value);
        const newArr = dateTypeArr?.map((el) => {
            if (name === el.name) {
                return {
                    name: el.name,
                    status: !el.status
                }
            } else {
                return {
                    name: el.name,
                    status: false
                }
            }
        })
        setDateTypeArr(newArr);
    }
    const handleCatChange = (e) => {
        // console.log(e.target.value);
        setCategory(e.target.value);
    }

    return (
        <div>
            <div className='keyword_cat_container-content' >
                <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: '14px', marginRight: '10px', paddingTop: '3px' }}>Category: </div>
                    <select value={category} onChange={handleCatChange} name="" id="" className='form-select form-select-sm keyword_cat_container-select'>
                        {keywordCategories?.map((category, index) => {
                            return (
                                <option key={index} value={category}>{category}</option>
                            )
                        })}
                    </select>
                </div>
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        onChange={handleDWMChange}
                        aria-label="Platform"
                        // disabled={loader ? true : false}
                        sx={{ maxHeight: 28, boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px" }}
                    >
                        {
                            dateTypeArr?.map((el) => {
                                return (
                                    <ToggleButton key={el.name} name={el.name} value={el.name}>{el.name[0]}</ToggleButton>
                                )
                            })
                        }
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: 550, width: '100%' }}>
                {error ? <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ReportsErrorPage error={error} /></div> :
                    <AgGridReact
                        // ref={gridRef}
                        rowData={rowData}
                        rowHeight={AG_AR.rH}
                        headerHeight={60}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressNoRowsOverlay={true}
                        suppressDragLeaveHidesColumns={true}
                        suppressMenuHide={true}
                    >
                    </AgGridReact>
                }
                {
                    isLoading && <div style={{ position: "absolute", left: "calc(50% - 20px) ", top: "calc(50% - 15px)" }} >< Loader /></div>
                }
            </div>
        </div>
    )
}

export default SearchVolume