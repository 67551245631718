import { useState } from "react";

import "./Tile.css";
const Tiles = (props) => {
    const { tile, tgic, topBorderColor, showDetail, } = props;
    const [metricArray, setMetricArray] = useState(["cost", "cpc", "acos"]);

    const getFlowColorClass = (metric, flow) => {
        if (flow === "positive") {
            return metricArray.includes(metric) ? "negative" : "positive"
        } else if (flow === "negative") {
            return metricArray.includes(metric) ? "positive" : "negative"
        } else if (flow === "zero") {
            return "zero"
        }
    }

    return (
        <div className="tileAndIconContainer" >
            <div
                className={"tileContainer"}
                style={{ borderTop: topBorderColor ? `3px solid ${topBorderColor}` : "3px solid white" }}
                onClick={() => props.tileClickedFn(tile.name)}
            >
                <p style={{ borderBottom: showDetail ? "1px solid #372f44" : "" }} className="tileRow_1" >{tile.name === "cost" ? "SPEND" : `${tile.name}`.toUpperCase()}</p>
                {
                    showDetail && <>
                        <p className={`tileRow_2 ${getFlowColorClass(tile.name, tile.flow)}`} >
                            {tile.name === "sales" && "₹"}
                            {tile.name === "cost" && "₹"}
                            {tile.name === "cpc" && "₹"}
                            {tile.todays.toLocaleString('en-IN')}
                            {tile.name === "acos" && "%"}
                            {tile.name === "ctr" && "%"}
                            {tile.name === "conversion" && "%"}
                            {tile.name === "tos_is" && "%"}
                            {tile.flow === "positive" && <i className="bi bi-arrow-up-short"></i>}
                            {tile.flow === "negative" && <i className="bi bi-arrow-down-short"></i>}
                            {tile.flow === "zero"}
                        </p>
                        <p className="tileRow_3"  >L7D Avg: {tile.from_yesterday_pre_seven_days_avg.toLocaleString('en-IN')}</p>
                        <p className="tileRow_4" >({tile.percentage_change}%)</p>
                    </>
                }

            </div>
            <div className={tgic === tile.name ? "tileGraphIconContainer graphIconClicked" : "tileGraphIconContainer"}
            >
                {
                    tgic === tile.name ? <i onClick={() => props.tileGraphIconClickedFn(null)} className="bi bi-bar-chart"></i> : <i onClick={() => props.tileGraphIconClickedFn(tile.name)} className="bi bi-bar-chart"></i>
                }
            </div>
        </div >
    )
}

export default Tiles;