import React, { useState, useEffect, useRef } from 'react'
import YourBrandTable from './adminComponents/yourBrand/YourBrandTable';
import "./AdminContainer.css";
import { useSelector, useDispatch } from 'react-redux';
import NewUserModal from './adminComponents/inviteUser/NewUserModal';
import Profile from './adminComponents/yourProfile/Profile';
import axios from 'axios';
import { BASE_URL } from '../../../appConstants';
import { saveAppParamsData } from '../../../redux/appParams/appParamsActions';


const AdminContainer = () => {
    const popupRef = useRef();
    const dispatch = useDispatch();
    const appParams = useSelector(state => state.appParams);
    const user = useSelector(state => state.user);
    let { current_brand, brand_array, agency_array, current_agency } = appParams;
    const [currentBrand, setCurrentBrand] = useState("all");
    const [toggle, setToggle] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [profilePage, setProfilePage] = useState(false);
    const [currentAgency, setCurrentAgency] = useState('all');
    const [brands, setBrands] = useState([]);
    const token = localStorage.getItem('token');

    const [rowData, setRowData] = useState([]);

    // useEffect(() => {
    //     setCurrentAgency(current_agency._id);
    //     setCurrentBrand(current_brand);
    //     setBrands(brand_array);
    // }, []);

    //new user modal state
    const [ModalNewUser, setModalNewUser] = useState(false);

    //refresh brand table
    const [refreshBrandUser, setRefreshBrandUser] = useState(false);

    // modal fn
    const handleNewUser = () => {
        setModalNewUser(true);
    };

    const handleToggle = () => {
        setToggle(!toggle);
        setSearchQuery("");
    }
    const handleProfilePage = () => {
        setProfilePage(!profilePage);
    }
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        let url = `${BASE_URL}user/getUser`
        if (user.user_domain === 'enlytical_admin') {
            if (currentAgency === 'all' && currentBrand === 'all') {

            } else if (currentAgency != 'all' && currentBrand === 'all') {
                url += `?agencyId=${currentAgency}`
            } else if (currentAgency != 'all' && currentBrand != 'all') {
                url += `?brandId=${currentBrand}`
            }
        } else if (user.user_domain === "user") {
            const agency = agency_array[0];
            if (agency) {
                if (currentBrand === 'all') {
                    url += `?agencyId=${agency._id}`
                } else {
                    url += `?agencyId=${agency._id}&brandId=${currentBrand}`
                }
            } else {
                if (currentBrand) {
                    url += `?brandId=${currentBrand}`
                }
            }
        }

        axios.get(url, {
            headers: {
                token
            }
        }).then(response => {
            let { user_array } = response.data.data;
            // console.log(user_array);
            setRowData(user_array);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })

        // ////////////////
        // const handleClickOutside = (event) => {
        //     if (popupRef.current && !popupRef.current.contains(event.target)) {
        //         setToggle(false);
        //     }
        // };
        // document.addEventListener('mousedown', handleClickOutside);

        // return () => {
        //     document.removeEventListener('mousedown', handleClickOutside);
        // };
    }, [currentAgency, currentBrand, refreshBrandUser]);

    const handleBrandChange = (e) => {
        // console.log(e.target.value)
        setCurrentBrand(e.target.value);
    }
    const handleInputChange = (e) => {
        // console.log(e.target.value);
        setSearchQuery(e.target.value);
    };

    const handleAgencyChange = (e) => {
        setCurrentAgency(e.target.value);
        const current_agency = agency_array.find(agency => agency._id === e.target.value);
        dispatch(saveAppParamsData({ current_agency: current_agency }));
        setCurrentBrand("all");

        if (e.target.value === "all") {
            dispatch(saveAppParamsData({ brand_array: [] }))
            dispatch(saveAppParamsData({ current_brand: {} }))
        } else {
            axios.get(`${BASE_URL}brand?agencyId=${current_agency._id}`, {
                headers: {
                    token
                }
            }).then(res => {
                const { brand_array } = res.data.data;

                // console.log(brand_array);
                dispatch(saveAppParamsData({ brand_array: brand_array }))
                dispatch(saveAppParamsData({ current_brand: brand_array[0] }))
            }).catch(err => {
                console.log(err);
            })
        }
    }
    // console.log(rowData);
    const filterRowData = rowData.filter((el) => (el.first_name).toLowerCase().includes(searchQuery.toLowerCase()) || (el.last_name).toLowerCase().includes(searchQuery.toLowerCase()));
    const newArr = filterRowData.map(el => el._id);
    const newSet = new Set([...newArr]);


    if (agency_array.length === 0) {
        brand_array = brand_array.filter((el) => el.user_type === "brand_admin")
    }

    return (
        <>{
            current_brand.user_type !== "brand_viewer" ?
                <div className='admin-container'>
                    <div className='admin-container-header'>
                        {
                            !profilePage &&
                            <div className='admin-container-header-left'>
                                {
                                    user?.user_domain === 'enlytical_admin' &&
                                    <div className='agencySelect'>
                                        <label className='adminLabels' htmlFor="select">Entity:</label>
                                        <select className='form-select' value={currentAgency} onChange={handleAgencyChange} >
                                            <option key='all' value="all">All</option>
                                            {agency_array.length > 0 &&
                                                agency_array.map((el) => {
                                                    return <option key={el._id} value={el._id}>{el.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                }

                                <div ref={popupRef} className='brandSelect'>
                                    <label className='adminLabels' htmlFor="select">Brands: </label>
                                    <select className='form-select' value={currentBrand} onChange={handleBrandChange} >
                                        <option key='all' value="all">All</option>
                                        {brand_array.length > 0 &&
                                            brand_array.map((el) => {
                                                return <option key={el._id} value={el._id}>{el.client_name}</option>
                                            })
                                        }
                                    </select>

                                    {/* <div className="currentBrandNameAdmin" onClick={handleToggle}>{currentBrand?.client_name.charAt(0).toUpperCase() + currentBrand?.client_name.slice(1)} <span style={{ marginLeft: '20px' }}><i className="bi bi-chevron-down"></i></span></div>
                                    <div className="brandOptionAdmin" style={{ display: toggle ? 'block' : 'none' }}>
                                        <div className="selectBrandDetails1">
                                            <h5>{currentBrand?.client_name.charAt(0).toUpperCase() + currentBrand?.client_name.slice(1)}</h5>
                                            <div style={{ color: '#757575', fontSize: '14px' }}>ID: <span style={{ color: 'black' }}>{currentBrand?.entity_id}</span></div>
                                            <div style={{ color: '#757575', fontSize: '14px' }}>Marketplace: <span style={{ color: 'black' }}>{currentBrand?.country}</span></div>
                                        </div>
                                        {
                                            brand_array.length !== 1 &&
                                            <div className="searchInputContainer">
                                                <input className="brandSearchInput" type="text" value={searchQuery} onChange={handleInputChange} placeholder="search" />
                                            </div>
                                        }
                                        <hr style={{ marginBottom: '10px', marginTop: '0px' }} />
                                        <div className="brandListContainerAdmin">
                                            {brands.length > 0 &&
                                                searchResults.map((brand, i) => {
                                                    return (
                                                        <div
                                                            className={`brandList-${brand === currentBrand ? 'active' : ""}`}
                                                            key={i}
                                                            onClick={() => handleBrandChange(brand)}
                                                        >
                                                            {brand.client_name.charAt(0).toUpperCase() + brand.client_name.slice(1)}
                                                            <div style={{ fontSize: '11px' }}>{brand.country}</div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        }

                        <div className='admin-container-header-right'>
                            <div className='admin-container-header-right-content'>
                                <input type="text" className='form-control-sm' placeholder='Search By Username' value={searchQuery} onChange={handleInputChange} />
                                <button type='button' className='btn btn-sm mx-2 add-user-btn' onClick={handleNewUser}><i className="bi bi-person-plus-fill"></i> Invite User</button>
                            </div>
                            {/* <button type='button' className='btn btn-sm add-user-btn' onClick={handleProfilePage}>{profilePage ? <span><i className="bi bi-arrow-left"></i> Back</span> : <span><i className="bi bi-eye-fill"></i> View Users</span>}</button> */}
                            <NewUserModal
                                ModalNewUser={ModalNewUser}
                                setModalNewUser={setModalNewUser}
                                setRefreshBrandUser={setRefreshBrandUser}
                            />
                        </div>
                    </div>

                    <div className='admin-container-content'>
                        {
                            profilePage ?
                                <div>
                                    <Profile />
                                </div> :
                                <div>
                                    <YourBrandTable
                                        setRefreshBrandUser={setRefreshBrandUser}
                                        loading={loading}
                                        filterRowData={filterRowData}
                                    />
                                </div>
                        }
                    </div>
                    <div className='admin-container-footer'>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <div className='footer-div1'>Total Count: {filterRowData.length}</div>
                            <div className='footer-div2'>User Count: {newSet.size}</div>
                        </div>
                    </div>
                </div>
                :
                <div className='admin-subsititute-container'>
                    <div className='admin-box'>
                        <h4 >Sorry!! You do not have admin access for this brand to perform any actions in this panel.</h4>
                        <p>Please ask administrator for permission.</p>
                    </div>
                </div>
        }
        </>
    )
}

export default AdminContainer;