import React, { useEffect, useState, useMemo } from 'react';
import './YourBrandTable.css';
import { AgGridReact } from 'ag-grid-react';
import { formatDate, getFirstLetterUpper } from '../../../../../commonFunction/commomFunction';
import { Avatar } from '@mui/material';
import { USER_TYPE_TO_DISPLAY } from '../../../../../appConstants';
import Edit from './Edit';
import Loader from '../../../../commonComponent/Loader/Loader';
import { HEADER } from '../../../../../appUiConstants';
import { useSelector } from 'react-redux';

const YourBrandTable = (props) => {
    const { setRefreshBrandUser, loading, filterRowData } = props;
    const user = useSelector(state => state.user);

    // edit modal state
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    // modal data state
    const [modalData, setModalData] = useState({});

    //loader


    //To get the height for grid
    const [containerHeight, setContainerHeight] = useState();
    useEffect(() => {
        const height = window.innerHeight;
        const netHeight = height - HEADER.height - 105;
        setContainerHeight(netHeight);
    }, []);
    window.addEventListener("resize", () => {
        const height = window.innerHeight;
        const netHeight = height - HEADER.height - 105;
        setContainerHeight(netHeight);
    });
    //

    // const urls = [`${BASE_URL}user/getUser`,
    // `${BASE_URL}user/getUser?agencyId=${currentAgency}`,
    // `${BASE_URL}user/getUser?agencyId=${currentAgency}&brandId=${currentBrand}`]

    // useEffect(() => {
    //     setLoading(true);
    //     const token = localStorage.getItem("token");
    //     const fetchData = async () => {
    //         try {
    //             if (user.user_domain === "enlytical_admin") {
    //                 const allUser = await axios.get(`${BASE_URL}user/getUser?agencyId=${currentAgency}`, {
    //                     headers: {
    //                         token
    //                     }
    //                 })
    //                 const { user_array } = allUser.data.data;
    //                 setRowData(user_array);
    //                 // console.log(user_array);
    //                 setLoading(false);
    //             } else if (user.user_domain === 'user') {
    //                 const allUser = await axios.get(`${BASE_URL}user/getUser?agencyId=${currentAgency}&brandId=${currentBrand}`, {
    //                     headers: {
    //                         token
    //                     }
    //                 })
    //                 const { user_array } = allUser.data.data;
    //                 setRowData(user_array);
    //                 // console.log(user_array);
    //                 setLoading(false);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //   //  fetchData();
    //     // axios.get(`${BASE_URL}user/getUser`, {
    //     //     headers: {
    //     //         token
    //     //     }
    //     // }).then((response) => {
    //     //     console.log(response.data.data);
    //     //     const { user_array } = response.data.data
    //     //     // console.log(user_array);
    //     //     setRowData(user_array);
    //     //     setLoading(false);
    //     // }).catch((error) => {
    //     //     console.log('==>', error);
    //     //     setLoading(false);
    //     // })
    //     // })

    // }, [rowData])

    const arrayType = (brandArr, agencyArr) => {
        let obj;
        if (brandArr) {
            obj = brandArr[0];
        } else if (agencyArr) {
            obj = agencyArr[0];
        }
        return obj;
    }

    const UsernameComponent = (props) => {
        // console.log(props.data);
        const { first_name, last_name } = props.data
        const userName = (`${first_name} ${last_name}`);
        return (
            <div className='username-component'>
                <Avatar sx={{ color: '#6c757d' }} />
                <span style={{ marginLeft: "5px", color: 'rgb(0, 115, 199)', fontWeight: '600' }}>{userName && getFirstLetterUpper(userName)}</span>
            </div>
        )
    }

    const AccessComponent = (props) => {
        const { brand_array, agency_array } = props.data;
        return (
            <div>
                {USER_TYPE_TO_DISPLAY[arrayType(brand_array, agency_array).user_type]}
            </div>
        )
    }

    const CreatedOnComponent = (props) => {
        const { brand_array, agency_array } = props.data;
        return (
            <div>
                {formatDate(arrayType(brand_array, agency_array).created_at)}
            </div>
        )
    }

    const StatusComponent = (props) => {
        const { brand_array, agency_array } = props.data;

        return (
            <div className='status-admin'>
                {arrayType(brand_array, agency_array).status === true ? <div className='status-active'><i className='bi bi-circle-fill status-icon-active'></i> Active</div> : <div className='status-inactive'><i className='bi bi-circle-fill status-icon-inactive'></i> Inactive</div>}
            </div>
        )
    }

    const editPopup = (userData) => {
        // console.log(userData);
        setModalData(userData);
        setOpen(true);
    }
    // //console.log(modalData);

    const EditComponent = (props) => {
        return (
            <>
                <button onClick={() => editPopup(props.data)} className='btn btn-sm admin-edit-btn'>
                    <i className="bi bi-pencil-square"></i> Edit User
                </button>
                {/* <button onClick={handleProfilePage} className='btn btn-sm admin-delete-btn'>
                    <i className="bi bi-eye"></i> View Profile
                </button> */}
            </>
        )
    }
    const AgencyComponent = (props) => {
        const { brand_array, agency_array } = props.data;
        // console.log(brand_array);
        // let obj = arrayType(brand_array, agency_array);
        // console.log(obj);

        return (
            <div>
                {agency_array ? agency_array[0]?.agency_name : brand_array[0]?.agency_name}
            </div>
        )
    }

    const BrandComponent = (props) => {
        const { brand_array, agency_array } = props.data;
        return (
            <div>
                {(brand_array && brand_array[0]?.brand_name) || (agency_array && "All Brands")}
            </div>
        )
    }


    const columnDefs = useMemo(() => [
        { headerName: "Username", field: "first_name", cellRenderer: UsernameComponent },
        { headerName: "E-mail", field: 'email', cellClass: "email-cell" },
        { headerName: "Agency Name", field: '', cellRenderer: AgencyComponent },
        { headerName: "Brand Name", field: '', cellRenderer: BrandComponent },
        { headerName: "Role", field: '', cellRenderer: AccessComponent },
        { headerName: "Created On", field: '', cellRenderer: CreatedOnComponent },
        { headerName: "Status", field: '', cellRenderer: StatusComponent },
        { headerName: "Actions", field: "", cellRenderer: EditComponent }
    ], [])

    const defaultColDef = useMemo(() => ({
        sortable: true, resizable: true, flex: 1, minWidth: 100
    }), []);

    // const brandHandler = (brandId) => {
    //     setCurrentBrand(brandId)
    // }

    return (
        <>
            {
                loading ? <div style={{ position: "absolute", top: '50%', left: '50%' }}><Loader /></div> : (
                    <>
                        <div className='your-brand-container'>
                            {/* <div className='all-brands'>
                                {
                                    brand_array.map((brand) => {
                                        return <div className={brand._id === currentBrand ? "active-brand" : "brand"} key={brand._id} onClick={() => brandHandler(brand._id)}>{getFirstLetterUpper(brand.brand_name)}</div>
                                    })
                                }
                            </div> */}
                            <div className="ag-theme-alpine" style={{ height: containerHeight }}>
                                <AgGridReact
                                    rowData={filterRowData}
                                    columnDefs={columnDefs}
                                    rowHeight={50}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    suppressDragLeaveHidesColumns={true}
                                    suppressMenuHide={true}
                                // domLayout='autoHeight'
                                />
                            </div>
                            {
                                open && <Edit open={open}
                                    handleClose={handleClose}
                                    modalData={modalData}
                                    setModalData={setModalData}
                                    setRefreshBrandUser={setRefreshBrandUser} />
                            }

                        </div>
                    </>
                )
            }
        </>

    );
};

export default YourBrandTable;