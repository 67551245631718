import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import "../src/scss/main.scss";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./utils/agGridStyles.css";

import Login from "./components/auth/Login";
import './App.css';
import Home from './components/home/Home';
import Otp from "./components/auth/Otp";
import User from "./components/auth/User";
import DashboardsContainer from "./components/Modules/DashboardsContainer";

// import BrandHealth from "./components/Modules/powerBiDashboard/powerBiDashboards/BrandHealth";
// import PowerBiDashboardContainer from "./components/Modules/powerBiDashboard/PowerBiDashboardContainer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { saveUserData } from "./redux/user/userActions";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { saveAppParamsData } from "./redux/appParams/appParamsActions";
import { BASE_URL } from "./appConstants";
import Loader from "./components/commonComponent/Loader/Loader";
import InactiveUserPage from './components/errorComponent/InactiveUserPage.jsx';
// import LoadingPage from './components/commonComponent/Loader/LoadingPage.jsx';
import ForgetPassword from './components/auth/ForgetPassword.jsx';
import ClientListPage from './components/clientListPage/ClientListPage.jsx';

function App() {
  const user = useSelector(state => state.user);
  // const { user_domain } = user;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    navigate("loading");
    const fetchData = async () => {
      try {
        const getUser = await axios.get(`${BASE_URL}user`, {
          headers: {
            token
          }
        })
        const { user } = getUser.data.data;
        dispatch(saveUserData(user));
        // console.log(user);

        if (user.user_domain === 'enlytical_admin') {
          const getAgency = await axios.get(`${BASE_URL}agency`, {
            headers: {
              token
            }
          })
          const { agency_array } = getAgency.data.data;
          const { _id } = agency_array[0];
          const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
            headers: {
              token
            }
          })
          const { brand_array } = getBrand.data.data;
          let current_brand = brand_array[0];
          if (brand_array?.length) {
            let currentBrandInLocal = localStorage.getItem("currentBrand");
            if (currentBrandInLocal) {
              for (let b of brand_array) {
                if (b._id === currentBrandInLocal) {
                  current_brand = b;
                  break;
                }
              }
            }
          }
          dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
          if (brand_array.length === 0) {
            navigate("/inactiveUser");
          } else {
            navigate("/home/app");
          }

        } else if (user.user_domain === "user") {
          const getAgency = await axios.get(`${BASE_URL}agency`, {
            headers: {
              token
            }
          })
          const { agency_array } = getAgency.data.data;
          // console.log(agency_array);
          if (agency_array.length === 0) {
            const getBrand = await axios.get(`${BASE_URL}brand`, {
              headers: {
                token
              }
            })
            const { brand_array } = getBrand.data.data;
            // console.log(brand_array);
            let current_brand = brand_array[0];
            if (brand_array?.length) {
              let currentBrandInLocal = localStorage.getItem("currentBrand");
              if (currentBrandInLocal) {
                for (let b of brand_array) {
                  if (b._id === currentBrandInLocal) {
                    current_brand = b;
                    break;
                  }
                }
              }
            }
            dispatch(saveAppParamsData({ agency_array, current_agency: [], brand_array, current_brand: current_brand }));
            if (brand_array.length === 0) {
              navigate("/inactiveUser");
            } else {
              navigate("/home/app");
            }
          } else if (agency_array.length > 0) {
            const { _id } = agency_array[0];
            const getBrand = await axios.get(`${BASE_URL}brand?agencyId=${_id}`, {
              headers: {
                token
              }
            })
            const { brand_array } = getBrand.data.data;
            let current_brand = brand_array[0];
            if (brand_array?.length) {
              let currentBrandInLocal = localStorage.getItem("currentBrand");
              if (currentBrandInLocal) {
                for (let b of brand_array) {
                  if (b._id === currentBrandInLocal) {
                    current_brand = b;
                    break;
                  }
                }
              }
            }
            dispatch(saveAppParamsData({ agency_array, current_agency: agency_array[0], brand_array, current_brand: current_brand }));
            if (brand_array.length === 0) {
              navigate("/inactiveUser");
            } else {
              navigate("/home/app");
            }
          }
        }


      } catch (error) {
        if (token) {
          NotificationManager.error(`${error.response.data.data.message} Please Login.`, 'Error', 2000);
        }
        navigate("/user/login");
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="App">
      <Routes>
        <Route path="user" element={<User />} >
          <Route path="login" element={<Login />} />
          {/* <Route path="signup" element={<Signup />} /> */}
          <Route path="forgetPassword" element={<ForgetPassword />} />
          <Route path="verifyOtp" element={<Otp />} />
        </Route>
        {
          user.email && <>
            <Route path="home" element={<Home />} >
              {/* <Route path="app" element={<ClientListPage />} /> */}
              {/* <Route path=':id' element={<DashboardsContainer />} /> */}
              <Route path='app' element={<DashboardsContainer />} />
            </Route>
          </>
        }

        <Route path="loading" element={<Loader />} />
        <Route path="inactiveUser" element={<InactiveUserPage />} />
      </Routes>
      <NotificationContainer />
    </div >
  );
}

export default App;
