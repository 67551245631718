import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import './Keywords.css';
import SalesKeywords from './subComponents/SalesKeywords';
import CategoryKeywords from './subComponents/CategoryKeywords';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Keywords() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
            {/* <div className='instruction_header'>
                    <span style={{ color: '#b8b6b6', fontSize: "15px", paddingLeft: '18px' }}>Instructions:</span>
                    <ul style={{ color: '#b8b6b6', fontSize: "12px", margin: 0 }}>
                        <li>Please double click on the "Targeting","Type","Priority rank" columns to edit the value.</li>
                    </ul>
                </div> */}
            {/* <div>
                    <button style={{ marginRight: "10px" }} type='button' className='cancel_btn'>Cancel</button>
                    <button type='button' className='submit_btn'>Submit</button>
                </div> */}
            {/* </div> */}

            {/* <Box sx={{ width: '100%' }}> */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab sx={{ fontSize: '11px' }} label="Top sales Keywords" {...a11yProps(0)} />
                    <Tab sx={{ fontSize: '11px' }} label="Top category keywords" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <SalesKeywords />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CategoryKeywords />
            </CustomTabPanel>
        </div>
    )
}

export default Keywords;
