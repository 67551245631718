import React, { useState, useEffect, useRef, Suspense } from 'react';
import './ComparisonARContainer.css';
import { addDays } from 'date-fns';
import MultiDateRange from '../../commonComponent/DateRangePicker/MultiDateRange';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModalComponent from '../../commonComponent/Modal/ModalComponent';
import { formatDateFn } from '../../../commonFunction/commomFunction';
import { asin_row_height } from '../../../appConstants';
// import Pagination from '../../commonComponent/pagination/Pagination';
import Loader from '../../commonComponent/Loader/Loader';
const ComparisionDynamicTable = React.lazy(() => import('./ComparisonARComponents/ComparisionDynamicTable'));

const ComparisonARContainer = () => {
    const popupRef = useRef();
    const [dateRange, setDateRange] = useState(null);
    const [dateRangeToggle, setDateRangeToggle] = useState(false);
    // filter State
    const [fs, setFs] = useState({
        fs_category: [],
        fs_asin: [],
        fs_ad_type: [],
        fs_target_type: [],
        fs_placement: [],
        fs_keyword_type: [],
        fs_campaign: [],
        fs_keyword_targeting: []
    });
    const { fs_category, fs_asin, fs_ad_type, fs_target_type, fs_placement, fs_keyword_type, fs_campaign, fs_keyword_targeting } = fs;

    // pagination states
    const [docCount1, setDocCount1] = useState();
    const [currentPage1, setCurrentPage1] = useState(1);
    const [rowPerPage1, setRowPerPage1] = useState(20);

    const [docCount2, setDocCount2] = useState();
    const [currentPage2, setCurrentPage2] = useState(1);
    const [rowPerPage2, setRowPerPage2] = useState(20);

    const kpiArr1 = [
        { id: "sales", name: "Sales", checked: true },
        { id: "cost", name: "Spend", checked: false },
        { id: "acos", name: "ACOS", checked: false },
        { id: "roas", name: "ROAS", checked: false },
        { id: "impressions", name: "Impressions", checked: false },
        { id: "clicks", name: "Clicks", checked: false },
        { id: "cpc", name: "CPC", checked: false },
        { id: "ctr", name: "CTR", checked: false },
        { id: "orders", name: "Orders", checked: false },
        { id: "conversions", name: "Conversions", checked: false },
        { id: "tos", name: "TOS_IS", checked: false }
    ]
    const kpiArr2 = [
        { id: "sales", name: "Sales", checked: true },
        { id: "cost", name: "Spend", checked: false },
        { id: "acos", name: "ACOS", checked: false },
        { id: "roas", name: "ROAS", checked: false },
        { id: "impressions", name: "Impressions", checked: false },
        { id: "clicks", name: "Clicks", checked: false },
        { id: "cpc", name: "CPC", checked: false },
        { id: "ctr", name: "CTR", checked: false },
        { id: "orders", name: "Orders", checked: false },
        { id: "conversions", name: "Conversions", checked: false },
    ]

    const [columnState0, setColumnState0] = useState(kpiArr2);
    const [columnState1, setColumnState1] = useState(kpiArr1);
    const [columnState2, setColumnState2] = useState(kpiArr1);
    const [columnState3, setColumnState3] = useState(kpiArr1);
    const [columnState4, setColumnState4] = useState(kpiArr1);
    const [columnState5, setColumnState5] = useState(kpiArr2);
    const [columnState6, setColumnState6] = useState(kpiArr2);
    const [columnState7, setColumnState7] = useState(kpiArr1);
    const [columnState8, setColumnState8] = useState(kpiArr2);

    useEffect(() => {
        setDateRange({
            selection1: {
                startDate: addDays(new Date(), -14),
                endDate: addDays(new Date(), -8),
                key: 'selection1'
            },
            selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: 'selection2'
            }
        });

        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setDateRangeToggle(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // modal state
    const [isModalOpen, setIsModalOpen] = useState(false);

    const modalOpen = () => {
        if (
            dateRangeToggle ||
            (dateRange?.selection1?.startDate)?.getDate() !== (addDays(new Date(), -14)).getDate() ||
            (dateRange?.selection1?.endDate)?.getDate() !== (addDays(new Date(), -8)).getDate() ||
            (dateRange?.selection2?.startDate)?.getDate() !== (addDays(new Date(), -7)).getDate() ||
            (dateRange?.selection2?.endDate)?.getDate() !== (addDays(new Date(), -1)).getDate() ||
            fs_category.length > 0 ||
            fs_asin.length > 0 ||
            fs_ad_type.length > 0 ||
            fs_target_type.length > 0 ||
            fs_placement.length > 0 ||
            fs_keyword_type.length > 0 ||
            fs_campaign.length > 0 ||
            fs_keyword_targeting.length > 0 ||
            columnState1.filter(el => el.checked === true).length !== 1 ||
            columnState1.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState2.filter(el => el.checked === true).length !== 1 ||
            columnState2.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState3.filter(el => el.checked === true).length !== 1 ||
            columnState3.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState4.filter(el => el.checked === true).length !== 1 ||
            columnState4.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState5.filter(el => el.checked === true).length !== 1 ||
            columnState5.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState6.filter(el => el.checked === true).length !== 1 ||
            columnState6.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState7.filter(el => el.checked === true).length !== 1 ||
            columnState7.filter(el => el.checked === true)[0].id !== 'sales' ||
            columnState8.filter(el => el.checked === true).length !== 1 ||
            columnState8.filter(el => el.checked === true)[0].id !== 'sales'
        ) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    };
    const modalClose = () => setIsModalOpen(false);

    const removeAllFilter = () => {
        setFs({
            fs_category: [],
            fs_asin: [],
            fs_ad_type: [],
            fs_target_type: [],
            fs_placement: [],
            fs_keyword_type: [],
            fs_campaign: [],
            fs_keyword_targeting: []
        });
        setDateRangeToggle(false);
        setDateRange({
            selection1: {
                startDate: addDays(new Date(), -14),
                endDate: addDays(new Date(), -8),
                key: 'selection1'
            },
            selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: 'selection2'
            }
        });
        setColumnState1([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
            { id: "tos", name: "TOS_IS", checked: false }

        ]);
        setColumnState2([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
            { id: "tos", name: "TOS_IS", checked: false }
        ]);
        setColumnState3([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
            { id: "tos", name: "TOS_IS", checked: false }

        ]);
        setColumnState4([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
            { id: "tos", name: "TOS_IS", checked: false }
        ]);
        setColumnState5([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
        ]);
        setColumnState6([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
        ]);
        setColumnState7([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
            { id: "tos", name: "TOS_IS", checked: false }
        ]);
        setColumnState8([
            { id: "sales", name: "Sales", checked: true },
            { id: "cost", name: "Spend", checked: false },
            { id: "acos", name: "ACOS", checked: false },
            { id: "roas", name: "ROAS", checked: false },
            { id: "impressions", name: "Impressions", checked: false },
            { id: "clicks", name: "Clicks", checked: false },
            { id: "cpc", name: "CPC", checked: false },
            { id: "ctr", name: "CTR", checked: false },
            { id: "orders", name: "Orders", checked: false },
            { id: "conversions", name: "Conversions", checked: false },
            // { id: "tos", name: "TOS_IS", checked: false }
        ]);

        setIsModalOpen(false);
    }

    const [arScroll, setArScroll] = useState(false);
    let scrollRowMasterTimer;

    const onArScroll = (e) => {
        const top = e.target.scrollTop;
        clearTimeout(scrollRowMasterTimer);
        scrollRowMasterTimer = setTimeout(() => {
            if (top > 0) {
                setArScroll(true);
            } else {
                setArScroll(false);
            }

        }, 10);
    }

    const onDateRangeApply = (data) => {
        setDateRange(data);
        setDateRangeToggle(false);
    }

    const closeDateRange = () => {
        setDateRangeToggle(false);
    }

    return (
        <div className='ComparisonARContainer' onScroll={onArScroll}>
            <div style={{ backgroundColor: arScroll ? "#f2f2f2" : "", boxShadow: arScroll ? "rgba(0, 0, 0, 0.15) 0px 1.95px 2.6px" : "" }} className="ComparisonARStickyContainer">
                <div className='ComparisonARContainer_row1'>
                    <h1>Comparative Report</h1>
                    <div className='ComparisonARContainer_row1Filter'>
                        <div className="row_1Filter" >
                            <div className='row_1FilterBtn'>
                                <div
                                    onClick={modalOpen}
                                    className={(
                                        fs_category.length > 0 ||
                                        fs_asin.length > 0 ||
                                        fs_ad_type.length > 0 ||
                                        fs_target_type.length > 0 ||
                                        fs_placement.length > 0 ||
                                        fs_keyword_type.length > 0 ||
                                        fs_campaign.length > 0 ||
                                        fs_keyword_targeting.length > 0 ||
                                        (dateRange?.selection1?.startDate)?.getDate() !== (addDays(new Date(), -14)).getDate() ||
                                        (dateRange?.selection1?.endDate)?.getDate() !== (addDays(new Date(), -8)).getDate() ||
                                        (dateRange?.selection2?.startDate)?.getDate() !== (addDays(new Date(), -7)).getDate() ||
                                        (dateRange?.selection2?.endDate)?.getDate() !== (addDays(new Date(), -1)).getDate() ||
                                        dateRangeToggle ||
                                        columnState1.filter(el => el.checked === true).length !== 1 ||
                                        columnState1.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState2.filter(el => el.checked === true).length !== 1 ||
                                        columnState2.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState3.filter(el => el.checked === true).length !== 1 ||
                                        columnState3.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState4.filter(el => el.checked === true).length !== 1 ||
                                        columnState4.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState5.filter(el => el.checked === true).length !== 1 ||
                                        columnState5.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState6.filter(el => el.checked === true).length !== 1 ||
                                        columnState6.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState7.filter(el => el.checked === true).length !== 1 ||
                                        columnState7.filter(el => el.checked === true)[0].id !== 'sales' ||
                                        columnState8.filter(el => el.checked === true).length !== 1 ||
                                        columnState8.filter(el => el.checked === true)[0].id !== 'sales'
                                        // columnState.filter(el => el.checked === true).length !== 1 || columnState.filter(el => el.checked === true)[0].id !== 'sales'
                                    ) ? "removeAllFilterClicked removeAllFilter" : "removeAllFilter"}
                                >
                                    <i style={{ marginRight: '5px' }}>
                                        <i className="bi bi-arrow-counterclockwise" ></i>
                                    </i>
                                    Reset
                                </div>
                                {
                                    isModalOpen &&
                                    <ModalComponent open={isModalOpen} modalClose={modalClose}>
                                        <div className='modal_text'>Do you really want to reset ?</div>
                                        <div className='modal_button_container'>
                                            <button className='modal_button_cancel' onClick={modalClose}>Cancel</button>
                                            <button className='modal_button_ok' onClick={removeAllFilter}>Reset</button>
                                        </div>
                                    </ModalComponent>
                                }

                            </div>
                        </div>
                        <div ref={popupRef}>
                            {dateRange &&
                                <div className='dateRangeDisplayDateContainer' onClick={() => setDateRangeToggle(prevState => !prevState)}>
                                    <CalendarMonthIcon sx={{ marginRight: '5px' }} />
                                    <div className='dateRangeDisplayDate'>
                                        <div className='dateRangeDisplayDate_1'>
                                            {formatDateFn(dateRange.selection1.startDate, dateRange.selection1.endDate)}
                                        </div>
                                        <div className='dateRangeDisplayDate_2'>
                                            {formatDateFn(dateRange.selection2.startDate, dateRange.selection2.endDate)}
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                dateRangeToggle ? (
                                    <div className='dateRangeDisplay'>
                                        {
                                            dateRange &&
                                            <MultiDateRange
                                                dateRange={dateRange}
                                                setDateRange={setDateRange}
                                                onDateRangeApply={onDateRangeApply}
                                                closeDateRange={closeDateRange}
                                            />
                                        }
                                    </div>
                                ) : " "
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* metric comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>

                            <ComparisionDynamicTable
                                heading={'Metric-wise Comparison'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'metric'}
                                depandency_array={[]}
                                height={'320px'}
                                columnState={columnState0}
                                setColumnState={setColumnState0}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* category comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Category'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'category'}
                                depandency_array={[]}
                                selectRow={fs.fs_category}
                                selectFsName={"fs_category"}
                                setFs={setFs}
                                height={'250px'}
                                columnState={columnState1}
                                setColumnState={setColumnState1}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* ad-type comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Ad-Type'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'campaign_type'}
                                depandency_array={[
                                    fs.fs_category,
                                    // fs.fs_asin
                                ]}
                                selectRow={fs.fs_ad_type}
                                selectFsName={"fs_ad_type"}
                                setFs={setFs}
                                height={'250px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    // asin_array: fs.fs_asin
                                }}
                                columnState={columnState2}
                                setColumnState={setColumnState2}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* targeting comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Targeting Type'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'type'}
                                depandency_array={[
                                    fs.fs_category,
                                    // fs.fs_asin,
                                    fs.fs_ad_type
                                ]}
                                selectRow={fs.fs_target_type}
                                selectFsName={"fs_target_type"}
                                setFs={setFs}
                                height={'250px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    // asin_array: fs.fs_asin,
                                    campaign_type_array: fs.fs_ad_type
                                }}
                                columnState={columnState3}
                                setColumnState={setColumnState3}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* keyword comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Keyword Type'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'keyword_type'}
                                depandency_array={[
                                    fs.fs_category,
                                    // fs.fs_asin,
                                    fs.fs_ad_type,
                                    fs.fs_target_type
                                ]}
                                selectRow={fs.fs_keyword_type}
                                selectFsName={"keyword_type"}
                                setFs={setFs}
                                height={'300px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    // asin_array: fs.fs_asin,
                                    campaign_type_array: fs.fs_ad_type,
                                    targeting_type_array: fs.fs_target_type
                                }}
                                columnState={columnState4}
                                setColumnState={setColumnState4}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* placement comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Placement'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'placement'}
                                depandency_array={[
                                    fs.fs_category,
                                    // fs.fs_asin,
                                    fs.fs_ad_type,
                                    fs.fs_target_type,
                                    // fs.fs_keyword_type
                                ]}
                                selectRow={fs.fs_placement}
                                selectFsName={"fs_placement"}
                                setFs={setFs}
                                height={'200px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    // asin_array: fs.fs_asin,
                                    campaign_type_array: fs.fs_ad_type,
                                    targeting_type_array: fs.fs_target_type
                                }}
                                columnState={columnState5}
                                setColumnState={setColumnState5}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* campaign comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Campaign'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'campaign_id'}
                                depandency_array={[fs.fs_category, fs.fs_ad_type, fs.fs_target_type]}
                                selectRow={fs.fs_campaign}
                                selectFsName={"fs_campaign"}
                                setFs={setFs}
                                height={'400px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    campaign_type_array: fs.fs_ad_type,
                                    targeting_type_array: fs.fs_target_type,
                                }}
                                columnState={columnState7}
                                setColumnState={setColumnState7}
                                setDocCount={setDocCount1}
                                currentPage={currentPage1}
                                rowPerPage={rowPerPage1}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* asin comparison table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={'Comparison by Asin'}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'ad_asin'}
                                selectRow={fs.fs_asin}
                                selectFsName={"fs_asin"}
                                setFs={setFs}
                                height={'400px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    campaign_type_array: fs.fs_ad_type,
                                    campaign_id_array: fs.fs_campaign
                                }}
                                depandency_array={[fs.fs_category, fs.fs_ad_type, fs.fs_campaign]}
                                columnState={columnState6}
                                setColumnState={setColumnState6}
                                rowHeight={asin_row_height}
                            />
                        </Suspense>
                    }
                </div>
            </div>

            {/* on Keyword level table */}
            <div className='ComparisonARContainer_row2'>
                <div>
                    {
                        dateRange &&
                        <Suspense fallback={<Loader />}>
                            <ComparisionDynamicTable
                                heading={"Comparison by Search-term"}
                                dateRangeOne={dateRange.selection1}
                                dateRangeTwo={dateRange.selection2}
                                group_by={'keyword'}
                                depandency_array={[fs.fs_category, fs.fs_ad_type, fs.fs_target_type, fs.fs_campaign, fs.fs_keyword_type]}
                                selectRow={fs.fs_keyword_targeting}
                                selectFsName={"fs_keyword_targeting"}
                                setFs={setFs}
                                height={'400px'}
                                filterObj={{
                                    category_array: fs.fs_category,
                                    campaign_type_array: fs.fs_ad_type,
                                    targeting_type_array: fs.fs_target_type,
                                    campaign_id_array: fs.fs_campaign,
                                    keyword_type_array: fs.fs_keyword_type
                                }}
                                columnState={columnState8}
                                setColumnState={setColumnState8}
                                setDocCount={setDocCount2}
                                currentPage={currentPage2}
                                rowPerPage={rowPerPage2}
                            />
                        </Suspense>
                    }
                </div>
            </div>
        </div>
    )
}

export default ComparisonARContainer;