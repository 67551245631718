import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './OnBoardingContainer2.css';
import ProductMaster from './components/ProductMaster';
import SellersList from './components/SellersList';
import Targets from './components/Targets';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import Keywords from './components/Keywords';
import Competition from './components/Competition';
import MonthlyBudget from './components/MonthlyBudgets';
import ProductPriority from './components/ProductPriority';
// import NewBrands from './components/NewBrands';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function OnBoardingContainer2() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='onboarding_container'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '10px' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Products Master" {...a11yProps(0)} />
                        <Tab label="Sellers" {...a11yProps(1)} />
                        <Tab label="Monthly Budgets" {...a11yProps(2)} />
                        <Tab label="Keyword" {...a11yProps(3)} />
                        <Tab label="Competition" {...a11yProps(4)} />
                        {/* <Tab label="Product priority" {...a11yProps(5)} /> */}
                        {/* <Tab label="Targets" {...a11yProps(2)} /> */}
                        {/* <Tab label="New Brands" {...a11yProps(3)} /> */}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <ProductMaster />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <SellersList />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    {/* <PrimeReactProvider>
                        <Targets />
                    </PrimeReactProvider> */}
                    <MonthlyBudget />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Keywords />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <Competition />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <ProductPriority />
                </CustomTabPanel>
                {/* <CustomTabPanel value={value} index={3}>
                    <PrimeReactProvider>
                        <NewBrands />
                    </PrimeReactProvider>
                </CustomTabPanel> */}
            </Box>
        </div>
    );
}
export default OnBoardingContainer2;
