import { getMontheInText } from "../../../utils/commonFunction";
import "./MonthPicker.css";
const MonthPicker = (props) => {
    const { monthYearArray, monthYearArrayWeek, graphDataType } = props;
    return (
        <div className="monthPicker" >
            {graphDataType === 'monthly' ? <>
                {
                    monthYearArray?.map(el => {
                        let month = el.month_year.split("-")[0];
                        let year = el.month_year.split("-")[1];
                        return (
                            <div key={el.month_year} className="monthPickerMonth">
                                <input style={{ paddingLeft: 10 }} type="checkbox" key={el.month_year} onClick={() => props.onMonthSelect(el.month_year)} defaultChecked={el.selected} ></input>
                                <div style={{ marginLeft: 10, padding: '0px 10px' }} >
                                    {getMontheInText(month)} - {year}
                                </div>
                            </div>
                        )
                    })
                }
            </> : <>
                {
                    monthYearArrayWeek?.map(el => {
                        let month = el.month_year.split("-")[0];
                        let year = el.month_year.split("-")[1];
                        return (
                            <div key={el.month_year} className="monthPickerMonth">
                                <input style={{ paddingLeft: 10 }} type="checkbox" key={el.month_year} onClick={() => props.onWeekSelect(el.month_year)} defaultChecked={el.selected} ></input>
                                <div style={{ marginLeft: 10, padding: '0px 10px' }} >
                                    {getMontheInText(month)} - {year}
                                </div>
                            </div>
                        )
                    })
                }
            </>
            }

        </div>
    )
}

export default MonthPicker;