import React, { useState, useEffect, useRef, Suspense } from "react";
import axios from "axios";
import "./AdvertisingReportContainer.css"
import LineGraphComponent from "./ARComponents/LineGraphComponent";
import Tile from "./ARComponents/Tile";
import { useSelector } from "react-redux";
import { BASE_URL, asin_row_height } from "../../../appConstants";
import Loader from "./../../commonComponent/Loader/Loader";
import { getYesterdaysDate } from "../../../utils/commonFunction";
import DateRangePickerComponent from "../../commonComponent/DateRangePicker/DateRangePicker";
import { addDays } from 'date-fns';
// import PlacementGraph from "./ARComponents/PlacementGraph";
// import AdTypeGraph from "./ARComponents/AdTypeGraph";
// import PlacementGraphMonthly from "./ARComponents/PlacementGraphMonthly";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LineGraphComponentFilter from "./ARComponents/LineGraphComponentFilter";
import { AsinComponent, ProductNameComponent } from "../advertisingReport/ARComponents/cellRenderer";
import ModalComponent from "../../commonComponent/Modal/ModalComponent";
import { formatDateFn, getMonthInTextArray, getMonthYearArray } from "../../../commonFunction/commomFunction";
import ReportsErrorPage from "../../errorComponent/ReportsErrorPage";
import MonthPicker from "../../commonComponent/DateRangePicker/MonthPicker";
// import Skeleton from '@mui/material/Skeleton';
import Pagination from "../../commonComponent/pagination/Pagination";
import SkeletonLoader from "../../commonComponent/SkeletonLoader/Skeleton";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
const GridComponent = React.lazy(() => import("./ARComponents/GridComponent"));
const DynamicGrid = React.lazy(() => import("./ARComponents/dynamicGrid/DynamicGrid"));
const CampaignTable = React.lazy(() => import("./ARComponents/CampaignTable"));

const AdvertisingReportContainer = (props) => {
    const popupRef = useRef();
    const token = localStorage.getItem("token");
    const user = useSelector(state => state.user);
    const appParams = useSelector(state => state.appParams);
    const { current_brand } = appParams;

    const [adType, setAdType] = useState([]);
    const [tableType, setTableType] = useState("metric");
    const [filter1, setFilter1] = useState([
        { name: "Daily", status: false },
        { name: "Weekly", status: false },
        { name: "Monthly", status: false },
    ]);

    const [loader, setLoader] = useState(false);
    const [graphDataType, setGraphDataType] = useState("daily");//graphDataType,setGraphDataType
    const [state, setState] = useState({
        tile_array: [],
    });
    //state for filters
    const [fs, setFs] = useState({
        fs_category: [],
        fs_asin: [],
        fs_ad_type: [],
        fs_target_type: [],
        fs_placement: [],
        fs_keyword_type: [],
        fs_campaign_type: [],
        fs_keyword_targeting: [],
        fs_range: []
    });
    const [arScroll, setArScroll] = useState(false);
    const [clickedTile, setClickedTile] = useState([
        { id: "sales", borderColor: "rgba(221, 119, 3, 1)", backgroundColor: "rgba(221, 119, 3, 0.6)" },
        { id: 'cost', borderColor: "rgba(163, 49, 123,1)", backgroundColor: "rgba(163, 49, 123,0.6)" },
        { id: 'acos', borderColor: "rgba(46, 140, 184, 1)", backgroundColor: "rgba(46, 140, 184, 0.6)" },
    ]);
    const [tgic, setTileGraphIconClicked] = useState('sales');//tileGraphIconClicked,setTileGraphIconClicked
    const [lineGraphErrorToggle, setLineGraphErrorToggle] = useState(false);
    const [toggleDateRange, setToggleDateRange] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [dataViewToggle, setDataViewToggle] = useState({
        graphToggle: true,
        catToggle: true,
        asinToggle: true,
        adTypeToggle: true,
        targetToggle: true,
        placementToggle: true,
        rangeToggle: true,
    });
    const [error, setError] = useState(null);
    const [monthYearArray, setMonthYearArray] = useState(null);
    const [monthYearArrayWeek, setMonthYearArrayWeek] = useState(null);

    // pagination states
    // const [docCount1, setDocCount1] = useState();
    // const [currentPage1, setCurrentPage1] = useState(1);
    // const [rowPerPage1, setRowPerPage1] = useState(20);
    // const [docCount2, setDocCount2] = useState();
    // const [currentPage2, setCurrentPage2] = useState(1);
    // const [rowPerPage2, setRowPerPage2] = useState(20);


    // modal state
    const [isModalOpen, setIsModalOpen] = useState(false);

    const modalOpen = () => {
        if (
            toggleDateRange ||
            tgic !== 'sales' ||
            graphDataType !== 'daily' ||
            fs.fs_category.length > 0 ||
            fs.fs_asin.length > 0 ||
            fs.fs_ad_type.length > 0 ||
            fs.fs_target_type.length > 0 ||
            fs.fs_placement.length > 0 ||
            fs.fs_keyword_type.length > 0 ||
            fs.fs_campaign_type.length > 0 ||
            fs.fs_keyword_targeting.length > 0 ||
            fs.fs_range.length > 0 ||
            !(clickedTile.filter(el => ["sales", "cost", "acos"].includes(el.id)).length === 3 && clickedTile.length === 3) ||
            !dataViewToggle.graphToggle ||
            !dataViewToggle.catToggle ||
            !dataViewToggle.asinToggle ||
            !dataViewToggle.adTypeToggle ||
            !dataViewToggle.targetToggle ||
            !dataViewToggle.placementToggle ||
            !dataViewToggle.rangeToggle ||
            ((dateRange[0]?.startDate)?.getDate() !== (addDays(new Date(), -15)).getDate() || (dateRange[0]?.endDate)?.getDate() !== (addDays(new Date(), -1)).getDate())
        ) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    };
    const modalClose = () => setIsModalOpen(false);

    useEffect(() => {
        setFs({
            fs_category: [],
            fs_asin: [],
            fs_ad_type: [],
            fs_target_type: [],
            fs_placement: [],
            fs_keyword_type: [],
            fs_campaign_type: [],
            fs_keyword_targeting: [],
            fs_range: []
        });

    }, [current_brand._id])

    const removeAllFilter = () => {
        setToggleDateRange(false);
        setClickedTile([
            { id: "sales", borderColor: "rgba(221, 119, 3, 1)", backgroundColor: "rgba(221, 119, 3, 0.6)" },
            { id: 'cost', borderColor: "rgba(163, 49, 123,1)", backgroundColor: "rgba(163, 49, 123,0.6)" },
            { id: 'acos', borderColor: "rgba(46, 140, 184, 1)", backgroundColor: "rgba(46, 140, 184, 0.6)" },
        ]);
        setTileGraphIconClicked('sales');
        setGraphDataType('daily');
        setFs({
            fs_category: [],
            fs_asin: [],
            fs_ad_type: [],
            fs_target_type: [],
            fs_placement: [],
            fs_keyword_type: [],
            fs_campaign_type: [],
            fs_keyword_targeting: [],
            fs_range:[]
        })
        setFilter1([
            { name: "Daily", status: false },
            { name: "Weekly", status: false },
            { name: "Monthly", status: false },
        ]);
        setDataViewToggle({
            graphToggle: true,
            catToggle: true,
            asinToggle: true,
            adTypeToggle: true,
            targetToggle: true,
            placementToggle: true,
            rangeToggle:true
        });
        setDateRange([
            {
                startDate: addDays(new Date(), -15),
                endDate: addDays(new Date(), - 1),
                key: 'selection',
                name: "Daily"
            },
        ]);

        setIsModalOpen(false);
        setGraphTableToggle('graph');
        setDWMtoggle('');
    }

    let scrollRowMasterTimer;

    const onArScroll = (e) => {
        const top = e.target.scrollTop;
        clearTimeout(scrollRowMasterTimer);
        scrollRowMasterTimer = setTimeout(() => {
            if (top > 0) {
                setArScroll(true);
            } else {
                setArScroll(false);
            }

        }, 10);
    }
    useEffect(() => {
        setDateRange([
            {
                startDate: addDays(new Date(), -15),
                endDate: addDays(new Date(), - 1),
                key: 'selection',
                name: "Daily"
            },
        ]);
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setToggleDateRange(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    useEffect(() => {
        axios.post(`${BASE_URL}dashboard/advertisingReport/getTileData?brandId=${current_brand._id}`, {
            time_stamp: getYesterdaysDate()
        }, {
            headers: {
                token
            }
        }).then(function (response) {
            const { tile_array } = response.data.data;
            if (response.status === 400) {
                throw new Error('SORRY!! we do not have tile data for this brand!!')
            }
            setError(false);
            setState(prevState => ({ ...prevState, tile_array }));
        }).catch(function (error) {
            console.log(error);
            setError(error.message);
        });
        setState({ tile_array: [] });
    }, [current_brand?._id])

    const filterClicked1 = (e) => {
        const id = e.target.id;
        let selected;
        const newFilter = filter1.map(el => {
            if (id === el.name) {
                selected = !el.status;
                return {
                    name: el.name,
                    status: !el.status
                }
            } else {
                return {
                    name: el.name,
                    status: false
                }
            }
        })
        setFilter1(newFilter);
        // localStorage.setItem('filter1', JSON.stringify(newFilter));
        setFs({
            fs_category: [],
            fs_asin: [],
            fs_ad_type: [],
            fs_target_type: [],
            fs_placement: [],
            fs_keyword_type: [],
            fs_campaign_type: [],
            fs_keyword_targeting: [],
            fs_range:[]
        })
        if (selected === true) {
            setGraphDataType(`${id}`.toLowerCase())
            // console.log("if")
        } else {
            setGraphDataType('daily');
            // console.log("else")
        }
        // setDataViewToggle(!dataViewToggle);
        if (e.target.id === "Daily" && selected) {
            setDataViewToggle(prevState => ({
                ...prevState,
                catToggle: false,
                asinToggle: false,
                adTypeToggle: false,
                targetToggle: false,
                placementToggle: false,
                rangeToggle:false
            }));
        } else if (e.target.id === "Weekly" && selected) {
            setDataViewToggle(prevState => ({
                ...prevState,
                catToggle: false,
                asinToggle: false,
                adTypeToggle: false,
                targetToggle: false,
                placementToggle: false,
                rangeToggle:false
            }));
            let monthYearArray = getMonthYearArray(4, "weekly");
            setMonthYearArrayWeek(monthYearArray);
        } else if (e.target.id === "Monthly" && selected) {
            setDataViewToggle(prevState => ({
                ...prevState,
                catToggle: false,
                asinToggle: false,
                adTypeToggle: false,
                targetToggle: false,
                placementToggle: false,
                rangeToggle:false
            }));
            let monthYearArray = getMonthYearArray(4, "monthly");
            setMonthYearArray(monthYearArray);
        } else {
            setDateRange([
                {
                    startDate: addDays(new Date(), -15),
                    endDate: addDays(new Date(), - 1),
                    key: 'selection',
                    name: "Daily"
                },
            ]);
            setDataViewToggle({
                graphToggle: true,
                catToggle: true,
                asinToggle: true,
                adTypeToggle: true,
                targetToggle: true,
                placementToggle: true,
                rangeToggle:true
            });
        }
    }


    const tileClickedFn = (id) => {
        const colorArray = [
            { borderColor: "rgba(221, 119, 3, 1)", backgroundColor: "rgba(221, 119, 3, 0.6)" },
            { borderColor: "rgba(163, 49, 123,1)", backgroundColor: "rgba(163, 49, 123,0.6)" },
            { borderColor: "rgba(46, 140, 184, 1)", backgroundColor: "rgba(46, 140, 184, 0.6)" },
            { borderColor: "rgba(88, 112, 33, 1)", backgroundColor: "rgba(88, 112, 33, 0.6)" }
        ]//RGB code for color
        const arr = [...clickedTile];
        const index = arr.findIndex(obj => obj.id === id) //indexOf(id);
        if (index === -1) {
            if (arr.length < 4) {
                arr.push({ id });
                arr.forEach((el, i) => {
                    let { borderColor, backgroundColor } = colorArray[i]
                    el.borderColor = borderColor;
                    el.backgroundColor = backgroundColor;
                })
            } else {
                setLineGraphErrorToggle(true);
                setTimeout(() => {
                    setLineGraphErrorToggle(false);
                }, 2000);
            }
        } else {
            arr.splice(index, 1);
        }
        setClickedTile(arr);
    }

    const tileGraphIconClickedFn = (id) => {
        setTileGraphIconClicked(id);
    }

    const onDateRangeApply = (item) => {
        // let { startDate, endDate } = item[0]
        // localStorage.setItem("sd", startDate);
        // localStorage.setItem("ed", endDate);
        setDateRange(item);
        setToggleDateRange(false)
    }
    const dateRangePickerOpen = () => {
        setToggleDateRange(true)
    }
    const dateRangePickerClose = () => {
        setToggleDateRange(false)
    }

    const changeTableType = (e) => {
        setTableType(e.target.value);
    }

    const toggleDataView = (e) => {
        // console.log(e);
        if (e === 'metric') {
            setDataViewToggle(prevState => ({ ...prevState, graphToggle: !dataViewToggle.graphToggle }));
        }
        if (e === 'category') {
            setDataViewToggle(prevState => ({ ...prevState, catToggle: !dataViewToggle.catToggle }));
        }
        if (e === 'asin') {
            setDataViewToggle(prevState => ({ ...prevState, asinToggle: !dataViewToggle.asinToggle }));
        }
        if (e === 'adType') {
            setDataViewToggle(prevState => ({ ...prevState, adTypeToggle: !dataViewToggle.adTypeToggle }));
        }
        if (e === 'target') {
            setDataViewToggle(prevState => ({ ...prevState, targetToggle: !dataViewToggle.targetToggle }));
        }
        if (e === 'placement') {
            setDataViewToggle(prevState => ({ ...prevState, placementToggle: !dataViewToggle.placementToggle }));
        }
        if (e === 'range') {
            setDataViewToggle(prevState => ({ ...prevState, rangeToggle: !dataViewToggle.rangeToggle }));
        }
    }


    const onMonthSelect = (month_year) => {
        // console.log(monthYearArray);
        let mya = structuredClone(monthYearArray);
        // console.log(month_year)
        mya.forEach(el => {
            if (el.month_year === month_year) {
                el.selected = !el.selected
            }
        })
        setMonthYearArray(mya);
    }
    const onWeekSelect = (month_year) => {
        let myaw = structuredClone(monthYearArrayWeek);
        myaw.forEach(el => {
            if (el.month_year === month_year) {
                el.selected = !el.selected
            }
        })
        setMonthYearArrayWeek(myaw);
    }
    //State Variables
    const { fs_category, fs_asin, fs_ad_type, fs_target_type, fs_placement, fs_keyword_type, fs_campaign_type, fs_keyword_targeting, fs_range } = fs;

    const [graphTableToggle, setGraphTableToggle] = useState('graph');
    function handleGraphTableToggle(e, value) {
        setGraphTableToggle(value);
        setDataViewToggle(prevState => ({ ...prevState, graphToggle: !dataViewToggle.graphToggle }));
    }

    const [DWMtoggle, setDWMtoggle] = useState('');
    function handleDWMChange(e, value) {
        setDWMtoggle(value);
    }

    return (
        <div id="advertisingReportContainer" className='advertisingReportContainer' onScroll={onArScroll} >
            <div style={{ backgroundColor: arScroll ? "#f2f2f2" : "", boxShadow: arScroll ? "rgba(0, 0, 0, 0.1) 0px 1.95px 2px" : "" }} className='advertisingReportStickyContainer' >
                <div className="advertisingReportContainerRow_2" >
                    <div className="row_heading">
                        <h1>Daily Report</h1>
                    </div>

                    <div className="row_Filters">
                        <div className="row_1Filter" >
                            <div
                                onClick={modalOpen}
                                className={(
                                    toggleDateRange ||
                                    tgic !== 'sales' ||
                                    graphDataType !== 'daily' ||
                                    fs.fs_category.length > 0 ||
                                    fs.fs_asin.length > 0 ||
                                    fs.fs_ad_type.length > 0 ||
                                    fs.fs_target_type.length > 0 ||
                                    fs.fs_placement.length > 0 ||
                                    fs.fs_keyword_type.length > 0 ||
                                    fs.fs_campaign_type.length > 0 ||
                                    fs.fs_keyword_targeting > 0 ||
                                    fs.fs_range.length > 0 ||
                                    !(clickedTile.filter(el => ["sales", "cost", "acos"].includes(el.id)).length === 3 && clickedTile.length === 3) ||
                                    !dataViewToggle.graphToggle ||
                                    !dataViewToggle.catToggle ||
                                    !dataViewToggle.asinToggle ||
                                    !dataViewToggle.adTypeToggle ||
                                    !dataViewToggle.targetToggle ||
                                    !dataViewToggle.placementToggle ||
                                    !dataViewToggle.rangeToggle ||
                                    ((dateRange[0]?.startDate)?.getDate() !== (addDays(new Date(), -15)).getDate() || (dateRange[0]?.endDate)?.getDate() !== (addDays(new Date(), -1)).getDate())
                                ) ? "removeAllFilterClicked removeAllFilter" : "removeAllFilter"}
                            >
                                <i style={{ marginRight: '5px' }}>
                                    <i className="bi bi-arrow-counterclockwise" ></i>
                                </i>
                                Reset
                            </div>
                            {
                                isModalOpen &&
                                <ModalComponent open={isModalOpen} modalClose={modalClose}>
                                    <div className="modal_text">Do you really want to reset ?</div>
                                    <div className="modal_button_container">
                                        <button className="modal_button_cancel" onClick={modalClose}>Cancel</button>
                                        <button className="modal_button_ok" onClick={removeAllFilter}>Reset</button>
                                    </div>
                                </ModalComponent>
                            }
                        </div>
                        <div className="row_4Filter" >
                            {/* {
                                filter1.map((e) => {
                                    return (
                                        // <button
                                        //     disabled={loader ? true : false}
                                        //     className={e.status ? "filterClicked filter" : "filter"}
                                        //     key={e.name}
                                        //     id={e.name}
                                        //     onClick={filterClicked1}
                                        // >{e.name[0]}</button>
                                    )
                                })
                            } */}
                            <ToggleButtonGroup
                                color="primary"
                                value={DWMtoggle}
                                exclusive
                                onChange={handleDWMChange}
                                aria-label="Platform"
                                disabled={loader ? true : false}
                                sx={{ maxHeight: 28, boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 4px" }}
                            >
                                {filter1.map((el) => {
                                    return (
                                        <ToggleButton sx={{ color: '#525252' }} key={el.name} id={el.name} value={el.name} onClick={filterClicked1}>{el.name[0]}</ToggleButton>
                                    )
                                })}
                            </ToggleButtonGroup>

                        </div>

                        <div ref={popupRef} className="dateRangeContainer">
                            {
                                dateRange.length > 0 &&
                                <div onClick={() => setToggleDateRange(prevState => !prevState)} className={`advertisingReportContainerRow_2_date_display ${toggleDateRange ? "date_display_active" : ""}`} >
                                    <CalendarMonthIcon sx={{ marginRight: '5px', height: '20px' }} />
                                    {
                                        dateRange.length && graphDataType === "daily" && <div style={{ minWidth: 170 }} >{formatDateFn(dateRange[0].startDate, dateRange[0].endDate)}</div>
                                    }
                                    {
                                        monthYearArrayWeek && graphDataType === "weekly" && <div style={{ minWidth: 170 }} >{getMonthInTextArray(monthYearArrayWeek)}</div>
                                    }
                                    {
                                        monthYearArray && graphDataType === "monthly" && <div style={{ minWidth: 170 }} >{getMonthInTextArray(monthYearArray)}</div>
                                    }
                                </div>
                            }
                            <div style={{ display: toggleDateRange ? "block" : "none" }} className="advertisingReportContainerRow_2_date_range" >
                                {
                                    dateRange.length && graphDataType === "daily" && <DateRangePickerComponent
                                        dateRange={dateRange}
                                        onDateRangeApply={onDateRangeApply}
                                        dateRangePickerOpen={dateRangePickerOpen}
                                        dateRangePickerClose={dateRangePickerClose}
                                    />
                                }
                                {
                                    monthYearArrayWeek && graphDataType === "weekly" && <MonthPicker
                                        onWeekSelect={onWeekSelect}
                                        monthYearArrayWeek={monthYearArrayWeek}
                                        graphDataType={graphDataType}
                                    />
                                }
                                {
                                    monthYearArray && graphDataType === "monthly" && <MonthPicker
                                        onMonthSelect={onMonthSelect}
                                        monthYearArray={monthYearArray}
                                        graphDataType={graphDataType}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advertisingReportContainerRow_3" >
                    {
                        error ? <ReportsErrorPage error={error} /> :
                            <>
                                {
                                    state.tile_array.length ? state.tile_array.map(tile => {
                                        const [status] = clickedTile.filter(obj => obj.id === tile.name);
                                        return <Tile
                                            clicked={status}
                                            clickedClass={status ? `clickedTile-${tile.name}` : ""}
                                            topBorderColor={status ? status.borderColor : ""}
                                            tileClickedFn={tileClickedFn}
                                            tileGraphIconClickedFn={tileGraphIconClickedFn}
                                            tgic={tgic}
                                            key={tile.name}
                                            tile={tile}
                                            showDetail={!arScroll}
                                            clickedTile={clickedTile}
                                        />
                                    }) : <div>Loading...</div>
                                    // <div style={{ display: 'flex' }}>
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    //     <Skeleton sx={{ marginRight: 2 }} variant="rectangular" width={115} height={80} />
                                    // </div>
                                }
                            </>
                    }
                </div>
            </div>

            <div className="advertisingReportContainerRow_5">
                <div className="graphTableToggleBtn" >
                    <ToggleButtonGroup
                        color="primary"
                        value={graphTableToggle}
                        exclusive
                        onChange={handleGraphTableToggle}
                        aria-label="Platform"
                        sx={{ maxHeight: 30, boxShadow: "rgba(0, 0, 0, 0.10) 0px 1px 4px" }}
                    >
                        <ToggleButton value="graph"><i className="bi bi-graph-up"></i></ToggleButton>
                        <ToggleButton value="table"><i className="bi bi-table"></i></ToggleButton>
                    </ToggleButtonGroup>
                </div>

                {
                    dataViewToggle.graphToggle ?
                        <div className="advertisingReportContainerRow_5_graph">
                            {
                                lineGraphErrorToggle && <div className="lineGraphComponentError"> <p>You cannot select more than four KPIs.</p></div>
                            }
                            {
                                ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) && <LineGraphComponent
                                    clickedTile={clickedTile}
                                    adType={adType}
                                    graphDataType={graphDataType}
                                    tgic={tgic}
                                    setLoader={setLoader}
                                    loader={loader}
                                    dateFilter={dateRange[0]}
                                    group_by={"category"}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            }
                        </div>
                        :
                        <div className="advertisingReportContainerRow_5_table">
                            {
                                ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                                <Suspense fallback={<Loader />}>
                                    <DynamicGrid
                                        dateFilter={dateRange[0]}
                                        graphDataType={graphDataType}
                                        group_by={"metric"}
                                        fs={fs}
                                        setFs={setFs}
                                        depandency_array={[]}
                                        adType={adType}
                                        height={300}
                                        monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                    />
                                </Suspense>
                            }
                        </div>
                }
            </div>


            {/* category wise table */}
            <div className="advertisingReportContainerRow_16" >
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >Category wise performance</span>
                </div>
                {
                    dataViewToggle.catToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                adType={adType}
                                filterObj={{}}
                                depandency_array={[]}
                                setFs={setFs}
                                height={250}
                                selectRow={fs.fs_category}
                                selectFsName={"fs_category"}
                                group_by={"category"}
                                startingColumn={[{ headerName: "Category", field: 'category', width: 200, pinned: true, cellClass: 'color-column' }]}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_category}
                                    selectFsName={"fs_category"}
                                    group_by={"category"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[]}
                                    adType={adType}
                                    height={250}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            </Suspense>
                        }
                    </>
                }
                <div className={`fg-container ${fs_category.length > 0 ? "open" : ""}`}>
                    {
                        fs_category.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            depandency_array={[fs_category]}
                            fs={fs}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"category"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />

                    }
                </div>
            </div>


            
            {/* Range Table and Graph */}
            <div className="advertisingReportContainerRow_16">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >Range wise performance</span>
                </div>
                {
                    dataViewToggle.placementToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                filterObj={{
                                    category_array: fs_category,
                                    // asin_array: fs_asin,
                                    // campaign_type_array: fs_ad_type,
                                    // targeting_type_array: fs_target_type
                                }}
                                setFs={setFs}
                                height={300}
                                selectRow={fs.fs_range}
                                selectFsName={"fs_range"}
                                group_by={"range"}
                                depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                startingColumn={[{ headerName: 'Range', field: 'range', width: 200, pinned: true, cellClass: 'color-column' },
                                ]}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    filterObj={{
                                        category_array: fs_category,
                                        // asin_array: fs_asin,
                                        // campaign_type_array: fs_ad_type,
                                        // targeting_type_array: fs_target_type
                                    }}
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_range}
                                    selectFsName={"fs_range"}
                                    group_by={"range"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                    height={300}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            </Suspense>
                        }
                    </>
                }
                <div className={`fg-container ${fs_range.length > 0 ? "open" : ""}`}>
                    {
                        fs_range.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                // fs_asin,
                                fs_ad_type,
                                fs_target_type,
                                fs_keyword_type,
                                fs_placement,
                                fs_range
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"range"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />

                    }
                </div>
            </div>


            {/* ad-type wise table */}
            <div className="advertisingReportContainerRow_16">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >Ad-Type wise performance</span>
                </div>
                {
                    dataViewToggle.adTypeToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                filterObj={{
                                    category_array: fs_category,
                                }}
                                setFs={setFs}
                                height={220}
                                selectRow={fs.fs_ad_type}
                                selectFsName={"fs_ad_type"}
                                group_by={"campaign_type"}
                                depandency_array={[fs_category,]}
                                startingColumn={[{ headerName: 'Ad Type', field: 'campaign_type', width: 200, pinned: true, cellClass: 'color-column' }]}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    filterObj={{
                                        category_array: fs_category,
                                        // asin_array: fs_asin
                                    }}
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_ad_type}
                                    selectFsName={"fs_ad_type"}
                                    group_by={"campaign_type"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[fs_category]}
                                    height={220}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            </Suspense>
                        }
                    </>
                }

                <div className={`fg-container ${fs_ad_type.length > 0 ? "open" : ""}`}>
                    {
                        fs_ad_type.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                fs_ad_type
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"campaign_type"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />
                    }
                </div>
            </div>

            {/* Target-type Table and Graph */}
            <div className="advertisingReportContainerRow_16">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >Targeting wise performance</span>
                </div>
                {
                    dataViewToggle.targetToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                filterObj={{
                                    category_array: fs_category,
                                    campaign_type_array: fs_ad_type
                                }}
                                setFs={setFs}
                                height={220}
                                selectRow={fs.fs_target_type}
                                selectFsName={"fs_target_type"}
                                group_by={"type"}
                                depandency_array={[fs_category, fs_ad_type]}
                                startingColumn={[{ headerName: 'Targeting Type', field: 'type', width: 200, pinned: true, cellClass: 'color-column' },
                                    //  { headerName: 'Ad-Type', field: 'campaign_type', width: 125, pinned: true, cellClass: 'color-column' }
                                ]}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    filterObj={{
                                        category_array: fs_category,
                                        campaign_type_array: fs_ad_type
                                    }}
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_target_type}
                                    selectFsName={"fs_target_type"}
                                    group_by={"type"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[fs_category, fs_ad_type]}
                                    height={220}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            </Suspense>
                        }
                    </>
                }

                <div className={`fg-container ${fs_target_type.length > 0 ? "open" : ""}`}>
                    {
                        fs_target_type.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            // filter={filter}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                fs_ad_type,
                                fs_target_type
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"type"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />
                    }
                </div>
            </div>

            {/* Keyword Table and Graph */}
            <div className="advertisingReportContainerRow_16">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >Keyword Type wise performance</span>
                </div>
                {
                    dataViewToggle.placementToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                filterObj={{
                                    category_array: fs_category,
                                    campaign_type_array: fs_ad_type,
                                    targeting_type_array: fs_target_type,
                                }}
                                setFs={setFs}
                                height={250}
                                selectRow={fs.fs_keyword_type}
                                selectFsName={"fs_keyword_type"}
                                group_by={"keyword_type"}
                                depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                startingColumn={[{ headerName: 'Keyword Type', field: 'keyword_type', width: 200, pinned: true, cellClass: 'color-column' },
                                    //  { headerName: 'Ad-Type', field: 'campaign_type', width: 125, pinned: true, cellClass: 'color-column' }
                                ]}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    filterObj={{
                                        category_array: fs_category,
                                        campaign_type_array: fs_ad_type,
                                        targeting_type_array: fs_target_type
                                    }}
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_keyword_type}
                                    selectFsName={"fs_keyword_type"}
                                    group_by={"keyword_type"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                    height={250}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            </Suspense>
                        }
                    </>
                }
                <div className={`fg-container ${fs_keyword_type.length > 0 ? "open" : ""}`}>
                    {
                        fs_keyword_type.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                fs_ad_type,
                                fs_target_type,
                                fs_keyword_type
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"keyword_type"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />

                    }
                </div>
            </div>

            {/* Placement Table and Graph */}
            <div className="advertisingReportContainerRow_16">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >Placement wise performance</span>
                </div>
                {
                    dataViewToggle.placementToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                filterObj={{
                                    category_array: fs_category,
                                    // asin_array: fs_asin,
                                    campaign_type_array: fs_ad_type,
                                    targeting_type_array: fs_target_type
                                }}
                                setFs={setFs}
                                height={200}
                                selectRow={fs.fs_placement}
                                selectFsName={"fs_placement"}
                                group_by={"placement"}
                                depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                startingColumn={[{ headerName: 'Placement', field: 'placement', width: 200, pinned: true, cellClass: 'color-column' },
                                    //  { headerName: 'Ad-Type', field: 'campaign_type', width: 125, pinned: true, cellClass: 'color-column' }
                                ]}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    filterObj={{
                                        category_array: fs_category,
                                        // asin_array: fs_asin,
                                        campaign_type_array: fs_ad_type,
                                        targeting_type_array: fs_target_type
                                    }}
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_placement}
                                    selectFsName={"fs_placement"}
                                    group_by={"placement"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                    height={200}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                />
                            </Suspense>
                        }
                    </>
                }
                <div className={`fg-container ${fs_placement.length > 0 ? "open" : ""}`}>
                    {
                        fs_placement.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                // fs_asin,
                                fs_ad_type,
                                fs_target_type,
                                fs_keyword_type,
                                fs_placement
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"placement"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />

                    }
                </div>
            </div>

            {/* camapign-wise-table */}
            <div className="advertisingReportContainerRow_16">
                <span className="advertisingReportTableHeading">Campaign wise performance</span>
                <div>
                    {
                        dataViewToggle.asinToggle ?
                            <Suspense fallback={<Loader />}>
                                <CampaignTable
                                    filterObj={{
                                        category_array: fs_category,
                                        // asin_array: fs_asin,
                                        campaign_type_array: fs_ad_type,
                                        targeting_type_array: fs_target_type,
                                        //  placement_array: fs_placement
                                    }}
                                    depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                    adType={adType}
                                    selectRow={fs.fs_campaign_type}
                                    selectFsName={"fs_campaign_type"}
                                    group_by={"campaign_id"}
                                    setFs={setFs}
                                // setDocCount={setDocCount1}
                                // currentPage={currentPage1}
                                // rowPerPage={rowPerPage1}
                                />
                            </Suspense>
                            : <>
                                {
                                    ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                                    <Suspense fallback={<Loader />}>
                                        <DynamicGrid
                                            filterObj={{
                                                category_array: fs_category,
                                                // asin_array: fs_asin,
                                                campaign_type_array: fs_ad_type,
                                                targeting_type_array: fs_target_type,
                                            }}
                                            dateFilter={dateRange[0]}
                                            graphDataType={graphDataType}
                                            tableType={tableType}
                                            changeTableType={changeTableType}
                                            selectRow={fs.fs_campaign_type}
                                            selectFsName={"fs_campaign_type"}
                                            group_by={"campaign_id"}
                                            fs={fs}
                                            setFs={setFs}
                                            depandency_array={[fs_category, fs_ad_type, fs_target_type]}
                                            height={400}
                                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                        // setDocCount={setDocCount1}
                                        // currentPage={currentPage1}
                                        // rowPerPage={rowPerPage1}
                                        />
                                    </Suspense>
                                }
                            </>
                    }
                </div>
                {/* <Pagination
                    docCount={docCount1}
                    currentPage={currentPage1}
                    setCurrentPage={setCurrentPage1}
                    rowPerPage={rowPerPage1}
                    setRowPerPage={setRowPerPage1}
                /> */}
                <div className={`fg-container ${fs_campaign_type.length > 0 ? "open" : ""}`}>
                    {
                        fs_campaign_type.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                fs_ad_type,
                                fs_target_type,
                                fs_keyword_type,
                                fs_placement,
                                fs_campaign_type
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"campaign_type"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />
                    }
                </div>
            </div>

            {/* asin wise table */}
            <div className="advertisingReportContainerRow_16">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: '60px' }} >
                    <span className="advertisingReportTableHeading" >ASIN wise performance</span>
                </div>
                {
                    dataViewToggle.asinToggle ? <>
                        <Suspense fallback={<Loader />}>
                            <GridComponent
                                filterObj={{
                                    category_array: fs_category,
                                    campaign_type_array: fs_ad_type,
                                    campaign_id_array: fs_campaign_type
                                }}
                                setFs={setFs}
                                height={400}
                                selectRow={fs.fs_asin}
                                selectFsName={"fs_asin"}
                                group_by={"ad_asin"}
                                depandency_array={[fs_category, fs_ad_type, fs_campaign_type]}
                                startingColumn={[{ headerName: 'Asin', field: 'ad_asin', width: 125, cellRenderer: AsinComponent, pinned: true }, { headerName: 'Product Name', field: 'product_name', width: 125, cellRenderer: ProductNameComponent, pinned: true, cellClass: 'color-column' }]}
                                rowHeight={asin_row_height}
                            />
                        </Suspense>
                    </> : <>
                        {
                            ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                            <Suspense fallback={<Loader />}>
                                <DynamicGrid
                                    filterObj={{
                                        category_array: fs_category,
                                        campaign_type_array: fs_ad_type,
                                        campaign_id_array: fs_campaign_type
                                    }}
                                    dateFilter={dateRange[0]}
                                    graphDataType={graphDataType}
                                    tableType={tableType}
                                    changeTableType={changeTableType}
                                    selectRow={fs.fs_asin}
                                    selectFsName={"fs_asin"}
                                    group_by={"ad_asin"}
                                    fs={fs}
                                    setFs={setFs}
                                    depandency_array={[fs_category, fs_ad_type, fs_campaign_type]}
                                    height={400}
                                    monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                    rowHeight={asin_row_height}
                                />
                            </Suspense>
                        }
                    </>
                }
                <div className={`fg-container ${fs_asin.length > 0 ? "open" : ""}`}>
                    {
                        fs_asin.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                fs_asin,
                                fs_ad_type,
                                fs_target_type,
                                fs_keyword_type,
                                fs_placement,
                                fs_campaign_type,
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"ad_asin"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />
                    }
                </div>
            </div>

            {/* Search term table */}
            <div className="advertisingReportContainerRow_16">
                <span className="advertisingReportTableHeading">Top 500 Search-term wise performance</span>
                <div>
                    {
                        dataViewToggle.asinToggle ?
                            <Suspense fallback={<Loader />}>
                                <GridComponent
                                    filterObj={{
                                        category_array: fs_category,
                                        // asin_array: fs_asin,
                                        campaign_type_array: fs_ad_type,
                                        targeting_type_array: fs_target_type,
                                        campaign_id_array: fs_campaign_type,
                                        keyword_type_array: fs_keyword_type
                                    }}
                                    setFs={setFs}
                                    height={400}
                                    selectRow={fs.fs_keyword_targeting}
                                    selectFsName={"fs_keyword_targeting"}
                                    group_by={"keyword"}
                                    depandency_array={[fs_category, fs_ad_type, fs_target_type, fs_campaign_type, fs_keyword_type]}
                                    startingColumn={[{ headerName: 'Search-term', field: 'keyword', width: 250, pinned: true, cellClass: 'color-column' },
                                    ]}
                                // setDocCount={setDocCount2}
                                // currentPage={currentPage2}
                                // rowPerPage={rowPerPage2}
                                />
                            </Suspense>
                            : <>
                                {
                                    ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                                    <Suspense fallback={<Loader />}>
                                        <DynamicGrid
                                            filterObj={{
                                                category_array: fs_category,
                                                // asin_array: fs_asin,
                                                campaign_type_array: fs_ad_type,
                                                targeting_type_array: fs_target_type,
                                                campaign_id_array: fs_campaign_type,
                                                keyword_type_array: fs_keyword_type
                                            }}
                                            dateFilter={dateRange[0]}
                                            graphDataType={graphDataType}
                                            tableType={tableType}
                                            changeTableType={changeTableType}
                                            selectRow={fs.fs_keyword_targeting}
                                            selectFsName={"fs_keyword_targeting"}
                                            group_by={"keyword"}
                                            fs={fs}
                                            setFs={setFs}
                                            depandency_array={[fs_category, fs_ad_type, fs_target_type, fs_campaign_type, fs_keyword_type]}
                                            height={400}
                                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                                        // setDocCount={setDocCount2}
                                        // currentPage={currentPage2}
                                        // rowPerPage={rowPerPage2}
                                        />
                                    </Suspense>
                                }
                            </>
                    }
                </div>
                {/* <Pagination
                    docCount={docCount2}
                    currentPage={currentPage2}
                    setCurrentPage={setCurrentPage2}
                    rowPerPage={rowPerPage2}
                    setRowPerPage={setRowPerPage2}
                /> */}
                <div className={`fg-container ${fs_keyword_targeting.length > 0 ? "open" : ""}`}>
                    {
                        fs_keyword_targeting.length > 0 && ((dateRange.length > 0 && graphDataType === "daily") || monthYearArray || monthYearArrayWeek) &&
                        <LineGraphComponentFilter
                            clickedTile={clickedTile}
                            adType={adType}
                            graphDataType={graphDataType}
                            tgic={tgic}
                            dateFilter={dateRange[0]}
                            fs={fs}
                            depandency_array={[
                                fs_category,
                                fs_ad_type,
                                fs_target_type,
                                fs_keyword_type,
                                fs_placement,
                                fs_campaign_type,
                                fs_keyword_targeting
                            ]}
                            lineGraphErrorToggle={lineGraphErrorToggle}
                            group_by={"keyword"}
                            monthYearArray={graphDataType === 'weekly' ? monthYearArrayWeek : monthYearArray}
                        />
                    }
                </div>
            </div >


            {/* <div className="advertisingReportContainerRow_17"  >
                <div className="row_17_child" >
                    <PlacementGraph
                        adType={adType}
                    />
                </div>
                <div className="row_17_child" >
                    <AdTypeGraph
                        adType={adType}
                    />
                </div>
                <div className="row_17_child" >
                    <PlacementGraphMonthly
                        adType={adType}
                    />
                </div>
            </div> */}
        </div>
    )
}

export default AdvertisingReportContainer;